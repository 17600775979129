<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }
  <mat-select
    [multiple]="multiple"
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (selectionChange)="onSelectionChange()">
    <mat-select-trigger>
      @if ((control.value?.length || 0) === 1) {
        <span>
          {{control.value?.[0].Name || ''}}
        </span>
      }
      @if ((control.value?.length || 0) > 1 && (control.value?.length) == options.length) {
        <span>
          All
        </span>
      }
      @if ((control.value?.length || 0) > 1 && (control.value?.length) < options.length) {
        <span>
          Multiple
        </span>
      }
      @if ((control.value?.length || 0) > 1) {
        <span>
          ({{getSelectCount()}})
        </span>
      }
    </mat-select-trigger>

    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="search..."
        noEntriesFoundLabel="No Payers Found"
        [id]="'filter-' + controlName"
        [formControl]="filterControl"
        [showToggleAllCheckbox]="multiple"
        [toggleAllCheckboxChecked]="allToggled"
        [toggleAllCheckboxIndeterminate]="indeterminateToggle"
      (toggleAll)="allOptionClicked($event)"></ngx-mat-select-search>
    </mat-option>

    @for (option of filteredOptions | async; track option) {
      <mat-option
        #item
        [value]="option"
        [disabled]="option.PayerID > 0 && option.PayerID != currentPayerID"
        [matTooltip]="getOptionTooltip(option)"
        [matTooltipClass]="'field-tooltip'"
        [matTooltipPosition]="'left'"
        (click)="toggleSelection(item, option)">
        @if (option.PayerID == currentPayerID) {
          <span>
            <strong>{{option.Name}}</strong>
          </span>
        }
        @if (option.PayerID != currentPayerID) {
          <span>
            {{option.Name}}
          </span>
        }
      </mat-option>
    }
  </mat-select>

  @if (control.hasError('required')) {
    <mat-error>Selection is <strong>required</strong></mat-error>
  }
</mat-form-field>
