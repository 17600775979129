<div class="flex basis-[7%] flex-row">
    <div class="flex flex-auto justify-start items-center content-center">
        <h1>MOR Data</h1>
    </div>
</div>
<div class="flex flex-wrap">
    <div class="flex flex-row self-start">
        <div class="payerSelectContainer flex-none w-48">
            <app-payer-select
            [form]="form"
            [multiple]="false"
            [selectAllEnabled]="false"
            [required]="true"
            [queryOptions]="true"
            (valueChanged)="handlePayerSelected($event)"></app-payer-select>
        </div>
        <div class="flex-none" styel="width: fit-content;">
            <mat-icon
            class="material-icons-outlined"
            style="font-size: 15px; height: auto;"
            [matTooltip]="iconTooltip"
            [matTooltipClass]="'app-tooltip'">info</mat-icon>
        </div>
    </div>

    <div class="flex-auto">
        <app-file-upload-dropzone
        [multiple]="false"
        [dropzoneLabel]="dropzoneLabel"
        [browseButtonLabel]="browseButtonLabel"
        (filesDropped)="handleFileDropped($event)"
        (filesDeleted)="handleFileDeleted($event)"></app-file-upload-dropzone>
    </div>

    <div class="flex-auto self-start">
        <button 
        class="submitButton"
        mat-flat-button type="button" 
        [disabled]="!store.formValid()" 
        (click)="handleUploadSubmit()">Upload</button>
    </div>
</div>
