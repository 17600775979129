import { Subscriber } from '../Subscriber';
export function isEmpty() {
  return source => source.lift(new IsEmptyOperator());
}
class IsEmptyOperator {
  call(observer, source) {
    return source.subscribe(new IsEmptySubscriber(observer));
  }
}
class IsEmptySubscriber extends Subscriber {
  constructor(destination) {
    super(destination);
  }
  notifyComplete(isEmpty) {
    const destination = this.destination;
    destination.next(isEmpty);
    destination.complete();
  }
  _next(value) {
    this.notifyComplete(false);
  }
  _complete() {
    this.notifyComplete(true);
  }
}
