<div class="flex flex-row basis-[5%] pr-[40%]">
  <div class="flex flex-auto justify-start items-center content-center">
    <h2>{{reportMenuOption.Name}}</h2>
  </div>
  @if (reportMenuOption.Name == 'All') {
    <div class="flex flex-auto justify-end items-center content-center">
      <mat-slide-toggle
        [(ngModel)]="displayAll"
        [matTooltip]="displayAllTooltip"
        [matTooltipClass]="'app-tooltip'"
        [hideIcon]="true"
      (toggleChange)="onDisplayAllToggle()">Display All</mat-slide-toggle>
    </div>
  }
</div>

<div class="pr-[40%]">
  <div class="app-table-list flex flex-col">
    <div class="header-row flex flex-auto flex-row">
      <div class="flex basis-[5%]"></div>
      <div class="col-padding flex basis-[25%] justify-start items-center content-center">
        <h3>Category</h3>
      </div>
      <div class="col-padding flex flex-auto justify-start items-center content-center">
        <h3>Report Name</h3>
      </div>
    </div>

    @if (currentReportCount == 0) {
      <div class="row-item flex flex-auto flex-row">
        <div class="flex basis-[5%]"></div>
        <div class="col-padding flex flex-auto justify-start items-center content-center">
          <h3>No Viewable Reports. Contact Admin to grant permissions.</h3>
        </div>
      </div>
    }

    @for (item of displayedReports | async; track item) {
      <div class="row-item flex flex-auto flex-row" [ngClass]="!item.Active ? 'row-item-disabled' : ''">
        <div class="list-col-1 col-border-right flex basis-[5%] justify-center items-center content-center" (click)="onFavoriteClick(item)">
          @if (item.Favorited) {
            <mat-icon [color]="'primary'"
              [matTooltip]="'Deselect as Favorite'"
            [matTooltipClass]="'app-tooltip'">grade</mat-icon>
          }
          @if (!item.Favorited) {
            <mat-icon [color]="'primary'" fontSet="material-icons-outlined"
              [matTooltip]="'Set as Favorite'"
            [matTooltipClass]="'app-tooltip'">grade</mat-icon>
          }
        </div>
        <div class="list-col-2 col-padding col-border-right flex basis-[25%] justify-start items-center content-center">
          <h3
            [matTooltip]="item.Category.Description || ''"
          [matTooltipClass]="'app-tooltip'">{{item.Category.Name}}</h3>
        </div>
        <div class="list-col-3 col-padding flex flex-auto justify-start items-center content-center">
          <h3 (click)="reportClicked.emit(item)" style="cursor: pointer;">{{item.Name}}</h3>
        </div>
      </div>
    }
  </div>
</div>