import { formatDate, formatNumber } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgCartesianChartOptions, AgCartesianSeriesTooltipRendererParams, AgChartInstance, AgCharts } from 'ag-charts-community';
import { Subscription } from 'rxjs';
import { HccSummaryCalculations } from '../../../../../models/hcc-summary-calculations';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AuditAction } from '../../../../../models/audit-action';
import { AgBarSeriesLabelFormatterParams } from 'ag-grid-enterprise/dist/lib/ag-charts-community/options/agChartOptions';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatCard, MatCardHeader, MatCardContent } from '@angular/material/card';
import { MatTooltip } from '@angular/material/tooltip';
import { AgChartsAngularModule } from 'ag-charts-angular';

@Component({
    selector: 'app-diagnosis-events-summary-trend-graphs',
    templateUrl: './diagnosis-events-summary-trend-graphs.component.html',
    styleUrls: ['./diagnosis-events-summary-trend-graphs.component.scss'],
    standalone: true,
    imports: [MatSlideToggle, FormsModule, MatCard, MatCardHeader, MatTooltip, MatCardContent, AgChartsAngularModule]
})
export class DiagnosisEventsSummaryTrendGraphsComponent implements OnInit, OnDestroy {

  data: HccSummaryCalculations[] = [];
  nonActionableData = [];

  currentRefreshDate = '';

  dxEventOptions: AgCartesianChartOptions;
  claimSuppFeedOptions: AgCartesianChartOptions;
  nonActionableOptions: AgCartesianChartOptions;
  onRapsOptions: AgCartesianChartOptions;
  postedOnRapsOptions: AgCartesianChartOptions;

  parentDiv = document.getElementById('snav');
  chartsColor1 = '';
  chartsColor2 = '';
  chartsColor3 = '';
  chartsColor4 = '';
  chartsColor5 = '';
  accentColor = '';

  dxSwitch = false;
  claimSuppFeedPercentSwitch = false;
  nonActionablePercentSwitch = false;
  onRapsPercentSwitch = false;
  postedOnRapsPercentSwitch = false;

  dxEventTitle = {text: 'Documented Diagnosis Events', fontSize: 12, enabled: true};
  claimSuppFeedTitle = {text: 'Posted Via Claim or Supp Feed', fontSize: 12, enabled: true};
  nonActionableTitle = {text: 'NonActionable', fontSize: 12, enabled: true};
  onRapsTitle = {text: 'On RAPs/MAO or MOR', fontSize: 12, enabled: true};
  postedOnRapsTitle = {text: 'Posted and on RAPs/MAO or MOR', fontSize: 12, enabled: true};

  subs: Subscription[] = [];

  constructor(
    private caclulationsService: CalculationsService,
    private loggingService: LoggingService) {
    this.chartsColor1 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-1');
    this.chartsColor2 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-2');
    this.chartsColor3 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-3');
    this.chartsColor4 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-4');
    this.chartsColor5 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-5');
    this.accentColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-accent');
    this.setChartOptions();
  }

  ngOnInit(): void {
    this.subs.push(this.caclulationsService.hccSummaryCalculationsRange.subscribe(result => {
      if(!result) {
        return;
      }

      this.data = result;
      this.currentRefreshDate = this.data && this.data.length > 0 ? this.data[0].DateUpdated : '';
      this.setNonActionableData();
      this.setChartOptions();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  setNonActionableData() {
    this.nonActionableData = [];
    this.data.forEach(i => {
      const entry = {ServiceYear: i.ServiceYear};
      i.NonActionables.forEach(s => {
        entry[`${s.RootCauseID}HCCCount`] = s.HCCCount;
        entry[`${s.RootCauseID}HCCPercent`] = s.HCCPercent;
        entry[`${s.RootCauseID}DXCount`] = s.DXCount;
        entry[`${s.RootCauseID}DXPercent`] = s.DXPercent;
      });

      this.nonActionableData.push(entry);
    });
  }

  setChartOptions() {
    const prefix = this.dxSwitch ? 'Dx' : 'HCC';
    const claimsSuffix = this.claimSuppFeedPercentSwitch ? 'Percent' : '';
    const nonActionableSuffix = this.nonActionablePercentSwitch ? 'Percent' : 'Count';
    const onRapsSuffix = this.onRapsPercentSwitch ? 'Percent' : '';
    const postedOnRapsSuffix = this.postedOnRapsPercentSwitch ? 'Percent' : '';
    const nonActionPrefix = this.dxSwitch ? 'DX' : 'HCC';
    const dateRefreshed = `Data Refreshed ${this.currentRefreshDate ? formatDate(this.currentRefreshDate, 'MM/dd/YYYY', 'en') : 'N/A'}`;

    this.dxEventOptions = {
      autoSize: true,
      title: this.dxEventTitle,
      data: this.data,
      series: [
        {
          type: 'line',
          xKey: 'ServiceYear',
          yKey: `${prefix}DocumentedDiagnosisEvents`,
          yName: 'Documented Dx Events',
          stroke: this.chartsColor1,
          marker: {
            fill: this.accentColor,
            stroke: this.chartsColor1
          },
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          }
        },
      ],
      axes: [
        {
          type: 'number',
          position: 'bottom',
          title: {
            text: ''
          },
          tick: {
            interval: 1
          }
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: ''
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter(params.value).replace('0y', '0')
          },
          min: 0
        }
      ],
      theme: {
        overrides: {
          bar: {
            series: {
              highlightStyle: {
                item: {
                  fill: this.accentColor,
                  stroke: this.chartsColor1,
                  strokeWidth: 3
                }
              },
            }
          }
        }
      },
      legend: {
        enabled: false
      },
      footnote: {
        text: dateRefreshed,
        fontStyle: 'italic'
      },
    };

    this.claimSuppFeedOptions = {
      autoSize: true,
      title: this.claimSuppFeedTitle,
      data: this.data,
      series: [
        {
          type: 'bar',
          fill: this.chartsColor1,
          stroke: this.chartsColor1,
          xKey: 'ServiceYear',
          yKey: `${prefix}PostedViaClaimOrSuppFeed${claimsSuffix}`,
          yName: 'Posted Via Claim or Supp Feed',
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          },
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, claimsSuffix == 'Percent'),
            placement: 'outside'
          },
        },
        {
          type: 'bar',
          fill: this.chartsColor2,
          stroke: this.chartsColor2,
          xKey: 'ServiceYear',
          yKey: `${prefix}PostedOnClaim${claimsSuffix}`,
          yName: 'Posted on Claim',
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          },
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, claimsSuffix == 'Percent'),
            placement: 'outside'
          },
        },
        {
          type: 'bar',
          fill: this.chartsColor3,
          stroke: this.chartsColor3,
          xKey: 'ServiceYear',
          yKey: `${prefix}PostedOnSuppFeed${claimsSuffix}`,
          yName: 'Posted on Supp Feed',
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          },
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, claimsSuffix == 'Percent'),
            placement: 'outside'
          },
        }
      ],
      theme: {
        overrides: {
          bar: {
            series: {
              highlightStyle: {
                item: {
                  fill: this.accentColor,
                  stroke: this.chartsColor1,
                  strokeWidth: 3
                }
              }
            },
            axes: {
              category: {
                groupPaddingInner: 0.2
              }
            }
          }
        }
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: {
            text: ''
          }
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: ''
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter(params.value).replace('0y', '0')
          },
          min: 0
        }
      ],
      legend: {
        position: 'bottom'
      },
      footnote: {
        text: dateRefreshed,
        fontStyle: 'italic'
      },
    };

    const nonActionableSeries = [];

    if(this.data.length) {

      this.data[0].NonActionables.forEach((i, index) => {
        let color = this.chartsColor1;
        const indexCheck = index % 5;

        switch(indexCheck) {
          case 1:
            color = this.chartsColor2;
            break;
          case 2:
            color = this.chartsColor3;
            break;
          case 3:
            color = this.chartsColor4;
            break;
          case 4:
            color = this.chartsColor5;
            break;
          default:
            break;
        }

        nonActionableSeries.push(
        {
          type: 'bar',
          fill: color,
          stroke: color,
          xKey: 'ServiceYear',
          yKey: `${i.RootCauseID}${nonActionPrefix}${nonActionableSuffix}`,
          yName: `${i.RootCauseDescription}`,
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          },
          stacked: true
        });
      });
    }

    this.nonActionableOptions = {
      autoSize: true,
      title: this.nonActionableTitle,
      data: this.nonActionableData,
      series: nonActionableSeries,
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: {
            text: ''
          }
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: ''
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter(params.value).replace('0y', '0')
          },
          min: 0
        }
      ],
      theme: {
        overrides: {
          bar: {
            series: {
              highlightStyle: {
                item: {
                  fill: this.accentColor,
                  stroke: this.chartsColor1,
                  strokeWidth: 3
                }
              }
            },
            axes: {
              category: {
                groupPaddingInner: 0.2
              }
            }
          }
        }
      },
      legend: {
        position: 'bottom'
      },
      footnote: {
        text: dateRefreshed,
        fontStyle: 'italic'
      },
    };

    this.onRapsOptions = {
      autoSize: true,
      title: this.onRapsTitle,
      data: this.data,
      series: [
        {
          type: 'bar',
          fill: this.chartsColor1,
          stroke: this.chartsColor1,
          xKey: 'ServiceYear',
          yKey: `${prefix}OnRapsOrMOR${onRapsSuffix}`,
          yName: 'On RAPs/MAO or MOR',
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, onRapsSuffix == 'Percent'),
            placement: 'outside'
          },
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          }
        },
        {
          type: 'bar',
          fill: this.chartsColor2,
          stroke: this.chartsColor2,
          xKey: 'ServiceYear',
          yKey: `${prefix}OnRAPS${onRapsSuffix}`,
          yName: 'RAPs/MAO',
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, onRapsSuffix == 'Percent'),
            placement: 'outside'
          },
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          }
        },
        {
          type: 'bar',
          fill: this.chartsColor3,
          stroke: this.chartsColor3,
          xKey: 'ServiceYear',
          yKey: `${prefix}OnMOR${onRapsSuffix}`,
          yName: 'MOR',
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, onRapsSuffix == 'Percent'),
            placement: 'outside'
          },
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          }
        }
      ],
      theme: {
        overrides: {
          bar: {
            series: {
              highlightStyle: {
                item: {
                  fill: this.accentColor,
                  stroke: this.chartsColor1,
                  strokeWidth: 3
                }
              }
            },
            axes: {
              category: {
                groupPaddingInner: 0.2
              }
            }
          }
        }
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: {
            text: ''
          }
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: ''
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter(params.value).replace('0y', '0')
          },
          min: 0
        }
      ],
      legend: {
        position: 'bottom'
      },
      footnote: {
        text: dateRefreshed,
        fontStyle: 'italic'
      },
    };

    this.postedOnRapsOptions = {
      autoSize: true,
      title: this.postedOnRapsTitle,
      data: this.data,
      series: [
        {
          type: 'bar',
          fill: this.chartsColor1,
          stroke: this.chartsColor1,
          xKey: 'ServiceYear',
          yKey: `${prefix}PostedAndOnRapsOrMOR${postedOnRapsSuffix}`,
          yName: 'Posted and on RAPs/MAO',
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, postedOnRapsSuffix == 'Percent'),
            placement: 'outside'
          },
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          }
        },
        {
          type: 'bar',
          fill: this.chartsColor2,
          stroke: this.chartsColor2,
          xKey: 'ServiceYear',
          yKey: `${prefix}PostedNotOnRapsOrMOR${postedOnRapsSuffix}`,
          yName: 'Posted: Not on RAPs/MAO or MOR',
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, postedOnRapsSuffix == 'Percent'),
            placement: 'outside'
          },
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          }
        },
        {
          type: 'bar',
          fill: this.chartsColor3,
          stroke: this.chartsColor3,
          xKey: 'ServiceYear',
          yKey: `${prefix}NotPostedButOnRapsOrMOR${postedOnRapsSuffix}`,
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, postedOnRapsSuffix == 'Percent'),
            placement: 'outside'
          },
          yName: 'Not Posted: On RAPs/MAO or MOR',
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          }
        }
      ],
      theme: {
        overrides: {
          bar: {
            series: {
              highlightStyle: {
                item: {
                  fill: this.accentColor,
                  stroke: this.chartsColor1,
                  strokeWidth: 3
                }
              }
            },
            axes: {
              category: {
                groupPaddingInner: 0.2
              }
            }
          }
        }
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: {
            text: ''
          }
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: ''
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter(params.value).replace('0y', '0')
          },
          min: 0
        }
      ],
      legend: {
        position: 'bottom'
      },
      footnote: {
        text: dateRefreshed,
        fontStyle: 'italic'
      },
    };
  }

  onDownload(instance: AgChartInstance) {
    const startYear = this.data.length > 0 ? this.data[0].ServiceYear : '_';
    const endYear = this.data.length > 1 ? this.data[this.data.length - 1].ServiceYear : '_';
    const instanceName = instance.getOptions().title.text;
    const filename = `${startYear}-${endYear}_${instanceName}`;

    AgCharts.download(instance, {
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: 'DX Events Trend Graphs', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: DX Events Trend Graphs, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });

  }

  valueFormatFn(params: any, isPercent: boolean) {
    const formatter = Intl.NumberFormat('en', {notation: 'compact'});
    return `${formatter.format(params.datum[params.yKey])}${isPercent ? '%' : ''}`;
  }

}
