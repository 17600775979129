import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { HccSummaryCalculations } from '../../../../../models/hcc-summary-calculations';
import { Subscription } from 'rxjs';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { formatDate, formatNumber } from '@angular/common';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AuditAction } from '../../../../../models/audit-action';
import { RootCauseSummaryCalculation } from '../../../../../models/root-cause-summary-calculation';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

export class DxEventsSummaryReportRowItem {
  ServiceYear: number;
  PropertyName: string;
  DisplayName: string;
  HCCs: string;
  HCCPercent: string;
  DX: string;
  DXPercent: string;
  Parent: string;
}

@Component({
    selector: 'app-diagnosis-events-summary-report',
    templateUrl: './diagnosis-events-summary-report.component.html',
    styleUrls: ['./diagnosis-events-summary-report.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class DiagnosisEventsSummaryReportComponent implements OnInit, OnDestroy {

  @Input() serviceYear = 2022;

  data: HccSummaryCalculations = new HccSummaryCalculations();
  rowItems: DxEventsSummaryReportRowItem[] = [];

  dxEventsRowName = 'DocumentedDiagnosisEvents';
  row2Name = 'PostedViaClaimOrSuppFeed';
  row2Sub1Name = 'PostedOnClaim';
  row2Sub2Name = 'PostedOnSuppFeed';
  row3Name = 'NonActionable';
  row3SubName = "NonActionableSub";
  row3SubBottomName = 'NonActionableSubBottom';
  row4Name = 'OnRapsOrMOR';
  row4Sub1Name = 'OnRAPS';
  row4Sub2Name = 'OnMOR';
  row5Name = 'PostedAndOnRapsOrMOR';
  row5Sub1Name = 'PostedNotOnRapsOrMOR';
  row5Sub2Name = 'NotPostedButOnRapsOrMOR';

  columnDefs: (ColDef | ColGroupDef)[] = [];

  defaultColDef: ColDef = {
    menuTabs: [],
    minWidth: 100,
    autoHeight: true
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  excelStyles = DxsrExcelStyling.excelStyles;

  gridContext: any;

  subs: Subscription[] = [];

  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
  }

  constructor(
    private calculationsService: CalculationsService,
    private loggingService: LoggingService
  ) { 
    this.gridContext = { componentParent: this };
  }

  ngOnInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  setRowItems() {
    this.rowItems = [];

    this.addNewRowItem(this.data, '', 'DocumentedDiagnosisEvents', 'Documented Dx Events', 'HCC', 'Dx');
    this.addNewRowItem(this.data, 'Posted Via Claim or Supp Feed', 'PostedViaClaimOrSuppFeed', 'Posted Via Claim or Supp Feed', 'HCC', 'Dx');
    this.addNewRowItem(this.data, 'Posted Via Claim or Supp Feed', 'PostedOnClaim', 'Billing Charge Posted on Claim', 'HCC', 'Dx');
    this.addNewRowItem(this.data, 'Posted Via Claim or Supp Feed', 'PostedOnSuppFeed', 'Billing Charge Posted on Supp Feed', 'HCC', 'Dx');

    this.addNewRowItem(this.data, 'Non Actionable', 'NonActionable', 'Non Actionable', 'HCC', 'Dx');
    this.data.NonActionables.forEach((i, index) => {
      const propName = index == this.data.NonActionables.length - 1 ? this.row3SubBottomName : this.row3SubName;
      this.addNonActionableRowItem(i, 'Non Actionable', propName, i.RootCauseDescription);
    });

    this.addNewRowItem(this.data, 'On RAPs/MAO or MOR', 'OnRapsOrMOR', 'On RAPs/MAO or MOR', 'HCC', 'Dx');
    this.addNewRowItem(this.data, 'On RAPs/MAO or MOR', 'OnRAPS', 'RAPs/MAO', 'HCC', 'Dx');
    this.addNewRowItem(this.data, 'On RAPs/MAO or MOR', 'OnMOR', 'MOR', 'HCC', 'Dx');

    this.addNewRowItem(this.data, 'Posted and on RAPs/MAO or MOR', 'PostedAndOnRapsOrMOR', 'Posted and on RAPs/MAO or MOR', 'HCC', 'Dx');
    this.addNewRowItem(this.data, 'Posted and on RAPs/MAO or MOR', 'PostedNotOnRapsOrMOR', 'Posted: Not on RAPs/MAO or MOR', 'HCC', 'Dx');
    this.addNewRowItem(this.data, 'Posted and on RAPs/MAO or MOR', 'NotPostedButOnRapsOrMOR', 'Not Posted: On RAPs/MAO or MOR', 'HCC', 'Dx');
  }

  addNewRowItem(dataItem: HccSummaryCalculations, parentName: string, propertyName: string, displayName: string, hccPrefix:string, dxPrefix: string) {
    const rowItem = new DxEventsSummaryReportRowItem();
    rowItem.ServiceYear = dataItem.ServiceYear;
    rowItem.PropertyName = propertyName;
    rowItem.DisplayName = displayName;
    rowItem.HCCs = dataItem[hccPrefix + propertyName];
    rowItem.HCCPercent = dataItem[hccPrefix + propertyName + 'Percent'];
    rowItem.DX = dataItem[dxPrefix + propertyName];
    rowItem.DXPercent = dataItem[dxPrefix + propertyName + 'Percent'];
    rowItem.Parent = parentName;

    this.rowItems.push(rowItem);
  }

  addNonActionableRowItem(item: RootCauseSummaryCalculation, parentName: string, propertyName: string, displayName: string) {
    const rowItem = new DxEventsSummaryReportRowItem();
    rowItem.ServiceYear = item.ServiceYear;
    rowItem.PropertyName = propertyName;
    rowItem.DisplayName = displayName;
    rowItem.HCCs = item.HCCCount.toString();
    rowItem.HCCPercent = item.HCCPercent.toString();
    rowItem.DX = item.DXCount.toString();
    rowItem.DXPercent = item.DXPercent.toString();
    rowItem.Parent = parentName;

    this.rowItems.push(rowItem);
  }

  onGridReady(params) {
    this.updateReport(this.serviceYear);
    
    this.subs.push(this.calculationsService.hccSummaryCalculations.subscribe(result => {
      this.data = result;
      this.setRowItems();
      this.agGrid.api.setGridOption('rowData', this.rowItems);
      this.agGrid.api.setGridOption('columnDefs', this.getColDef(this.serviceYear, this.data.DateUpdated));
      this.agGrid.api.sizeColumnsToFit();
      this.agGrid.api.hideOverlay();
      this.setExportEnable.emit(true);
    }));
  }

  updateReport(serviceYear: number) {
    this.serviceYear = serviceYear;
    this.calculationsService.getHccSummaryCalculations(serviceYear);
    this.agGrid.api.showLoadingOverlay();
  }

  onExport() {
    const filename = `DX Events Summary_${formatDate(Date.now(), 'MM-dd-yyyy', 'en')}`;

    this.agGrid.api.exportDataAsExcel({
      processCellCallback: (params) => {
        if (params.column.getColDef().field === 'HCCPercent' || params.column.getColDef().field === 'DXPercent') {
          return params.value ? params.value / 100 : 0;
        }
        return params.value;
      },
      fileName: filename,
      sheetName: 'DX Events Summary'
    });

    const reportJSON = JSON.stringify({ReportName: 'DX Events Summary', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: DX Events Summary, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }

  getColDef(serviceYear: number, refreshDate: string): (ColDef | ColGroupDef)[] {
    return [
      //{ field: 'Parent', rowGroup: true, hide: true },
      { field: 'DisplayName', headerName: `Service Year ${serviceYear} - Data Refreshed ${refreshDate}`,
        headerTooltip: `Service Year ${this.serviceYear} - Data Refreshed ${this.data.DateUpdated}`,
        tooltipField: 'DisplayName',
        minWidth: 280,
        cellClassRules: {
          'dxsr-display-name-1': (params) => {
            return params.data.PropertyName === this.dxEventsRowName;
          },
          'dxsr-display-name-2': (params) => {
            return  params.data.PropertyName === this.row2Name ||
                    params.data.PropertyName === this.row3Name ||
                    params.data.PropertyName === this.row4Name ||
                    params.data.PropertyName === this.row5Name;
          },
          'dxsr-display-name-3': (params) => {
            return  params.data.PropertyName === this.row2Sub2Name ||
                    params.data.PropertyName === this.row3SubBottomName ||
                    params.data.PropertyName === this.row4Sub2Name;
          },
          'dxsr-display-name-4': (params) => {
            return  params.data.PropertyName === this.row2Sub1Name ||
                    params.data.PropertyName === this.row3SubName ||
                    params.data.PropertyName === this.row4Sub1Name ||
                    params.data.PropertyName === this.row5Sub1Name;
          },
          'dxsr-display-name-5': (params) => {
            return params.data.PropertyName === this.row5Sub2Name;
          }
        }
      },
      { field: 'HCCs', headerName: 'HCCs', type: 'numericColumn', 
        valueFormatter: this.getNumberValue,
        cellClassRules: {
          'dxsr-values-1': (params) => {
            return params.data.PropertyName === this.dxEventsRowName;
          },
          'dxsr-values-2': (params) => {
            return  params.data.PropertyName === this.row2Name ||
                    params.data.PropertyName === this.row3Name ||
                    params.data.PropertyName === this.row4Name ||
                    params.data.PropertyName === this.row5Name;
          },
          'dxsr-values-3': (params) => {
            return  params.data.PropertyName === this.row2Sub2Name ||
                    params.data.PropertyName === this.row3SubBottomName ||
                    params.data.PropertyName === this.row4Sub2Name;
          },
          'dxsr-values-4': (params) => {
            return  params.data.PropertyName === this.row2Sub1Name ||
                    params.data.PropertyName === this.row3SubName ||
                    params.data.PropertyName === this.row4Sub1Name ||
                    params.data.PropertyName === this.row5Sub1Name;
          },
          'dxsr-values-5': (params) => {
            return params.data.PropertyName === this.row5Sub2Name;
          }
        }
      },
      { field: 'HCCPercent', headerName: 'HCC %', type: 'numericColumn', 
        valueFormatter: this.getPercentValue,
        cellClassRules: {
          'dxsr-percent-1': (params) => {
            return params.data.PropertyName === this.dxEventsRowName;
          },
          'dxsr-percent-2': (params) => {
            return  params.data.PropertyName === this.row2Name ||
                    params.data.PropertyName === this.row3Name ||
                    params.data.PropertyName === this.row4Name ||
                    params.data.PropertyName === this.row5Name;
          },
          'dxsr-percent-3': (params) => {
            return  params.data.PropertyName === this.row2Sub2Name ||
                    params.data.PropertyName === this.row3SubBottomName ||
                    params.data.PropertyName === this.row4Sub2Name;
          },
          'dxsr-percent-4': (params) => {
            return  params.data.PropertyName === this.row2Sub1Name ||
                    params.data.PropertyName === this.row3SubName ||
                    params.data.PropertyName === this.row4Sub1Name ||
                    params.data.PropertyName === this.row5Sub1Name;
          },
          'dxsr-percent-5': (params) => {
            return params.data.PropertyName === this.row5Sub2Name;
          }
        }
      },
      { field: 'DX', headerName: 'DX', type: 'numericColumn', 
        valueFormatter: this.getNumberValue,
        cellClassRules: {
          'dxsr-values-1': (params) => {
            return params.data.PropertyName === this.dxEventsRowName;
          },
          'dxsr-values-2': (params) => {
            return  params.data.PropertyName === this.row2Name ||
                    params.data.PropertyName === this.row3Name ||
                    params.data.PropertyName === this.row4Name ||
                    params.data.PropertyName === this.row5Name;
          },
          'dxsr-values-3': (params) => {
            return  params.data.PropertyName === this.row2Sub2Name ||
                    params.data.PropertyName === this.row3SubBottomName ||
                    params.data.PropertyName === this.row4Sub2Name;
          },
          'dxsr-values-4': (params) => {
            return  params.data.PropertyName === this.row2Sub1Name ||
                    params.data.PropertyName === this.row3SubName ||
                    params.data.PropertyName === this.row4Sub1Name ||
                    params.data.PropertyName === this.row5Sub1Name;
          },
          'dxsr-values-5': (params) => {
            return params.data.PropertyName === this.row5Sub2Name;
          }
        }
      },
      { field: 'DXPercent', headerName: 'DX %', type: 'numericColumn', 
        valueFormatter: this.getPercentValue,
        cellClassRules: {
          'dxsr-percent-1': (params) => {
            return params.data.PropertyName === this.dxEventsRowName;
          },
          'dxsr-percent-2': (params) => {
            return  params.data.PropertyName === this.row2Name ||
                    params.data.PropertyName === this.row3Name ||
                    params.data.PropertyName === this.row4Name ||
                    params.data.PropertyName === this.row5Name;
          },
          'dxsr-percent-3': (params) => {
            return  params.data.PropertyName === this.row2Sub2Name ||
                    params.data.PropertyName === this.row3SubBottomName ||
                    params.data.PropertyName === this.row4Sub2Name;
          },
          'dxsr-percent-4': (params) => {
            return  params.data.PropertyName === this.row2Sub1Name ||
                    params.data.PropertyName === this.row3SubName ||
                    params.data.PropertyName === this.row4Sub1Name ||
                    params.data.PropertyName === this.row5Sub1Name;
          },
          'dxsr-percent-5': (params) => {
            return params.data.PropertyName === this.row5Sub2Name;
          }
        }
      }
    ];
  }

  getNumberValue(item: any) {
    if(!item.value) {
      return '0';
    }

    return formatNumber(item.value, 'en').toString();
  }

  getPercentValue(item: any) {
    if(!item.value) {
      return '0%';
    }

    return item.value + "%";
  }

}
