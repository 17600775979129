<h1 mat-dialog-title>Risk Adjustment Policy Service Year</h1>

<div mat-dialog-content>
  <div class="content-container flex flex-col">
    <div class="grid grid-cols-2 gap-3">
      <div class="col-start-1">
        <h3>Service Year Start Date *</h3>
      </div>

      <div class="col-start-2">
        <mat-form-field color="primary" appearance="outline">
          <input matInput [matDatepicker]="startPicker" [formControl]="serviceYearStartControl" (dateChange)="onStartDateChange($event)" required>
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
          @if (serviceYearStartControl.hasError('required')) {
            <mat-error>Date is <strong>required</strong></mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-start-1">
        <h3>Service Year End Date *</h3>
      </div>

      <div class="col-start-2">
        <mat-form-field color="primary" appearance="outline">
          <input matInput [matDatepicker]="endPicker" [min]="serviceYearStartControl.value" [formControl]="serviceYearEndControl" required>
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
          @if (serviceYearEndControl.hasError('required')) {
            <mat-error>Date is <strong>required</strong></mat-error>
          }
          @if (serviceYearEndControl.hasError('matDatepickerMin')) {
            <mat-error>Date must be greater than start date</mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-start-1">
        <h3>Risk Ajdustment Policy? *</h3>
      </div>

      <div class="col-start-2">
        <app-boolean-select
          #riskPolicySelect
          [form]="form"
          [controlName]="hasRiskAdjustmentControlName"
          [defaultValue]="'Yes'"
          [required]="true"
          (ready)="isRiskAdjustmentReady = true; fieldsReadyCheck()"
        (valueChanged)="onRiskPolicyChanged($event)"></app-boolean-select>
      </div>

      <div class="col-start-1">
        <h3>Override Policy To Manual Omit?</h3>
      </div>

      <div class="col-start-2">
        <app-boolean-select
          #manualOmitSelect
          [form]="form"
          [controlName]="hasManualOmitControlName"
          [defaultValue]="'No'"
          [disabled]="form.get(hasRiskAdjustmentControlName)?.value == 'No'"
          [required]="true"
          (ready)="isManualOmitReady = true; fieldsReadyCheck()"
        (valueChanged)="onManualOmitChanged($event)"></app-boolean-select>
      </div>

      <div class="col-start-1">
        <h3>Preferred Action Submission Type @if (form.get(hasRiskAdjustmentControlName)?.value == 'Yes') {
          <span>*</span>
        }</h3>
      </div>

      <div class="col-start-2">
        <app-preferred-action-select
          #riskPolicyPaSelect
          [form]="form"
          [controlName]="preferredActionControlName"
          [label]="''"
          [placeholder]="'Make a Selection'"
          [onlyShowOptions]="['99499', 'Supplemental Feed']"
          [multiple]="false"
          [required]="form.get(hasRiskAdjustmentControlName)?.value == 'Yes' && form.get(hasManualOmitControlName)?.value == 'No'"
          [selectAllEnabled]="false"
          [disabled]="form.get(hasRiskAdjustmentControlName)?.value == 'No' || form.get(hasManualOmitControlName)?.value == 'Yes'"
          (ready)="isPreferredActionReady = true; fieldsReadyCheck();"
        (valueChanged)="onPreferredActionChanged($event)"></app-preferred-action-select>
      </div>

      <div class="col-start-1" style="padding-left: 30px;">
        <h3>Supp Feed Export Template</h3>
      </div>

      <div class="col-start-2">
        <app-filter-field-search-select
          #sfTemplate
          [form]="form"
          [controlName]="suppFeedTemplateControlName"
        [noneEnabled]="true"></app-filter-field-search-select>
      </div>

      <div class="col-start-1">
        <h3>Payer Validation Export Template</h3>
      </div>

      <div class="col-start-2">
        <app-filter-field-search-select
          #pvTemplate
          [form]="form"
          [controlName]="payerValidationTemplateControlName"
        [noneEnabled]="true"></app-filter-field-search-select>
      </div>

      <div class="col-start-1 col-span-2">
        <mat-form-field appearance="outline" color="primary">
          <mat-label>Notes</mat-label>
          <textarea matInput [formControl]="notesControl" [rows]="3"></textarea>
        </mat-form-field>
      </div>
    </div>

  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-flat-button class="secondary-button" mat-dialog-close>Cancel</button>
  @if (data.mode == 'edit') {
    <button
      mat-flat-button
      class="primary-button"
      [disabled]="form.invalid"
      (click)="onSaveClick()">
      Save
    </button>
  }

  @if (data.mode == 'add') {
    <button
      mat-flat-button
      class="primary-button"
      [disabled]="form.invalid"
      (click)="onSaveClick()">
      Add
    </button>
  }
</mat-dialog-actions>
