import {
  getState,
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from "@ngrx/signals";
import { PayerItem } from "../models/payer-item";
import { computed, inject } from "@angular/core";
import { UtcDateTimeFormatter } from "../helpers/utc-datetime-formatter";
import { MorDataService } from "../services/mor/mor-data.service";
import { MorDataTableValuesRequest } from "../models/mor-data-table-values-request";
import { MorDataTableRow } from "../components/sub-components/mor-data/mor-data-table/mor-data-table.component";
import { MorDataTableItem } from "../models/mor-data-table-item";
import { MorFileDetailsResult } from "../models/mor-file-details-result";
import { take } from 'rxjs/operators';
import { MorDataViewDetailsHelperModel } from "../dialogs/mor-data-view-details-dialog/mor-data-view-details-dialog.component";
import { ColDef } from "ag-grid-community";
import { ViewProcessedRecordsGridConfig } from "../dialogs/mor-data-view-details-dialog/view-processed-records-dialog/view-processed-records-dialog.component";
import { MorFileProcessedDataRequest } from "../models/mor-file-processed-data-request";
import { QueryMetadata } from "../models/query-metadata";
import { MorFileErrorResult, MorFileErrorResultDataRow, MorFileErrorResults } from "../models/mor-file-error-result";
import { ProcessedMorRecordsResult } from "../models/processed-mor-records-result";

type MorDataState = {
  selectedPayers: PayerItem[];
  localThresholdDate: Date | null;
  tableData: MorDataTableRow[];
  viewRecordsColumns: ColDef[] | null;
  viewRecordsData: object[] | null;
};

const initialState: MorDataState = {
  selectedPayers: [],
  localThresholdDate: null,
  tableData: [],
  viewRecordsColumns: null,
  viewRecordsData: [],
};

const setPayersHelper = (store, payers?: PayerItem[]): void => {
  if (payers) {
    patchState(store, { selectedPayers: payers });
  }
};

const morDbItemsToTableDataHelper = (results: MorDataTableItem[]) => {
  const rows: MorDataTableRow[] = [];
  for (const item of results) {
    const utcDate = new Date(item.UploadDate + 'Z')
    const row: MorDataTableRow = {
      id: item.ID,
      uploadDate: utcDate,
      payerAlias: item.PayerAlias,
      uploadedBy: item.FirstName.charAt(0) + item.LastName,
      fileName: item.FileName,
      status: item.Status || 'Processing'
    };
    rows.push(row);
  }
  return rows;
}

const getSelectedPayerIdsString = (state: MorDataState) => {
    let payerIds: string = "";
    for (const payer of state.selectedPayers) {
      if (payerIds == "") {
          payerIds += `${payer.ID}`;
      } else {
          payerIds += `,${payer.ID}`;
      }
    }
    return payerIds;
}

const generateColumnDefs = (records: object[]): ColDef[] => {
  if (records.length === 0) {
    return [];
  }

  return Object.keys(records[0]).map((key) => ({
    field: key,
    headerName: key.charAt(0).toUpperCase() + key.slice(1),
    sortable: false
  } as ColDef)).filter(obj => obj.field != 'id');
}

const getErrorRecordColumnDefs = (): ColDef[] => {
  return [
    {
      field: 'exceptionTypeDescription',
      headerName: 'Error Type',
      sortable: true,
      flex: 1
    },
    {
      field: 'exceptionMessage',
      headerName: 'Error Description',
      sortable: true,
      flex: 1
    },
    {
      field: 'morData',
      headerName: 'MOR Record',
      sortable: true,
      flex: 3
    }
  ];
}

export const MorDataStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((state) => ({
    formValid: computed(() => {
      return state.selectedPayers().length > 0;
    }),
  })),
  withMethods(
    (
      store,
      utcHelper = inject(UtcDateTimeFormatter),
      service = inject(MorDataService)
    ) => ({
      setSelectedPayers(payers: PayerItem[]) {
        setPayersHelper(store, payers);
      },
      setThresholdDate(date: Date | null) {
        patchState(store, { localThresholdDate: date });
      },
      getSelectedFileDetails(row: MorDataTableRow | null): Promise<MorFileDetailsResult | null> {
        return new Promise((resolve, reject) => {
          if (row) {
            service.getMORFileDetails(row.id).pipe(take(1)).subscribe({
              next: (results) => {
                resolve({
                  id: row.id,
                  payerAlias: row.payerAlias,
                  fileName: row.fileName,
                  uploadDate: row.uploadDate,
                  uploadedBy: row.uploadedBy,
                  fileStatus: row.status,
                  recordTypeCounts: results && results[0] ? results[0].RecordTypeCounts ?? [] : [],
                });
              },
              error: (err) => reject(err),
            });
          } else {
            resolve(null);
          }
        });
      },
      getTableData() {
        const state = getState(store);
        const query: MorDataTableValuesRequest = {
          payerIds: getSelectedPayerIdsString(state),
          thresholdDateString: state.localThresholdDate
            ? utcHelper.getFormattedUTCString(state.localThresholdDate)
            : "",
        };
        service
          .getMORDataTableItems(query)
          .subscribe((results?: MorDataTableItem[]) => {
            if (results) {
              const rows = morDbItemsToTableDataHelper(results);
              patchState(store, { tableData: rows });
            }
          });
      },
      getProcessedRecordData(request: MorFileProcessedDataRequest): Promise<ViewProcessedRecordsGridConfig | null> {
        return new Promise((resolve, reject) => {
          if (request) {
            service.getProcessedRecordDataByMorQueueID(request).pipe(take(1)).subscribe({
              next: (result: ProcessedMorRecordsResult) => {
                const columnDefs = generateColumnDefs(result.RecordData);
                resolve({
                  columnDefs: columnDefs,
                  rowData: result.RecordData,
                  listSize: result.ListSize,
                } as ViewProcessedRecordsGridConfig);
              },
              error: (err) => reject(err),
            });
          } else {
            resolve(null);
          }
        });
      },
      getErrorRecordData(request: MorFileProcessedDataRequest): Promise<ViewProcessedRecordsGridConfig | null> {
        return new Promise((resolve, reject) => {
          if (request) {
            service.getErrorRecordDataByMorQueueID(request).pipe(take(1)).subscribe({
              next: (response: MorFileErrorResults) => {
                const dataSet: MorFileErrorResultDataRow[] = [];
                for (let i = 0; i < response.Results.length; i++) {
                  const dataRow: MorFileErrorResultDataRow = {
                    id: i + 1,
                    exceptionTypeDescription: response.Results[i].ExceptionTypeDescription,
                    exceptionMessage: response.Results[i].ExceptionMessage,
                    morData: response.Results[i].MorData
                  };
                  dataSet.push(dataRow);
                }
                resolve({
                  columnDefs: getErrorRecordColumnDefs(),
                  rowData: dataSet,
                  listSize: response.ListSize,
                } as ViewProcessedRecordsGridConfig);
              },
              error: (err) => reject(err),
            });
          } else {
            resolve(null);
          }
        });
      }
    })
  )
);