import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { ColDef, DataTypeDefinition, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, StatusPanelDef } from 'ag-grid-enterprise';
import { Subscription } from 'rxjs';
import { AgNoRowsOverlayComponent } from '../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgPaginatorComponent, IAgPaginator } from '../../ag-grid-components/ag-paginator/ag-paginator.component';
import { SettingsKeys, SettingsService } from '../../../services/settings/settings.service';
import { AgGridFunctions } from '../../../helpers/ag-grid-functions';
import { Clipboard } from '@angular/cdk/clipboard';
import { TemplateConfigItem } from '../../../models/template-config-item';
import { MatDialog } from '@angular/material/dialog';
import { AgIconRendererComponent } from '../../ag-grid-components/ag-icon-renderer/ag-icon-renderer.component';
import { TemplateService } from '../../../services/settings/template.service';
import { AddUpdateTemplateConfigDialogComponent, AddUpdateTemplateConfigDialogModel } from '../../../dialogs/add-update-template-config-dialog/add-update-template-config-dialog.component';
import { FieldFilterService } from '../../../services/filters/field-filter.service';
import { FilterSelectComponent } from '../../field-components/filter-select/filter-select.component';
import { BooleanSelectComponent } from '../../field-components/static/boolean-select/boolean-select.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgLoadingOverlayComponent } from '../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-template-config',
    templateUrl: './template-config.component.html',
    styleUrls: ['./template-config.component.scss'],
    standalone: true,
    imports: [MatButton, MatTooltip, FilterSelectComponent, BooleanSelectComponent, AgGridModule]
})
export class TemplateConfigComponent implements OnInit, OnDestroy {

  frameworkComps = {
    iconRenderer: AgIconRendererComponent
  }

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgPaginatorComponent,
        key: 'ag-paginator',
        statusPanelParams: {
          isClientSide: true
        }
      }
    ],
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };
  
  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [

    { field: 'Name', headerName: 'Name', filter: 'agTextColumnFilter', tooltipField: 'Name' },
    { field: 'TypeName', headerName: 'Type', filter: false, tooltipField: 'Type' },
    { field: 'Payers', headerName: 'Payer Assoc.', filter: 'agTextColumnFilter', tooltipField: 'Payers' },
    { field: 'Created', headerName: 'Create Date', filter: false },
    { 
      field: 'CreatedBy', headerName: 'Created By',
      valueFormatter: AgGridFunctions.userItemValue, 
      valueParser: AgGridFunctions.userItemValue,
      tooltipValueGetter: AgGridFunctions.assingedUserTooltip,
      filter: 'agSetColumnFilter',
      filterParams: {
        keyCreator: params => {
          return params.data.FirstName + ' ' + params.data.LastName
        },
        valueFormatter: AgGridFunctions.userItemValue,
      }
    },
    { field: 'LastModified', headerName: 'Modified Date', filter: false },
    { 
      field: 'ModifiedBy', headerName: 'Modified By',
      valueFormatter: AgGridFunctions.userItemValue, 
      valueParser: AgGridFunctions.userItemValue,
      tooltipValueGetter: AgGridFunctions.assingedUserTooltip,
      filter: 'agSetColumnFilter',
      filterParams: {
        keyCreator: params => {
          return params.data.FirstName + ' ' + params.data.LastName
        },
        valueFormatter: AgGridFunctions.userItemValue,
      }
    },
    { 
      field: 'Enabled', headerName: 'Active',
      cellDataType: false,
      valueFormatter: AgGridFunctions.booleanValue, 
      valueParser: AgGridFunctions.booleanValue, 
      filterValueGetter: AgGridFunctions.booleanValue,
      filter: false
    },

    { field: 'Notes', headerName: 'Internal Notes', filter: false, tooltipField: 'Notes' },

    { colId: 'edit', minWidth: 48, maxWidth: 48, headerName: '', suppressColumnsToolPanel: true,
      tooltipValueGetter: params => {return 'Edit'},
      cellRenderer: 'iconRenderer',
      cellRendererParams: {
        icon: 'edit'
      },
      onCellClicked: this.onEditClicked,
      sortable: false,
      filter: false,
      pinned: 'right'
    },
    { colId: 'duplicate', minWidth: 48, maxWidth: 48, headerName: '', suppressColumnsToolPanel: true,
      tooltipValueGetter: params => {return 'Duplicate'},
      cellRenderer: 'iconRenderer',
      cellRendererParams: {
        icon: 'content_copy',
        // disabledObject: {
        //   field: 'Name',
        //   value: '[Duplicate]'
        // }
      },
      onCellClicked: this.onDuplicateClicked,
      sortable: false,
      filter: false,
      pinned: 'right'
    },
    { colId: 'delete', minWidth: 48, maxWidth: 48, headerName: '', suppressColumnsToolPanel: true,
      tooltipValueGetter: params => {return 'Delete'},
      cellRenderer: 'iconRenderer',
      cellRendererParams: {
        icon: 'delete'
      },
      onCellClicked: this.onDeleteClicked,
      sortable: false,
      filter: false,
      pinned: 'right'
    },
  ];

  defaultColDef: ColDef = {
    sortable: true,
    floatingFilter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  gridContext: any;
  currentListSize = 0;
  isFirstGenerate = true;
  editAllActive = true;

  form: FormGroup;
  activeFilterControlName = 'templateActiveFilter';

  items: TemplateConfigItem[] = [];

  subs: Subscription[] = [];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @ViewChild(AgGridAngular) agGrid2!: AgGridAngular;
  @ViewChild(FilterSelectComponent) typeSelect: FilterSelectComponent;
  @ViewChild(BooleanSelectComponent) activeSelect: BooleanSelectComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
  }

  constructor(
    private settingsService: SettingsService,
    private templateService: TemplateService,
    private filterService: FieldFilterService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private clipboard: Clipboard,
    private snackbar: MatSnackBar
  ) { 
    this.gridContext = { componentParent: this };
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    this.filterService.getTemplateConfigTypes();
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onTypeFilterReady() {
    this.subs.push(this.filterService.templateConfigTypes.subscribe(result => {
      this.typeSelect?.setOptions(result);

      //delay for the option list to update
      setTimeout(() => {
        this.typeSelect?.setValues([]);
        this.onGenerateGridData();
      }, 200);
    }));
  }

  onGridReady() {
    this.settingsService.getPaginatorPageSize(SettingsKeys.TEMPLATE_CONFIG_PAGE_SIZE).subscribe(result => {
      if(result == 0) {
        return;
      }

      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
    });
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.ID;
  };

  onGridGetContextMenu(params: GetContextMenuItemsParams) {

    if(params.column.getColId() == 'edit') {
      return [];
    }

    const result = [
      {
        name: 'Copy',
        action: () => {
          const value = params.context.componentParent.getColumnValue(params.column.getColId(), params.value);
          params.context.componentParent.clipboard.copy(value);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>'
      }
    ];

    return result;
  }

  onCellKeyDown(event) {
    const kbEvent:KeyboardEvent = event.event;
    if (kbEvent.ctrlKey && kbEvent.key === "c") {
      const value = AgGridFunctions.getColumnValue(event.column.getColId(), event.value);
      this.clipboard.copy(value);
    }
  }

  onGenerateGridData() {
    const types = this.typeSelect?.getValues().map(i => i.ID).toString();
    const statusValues = this.activeSelect?.getSelectedValues() || "0,1";

    this.templateService.getTemplateConfigItems(types, statusValues).subscribe(result => {
      this.items = result;
      this.agGrid.api.sizeColumnsToFit();
    });
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.TEMPLATE_CONFIG_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }

  onCreateClick() {
    const data = {actionType: 'add', item: null}
    const dialogRef = this.dialog.open(AddUpdateTemplateConfigDialogComponent, new AddUpdateTemplateConfigDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onGenerateGridData();
      }
    }));
  }

  onEditClicked(params) {
    const data = {actionType: 'edit', item: params.data}
    const dialogRef = params.context.componentParent.dialog.open(AddUpdateTemplateConfigDialogComponent, new AddUpdateTemplateConfigDialogModel(data));

    params.context.componentParent.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        params.context.componentParent.onGenerateGridData();
      }
    }));
  }

  onDuplicateClicked(params) {
    const parent = params.context.componentParent;

    parent.templateService.duplicateTemplate(params.data.ID).subscribe(() => {
      parent.snackbar.open("Template Duplicated", null, {duration: 3000});
      parent.onGenerateGridData();
    });
  }

  onDeleteClicked(params) {
    const parent = params.context.componentParent;
    const message = 'Deleting a template will remove the template and any associations to a risk policy. Once deleted, the template cannot be restored.';
    const additionalMessages = ['Do you want to continue to delete?'];
    const title = 'Delete Warning';

    const dialogRef = parent.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: new ConfirmDialogModel(title, message, '', additionalMessages)
    });

    parent.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        parent.deleteTemplate(params);
      }
    }));
  }

  deleteTemplate(params) {
    const parent = params.context.componentParent;

    parent.templateService.deleteTemplate(params.data.ID).subscribe(() => {
      parent.snackbar.open("Template Deleted", null, {duration: 3000});
      parent.onGenerateGridData();
    });
  }

}