import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { APIService } from "../../../services/api/api.service";
import { User } from "../../../models/users";
import { Organization } from '../../../models/organization.model';
import { Subscription } from "rxjs";
import { OrganizationService } from "../../../services/organization/organization.service";
import { HuntProDataset } from "../../../../labeler-shared/models/dataset.model";
import { HccSummaryConfigItem } from "../../../models/hcc-summary-config-item";
import { UserService } from "../../../services/user/user.service";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { CalculationsService } from "../../../services/calculations/calculations.service";
import { SettingsService } from "../../../services/settings/settings.service";
import { ReportNames } from "../../../models/report-names";
import { PermissionMap, PermissionService } from "../../../services/settings/permission.service";
import { Permission } from "../../../models/permission";
import { CMSTimelineComponent } from "../../sub-components/cms-timeline/cms-timeline.component";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { HccSummaryInfoComponent } from "../../sub-components/hcc-summary-info/hcc-summary-info.component";
import { OpportunitySubmissionSummaryComponent } from "../../sub-components/opportunity-submission-summary/opportunity-submission-summary.component";
import { ValidationSummaryComponent } from "../../sub-components/validation-summary/validation-summary.component";
import { MatCard, MatCardHeader, MatCardContent } from "@angular/material/card";
import { MatTooltip } from "@angular/material/tooltip";
import { ProgressAllocationUserComponent } from "../../sub-components/progress-allocation-user/progress-allocation-user.component";
import { ProgressAllocationPreferredActionComponent } from "../../sub-components/progress-allocation-preferred-action/progress-allocation-preferred-action.component";
import { ProgressAllocationPayerComponent } from "../../sub-components/progress-allocation-payer/progress-allocation-payer.component";
import { DocumentedDxEventGraphComponent } from "../../sub-components/documented-dx-event-graph/documented-dx-event-graph.component";
import { DatePipe } from "@angular/common";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
    standalone: true,
    imports: [MatButton, MatMenuTrigger, MatMenu, MatMenuItem, MatCheckbox, FormsModule, HccSummaryInfoComponent, OpportunitySubmissionSummaryComponent, ValidationSummaryComponent, MatCard, MatCardHeader, RouterLink, MatTooltip, MatCardContent, ProgressAllocationUserComponent, ProgressAllocationPreferredActionComponent, ProgressAllocationPayerComponent, CMSTimelineComponent, DocumentedDxEventGraphComponent, DatePipe]
})
export class HomeComponent implements OnInit, OnDestroy {
  config: Organization;
  isAdmin: boolean;
  isSystemAdmin: boolean;
  isReadOnly: boolean;
  isLimitedUser = false;
  isAtFolderLimit: boolean;
  folderLimit: number;
  subscriptions: Subscription[] = [];
  public datasets: HuntProDataset[] = [];

  insightsConfig: HccSummaryConfigItem[] = [];
  initialInsightsConfig: HccSummaryConfigItem[] = [];
  configLoaded = false;
  summaryActive = true;
  opportunityActive = true;
  validationActive = true;
  progressUserActive = true;
  progressPreferredActionActive = true;
  progressPayorActive = true;
  cmsTimelineActive = true;
  documentedDxChartActive = true;
  hccPatientTrendActive = true;
  serviceYear = -1;
  reportingEnabled = false;

  lastDataRefreshDate = null;

  userPermissions: Permission[] = [];

  SUBMISSION_OPPTY_USER_ALLOCATION_NAME = ReportNames.SUBMISSION_OPPTY_USER_ALLOCATION_NAME;
  SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME = ReportNames.SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME;
  SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME = ReportNames.SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME;

  @ViewChild('configMenuTrigger') configTrigger: MatMenuTrigger;
  @ViewChild(CMSTimelineComponent) cmsTimeline: CMSTimelineComponent;

  constructor(
    private api: APIService,
    private userService: UserService,
    public router: Router,
    private orgService: OrganizationService,
    private calculationsService: CalculationsService,
    private settingsService: SettingsService,
    private permissionService: PermissionService
  ) {
    this.config = null;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.isAdmin = false;
    this.isSystemAdmin = false;
    this.isReadOnly = true;

    this.userService.getInsightsConfig();

    this.api.getCurrentUser().subscribe((data: User) => {
      const user = data;
      if (user.app_metadata.role == 'readonlyuser') {
        this.isReadOnly = true;
      }
      else {
        this.isReadOnly = false;
      }

      if (user.app_metadata.role == 'admin') {
        this.isAdmin = true;
      }
      else {
        this.isAdmin = false;
      }

      if (user.app_metadata.systemAdmin == "true") {
        this.isSystemAdmin = true;
      }
      else {
        this.isSystemAdmin = false;
      }

      this.isLimitedUser = user.limitedUser;

    }, error => console.log(error));



    this.subscriptions.push(this.orgService.organization$.subscribe(response => {
      if (response) {


        this.config = response;
      }

    }));

    this.subscriptions.push(this.userService.insightsConfig$.subscribe(result => {
      this.insightsConfig = result;
      this.initialInsightsConfig = JSON.parse(JSON.stringify(result));
      this.setComponentVisibility();
    }));

    this.subscriptions.push(this.permissionService.activeUserPermissions.subscribe(result => {
      this.userPermissions = result;
      this.permissionsCheck();
    }));

    this.subscriptions.push(this.settingsService.serviceYear.subscribe(result => {
      if(result > -1) {
        this.serviceYear = result;
        this.calculationsService.getHccSummaryCalculations(this.serviceYear);
        this.cmsTimeline?.getData(result);
      }
    }));

    this.subscriptions.push(this.calculationsService.lastRefreshDate.subscribe(result => {
      this.lastDataRefreshDate = result;
    }));

  }

  getDataSetName() {
   return "test";
  }
  getDataSetID() {
   return 1
  }

  isFolderLimitMet(): boolean {
   
      return false;
    
  }

  setComponentVisibility() {
    this.insightsConfig.forEach(item => {
      switch(item.Name) {
        case "Dx Events Summary":
          this.summaryActive = item.Activated;
          break;
        case "Opportunity":
          this.opportunityActive = item.Activated;
          break;

        case "Validation":
          this.validationActive = item.Activated;
          break;

        case "Progress: User":
          this.progressUserActive = item.Activated;
          break;

        case "Progress: Preferred Action":
          this.progressPreferredActionActive = item.Activated;
          break;

        case "Progress: Payer":
          this.progressPayorActive = item.Activated;
          break;

        case "CMS Schedule":
          this.cmsTimelineActive = item.Activated;
          break;

        case "DX Trend Chart":
          this.documentedDxChartActive = item.Activated;
          break;

        case "HCC Patient Trend":
          this.hccPatientTrendActive = item.Activated;
          break;
          
        default:
          break;
      }
    });

    this.configLoaded = true;
  }

  onConfigMenuCancel() {
    this.insightsConfig = JSON.parse(JSON.stringify(this.initialInsightsConfig));
  }

  onConfigMenuSave() {
    this.userService.updateHccSummaryConfig(this.insightsConfig).then(() => {
      this.setComponentVisibility();
      this.configTrigger.closeMenu();
    });
  }

  permissionsCheck() {
    this.userPermissions.forEach(p => {
      if(p.PermissionID == PermissionMap.GenerateReportsOpportunities) {
        this.reportingEnabled = true;
      }
    })
  }

}
