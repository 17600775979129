import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatSelectSearchComponent, NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user/user.service';
import { PermissionMap, PermissionService } from '../../../services/settings/permission.service';
import { Permission } from '../../../models/permission';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { AsyncPipe } from '@angular/common';

interface UserOption {
  user: User;
  selected: boolean;
}

@Component({
    selector: 'app-user-search-by-permission',
    templateUrl: './user-search-by-permission.component.html',
    styleUrls: ['./user-search-by-permission.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatTooltip, MatSelectTrigger, MatOption, NgxMatSelectSearchModule, MatError, AsyncPipe]
})
export class UserSearchByPermissionComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() multiple = true;
  @Input() controlName = 'user-search';
  @Input() label = 'Search User';
  @Input() placeholder = '';
  @Input() searchLabel = 'Search';
  @Input() toolTipPosition = 'above';
  @Input() required = false;
  @Input() queryOptions = false;
  @Input() unassignedEnabled = true;

  userOptions: UserOption[] = [];
  filteredOptions: ReplaySubject<UserOption[]> = new ReplaySubject<UserOption[]>(1);
  control = new FormControl(null);
  filterControl = new FormControl<string>('');
  allToggled = false;
  indeterminateToggle = false;
  displayOptionLengthFeatureSubtract = 0;

  viewEditPermActive = false;
  acceptsAssignPermActive = false;
  assignPermActive = false;

  unassignedOption = {user: {user_id: -1, given_name: 'Unassigned', family_name: ''}, selected: false};

  currentUser: User;
  userPermissions: Permission[];
  subs: Subscription[] = [];

  protected _onDestroy = new Subject<void>();

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;
  @ViewChild(MatSelectSearchComponent) searchFilter: MatSelectSearchComponent;

  constructor(
    private userService: UserService,
    private permissionService: PermissionService) { 
    //TODO
  }

  ngOnInit(): void {
    this.displayOptionLengthFeatureSubtract = this.unassignedEnabled ? 1 : 0;
    
    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }

    this.subs.push(this.userService.currentUser$.subscribe(result => {
      this.currentUser = result;
    }));

    this.subs.push(this.permissionService.activeUserPermissions.subscribe(result => {
      this.userPermissions = result;
      this.viewEditPermActive = this.userPermissions.find(p => p.PermissionID == PermissionMap.ViewAndEditAllDxEvents) ? true : false;
      this.acceptsAssignPermActive = this.userPermissions.find(p => p.PermissionID == PermissionMap.AcceptsOpportunityAssignment) ? true : false;
      this.assignPermActive = this.userPermissions.find(p => p.PermissionID == PermissionMap.AssignOpportunities) ? true : false;

      this.subs.push(this.userService.usersByPermission.get(PermissionMap.AcceptsOpportunityAssignment).subscribe(result => {
        if(!result) {
          if(this.queryOptions) {
            this.getOptionData();
          }
          return;
        }
  
        this.userOptions = [];
  
        if(this.viewEditPermActive || this.assignPermActive) {

          result.forEach(u => {
            this.userOptions.push({user: u, selected: false});
          });

          this.setSelectedValues(null);

        } else if(this.acceptsAssignPermActive) {

          const foundUser = result.find(u => u.user_id == this.currentUser.uid);

          if(foundUser) {
            this.userOptions.push({user: foundUser, selected: true});
            this.setSelectedValues([foundUser]);
            this.control.disable();
          }
        }
  
        this.filteredOptions.next(this.userOptions);
        this.ready.emit(this.controlName);
      }));
    }));

    this.filterControl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterMulti();

      if(this.multiple) {
        this.setAllToggleState();
      }
    });

    if(this.multiple) {
      this.control.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.setAllToggleState();
      });
    }
  }

  ngAfterViewInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getOptionData() {
    this.userService.getUserListByPermission(PermissionMap.AcceptsOpportunityAssignment);
  }

  setSelectedValues(values: User[]) {

    if(!values) {
      this.control?.reset();
      this.control?.setValue([]);
      return;
    }

    if(values.length == 0) {
      this.allOptionClicked(true);
      return;
    }

    const options = [];
    values.forEach(u => {
      options.push({user: u, selected: true});
    });

    options.forEach(o => {
      const found = this.select.options.find((i, index) => {
        if(index == 0) {
          return;
        }

        return i.value.user.user_id == o.user.user_id;
      });

      if(found) {
        found.select();
      }
    });
  }

  getValues() {

    if(this.allToggled && this.multiple && this.viewEditPermActive) {
      return [{user_id: 0}];
    }
    
    return this.control?.value ? this.control?.value.map(i => i.user) : [];
  }

  getSingleModeValue() {
    if(this.allToggled && this.multiple) {
      return {user_id: 0};
    }

    return this.control?.value;
  }

  displayTooltip() {
    let result = '';
    const users = this.control?.value;

    users.forEach((u, index) => {
      if(index == 0 && u.ID == -1) {
        result += u.Description + ', ';
        return;
      }

      result += u.user.given_name + ' ' + u.user.family_name;

      if(index < users.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  allOptionClicked(isChecked: boolean) {

    this.select.options.forEach((o, index) => {
      if(index == 0) {
        return;
      }

      isChecked ? o.select() : o.deselect();
    });

    this.userOptions.forEach(o => {
      o.selected = isChecked;
    });

    if(this.viewEditPermActive) {
      this.unassignedOption.selected = isChecked;
    }

  }

  toggleSelection(option: MatOption, userOption: UserOption) {
    userOption.selected = option.selected;
  }

  checkAllOptions() {
    let result = true;
    this.userOptions.forEach(o => {
      if(!o.selected) {
        result = false;
      }
    });

    return result;
  }

  onSelectionChange(selected: UserOption[]) {
    const values = selected.map(i => i.user);
    this.valueChanged.emit(values);
  }

  getSelectCount() {
    return this.control.value?.length;
  }

  filterMulti() {
    if (!this.userOptions) {
      return;
    }

    let search = this.filterControl?.value;

    if (!search) {
      this.filteredOptions.next(this.userOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredOptions.next(
      this.userOptions.filter(o => (o.user.given_name.toLowerCase() + ' ' + o.user.family_name).indexOf(search) > -1)
    );
  }

  setAllToggleState() {
    let filteredLength = 0;
    const listSize = this.unassignedEnabled ? this.userOptions.length + 1 : this.userOptions.length;
    if(this.control?.value) {
      this.userOptions.forEach(o => {
        if(this.control?.value.indexOf(o) > -1) {
          filteredLength++;
        }
      });

      if((this.unassignedEnabled && (this.viewEditPermActive || this.assignPermActive)) && this.control?.value.indexOf(this.unassignedOption) > -1) {
        filteredLength++;
      }

      this.indeterminateToggle = filteredLength > 0 && filteredLength < listSize;
      this.allToggled = filteredLength > 0 && filteredLength === listSize;
    }
  }

}
