import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { RootCauseSelectComponent } from '../../components/field-components/root-cause-select/root-cause-select.component';
import { StatusSelectComponent } from '../../components/field-components/status-select/status-select.component';
import { DiagnosisEventNote } from '../../models/diagnosis-event-note';
import { FilterSelectItem } from '../../models/filter-select-item';
import { DiagnosisEventItem } from '../../models/diagnosis-event-item';
import { DiagnosisEventService } from '../../services/diagnosis-event/diagnosis-event.service';
import { DiagnosisEventEditRequest } from '../../models/diagnosis-event-edit-request';
import { DiagnosisEventQuery } from '../../models/diagnosis-event-query';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { DecimalPipe, DatePipe } from '@angular/common';

type EditOpptyDialogMode = 'single' | 'batch';

export class EditOpptyDialogData {
  items: DiagnosisEventItem[];
  statusStateIDs: number[];
  parent: string;
  mode: EditOpptyDialogMode = 'single';
  allEnabled: boolean;
  queryResultCount: number;
  query: DiagnosisEventQuery;
  exportEnabled = false;
}

export class EditOpptyDialogModel {
  readonly component: ComponentType<any> = EditOpptyDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  width?: string = '35vw';
  data?: any;
  disableClose? = true;

  constructor(data: EditOpptyDialogData) {
    this.data = data;
  }
}

@Component({
    selector: 'app-edit-oppty-dialog',
    templateUrl: './edit-oppty-dialog.component.html',
    styleUrls: ['./edit-oppty-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatRadioGroup, FormsModule, ReactiveFormsModule, MatRadioButton, StatusSelectComponent, RootCauseSelectComponent, MatFormField, MatInput, MatLabel, MatDialogActions, MatButton, MatDialogClose, DecimalPipe, DatePipe]
})
export class EditOpptyDialogComponent implements OnInit, AfterViewInit {

  editDialogForm: FormGroup;
  statusControlName = 'edit-oppty-status';
  rootCauseControlName = 'edit-oppty-root-cause';
  rcReasonControlName = 'edit-oppty-rc-reason';
  notesControlName = 'edit-oppty-notes';
  batchTypeControlName = 'assign-mode';

  batchTypeControl = new FormControl('default', [Validators.required]);

  rcReasonControl = new FormControl('', [Validators.required]);
  notesControl = new FormControl('');

  singleItem: DiagnosisEventItem;
  notesHistory: DiagnosisEventNote[] = [];

  formReady = false;
  showRcReason = false;

  showStatusWarning = false;
  hasClosedItems = false;

  batchLimit = 300;
  batchStatusStats = '';
  batchPreferredActionStats = '';

  @ViewChild('editStatusSelect') statusComp: StatusSelectComponent;
  @ViewChild('editRootCauseSelect') rootCauseComp: RootCauseSelectComponent;

  constructor
  (
    public dialogRef: MatDialogRef<EditOpptyDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: EditOpptyDialogData,
    private opptyService: DiagnosisEventService,
    private fb: FormBuilder
  ) 
  { 
    this.editDialogForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.editDialogForm.addControl(this.notesControlName, this.notesControl);

    if(this.data.mode == 'single') {
      this.singleItem = this.data.items[0];
      this.opptyService.getNotesByEventID(this.singleItem.ID).subscribe(result => {
        this.notesHistory = result;
      });
    }

    if(this.data.mode == 'batch') {
      this.hasClosedItems = this.data.items.find(i => i.StatusState.ID == 2) ? true : false;
      this.getBatchStats();
    }

    if(this.data.mode == 'batch' && this.data.allEnabled) {
      this.editDialogForm.addControl(this.batchTypeControlName, this.batchTypeControl);
    }
  }

  ngAfterViewInit(): void {
    this.statusComp.getOptionData(this.data.statusStateIDs);
  }

  onStatusReady(filterOptions: FilterSelectItem[]) {
    const ids = filterOptions.map(o => o.ID);

    if(this.data.mode == 'single') {
      this.statusComp.setSingleModeValue(this.singleItem.Status);
    }

    this.rootCauseComp.getOptionData(ids);
  }

  onRootCauseReady() {
    if(this.data.mode == 'single' && this.singleItem.RootCause && this.singleItem.RootCause.ID) {
      this.rootCauseComp.setSingleModeValue(this.singleItem.RootCause);
      this.onRootCauseChanged(this.singleItem.RootCause);
    }
    this.formReady = true;
  }

  onStatusChanged(item: FilterSelectItem) {
    if(!item) {
      return;
    }

    this.rootCauseComp.control.reset();
    this.showStatusWarning = false;
    this.rootCauseComp.setValues([], [item.ID]);
    
    if(item.LinkAssociations.includes(1)) {
      if(this.data.mode == 'single' && this.singleItem.StatusState.ID == 2) {
        this.showStatusWarning = true;
      }

      if(this.data.mode == 'batch' && this.hasClosedItems) {
        this.showStatusWarning = true;
      }
    }
  }

  onRootCauseChanged(item: FilterSelectItem) {

    if(!item && this.editDialogForm.get(this.rcReasonControlName)) {
      this.showRcReason = false;
      this.editDialogForm.removeControl(this.rcReasonControlName);
    }

    if(!item || item.Description != 'Other') {
      this.showRcReason = false;
      this.editDialogForm.removeControl(this.rcReasonControlName);
      return;
    }

    this.editDialogForm.addControl(this.rcReasonControlName, this.rcReasonControl);
    this.showRcReason = true;
  }

  onSaveClick(hasExport?: boolean) {
    
    const statusValue = this.statusComp.control.value;
    const rcValue = this.rootCauseComp.control.value;

    const request = new DiagnosisEventEditRequest();
    request.BatchCount = 1;

    if(this.data.mode == 'single') {
      request.EventIDs = this.singleItem.ID.toString();
      request.VisitID = this.singleItem.VisitID;
      request.DXCode = this.singleItem.ICDCODE;
    }

    if(this.data.mode == 'batch') {
      request.EventIDs = this.data.items ? this.data.items.map(i => i.ID).toString() : '';
      request.BatchCount = this.data.allEnabled ? this.data.queryResultCount : (this.data.items?.length || 0);
    }

    request.StatusID = statusValue ? parseInt(statusValue.ID) : 0;
    request.RootCauseID = rcValue ? parseInt(rcValue.ID) : -1;
    request.RootCauseReason = this.rcReasonControl.value || null;
    request.Note = this.notesControl.value || null;
    request.Query = this.batchTypeControl?.value == 'default' ? null : this.data.query;

    this.opptyService.updateEditListItem(request).subscribe(result => {
      const value = hasExport && this.data.mode == 'batch' ? 'export' : result;
      this.dialogRef.close(value);
    });

  }

  getBatchStats() {
    let statusStatsMap = new Map<string, number>();
    let paStatsMap = new Map<string, number>();

    this.data.items.forEach(i => {
      if(i.Status && i.Status.ID) {
        const currentStatsValue = statusStatsMap.get(i.Status.Description);
        const updateValue = currentStatsValue ? currentStatsValue + 1 : 1;
        statusStatsMap.set(i.Status.Description, updateValue);
      }

      if(i.PreferredAction && i.PreferredAction.ID) {
        const currentPaValue = paStatsMap.get(i.PreferredAction.Description);
        const updatePaValue = currentPaValue ? currentPaValue + 1 : 1;
        paStatsMap.set(i.PreferredAction.Description, updatePaValue);
      }
    });

    statusStatsMap = new Map([...statusStatsMap.entries()].sort((a, b) => a[1] - b[1]));
    paStatsMap = new Map([...paStatsMap.entries()].sort((a, b) => a[1] - b[1]));

    statusStatsMap.forEach((v, k) => {
      this.batchStatusStats += ` ${k} (${v}),`;
    });

    paStatsMap.forEach((v, k) => {
      this.batchPreferredActionStats += ` ${k} (${v}),`;
    });

    if(this.batchStatusStats.endsWith(',')) {
      this.batchStatusStats = this.batchStatusStats.substring(0, this.batchStatusStats.length - 1);
    }

    if(this.batchPreferredActionStats.endsWith(',')) {
      this.batchPreferredActionStats = this.batchPreferredActionStats.substring(0, this.batchPreferredActionStats.length - 1);
    }
  }

}
