import { from } from '../observable/from';
import { isArray } from '../util/isArray';
import { SimpleOuterSubscriber, SimpleInnerSubscriber, innerSubscribe } from '../innerSubscribe';
export function onErrorResumeNext(...nextSources) {
  if (nextSources.length === 1 && isArray(nextSources[0])) {
    nextSources = nextSources[0];
  }
  return source => source.lift(new OnErrorResumeNextOperator(nextSources));
}
export function onErrorResumeNextStatic(...nextSources) {
  let source = undefined;
  if (nextSources.length === 1 && isArray(nextSources[0])) {
    nextSources = nextSources[0];
  }
  source = nextSources.shift();
  return from(source).lift(new OnErrorResumeNextOperator(nextSources));
}
class OnErrorResumeNextOperator {
  constructor(nextSources) {
    this.nextSources = nextSources;
  }
  call(subscriber, source) {
    return source.subscribe(new OnErrorResumeNextSubscriber(subscriber, this.nextSources));
  }
}
class OnErrorResumeNextSubscriber extends SimpleOuterSubscriber {
  constructor(destination, nextSources) {
    super(destination);
    this.destination = destination;
    this.nextSources = nextSources;
  }
  notifyError() {
    this.subscribeToNextSource();
  }
  notifyComplete() {
    this.subscribeToNextSource();
  }
  _error(err) {
    this.subscribeToNextSource();
    this.unsubscribe();
  }
  _complete() {
    this.subscribeToNextSource();
    this.unsubscribe();
  }
  subscribeToNextSource() {
    const next = this.nextSources.shift();
    if (!!next) {
      const innerSubscriber = new SimpleInnerSubscriber(this);
      const destination = this.destination;
      destination.add(innerSubscriber);
      const innerSubscription = innerSubscribe(next, innerSubscriber);
      if (innerSubscription !== innerSubscriber) {
        destination.add(innerSubscription);
      }
    } else {
      this.destination.complete();
    }
  }
}
