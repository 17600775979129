<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }
  <mat-select
    [multiple]="multiple"
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (selectionChange)="onSelectionChange()">
    @if (multiple) {
      <mat-select-trigger>
        @if ((control.value?.length || 0) === 1) {
          <span>
            {{control.value?.[0][labelField] || ''}}
          </span>
        }
        @if ((control.value?.length || 0) > 1 && (control.value?.length) == options.length) {
          <span>
            All
          </span>
        }
        @if ((control.value?.length || 0) > 1 && (control.value?.length) < options.length) {
          <span>
            Multiple
          </span>
        }
        @if ((control.value?.length || 0) > 1) {
          <span>
            ({{getSelectCount()}})
          </span>
        }
      </mat-select-trigger>
    }

    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="search..."
        noEntriesFoundLabel="No Payers Found"
        [id]="'filter-' + controlName"
        [formControl]="filterControl"
        [showToggleAllCheckbox]="multiple"
        [toggleAllCheckboxChecked]="allToggled"
        [toggleAllCheckboxIndeterminate]="indeterminateToggle"
      (toggleAll)="allOptionClicked($event)"></ngx-mat-select-search>
    </mat-option>

    @for (option of filteredOptions | async; track option) {
      <mat-option
        #item
        [value]="option"
        [matTooltip]="option[labelField]"
        [matTooltipClass]="'field-tooltip'"
        [matTooltipPosition]="'left'">
        {{option[labelField]}}
      </mat-option>
    }

    @if (noneEnabled) {
      <mat-option
        #noneItem
      [value]="noneOption">None</mat-option>
    }
  </mat-select>
</mat-form-field>
