<div>
    <div class="flex basis-full justify-end items-start content-start">
        <mat-slide-toggle color="accent" [(ngModel)]="dxSwitch" [hideIcon]="true" (change)="setChartOptions()">
            <span>DX View</span>
        </mat-slide-toggle>
    </div>

    <div class="flex flex-row flex-wrap gap-0">

        <mat-card appearance="outlined" class="vc-card vc-card-charts mat-elevation-z5 w-[650px]">
            <mat-card-header>
              <div class="title-container flex flex-row">
                <h3 class="flex basis-[80%]">{{graphTitle.text}}</h3>
                <div class="flex flex-auto justify-end items-center content-center">
                    <!--<mat-slide-toggle style="padding-right: 15px;" [(ngModel)]="percentSwitch" (change)="setChartOptions()">View %</mat-slide-toggle>-->
                    <span class="material-symbols-outlined download-icon"
                    [matTooltip]="'Download'"
                    [matTooltipClass]="'app-tooltip'"
                    [matTooltipPosition]="'above'"
                    (click)="onDownload(chart.chart)">download</span>
                </div>
              </div>
            </mat-card-header>
      
            <mat-card-content>
      
                <ag-charts-angular
                #chart
                class="h-full"
                [options]="graphOptions"></ag-charts-angular>
              
            </mat-card-content>
        </mat-card>
    </div>
</div>