import { Subscriber } from '../Subscriber';
export function find(predicate, thisArg) {
  if (typeof predicate !== 'function') {
    throw new TypeError('predicate is not a function');
  }
  return source => source.lift(new FindValueOperator(predicate, source, false, thisArg));
}
export class FindValueOperator {
  constructor(predicate, source, yieldIndex, thisArg) {
    this.predicate = predicate;
    this.source = source;
    this.yieldIndex = yieldIndex;
    this.thisArg = thisArg;
  }
  call(observer, source) {
    return source.subscribe(new FindValueSubscriber(observer, this.predicate, this.source, this.yieldIndex, this.thisArg));
  }
}
export class FindValueSubscriber extends Subscriber {
  constructor(destination, predicate, source, yieldIndex, thisArg) {
    super(destination);
    this.predicate = predicate;
    this.source = source;
    this.yieldIndex = yieldIndex;
    this.thisArg = thisArg;
    this.index = 0;
  }
  notifyComplete(value) {
    const destination = this.destination;
    destination.next(value);
    destination.complete();
    this.unsubscribe();
  }
  _next(value) {
    const {
      predicate,
      thisArg
    } = this;
    const index = this.index++;
    try {
      const result = predicate.call(thisArg || this, value, index, this.source);
      if (result) {
        this.notifyComplete(this.yieldIndex ? index : value);
      }
    } catch (err) {
      this.destination.error(err);
    }
  }
  _complete() {
    this.notifyComplete(this.yieldIndex ? -1 : undefined);
  }
}
