<div class="">

  <div class="flex basis-full justify-end items-start content-start">
    <mat-slide-toggle color="accent" [(ngModel)]="dxSwitch" [hideIcon]="true" (change)="setChartOptions()">
        <span>DX View</span>
    </mat-slide-toggle>
  </div>

  <div class="flex flex-row flex-wrap gap-0">
    <mat-card class="vc-card vc-card-charts mat-elevation-z5 w-[600px]">
      <mat-card-header>
        <div class="title-container flex flex-row">
          <h3 class="flex basis-[60%]">{{dxEventTitle.text}}</h3>
          <div class="flex flex-auto justify-end items-center content-center">
              <span class="material-symbols-outlined download-icon"
              [matTooltip]="'Download'"
              [matTooltipClass]="'app-tooltip'"
              [matTooltipPosition]="'above'"
              (click)="onDownload(dxEventChart.chart)">download</span>
          </div>
        </div>
      </mat-card-header>

      <mat-card-content>

          <ag-charts-angular
          #dxEventChart
          class="h-full"
          [options]="dxEventOptions"></ag-charts-angular>
        
      </mat-card-content>
    </mat-card>

    <mat-card class="vc-card vc-card-charts mat-elevation-z5 w-[600px]">
        <mat-card-header>
          <div class="title-container flex flex-row">
            <h3 class="flex basis-[60%]">{{claimSuppFeedTitle.text}}</h3>
            <div class="flex flex-auto justify-end items-center content-center">
                <mat-slide-toggle style="padding-right: 15px;" [(ngModel)]="claimSuppFeedPercentSwitch" [hideIcon]="true" (change)="setChartOptions()">View %</mat-slide-toggle>
                <span class="material-symbols-outlined download-icon"
                [matTooltip]="'Download'"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'"
                (click)="onDownload(claimsChart.chart)">download</span>
            </div>
          </div>
        </mat-card-header>
  
        <mat-card-content>
  
            <ag-charts-angular
            #claimsChart
            class="h-full"
            [options]="claimSuppFeedOptions"></ag-charts-angular>
          
        </mat-card-content>
    </mat-card>

    <mat-card class="vc-card vc-card-charts mat-elevation-z5 w-[600px]">
        <mat-card-header>
          <div class="title-container flex flex-row">
            <h3 class="flex basis-[60%]">{{nonActionableTitle.text}}</h3>
            <div class="flex flex-auto justify-end items-center content-center">
              <mat-slide-toggle style="padding-right: 15px;" [(ngModel)]="nonActionablePercentSwitch" [hideIcon]="true" (change)="setChartOptions()">View %</mat-slide-toggle>
                <span class="material-symbols-outlined download-icon"
                [matTooltip]="'Download'"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'"
                (click)="onDownload(nonActionableChart.chart)">download</span>
            </div>
          </div>
        </mat-card-header>
  
        <mat-card-content>
  
            <ag-charts-angular
            #nonActionableChart
            class="h-full"
            [options]="nonActionableOptions"></ag-charts-angular>
          
        </mat-card-content>
    </mat-card>

    <mat-card class="vc-card vc-card-charts mat-elevation-z5 w-[600px]">
        <mat-card-header>
          <div class="title-container flex flex-row">
            <h3 class="flex basis-[60%]">{{onRapsTitle.text}}</h3>
            <div class="flex flex-auto justify-end items-center content-center">
              <mat-slide-toggle style="padding-right: 15px;" [(ngModel)]="onRapsPercentSwitch" [hideIcon]="true" (change)="setChartOptions()">View %</mat-slide-toggle>
                <span class="material-symbols-outlined download-icon"
                [matTooltip]="'Download'"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'"
                (click)="onDownload(onRapsChart.chart)">download</span>
            </div>
          </div>
        </mat-card-header>
  
        <mat-card-content>
  
            <ag-charts-angular
            #onRapsChart
            class="h-full"
            [options]="onRapsOptions"></ag-charts-angular>
          
        </mat-card-content>
    </mat-card>

    <mat-card class="vc-card vc-card-charts mat-elevation-z5 w-[600px]">
        <mat-card-header>
          <div class="title-container flex flex-row">
            <h3 class="flex basis-[60%]">{{postedOnRapsTitle.text}}</h3>
            <div class="flex flex-auto justify-end items-center content-center">
              <mat-slide-toggle style="padding-right: 15px;" [(ngModel)]="postedOnRapsPercentSwitch" [hideIcon]="true" (change)="setChartOptions()">View %</mat-slide-toggle>
                <span class="material-symbols-outlined download-icon"
                [matTooltip]="'Download'"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'"
                (click)="onDownload(postedOnRapsChart.chart)">download</span>
            </div>
          </div>
        </mat-card-header>
  
        <mat-card-content>
  
            <ag-charts-angular
            #postedOnRapsChart
            class="h-full"
            [options]="postedOnRapsOptions"></ag-charts-angular>
          
        </mat-card-content>
    </mat-card>
  </div>
</div>
