<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
  <mat-label>Status State</mat-label>
  <mat-select
    [multiple]="multiple"
    [formControl]="control"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (closed)="onSelectionChange()">
    <mat-select-trigger>
      @if ((control.value?.length || 0) === 1) {
        <span>
          {{control.value?.[0].Description || ''}}
        </span>
      }
      @if ((control.value?.length || 0) > 1 && (control.value?.length - 1) == options.length) {
        <span>
          All
        </span>
      }
      @if ((control.value?.length || 0) > 1 && (control.value?.length - 1) < options.length) {
        <span>
          Multiple
        </span>
      }
      @if ((control.value?.length || 0) > 1) {
        <span>
          ({{getSelectCount()}})
        </span>
      }
    </mat-select-trigger>

    @if (selectAllEnabled) {
      <mat-option
        #allItem
        [value]="allOption"
      (click)="onToggleItem(allItem)">All</mat-option>
    }

    @for (option of options; track option) {
      <mat-option
        #item
        [value]="option"
        (click)="onToggleItem(item)">
        {{option.Description}}
      </mat-option>
    }
  </mat-select>

  @if (control.hasError('required')) {
    <mat-error>Status State is <strong>required</strong></mat-error>
  }
</mat-form-field>
