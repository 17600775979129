<h1 mat-dialog-title class="unselectable">
  {{ title }}
</h1>

<div mat-dialog-content class="unselectable">
  <p>{{ message }}</p>
  @for (m of additionalMessages; track m) {
    <p>
      {{m}}
    </p>
  }
</div>

@if (confirmKey != '') {
  <span class="unselectable" mat-dialog-content>Type "{{confirmKey}}" to confirm</span>
}
@if (confirmKey != '') {
  <mat-form-field>
    @if (confirmKey != '') {
      <input matInput autocomplete="off" type="text" maxlength="45" [(ngModel)]="textData" />
    }
  </mat-form-field>
}

<div mat-dialog-actions class="unselectable" style="justify-content: center;">

  <button [disabled]="isConfirmDisabled()" mat-stroked-button color="warn" color="primary"
  (click)="onConfirm()">Yes</button>
  <button mat-stroked-button color="warn" (click)="onDismiss()">No</button>
</div>