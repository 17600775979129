import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-edit-icon-renderer',
    templateUrl: './edit-icon-renderer.component.html',
    styleUrls: ['./edit-icon-renderer.component.scss'],
    standalone: true,
    imports: [MatIcon]
})
export class EditIconRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams<any, any>;
  isEditAllActive = false;
  iconEnabled = false;
  iconName = 'edit';

  constructor() {
    //TODO
  }

  agInit(params: ICellRendererParams<any, any> & {iconName: string}): void {
    this.params = params;
    this.isEditAllActive = this.params.context.componentParent.editAllActive ?? true;

    if(params.iconName) {
      this.iconName = params.iconName;
    }

    if(this.isEditAllActive && this.params.data.AssignedTo) {
      this.iconEnabled = true;
    }

    if(!this.isEditAllActive && this.params.data.AssignedTo) {
      if(this.params.data.AssignedTo.UserID.toString() == this.params.context.componentParent.currentUser.uid) {
        this.iconEnabled = true;
      }
    }

    if(this.params.data.RiskScoreRunYear || this.params.data.TypeName) {
      this.isEditAllActive = true;
      this.iconEnabled = true;
    }
  }
  
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onIconClick(event) {
    event.stopImmediatePropagation();
    this.params.context.componentParent.onListItemEditClick(this.params.data);
  }

  setIcon(name: string) {
    this.iconName = name;
  }

}
