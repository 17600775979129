import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { DecimalPipe } from '@angular/common';

export interface IAgRowSelectedCount {
  updateSelectedCount(count: number);
  updateTotalCount(count: number);
}

@Component({
    selector: 'app-ag-row-selected-count',
    templateUrl: './ag-row-selected-count.component.html',
    styleUrls: ['./ag-row-selected-count.component.scss'],
    standalone: true,
    imports: [DecimalPipe]
})
export class AgRowSelectedCountComponent implements IStatusPanelAngularComp {

  params: IStatusPanelParams<any, any>;
  selectedCount = 0;
  totalCount = 0;

  agInit(params: IStatusPanelParams<any, any>): void {
    this.params = params;
  }

  updateSelectedCount(count: number) {
    this.selectedCount = count;
  }

  updateTotalCount(count: number) {
    this.totalCount = count;
  }


}
