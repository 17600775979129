import { Injectable } from '@angular/core';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { ClientToastController } from '../../app/toasts/client-toast.lib';

@Injectable({
    providedIn: "root"
})

export class WebClientToast implements ClientToastController {
    constructor(private snackBar: MatSnackBar) { }

    public displayToast(message: string, duration: number) {
        return this.snackBar.open(message, '', { duration: duration })
    }
}