import { getState, patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { TemplateService } from "../services/settings/template.service";
import { TemplateConfigItem } from "../models/template-config-item";
import { map } from "rxjs/operators";
import { inject } from "@angular/core";
import { TemplateConfigFieldItem } from "../models/template-config-field-item";
import _ from "lodash";

type TemplateConfigState = {
    templateId: number;
    currentTemplate: TemplateConfigItem;
    originalTemplate: TemplateConfigItem;
    fieldItems: TemplateConfigFieldItem[];
    isNew: boolean;
    loading: boolean;
};

const initialState: TemplateConfigState = {
    currentTemplate: new TemplateConfigItem(),
    originalTemplate: new TemplateConfigItem(),
    fieldItems: [],
    templateId: 0,
    isNew: true,
    loading: false
};

/*** HELPERS ***/

const save = (service: TemplateService, template: TemplateConfigItem) => {
    return service?.addUpdateTemplate(template).pipe(
        map((result) => result)
    );
}

const updateFlow = (store, service: TemplateService, template: TemplateConfigItem) => {
    const updatedTemplate = _.cloneDeep(template);
    save(service, updatedTemplate).subscribe((result) => {
        getTemplateConfigFieldItems(result[`ID`], service).subscribe((items) => {
            updatedTemplate.ID = result[`ID`];
            updatedTemplate.FieldItems = items;
            patchState(store, {templateId: result[`ID`], currentTemplate: updatedTemplate, fieldItems: items});
        });
    });
}

const remove = (store, service: TemplateService) => {
    const state = getState(store) as TemplateConfigState;
    service?.deleteTemplate(state.currentTemplate.ID).subscribe(() => {
        return true;
    });
}

const getTemplateConfigFieldItems = (id, service: TemplateService) => {
    return service.getTemplateConfigFieldItems(id)
        .pipe(
            map((items) => items)
        );
}

const updateSortIdOfFieldItems = (fieldItems: TemplateConfigFieldItem[]) => {
    const updatedFieldItems: TemplateConfigFieldItem[] = [];

    fieldItems.forEach((item: TemplateConfigFieldItem) => {
        const newItem = _.cloneDeep(item);
        newItem.SortID = fieldItems.indexOf(item) + 1;
        updatedFieldItems.push(newItem);
    });

    return updatedFieldItems;
}

/*** STORE ***/

export const TemplateConfigStore = signalStore(
    withState(initialState),
    /** SIGNALS AVAILABLE TO COMPONENTS, CAN BE BOUND TO CONTROLS (E.G. AG GRID ROW DATA, FILTER LISTS, ETC.) **/
    withComputed((state) => ({
        fieldItemsSignal: state.fieldItems
    })),
    /** METHODS AVAILABLE TO COMPONENTS **/
    withMethods((store, service = inject(TemplateService)) => ({
        setTemplateId(id?: number): void {
            patchState(store, {templateId: id});
        },
        setCurrentTemplate(input: TemplateConfigItem) {
            return new Promise<TemplateConfigItem>((resolve, reject) => {
                patchState(store, {currentTemplate: input, fieldItems: input.FieldItems});
                resolve(input);
                reject(new Error(`Error setting current template.`));
            })
        },
        addRemoveFieldItems(index: number, input?: TemplateConfigFieldItem, isReorder?: boolean) {
            const state = getState(store);
            const apiItem = _.cloneDeep(state.currentTemplate);
            // if we're moving the current field item in the existing list, remove it at the old index and add it at the new one
            if (input && isReorder) {
                apiItem.FieldItems = apiItem.FieldItems.filter(item => item.ID != input.ID || apiItem.FieldItems.indexOf(item) == index);
                apiItem.FieldItems.splice(index, 0, input);
            // if we're adding field items to an existing list of field items attached to the template, splice at index
            } else if (input && apiItem.FieldItems && apiItem.FieldItems?.length > 0) {
                apiItem.FieldItems.splice(index, 0, input);
            // if we're adding the first field item attached to the template, instantiate the field items list with the new field item
            } else if (input) {
                apiItem.FieldItems = [input];
            // if we're removing a field item, delete 1 item at the given index
            } else {
                apiItem.FieldItems.splice(index, 1);
            }
            apiItem.FieldItems = updateSortIdOfFieldItems(apiItem.FieldItems);
            updateFlow(store, service, apiItem);
        },
        updateFieldItem(input: TemplateConfigFieldItem) {
            const state = getState(store);
            const currentTemplate = _.cloneDeep(state.currentTemplate);
            const updatedFieldItems: TemplateConfigFieldItem[] = [];
            state.fieldItems.forEach((item) => {
                const updatedItem = _.cloneDeep(item);
                if (item.ID == input.ID) {
                    updatedItem.Label = input.Label;
                    updatedItem.DefaultValue = input.DefaultValue;
                }
                updatedFieldItems.push(updatedItem);
            });
            currentTemplate.FieldItems = updatedFieldItems;
            patchState(store, {currentTemplate: currentTemplate, fieldItems: updatedFieldItems});
        },
        setOriginalTemplate(input: TemplateConfigItem) {
            const state = getState(store);
            const template = input;
            template.FieldItems = [];
            try {
                if (!state.isNew) {
                    getTemplateConfigFieldItems(template.ID, service).subscribe((items) => {
                        template.FieldItems = items;
                        patchState(store, {templateId: template.ID, currentTemplate: template, originalTemplate: template, fieldItems: items});
                    });
                }
            } catch(error) {
                console.log(`Error when generating ID for new template: ${error}`);
            }
        },
        setIsNew(input: boolean): void {
            patchState(store, {isNew: input});
        },
        saveCurrentTemplate() {
            const state = getState(store);
            const item = _.cloneDeep(state.currentTemplate);
            item.FieldItems = state.fieldItems;
            updateFlow(store, service, item);
            patchState(store, {isNew: false, templateId: item.ID, currentTemplate: item, originalTemplate: item, fieldItems: item.FieldItems});
        },
        revertToOriginalTemplate() {
            const state = getState(store);
            if (state.isNew) {
                return remove(store, service);
            } else {
                return save(service, state.originalTemplate);
            }
        },
        toggleLoading() {
            const state = getState(store);
            patchState(store, {loading: !state.loading});
        }
    })),
    
);