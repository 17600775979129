import { formatDate, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatHint, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDateRangeInput, MatStartDate, MatEndDate, MatDatepickerToggle, MatDateRangePicker } from '@angular/material/datepicker';

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatDateRangeInput, FormsModule, ReactiveFormsModule, MatStartDate, MatEndDate, MatHint, MatDatepickerToggle, MatSuffix, MatDateRangePicker, MatError, DatePipe]
})
export class DateRangeComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() controlName = 'date-range';
  @Input() required = false;
  @Input() startRequired = false;
  @Input() endRequired = false;
  @Input() appearance = 'outline';
  @Input() label = 'Date Range';
  @Input() startPlaceholder = '';
  @Input() endPlaceholder = '';
  @Input() hint = 'MM/DD/YYYY – MM/DD/YYYY';
  @Input() startDateDecrement = 30;
  @Input() endDateDecrement = 1;
  @Input() endDateDefault = new Date();
  @Input() endDateMax = new Date();
  @Input() startDateMin = new Date(0);

  range: FormGroup;

  @Output() ready = new EventEmitter();

  constructor() { 
    //TODO
  }

  ngOnInit(): void {
    this.setDefaultState(this.endDateDefault);
    this.ready.emit();
  }

  setDefaultState(endDate: Date) {
    if(this.form.get(this.controlName)) {
      this.form.removeControl(this.controlName);
    }

    const startValidators = this.startRequired ? [Validators.required] : [];
    const endValidators = this.endRequired ? [Validators.required] : [];

    const newEndDate = new Date(new Date().setDate(endDate.getDate() - this.endDateDecrement));

    endDate = newEndDate;
    this.endDateMax = newEndDate;
    
    const startDateDefault = endDate ? new Date(new Date().setDate(endDate.getDate() - this.startDateDecrement)) : null;

    this.range = new FormGroup({
      start: new FormControl<Date | null>(startDateDefault, startValidators),
      end: new FormControl<Date | null>(endDate, endValidators),
    });

    this.form.addControl(this.controlName, this.range);
  }

  setEndRangeDecrement(decrement: number) {
    this.endDateDecrement = decrement;

  }

  getStartDateValue() {
    return formatDate(this.range.value.start, 'MM/dd/yyyy', 'en');
  }

  getEndDateValue() {
    return formatDate(this.range.value.end, 'MM/dd/yyyy', 'en');
  }

}
