import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

export interface IAgLabelPanel {
  updateLabel(label: string) : void;
}

@Component({
    selector: 'app-ag-label-status-panel',
    templateUrl: './ag-label-status-panel.component.html',
    styleUrls: ['./ag-label-status-panel.component.scss'],
    standalone: true
})
export class AgLabelStatusPanelComponent implements IStatusPanelAngularComp {
  params: IStatusPanelParams;
  label: string;

  agInit(params: IStatusPanelParams<any, any> & {label: string}): void {
    this.params = params;
    this.label = params.label;
  }

  updateLabel(label: string) {
    this.label = label;
  }
}
