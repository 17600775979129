import { Subject } from '../Subject';
import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
export function windowWhen(closingSelector) {
  return function windowWhenOperatorFunction(source) {
    return source.lift(new WindowOperator(closingSelector));
  };
}
class WindowOperator {
  constructor(closingSelector) {
    this.closingSelector = closingSelector;
  }
  call(subscriber, source) {
    return source.subscribe(new WindowSubscriber(subscriber, this.closingSelector));
  }
}
class WindowSubscriber extends OuterSubscriber {
  constructor(destination, closingSelector) {
    super(destination);
    this.destination = destination;
    this.closingSelector = closingSelector;
    this.openWindow();
  }
  notifyNext(_outerValue, _innerValue, _outerIndex, _innerIndex, innerSub) {
    this.openWindow(innerSub);
  }
  notifyError(error) {
    this._error(error);
  }
  notifyComplete(innerSub) {
    this.openWindow(innerSub);
  }
  _next(value) {
    this.window.next(value);
  }
  _error(err) {
    this.window.error(err);
    this.destination.error(err);
    this.unsubscribeClosingNotification();
  }
  _complete() {
    this.window.complete();
    this.destination.complete();
    this.unsubscribeClosingNotification();
  }
  unsubscribeClosingNotification() {
    if (this.closingNotification) {
      this.closingNotification.unsubscribe();
    }
  }
  openWindow(innerSub = null) {
    if (innerSub) {
      this.remove(innerSub);
      innerSub.unsubscribe();
    }
    const prevWindow = this.window;
    if (prevWindow) {
      prevWindow.complete();
    }
    const window = this.window = new Subject();
    this.destination.next(window);
    let closingNotifier;
    try {
      const {
        closingSelector
      } = this;
      closingNotifier = closingSelector();
    } catch (e) {
      this.destination.error(e);
      this.window.error(e);
      return;
    }
    this.add(this.closingNotification = subscribeToResult(this, closingNotifier));
  }
}
