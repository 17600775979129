@if (actionType == 'add') {
  <h1 mat-dialog-title>Add Payer</h1>
}
@if (actionType == 'edit') {
  <h1 mat-dialog-title>Edit Payer</h1>
}

<div mat-dialog-content>
  <div class="content-container flex flex-col">
    <div class="grid grid-cols-[1fr_1.3fr_0.25fr_1.75fr] gap-3">
      <div class="col-start-1">
        <h3>Payer Full Name *</h3>
      </div>

      <div class="col-start-2">
        <mat-form-field color="primary" appearance="outline">
          <input matInput placeholder="Enter Payer Full Name" [formControl]="nameControl" [required]="true">
          @if (nameControl.hasError('required')) {
            <mat-error>Name is <strong>required</strong></mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-start-1 row-start-2">
        <h3>Abbreviation *</h3>
      </div>

      <div class="col-start-2 row-start-2">
        <mat-form-field color="primary" appearance="outline">
          <input matInput placeholder="Enter Abbreviation" [formControl]="abbreviationControl" [required]="true">
          @if (abbreviationControl.hasError('required')) {
            <mat-error>Abbreviation is <strong>required</strong></mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-start-1 row-start-3">
        <h3>Payer Aliases *</h3>
      </div>

      <div class="col-start-2 row-start-3">
        <app-payer-alias-select
          [form]="payerForm"
          [label]="''"
          [showDisabledOptions]="actionType == 'edit'"
          [currentPayerID]="this.payerConfigStore.currentPayerItem().PayerID"
          [queryOptions]="true"
          [placeholder]="'Make a Selection'"
          [required]="true"
        (ready)="isPayerAliasReady = true; filterReadyCheck();"></app-payer-alias-select>
      </div>
      @if (payerConfigStore.payerMapFileSubTypes().length > 0) {
        <div class="col-start-4 row-start-1 col-span-1 row-span-3">
          <div class="grid grid-cols-[0.75fr_1.25fr] gap-1">
            <div class="col-start-1 row-start-1 col-span-2">
              <h4>Data File Mappings:</h4>
            </div>
            <div class="col-start-1 row-start-2">
              <h4>File Type:</h4>
            </div>
            <div class="col-start-2 row-start-2">
              <h4>Effective Date:</h4>
            </div>
            @for (map of payerConfigStore.payerMapFileSubTypes(); track map.FileSubType) {
              <div [class]="'col-start-1 row-start-' + $index + 3">
                <span>{{ map.FileType }} {{ map.FileSubType }}</span>
              </div>
              <div [class]="'col-start-2 row-start-' + $index + 3">
                <span>{{ map.EffectiveDate }}</span>
              </div>
            }
          </div>
        </div>
      }
    </div>

    <mat-divider class="divider-row"></mat-divider>

    <div class="flex basis-[5%] flex-row">
      <div class="flex basis-[40%] justify-start items-center content-center">
        <h3>Risk Adjustment Policy</h3>
      </div>

      <div class="flex flex-auto justify-end items-center content-center">
        <button
          mat-flat-button
          class="primary-button-longtext"
          (click)="onAddPolicyClick()">
          Add Service Year Entry
        </button>
      </div>
    </div>

    <div class="flex flex-auto">
      <ag-grid-angular
        class="ag-theme-balham ag-grid-container w-full h-[30dvh]"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [pagination]="true"
        [rowData]="this.payerConfigStore.riskAdjustmentPolicies()"
        [tooltipShowDelay]="0"
        [noRowsOverlayComponent]="noRowsOverlayComponent"
        [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
        [rowClassRules]="rowClassRules"
        [context]="gridContext"
        [components]="frameworkComps"
        [suppressCopyRowsToClipboard]="true"
        [suppressPaginationPanel]="true"
        [getRowId]="getRowId"
        [loadingOverlayComponent]="loadingOverlayComponent"
      (gridReady)="onGridReady($event)"></ag-grid-angular>
    </div>

  </div>
</div>

<mat-dialog-actions align="end">
  @if (invalidServiceYear) {
    <label class="policy-error-label">Service Year range conflicts with existing entries.</label>
  }
  @if (emptyPolicyFields) {
    <label class="policy-error-label">Required fields are empty.</label>
  }
  <button mat-flat-button class="secondary-button" mat-dialog-close (click)="this.payerConfigStore.clearState()">Cancel</button>
  @if (actionType == 'edit') {
    <button
      mat-flat-button
      class="primary-button"
      [disabled]="payerForm.invalid || invalidPolicyRows"
      (click)="onSaveClick()">
      Save
    </button>
  }

  @if (actionType == 'add') {
    <button
      mat-flat-button
      class="primary-button"
      [disabled]="payerForm.invalid || invalidPolicyRows"
      (click)="onSaveClick()">
      Add
    </button>
  }
</mat-dialog-actions>
