import { ComponentType } from "@angular/cdk/portal";
import { Component, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ListMenuOption } from "../../models/list-menu-option";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSelectionListChange, MatSelectionList, MatListOption } from "@angular/material/list";
import { OrganizationLib } from "../../services/libs/organization-lib.service";
import { Org } from "../../models/org";
import { Timezone } from "../../models/timezone";
import { TimezoneSelectComponent } from "../../components/field-components/timezone-select/timezone-select.component";
import { BooleanSelectComponent } from "../../components/field-components/static/boolean-select/boolean-select.component";
import { JobsService } from "../../services/jobs/jobs.service";
import { MatIcon } from "@angular/material/icon";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatFormField, MatError } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";
import { MatSelect, MatSelectTrigger } from "@angular/material/select";
import { MatOption } from "@angular/material/core";

export class AddUpdateOrgDialogModel {
    readonly component: ComponentType<any> = AddUpdateOrgDialogComponent;
    panelClass?: string | string[] = 'side-menu-dialog';
    autoFocus?: boolean = false;
    data?: any;
    minWidth?: string = '50dvw';
    disableClose? = true;
  
    constructor(data: any) {
      this.data = data;
    }
  }
  
  @Component({
    selector: 'app-add-update-org-dialog',
    templateUrl: 'add-update-org-dialog.component.html',
    styleUrls: ['add-update-org-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIcon, MatSelectionList, MatListOption, CdkScrollable, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormField, MatInput, MatError, TimezoneSelectComponent, BooleanSelectComponent, MatButton, MatSelect, MatSelectTrigger, MatOption]
})
  export class AddUpdateOrgDialogComponent {
  
    form: FormGroup;
    authPolicyForm: FormGroup;

    nameControl: FormControl = new FormControl('', [Validators.required]);
    notesControl: FormControl = new FormControl('');

    authResetIntervalControl: FormControl = new FormControl(60);
    authExportExpirationControl: FormControl = new FormControl(30);
  
    sideMenuOptions: ListMenuOption[] = [
      {ID: 1, Name: "Details", Icon: "edit", IconOutlined: false, Disabled: false},
      {ID: 2, Name: "Authentication Policy", Icon: "security", IconOutlined: false, Disabled: true}
    ];

    authResetIntervalOptions = [60, 120, 365];
    authExportExpirationOptions = [30, 60, 90];
  
    selectedOption: ListMenuOption;

    @ViewChild(TimezoneSelectComponent) tzComp: TimezoneSelectComponent;
    @ViewChild(BooleanSelectComponent) statusComp: BooleanSelectComponent;
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any, 
      private snackBar: MatSnackBar, 
      private orgsService: OrganizationLib,
      private jobsService: JobsService,
      public dialogRef: MatDialogRef<AddUpdateOrgDialogComponent>,
      private fb: FormBuilder
    ) {
  
      this.form = this.fb.group({
        name: this.nameControl,
        notes: this.notesControl
      });

      this.authPolicyForm = this.fb.group({
        resetInterval: this.authResetIntervalControl,
        exportExiration: this.authExportExpirationControl
      });
  
      this.selectedOption = this.sideMenuOptions[0];

      if(this.data.mode == 'edit') {
        this.nameControl.setValue(this.data.orgData.OrgName);
        this.notesControl.setValue(this.data.orgData.Notes);

        this.authResetIntervalControl.setValue(this.data.orgData.UserResetPasswordInterval);
        this.authExportExpirationControl.setValue(this.data.orgData.ExportPasswordExpirationInterval);
  
        this.sideMenuOptions[1].Disabled = false;
      }
    }
  
    onCloseClick() {
      this.dialogRef.close(null);
    }

    onTimezoneReady() {
      if(this.data.mode == 'edit') {
        this.tzComp.setSingleModeValue(this.data.orgData.Timezone);
        this.statusComp.setValue(this.data.orgData.Disabled);
      }
    }
  
    addEditUser(): void {
        const updateOrg = new Org();
        updateOrg.OrgID = this.data.mode == 'add' ? 0 : this.data.orgData.OrgID;

        //Details Section
        if(this.selectedOption.ID == 1) {
          updateOrg.OrgName = this.nameControl.value;
          updateOrg.Notes = this.notesControl.value;
          updateOrg.Timezone = this.tzComp.getValues() as Timezone;

          updateOrg.UserResetPasswordInterval = this.data.mode == 'edit' ? this.data.orgData.UserResetPasswordInterval : null;
          updateOrg.ExportPasswordExpirationInterval = this.data.mode == 'edit' ? this.data.orgData.ExportPasswordExpirationInterval : null;
        }

        //Auth Policy Section
        if(this.selectedOption.ID == 2) {
          updateOrg.OrgName = this.data.orgData.OrgName;
          updateOrg.Notes = this.data.orgData.Notes;
          updateOrg.Timezone = this.data.orgData.Timezone;

          updateOrg.UserResetPasswordInterval = this.authResetIntervalControl.value;
          updateOrg.ExportPasswordExpirationInterval = this.authExportExpirationControl.value;
        }

        this.orgsService.addUpdateOrg(updateOrg, this.selectedOption.ID).subscribe(result => {

          if(this.data.mode == 'add') {
            this.data.orgData = updateOrg;
            this.data.orgData.OrgID = result;
            this.sideMenuOptions[1].Disabled = false;
            this.data.mode = 'edit';
            //load tab 2
            this.selectedOption = this.sideMenuOptions[1];
            this.snackBar.open('Org has been added!', null, { duration: 6000 });
            return;
          }

          if(this.data.mode == 'edit') {
            this.snackBar.open('Org has been updated!', null, { duration: 6000 });
          }
        },
        error => {
          this.snackBar.open('Error updating org!' + error, '', { duration: 6000 });
        });
    }
  
    onSelectedSideMenuOption(event: MatSelectionListChange) {
      if(!event.options) {
        return;
      }
  
      const option = event.options[0].value;
  
      if(option.ID == this.selectedOption.ID) {
        return;
      }
  
      this.selectedOption = option;
    }

    onManuallyResetExportPassword() {
      this.jobsService.triggerExportHashRefresh(this.data.orgData.OrgID, this.data.orgData.ExportPasswordExpirationInterval).subscribe(result => {
        this.snackBar.open('Org export password refresh triggered!', null, { duration: 6000 });
      });
    }
  
  }