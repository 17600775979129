<mat-form-field [appearance]="appearance">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="startDateMin" [max]="endDateMax" [required]="required">
    <input matStartDate formControlName="start" [placeholder]="startPlaceholder">
    <input matEndDate formControlName="end" [placeholder]="endPlaceholder">
  </mat-date-range-input>
  @if (hint) {
    <mat-hint>{{hint}}</mat-hint>
  }
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>

  @if (range.controls.start.hasError('matStartDateInvalid')) {
    <mat-error>Invalid start date</mat-error>
  }
  @if (range.controls.end.hasError('matEndDateInvalid')) {
    <mat-error>Invalid end date</mat-error>
  }
  @if (range.controls.start.hasError('matDatepickerMax')) {
    <mat-error>Date exceeds max range of {{endDateMax | date:'MM/dd/yyyy'}}</mat-error>
  }
  @if (range.controls.end.hasError('matDatepickerMax')) {
    <mat-error>Date exceeds max range of {{endDateMax | date:'MM/dd/yyyy'}}</mat-error>
  }
  @if (range.controls.start.hasError('matDatepickerMin')) {
    <mat-error>Date before min range of {{startDateMin | date:'MM/dd/yyyy'}}</mat-error>
  }
</mat-form-field>