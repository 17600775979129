import { Component, effect, inject, ViewChild } from '@angular/core';
import { FileUploadDropzoneComponent } from "../../field-components/file-upload-dropzone/file-upload-dropzone.component";
import { PayerSelectComponent } from "../../field-components/payer-select/payer-select.component";
import { PayerItem } from '../../../models/payer-item';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button'
import { ClientToastController } from '../../../toasts/client-toast.lib';
import _ from 'lodash';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MorDataStore } from '../../../store/mor-data.store';

@Component({
  selector: 'app-mor-data',
  standalone: true,
  imports: [
    FileUploadDropzoneComponent, 
    PayerSelectComponent, 
    MatButtonModule, 
    MatTooltipModule, 
    MatIcon,
  ],
  providers: [MorDataStore],
  templateUrl: './mor-data.component.html',
  styleUrl: './mor-data.component.scss'
})
export class MorDataComponent {
  form: FormGroup;

  @ViewChild(FileUploadDropzoneComponent) morUploadField: FileUploadDropzoneComponent;
  @ViewChild(PayerSelectComponent) payerSelect: PayerSelectComponent;

  formBuilder = inject(FormBuilder);

  iconTooltip = "Contains a listing of all payers that have a configured MOR file mapping. If payer is not listed, contact VC Customer Service.";
  dropzoneLabel = "Drag & Drop to Add File for Upload OR";
  browseButtonLabel = "Browse for File";

  readonly store = inject(MorDataStore);

  constructor(
    private toastController: ClientToastController
  ) {
    this.form = this.formBuilder.group({});
    effect(() => {
      this.morUploadField.disabled = this.store.currentFile() != null;
    });
  }

  ngAfterViewInit() {
    //TODO
  }

  handlePayerSelected(event: PayerItem) {
    if (event) {
      this.store.setSelectedPayer(event);
    }
  }

  handleFileDropped(event: File[]) {
    if (event) {
      this.store.setCurrentFile(event[0]); 
    }
  }

  handleFileDeleted(event: File[]) {
    if (event) {
      this.store.setCurrentFile(null);
    }
  }

  handleUploadSubmit() {
    /* TODO:
    - validate file (assert it is MOR/valid re: org/payer, etc.)
    - have file added for upload pass to API for insert into tables
    - subscribe to API call, display toast when complete. */
    const toastMessage = this.store.uploadCurrentFile();  
    this.toastController.displayToast(toastMessage, 3000);
    this.morUploadField.reset();
    this.payerSelect.control.reset();
  }
}
