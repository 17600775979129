import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { ColDef, ColGroupDef, GetRowIdFunc, GetRowIdParams, IServerSideDatasource, StatusPanelDef } from 'ag-grid-enterprise';
import { Subscription } from 'rxjs';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgPaginatorComponent, IAgPaginator } from '../../../../ag-grid-components/ag-paginator/ag-paginator.component';
import { UserAllocationQuery } from '../../../../../models/grid-query';
import { QueryMetadata, QuerySort } from '../../../../../models/query-metadata';
import { ProgressAllocationService } from '../../../../../services/progress/progress-allocation.service';
import { SettingsKeys, SettingsService } from '../../../../../services/settings/settings.service';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { ProgressCellRendererComponent } from '../../../../ag-grid-components/progress-cell-renderer/progress-cell-renderer.component';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { ProgressAllocationUserListItem } from '../../../../../models/progress-allocation-user-list-item';
import { AgSummationStatusPanelComponent, IAgSummationPanel } from '../../../../ag-grid-components/ag-summation-status-panel/ag-summation-status-panel.component';
import { AgLabelStatusPanelComponent } from '../../../../ag-grid-components/ag-label-status-panel/ag-label-status-panel.component';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AuditAction } from '../../../../../models/audit-action';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'app-submission-oppty-user-progress-report',
    templateUrl: './submission-oppty-user-progress-report.component.html',
    styleUrls: ['./submission-oppty-user-progress-report.component.scss'],
    standalone: true,
    imports: [NgStyle, AgGridModule]
})
export class SubmissionOpptyUserProgressReportComponent implements OnInit {

  AgGridFunctions = AgGridFunctions;
  isGridReady = false;

  formValid = false;
  isFirstGenerate = true;
  currentListSize = 0;

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgLabelStatusPanelComponent,
        key: 'lable-panel',
        statusPanelParams: {
          label: 'Totals',
        },
        align: 'left'
      },
      {
        statusPanel: AgSummationStatusPanelComponent,
        key: 'progress-panel',
        statusPanelParams: {
          label: '',
          col: 'Progress',
          flex: 1,
          display: false
        }
      },
      {
        statusPanel: AgSummationStatusPanelComponent,
        key: 'closed-panel',
        statusPanelParams: {
          label: '',
          col: 'Closed',
          flex: 1,
        }
      },
      {
        statusPanel: AgSummationStatusPanelComponent,
        key: 'open-panel',
        statusPanelParams: {
          label: '',
          col: 'Open',
          flex: 1,
        }
      },
      {
        statusPanel: AgSummationStatusPanelComponent,
        key: 'assigned-panel',
        statusPanelParams: {
          label: '',
          col: 'Assigned',
          flex: 1,
        }
      },
    ],
  };

  frameworkComps = {
    progress: ProgressCellRendererComponent
  }

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true
  };

  excelStyles = DxsrExcelStyling.excelStyles2;

  gridContext: any;
  query: UserAllocationQuery = new UserAllocationQuery();

  data: ProgressAllocationUserListItem[] = [];

  currentRowHeight = 140;
  currentGridWidth = 282;

  subs: Subscription[] = [];

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
    const cellWidth = this.agGrid.api.getColumn('Closed').getActualWidth();
    this.updateTotalPanelWidths(cellWidth);
  }

  constructor(
    private allocationService: ProgressAllocationService,
    private settingsService: SettingsService,
    private loggingService: LoggingService
  ) { 
    this.gridContext = { componentParent: this };
    this.query.Metadata = new QueryMetadata();
  }

  ngOnInit(): void {
    //TODO
  }

  onGridReady() {

    this.isGridReady = true;

    this.agGrid.api.sizeColumnsToFit();

    // this.subs.push(this.settingsService.getPaginatorPageSize(SettingsKeys.OPPTY_SUBMISSION_USER_ALLOCATION_PAGE_SIZE).subscribe(result => {
    //   if(result == 0) {
    //     return;
    //   }

    //   this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
    // }));

    this.ready.emit(true);
  }

  //#region AG Grid Functions

  getColDef(): (ColDef | ColGroupDef)[] {

    const result: any = [
      {
        headerName: `Service Year ${this.query.ServiceYear}`,
        children: [
          { field: 'User', headerName: 'User', valueFormatter: AgGridFunctions.userItemValue, tooltipValueGetter: AgGridFunctions.assingedUserTooltip},
          { field: 'Progress', headerName: 'Progress', type: 'numericColumn', valueFormatter: (params) => AgGridFunctions.getPercentValue(params, 1), cellClass: 'percent', flex: 1,},
          { field: 'Closed', headerName: 'Closed', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', flex: 1,},
          { field: 'Open', headerName: 'Open', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', flex: 1,},
          { field: 'Assigned', headerName: 'Assigned', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', flex: 1,},
        ]
      }
    ];

    return result;
  }

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {
        this.query.Metadata.PageSize = params.api.paginationGetPageSize();
        this.query.Metadata.PageNumber = params.api.paginationGetCurrentPage();

        const sortModel = params.request.sortModel;

        if(sortModel.length > 0) {
          this.query.Metadata.Sort = new QuerySort();
          this.query.Metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : null;
          this.query.Metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : null;
        }

        this.allocationService.getUserAllocationByQuery(this.query).subscribe(result => {
          this.currentListSize = result.ListSize;

          params.success({
            rowData: result.Items,
            rowCount: result.ListSize,
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          params.api.sizeColumnsToFit();
          this.setExportEnable.emit(true);
        },
        error => {
          params.fail();
        });

      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.User.UserID;
  };

  onGenerateGridData(serviceYear: number, assignedToIDs: string) {
    
    this.query.ServiceYear = serviceYear;
    this.query.AssignedToIDs = assignedToIDs;

    this.query.Metadata.PageSize = 0;
    this.query.Metadata.PageNumber = 0;

    this.allocationService.getUserAllocationByQuery(this.query).subscribe(result => {
      this.currentListSize = result.ListSize;
      const items = result.Items || [];
      const height = this.currentListSize == 0 ? 300 : 107 + (result.Items.length * 28);
      const width = 552;

      this.agGrid.api.setGridOption('columnDefs', this.getColDef());

      this.updateGrid(items, width, height);

      this.setExportEnable.emit(true);
    });
  }

  updateGrid(data: ProgressAllocationUserListItem[], width: number, height: number) {
    this.data = data;

    this.currentRowHeight = height;
    this.currentGridWidth = width;
    this.agGrid.api.setGridOption('rowData', data);

    setTimeout(() => {
      this.agGrid.api.sizeColumnsToFit();
      const cellWidth = this.agGrid.api.getColumn('Closed').getActualWidth();
      this.updateTotalPanelWidths(cellWidth);

      this.updateTotals();
    }, 200);
  }

  updateTotals() {
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('closed-panel').updateSum();
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('open-panel').updateSum();
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('assigned-panel').updateSum();
  }

  updateTotalPanelWidths(width: number) {
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('closed-panel').updatePanelWidth(width);
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('open-panel').updatePanelWidth(width);
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('assigned-panel').updatePanelWidth(width);
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('progress-panel').updatePanelWidth(width);
  }

  onExport() {
    const filename =`User_Progress_Export_${this.query.ServiceYear}`;
    const assignTotal = this.agGrid.api.getStatusPanel<IAgSummationPanel>('assigned-panel').getSum();
    const openTotal = this.agGrid.api.getStatusPanel<IAgSummationPanel>('open-panel').getSum();
    const closedTotal = this.agGrid.api.getStatusPanel<IAgSummationPanel>('closed-panel').getSum();

    this.agGrid.api.exportDataAsExcel({
      fileName: filename,
      processCellCallback(params) {
        if(params.column.getColId() == 'User') {
          return AgGridFunctions.userItemValue(params);
        }

        return params.value;
      },
      processHeaderCallback(params) {
        if(params.column.getColId() == 'Progress') {
          return 'Progress(%)';
        }

        return params.column.getColDef().headerName;
      },
      appendContent: [
        {
          cells: [
            {
              data: {
                value: 'Totals',
                type: 'String'
              },
              styleId: 'status-label'
            },
            {
              data: {
                value: '',
                type: 'String'
              }
            },
            {
              data: {
                value: closedTotal.toString(),
                type: 'Number'
              },
              styleId: 'summation'
            },
            {
              data: {
                value: openTotal.toString(),
                type: 'Number'
              },
              styleId: 'summation'
            },
            {
              data: {
                value: assignTotal.toString(),
                type: 'Number'
              },
              styleId: 'summation'
            }
          ]
        }
      ]
    });

    const reportJSON = JSON.stringify({ReportName: 'User Progress Export', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: User Progress Export, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.OPPTY_SUBMISSION_USER_ALLOCATION_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }

}
