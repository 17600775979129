<div class="flex flex-auto flex-col">
    <div class="flex basis-[5%] justify-end items-center content-center">
        <mat-slide-toggle color="accent" [(ngModel)]="dxSwitch" [hideIcon]="true" (change)="onDxToggleChange()">
            <span>DX View</span>
        </mat-slide-toggle>
    </div>

    <div class="dx-trend-row" [ngStyle]="{'--row-height': currentRowHeight + 'px', '--row-width': currentGridWidth + 'px'}">
        <ag-grid-angular
        #grid
        class="ag-theme-balham ag-theme-dx-event-summary-report w-full h-full"
        [context]="gridContext"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowItems"
        [rowStyle]="{background: 'white', border: 'none', 'margin-bottom': '0px'}"
        [excelStyles]="excelStyles"
        [suppressContextMenu]="true"
        [loadingOverlayComponent]="loadingOverlayComponent"
        (gridReady)="onGridReady($event)"></ag-grid-angular>
    </div>
</div>
