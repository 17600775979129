import { Subscriber } from '../Subscriber';
import { EmptyError } from '../util/EmptyError';
export function single(predicate) {
  return source => source.lift(new SingleOperator(predicate, source));
}
class SingleOperator {
  constructor(predicate, source) {
    this.predicate = predicate;
    this.source = source;
  }
  call(subscriber, source) {
    return source.subscribe(new SingleSubscriber(subscriber, this.predicate, this.source));
  }
}
class SingleSubscriber extends Subscriber {
  constructor(destination, predicate, source) {
    super(destination);
    this.predicate = predicate;
    this.source = source;
    this.seenValue = false;
    this.index = 0;
  }
  applySingleValue(value) {
    if (this.seenValue) {
      this.destination.error('Sequence contains more than one element');
    } else {
      this.seenValue = true;
      this.singleValue = value;
    }
  }
  _next(value) {
    const index = this.index++;
    if (this.predicate) {
      this.tryNext(value, index);
    } else {
      this.applySingleValue(value);
    }
  }
  tryNext(value, index) {
    try {
      if (this.predicate(value, index, this.source)) {
        this.applySingleValue(value);
      }
    } catch (err) {
      this.destination.error(err);
    }
  }
  _complete() {
    const destination = this.destination;
    if (this.index > 0) {
      destination.next(this.seenValue ? this.singleValue : undefined);
      destination.complete();
    } else {
      destination.error(new EmptyError());
    }
  }
}
