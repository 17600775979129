import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-enterprise';
import { formatNumber, NgStyle } from '@angular/common';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { Subscription } from 'rxjs';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { AuditAction } from '../../../../../models/audit-action';
import { LoggingService } from '../../../../../services/api/logging.service';
import { RootCauseTrendItem } from '../../../../../models/root-cause-trend-item';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

@Component({
    selector: 'app-root-cause-trend-report',
    templateUrl: './root-cause-trend-report.component.html',
    styleUrls: ['./root-cause-trend-report.component.scss'],
    standalone: true,
    imports: [NgStyle, AgGridModule]
})
export class RootCauseTrendReportComponent implements OnInit, OnDestroy {

  data: RootCauseTrendItem[] = [];

  nonActionableRowName = 'Non-Actionable';
  completeRowName = 'Complete';

  columnDefs: (ColDef | ColGroupDef)[] = [];

  defaultColDef: ColDef = {
    menuTabs: [],
    minWidth: 100,
    maxWidth: 100,
    autoHeight: true
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  excelStyles = DxsrExcelStyling.excelStylesTrends;

  gridContext: any;

  rowItems: any[] = [];
  gridReady = false;

  dxSwitch = false;

  currentStartYear = 0;
  currentEndYear = 0;

  currentRowHeight = 140;
  currentGridWidth = 282;

  subs: Subscription[] = [];

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('grid') agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGridColumnsToFit();
  }

  constructor(
    private calculationsService: CalculationsService,
    private loggingService: LoggingService) {
    this.gridContext = { componentParent: this };
  }

  ngOnInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onGridReady(event) {
    this.ready.emit(true);

    this.subs.push(this.calculationsService.rootCauseTrendCalculations.subscribe(result => {

      this.data = result || [];

      this.currentRowHeight = 96 + ((this.data.length + 3) * 29);
      this.currentGridWidth = 282 + (((this.currentEndYear - this.currentStartYear) + 1) * 200);

      this.setRowItems();
      this.agGrid.api.setGridOption('rowData', this.rowItems);
      this.agGrid.api.setGridOption('columnDefs', this.getColDef());
      this.hideGridsLoadingOverlay();
      this.setExportEnable.emit(true);

      setTimeout(() => {
        this.setGridColumnsToFit();
      }, 500);
    }));
  }

  updateServiceYearRange(startYear: number, endYear: number) {
    this.currentStartYear = startYear;
    this.currentEndYear = endYear;
    this.calculationsService.getRootCauseTrend(startYear, endYear);
    this.showGridsLoadingOverlay();
  }

  setRowItems() {
    this.rowItems = [];
    const nonActionTotals = [];
    const completeTotals = [];
    const grandTotals = [];

    for(let i = this.currentStartYear; i <= this.currentEndYear; i++) {
      let nonActionableTotal = 0;
      let completeTotal = 0;

      this.data.filter(d => d.ServiceYear == i).forEach(k => {
        nonActionableTotal += k.StatusID == 1 ? k.Count : 0;
        completeTotal += k.StatusID == 2 ? k.Count : 0;
      });

      this.data.filter(d => d.ServiceYear == i).forEach(k => {
        if(k.StatusID == 1) {
          k.Progress = k.Count / nonActionableTotal;
        }

        if(k.StatusID == 2) {
          k.Progress = k.Count / completeTotal;
        }
      });

      const grandTotal = nonActionableTotal + completeTotal;

      nonActionTotals.push({ServiceYear: i, Count: nonActionableTotal, Progress: nonActionableTotal / grandTotal});
      completeTotals.push({ServiceYear: i, Count: completeTotal, Progress: completeTotal / grandTotal});
      grandTotals.push({ServiceYear: i, Count: grandTotal});
    }

    const grouped = {};
    this.data.forEach(item => {
      const check = grouped[item.StatusID];
      if(!check) {
        grouped[item.StatusID] = [item];
        return;
      }

      grouped[item.StatusID].push(item);
    });

    Object.keys(grouped).forEach(k => {
      const items = grouped[k];

      if(k == '1') {
        this.rowItems.push(this.addNewCategory(nonActionTotals, '', 'Non-Actionable'));
      }

      if(k == '2') {
        this.rowItems.push(this.addNewCategory(completeTotals, '', 'Complete'));
      }

      items.forEach(item => {
        const parent = item.StatusID == 1 ? 'Non-Actionable' : 'Complete';
        const rcItems = items.filter(i => i.RootCause == item.RootCause);
        this.rowItems.push(this.addNewCategory(rcItems, parent, item.RootCause));
      });
    });

    this.rowItems.push(this.addNewCategory(grandTotals, '', 'Grand Total'));
  }

  addNewCategory(dataItems: any[], parentName: string, displayName: string) {

    if(!dataItems || dataItems.length == 0) {
      return;
    }

    let rowItem = {};
    rowItem["DisplayName"] = displayName;
    rowItem["Parent"] = parentName;

    dataItems.forEach(i => {
      rowItem = this.addNewRowItemColumns(i, rowItem);
    });

    return rowItem;
  }

  addNewRowItemColumns(dataItem: RootCauseTrendItem, rowItem: any) {
    const serviceYear = dataItem.ServiceYear;

    rowItem[`${serviceYear}Count`] = dataItem.Count;
    rowItem[`${serviceYear}Progress`] = dataItem.Progress;

    return rowItem;
  }

  getColDef(): (ColDef | ColGroupDef)[] {

    const result: any = [
      { field: 'DisplayName', headerName: '',
        tooltipField: 'DisplayName',
        minWidth: 280,
        maxWidth: 600,
        cellClassRules: {
          'dxsr-display-name-1': (params) => {
            return params.data.DisplayName === 'Non-Actionable' 
            || params.data.DisplayName === 'Complete' 
            || params.data.DisplayName === 'Grand Total';
          },
          'dxsr-display-name-4': (params) => {
            return  params.data.Parent !== null && params.data.Parent !== "";
          },
        }
      },
    ];

    for(let i = this.currentStartYear; i <= this.currentEndYear; i++) {
      const serviceYear = i;
      const newYear = {
        headerName: `${serviceYear} Service Year`,
        children: [
          { field: `${serviceYear}Count`, headerName: 'Count', type: 'numericColumn', 
            valueFormatter: AgGridFunctions.getNumberValue,
            cellClassRules: {
              'dxsr-values-1': (params) => {
                return params.data.DisplayName === 'Non-Actionable' 
                || params.data.DisplayName === 'Complete' 
                || params.data.DisplayName === 'Grand Total';
              },
              'dxsr-values-4': (params) => {
                return params.data.Parent !== null && params.data.Parent !== "";
              }
            }
          },
          { field: `${serviceYear}Progress`, headerName: 'Progress', type: 'numericColumn',
            valueFormatter: this.getPercentValue,
            cellClassRules: {
              'dxsr-percent-1': (params) => {
                return params.data.DisplayName === 'Non-Actionable' 
                || params.data.DisplayName === 'Complete' 
                || params.data.DisplayName === 'Grand Total';
              },
              'dxsr-percent-4': (params) => {
                return params.data.Parent !== null && params.data.Parent !== "";
              }
            }
          },
        ]
      }

      result.push(newYear);
    }

    return result;
  }

  setGridColumnsToFit() {
    if(!document.getElementById('grid')) {
      return;
    }

    this.agGrid.api.sizeColumnsToFit();
  }

  showGridsLoadingOverlay() {
    this.agGrid.api.showLoadingOverlay();
  }

  hideGridsLoadingOverlay() {
    this.agGrid.api.hideOverlay();
  }

  onExport() {
    const sheets = [
      this.agGrid.api.getSheetDataForExcel({sheetName: "Root Cause Trend YTD"}),
    ];

    const filename =`RootCauseTrend_${this.currentStartYear}-${this.currentEndYear}`;

    this.agGrid.api.exportMultipleSheetsAsExcel({
      data: sheets,
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: 'Root Cause Trend', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: Root Cause Trend, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }

  getPercentValue(item: any) {
    if(item.data.DisplayName == 'Grand Total') {
      return '';
    }

    if(!item.value) {
      return '0%';
    }

    return formatNumber(item.value * 100, 'en', '1.0-1') + "%";
  }

}
