import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CmsScheduleItem } from '../../models/cms-schedule-item';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatDialog } from '@angular/material/dialog';
import { SchedulesService } from '../../services/settings/schedules.service';
import { PaymentYearSelectComponent } from '../../components/field-components/payment-year-select/payment-year-select.component';
import { RiskScoreRunTypeSelectComponent } from '../../components/field-components/risk-score-run-type-select/risk-score-run-type-select.component';
import { MonthYearSelectComponent } from '../../components/field-components/month-year-select/month-year-select.component';
import { formatDate } from '@angular/common';
import { FilterSelectItem } from '../../models/filter-select-item';
import { markedDuplicateValidator } from '../../helpers/custom-validators';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';

export class AddUpdateCmsScheduleDialogModel {
  readonly component: ComponentType<any> = AddUpdateCmsScheduleDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  data?: any;
  minWidth?: string = '45vw';
  disableClose? = true;

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
    selector: 'app-add-update-cms-schedule-dialog',
    templateUrl: './add-update-cms-schedule-dialog.component.html',
    styleUrls: ['./add-update-cms-schedule-dialog.component.scss'],
    standalone: true,
    imports: [
      MatDialogTitle, 
      CdkScrollable, 
      MatDialogContent, 
      PaymentYearSelectComponent, 
      RiskScoreRunTypeSelectComponent, 
      MatFormField, 
      MatInput, 
      FormsModule, 
      MatDatepickerInput, 
      ReactiveFormsModule, 
      MatDatepickerToggle, 
      MatSuffix, 
      MatDatepicker, 
      MatError, 
      MonthYearSelectComponent, 
      MatDialogActions, 
      MatButton, 
      MatDialogClose
    ]
})
export class AddUpdateCmsScheduleDialogComponent implements OnInit {
  
  actionType: string;
  item: CmsScheduleItem;

  form: FormGroup;

  yearControlName = 'editRiskScoreRunYear';
  typeControlName = 'editRiskScoreRunType';
  dosStartControlName = 'editCmsDosStart';
  dosEndControlName = 'editCmsDosEnd';
  deadlineControlName = 'editCmsDeadline';
  anticipatedMonthControlName = 'editCmsAnticipatedMonth';

  dosStartControl = new FormControl(null, [Validators.required]);
  dosEndControl = new FormControl(null, [Validators.required]);
  deadlineControl = new FormControl(null, [Validators.required]);

  isRiskTypeReady = false;
  isYearReady = false;

  currentYear = 0;
  currentType = 0;

  hasDuplicateError = false;

  @ViewChild(PaymentYearSelectComponent) yearSelect: PaymentYearSelectComponent;
  @ViewChild(RiskScoreRunTypeSelectComponent) riskTypeSelect: RiskScoreRunTypeSelectComponent;
  @ViewChild(MonthYearSelectComponent) monthYearSelect: MonthYearSelectComponent;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateCmsScheduleDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scheduleService: SchedulesService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    
    this.actionType = this.data.actionType;
    this.item = this.data.item || new CmsScheduleItem();

    this.form = this.fb.group({});
    this.form.addControl(this.dosStartControlName, this.dosStartControl);
    this.form.addControl(this.dosEndControlName, this.dosEndControl);
    this.form.addControl(this.deadlineControlName, this.deadlineControl);

    this.currentYear = structuredClone(this.item.RiskScoreRunYear);
    this.currentType = structuredClone(this.item.RiskScoreRunType);
  }

  ngOnInit(): void {
    //TODO
  }

  filtersReadyCheck() {
    if(this.actionType == 'add') {
      return;
    }

    if(!this.isYearReady) {
      return;
    }

    if(!this.isRiskTypeReady) {
      return;
    }

    this.setFields();
  }

  setFields() {
    this.yearSelect.setValue(this.item.RiskScoreRunYear);

    const riskFilterItem = new FilterSelectItem();
    riskFilterItem.ID = this.item.RiskScoreRunType;
    riskFilterItem.Description = this.item.RiskScoreRunTypeName;
    this.riskTypeSelect.setSingleModeValue(riskFilterItem);

    const cStart = this.item.DatesOfServiceStart ? new Date(this.item.DatesOfServiceStart) : null;
    const cEnd = this.item.DatesOfServiceEnd ? new Date(this.item.DatesOfServiceEnd) : null;
    this.dosStartControl.setValue(cStart);
    this.dosEndControl.setValue(cEnd);

    const deadlineDate = this.item.DeadlineForSubmission ? new Date(this.item.DeadlineForSubmission) : null;
    this.deadlineControl.setValue(deadlineDate);

    if(this.item.AnticipatedPaymentMonth) {
      this.monthYearSelect.setValueByString(this.item.AnticipatedPaymentMonth);
    }
  }

  onSaveClick() {
    this.item.RiskScoreRunYear = this.yearSelect.control.value || 0;

    const riskType = this.riskTypeSelect.getSingleModeValue();
    this.item.RiskScoreRunType = riskType ? riskType.ID : 0;

    this.item.DatesOfServiceStart = this.dosStartControl.value ? formatDate(this.dosStartControl.value, 'MM/dd/yyyy', 'en') : null;
    this.item.DatesOfServiceEnd = this.dosEndControl.value ? formatDate(this.dosEndControl.value, 'MM/dd/yyyy', 'en') : null;
    this.item.DeadlineForSubmission = this.deadlineControl.value ? formatDate(this.deadlineControl.value, 'MM/dd/yyyy', 'en') : null;
    this.item.AnticipatedPaymentMonth = this.monthYearSelect.getValueToString();
    this.item.ID = this.data.item.ID;

    this.scheduleService.addUpdateCmsSchedule(this.item, this.currentYear, this.currentType).subscribe(() => {
      this.dialogRef.close(true);
    },
    error => {
      if(error == 'validation:duplicate') {
        this.invokeDuplicateError();
      }
    });
  }

  onYearSelectionChange() {
    if(this.hasDuplicateError) {
      this.riskTypeSelect.control.setValidators([Validators.required]);
      this.riskTypeSelect.control.updateValueAndValidity();
      this.riskTypeSelect.control.markAsTouched();
      this.hasDuplicateError = false;
    }
  }

  onTypeSelectionChange() {
    if(this.hasDuplicateError) {
      this.yearSelect.control.setValidators([Validators.required]);
      this.yearSelect.control.updateValueAndValidity();
      this.yearSelect.control.markAsTouched();
      this.hasDuplicateError = false;
    }
  }

  invokeDuplicateError() {
    this.hasDuplicateError = true;
    this.yearSelect.control.setValidators([Validators.required, markedDuplicateValidator(this.currentYear)]);
    this.yearSelect.control.updateValueAndValidity();
    this.yearSelect.control.markAsTouched();

    const riskType = this.riskTypeSelect.getSingleModeValue();
    this.riskTypeSelect.control.setValidators([Validators.required, markedDuplicateValidator(riskType)]);
    this.riskTypeSelect.control.updateValueAndValidity();
    this.riskTypeSelect.control.markAsTouched();
  }

  onDeleteClick() {
    const message = 'This will delete the item you are editing. Do you want to continue to delete the CMS item?';
    const title = 'Warning';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: new ConfirmDialogModel(title, message, '')
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.scheduleService.deleteCmsSchedule(this.data.item as CmsScheduleItem).subscribe((result) => {
          if (result) {
            this.dialogRef.close(true);
          }
        });
      }
    });
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }
}
