import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-enterprise';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

export interface IAgHeaderInfoIconRendererParams {
  iconTooltip: string;
}

@Component({
    selector: 'app-ag-header-info-icon-renderer',
    templateUrl: './ag-header-info-icon-renderer.component.html',
    styleUrls: ['./ag-header-info-icon-renderer.component.scss'],
    standalone: true,
    imports: [MatIcon, MatTooltip]
})
export class AgHeaderInfoIconRendererComponent implements IHeaderAngularComp  {
  params: IHeaderParams;
  iconTooltip: string;

  agInit(params: IHeaderParams & IAgHeaderInfoIconRendererParams): void {
    this.params = params;
    this.iconTooltip = params.iconTooltip;
  }

  refresh(params: IHeaderParams & IAgHeaderInfoIconRendererParams): boolean {
    this.params = params;
    this.iconTooltip = params.iconTooltip;
    return true;
  }
}
