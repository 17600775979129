import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListMenuOption } from '../../../models/list-menu-option';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatSelectionListChange, MatSelectionList, MatListOption } from '@angular/material/list';
import { MatTree, MatTreeNodeDef, MatTreeNode, MatTreeNodeToggle, MatNestedTreeNode, MatTreeNodeOutlet } from '@angular/material/tree';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-tree-menu',
    templateUrl: './tree-menu.component.html',
    styleUrls: ['./tree-menu.component.scss'],
    standalone: true,
    imports: [MatSelectionList, MatTree, MatTreeNodeDef, MatTreeNode, MatTreeNodeToggle, MatListOption, MatIcon, MatNestedTreeNode, NgClass, MatTreeNodeOutlet]
})
export class TreeMenuComponent implements OnInit {

  @Input() data: ListMenuOption[] = [];
  @Input() defaultOptionID = 1;

  selectedOption: ListMenuOption = new ListMenuOption();

  @Output() optionChange: EventEmitter<ListMenuOption> = new EventEmitter<ListMenuOption>();

  treeControl = new NestedTreeControl<ListMenuOption>(
    node => node.Children
  );

  constructor() { 
    //TODO
  }

  ngOnInit(): void {
    //TODO
  }

  hasChild = (_: number, node: ListMenuOption) => !!node.Children?.length;

  setSelectedOption(id: number) {
    this.data.forEach(d => {
      const options = this.treeControl.getChildren(d) as ListMenuOption[];
      const found = options?.find(o => o.ID == id);
      if(found) {
        this.treeControl.expand(d);
        this.selectedOption = found;
        this.optionChange.emit(this.selectedOption);
      }
    });

    if(this.selectedOption.ID) {
      return;
    }

    this.data.forEach(d => {
      if(d.ID == id) {
        this.selectedOption = d;
        this.optionChange.emit(this.selectedOption);
      }
    });
  }

  onSelectedConfigOption(event: MatSelectionListChange) {
    if(!event.options) {
      return;
    }

    const option = event.options[0].value;

    if(option.ID == this.selectedOption.ID) {
      return;
    }

    this.data.forEach(d => {
      const options = this.treeControl.getChildren(d) as ListMenuOption[];
      const prevFound = options?.find(o => o.ID == this.selectedOption.ID);
      const nextFound = options?.find(o => o.ID == option.ID);
      if(prevFound && !nextFound) {
        this.treeControl.collapse(d);
      }
    });

    this.selectedOption = option;

    this.optionChange.emit(option);
  }

}
