<h1 mat-dialog-title>Notes History</h1>

<div mat-dialog-content>
  <div class="flex flex-auto flex-col">
    <div class="note-history-container">
      @for (note of notesHistory; track note) {
        <div class="note-history-item">
          <p><span class="note-history-user">{{note.User.FirstName[0] + note.User.LastName[0]}} ({{note.Timestamp | date: 'MM/dd/yy'}})</span>
          {{note.Message}}
        </p>
      </div>
    }
  </div>
</div>
</div>

<mat-dialog-actions align="end">

  <button mat-flat-button class="primary-button" mat-dialog-close>OK</button>

</mat-dialog-actions>
