import { SimpleOuterSubscriber, innerSubscribe, SimpleInnerSubscriber } from '../innerSubscribe';
export function sample(notifier) {
  return source => source.lift(new SampleOperator(notifier));
}
class SampleOperator {
  constructor(notifier) {
    this.notifier = notifier;
  }
  call(subscriber, source) {
    const sampleSubscriber = new SampleSubscriber(subscriber);
    const subscription = source.subscribe(sampleSubscriber);
    subscription.add(innerSubscribe(this.notifier, new SimpleInnerSubscriber(sampleSubscriber)));
    return subscription;
  }
}
class SampleSubscriber extends SimpleOuterSubscriber {
  constructor() {
    super(...arguments);
    this.hasValue = false;
  }
  _next(value) {
    this.value = value;
    this.hasValue = true;
  }
  notifyNext() {
    this.emitValue();
  }
  notifyComplete() {
    this.emitValue();
  }
  emitValue() {
    if (this.hasValue) {
      this.hasValue = false;
      this.destination.next(this.value);
    }
  }
}
