import { Component, Inject, OnInit } from '@angular/core';
import { DiagnosisEventNote } from '../../models/diagnosis-event-note';
import { DiagnosisEventItem } from '../../models/diagnosis-event-item';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DiagnosisEventService } from '../../services/diagnosis-event/diagnosis-event.service';
import { ComponentType } from '@angular/cdk/portal';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { DatePipe } from '@angular/common';

export class DiagnosisEventNotesDialogModel {
  readonly component: ComponentType<any> = DiagnosisEventNotesDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  width?: string = '35vw';
  data?: any;

  constructor(data: DiagnosisEventItem) {
    this.data = data;
  }
}

@Component({
    selector: 'app-diagnosis-event-notes-dialog',
    templateUrl: './diagnosis-event-notes-dialog.component.html',
    styleUrls: ['./diagnosis-event-notes-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, MatDialogClose, DatePipe]
})
export class DiagnosisEventNotesDialogComponent implements OnInit {

  notesHistory: DiagnosisEventNote[] = [];

  constructor
  (
    public dialogRef: MatDialogRef<DiagnosisEventNotesDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: DiagnosisEventItem,
    private opptyService: DiagnosisEventService,
  ) { }

  ngOnInit(): void {
    this.opptyService.getNotesByEventID(this.data.ID).subscribe(result => {
      this.notesHistory = result;
    });
  }

}
