import { Component } from "@angular/core";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Component({
    selector: 'app-load-spinner',
    templateUrl: './load-spinner.component.html',
    styleUrls: ['./load-spinner.component.css'],
    standalone: true,
    imports: [MatProgressSpinner]
})

export class LoadSpinnerComponent {

}
