<div mat-dialog-title class="w-[100%]">
    <div class="w-[80%]">
        <h1>MOR File Details</h1>
    </div>
    <div class="w-[20%] flex justify-end">
        <button (click)="handleClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <div id="fileInfo">
        <div class="grid grid-cols-6 gap-2">
            <div class="col-span-3 flex items-center gap-2">
                <span class="mor-dialog-label whitespace-nowrap">Payer:</span>
                <p>{{data.payerAlias}}</p>
            </div>
            <div class="col-span-3 flex items-center gap-2">
                <span class="mor-dialog-label whitespace-nowrap">File Name:</span>
                <p class="overflow-hidden text-ellipsis whitespace-nowrap" [title]="data.fileName">
                    {{ data.fileName }}
                </p>
            </div>
            <div class="col-span-3 flex items-center gap-2">
                <span class="mor-dialog-label whitespace-nowrap">Upload Date:</span>
                <p>{{model.uploadDateTime}}</p>
            </div>
            <div class="col-span-3 flex items-center gap-2">
                <span class="mor-dialog-label whitespace-nowrap">Uploaded By:</span>
                <p>{{data.uploadedBy}}</p>
            </div>
            <div class="col-span-3 flex items-center gap-2">
                <span class="mor-dialog-label whitespace-nowrap">File Status:</span>
                <p>{{data.fileStatus}}</p>
            </div>
        </div>
    </div>        
    @if (data.fileStatus?.toLowerCase() != 'failed') 
    {
        <div id="recordsInfo">
            <table class="table-fixed">
                <thead>
                    <tr>
                        <th>File Type</th>
                        <th>Processed</th>
                        <th>Errors</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{model.recordType}}</td>
                        <td><a (click)="onOpenDialog(false)">{{model.processedCount}}</a></td>
                        <td><a (click)="onOpenDialog(true)">{{model.errorsCount}}</a></td>
                        <td>{{model.totalCount}}</td>
                    </tr>
                </tbody>
            </table>
        </div>   
    }
</div>