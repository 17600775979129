<div class="flex gap-4">
  <div 
    class="flex-1 w-64 dropzone" 
    (drop)="onDrop($event)" 
    (dragover)="onDragOver($event)" 
    (dragleave)="onDragLeave($event)">
    <div><div>{{ dropzoneLabel }}</div><button mat-flat-button type="button" [disabled]="disabled" (click)="fileInput.click()">{{ browseButtonLabel }}</button></div>
    <input 
      type="file" 
      #fileInput 
      [multiple]="multiple"
      [disabled]="disabled"
      (change)="onFileSelected($event)" 
      hidden>
  </div>

  @if (trackUploads) {
    <div class="flex-1 w-64">
      <ul>
        @for (file of files; track file) {
          <li>
            <div class="flex fileItem gap-1">
              <mat-icon fontSet="material-icons-outlined" class="self-center">description</mat-icon>
              <div class="flex-col fileInfo">
                {{ file.name + ", " + formatFileSize(file.size) }}
                <mat-progress-bar mode="determinate" [value]="progressBars[files.indexOf(file)]"></mat-progress-bar>
              </div>
              <button (click)="deleteFile(files.indexOf(file))" class="flex self-center">
                <mat-icon fontSet="material-icons-outlined" class="self-center">delete</mat-icon>
              </button>
            </div>
          </li>
        }
      </ul>
    </div>
  }
</div>
