import { Subscriber } from '../Subscriber';
export function sequenceEqual(compareTo, comparator) {
  return source => source.lift(new SequenceEqualOperator(compareTo, comparator));
}
export class SequenceEqualOperator {
  constructor(compareTo, comparator) {
    this.compareTo = compareTo;
    this.comparator = comparator;
  }
  call(subscriber, source) {
    return source.subscribe(new SequenceEqualSubscriber(subscriber, this.compareTo, this.comparator));
  }
}
export class SequenceEqualSubscriber extends Subscriber {
  constructor(destination, compareTo, comparator) {
    super(destination);
    this.compareTo = compareTo;
    this.comparator = comparator;
    this._a = [];
    this._b = [];
    this._oneComplete = false;
    this.destination.add(compareTo.subscribe(new SequenceEqualCompareToSubscriber(destination, this)));
  }
  _next(value) {
    if (this._oneComplete && this._b.length === 0) {
      this.emit(false);
    } else {
      this._a.push(value);
      this.checkValues();
    }
  }
  _complete() {
    if (this._oneComplete) {
      this.emit(this._a.length === 0 && this._b.length === 0);
    } else {
      this._oneComplete = true;
    }
    this.unsubscribe();
  }
  checkValues() {
    const {
      _a,
      _b,
      comparator
    } = this;
    while (_a.length > 0 && _b.length > 0) {
      let a = _a.shift();
      let b = _b.shift();
      let areEqual = false;
      try {
        areEqual = comparator ? comparator(a, b) : a === b;
      } catch (e) {
        this.destination.error(e);
      }
      if (!areEqual) {
        this.emit(false);
      }
    }
  }
  emit(value) {
    const {
      destination
    } = this;
    destination.next(value);
    destination.complete();
  }
  nextB(value) {
    if (this._oneComplete && this._a.length === 0) {
      this.emit(false);
    } else {
      this._b.push(value);
      this.checkValues();
    }
  }
  completeB() {
    if (this._oneComplete) {
      this.emit(this._a.length === 0 && this._b.length === 0);
    } else {
      this._oneComplete = true;
    }
  }
}
class SequenceEqualCompareToSubscriber extends Subscriber {
  constructor(destination, parent) {
    super(destination);
    this.parent = parent;
  }
  _next(value) {
    this.parent.nextB(value);
  }
  _error(err) {
    this.parent.error(err);
    this.unsubscribe();
  }
  _complete() {
    this.parent.completeB();
    this.unsubscribe();
  }
}
