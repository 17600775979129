<div mat-dialog-title class="w-[100%]">
    <div class="w-[80%]">
        <h1>Record Details</h1>
    </div>
    <div class="w-[20%] flex justify-end">
        <button (click)="handleClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <div class="flex flex-row pt-3 pb-1">
        <div class="flex flex-row gap-2 w-[40rem]">
            <mat-form-field color="primary" [appearance]="'outline'" floatLabel="always" class="flex-auto">
                <mat-label>MBI</mat-label>
                <input matInput type="text" [formControl]="mbiControl" maxlength="11" (focus)="mbiControl.markAsTouched()" />
                @if (mbiControl.hasError('invalidLength')) {
                    <mat-error class="flex text-[10px] whitespace-nowrap">
                        Enter 11 characters for MBI or leave blank to search all
                    </mat-error>
                }
            </mat-form-field>
            <button mat-flat-button class="primary-button mt-5"
            (click)="onClickSearchMBI()">Search</button>
        </div>
        <div class="flex flex-row w-[100%] pb-4 justify-end">
            <div class="flex flex-row ml-4 gap-2 items-end">
                <span class="mor-dialog-label whitespace-nowrap">
                    Upload Date:
                </span>
                <p class="whitespace-nowrap">
                    {{model.uploadDate}}
                </p>
            </div>
            <div class="flex flex-row ml-4 gap-2 items-end">
                <span class="mor-dialog-label whitespace-nowrap">
                    Payer:
                </span>
                <p>
                    {{model.payerAlias}}
                </p>
            </div>
            <!-- <div class="col-span-2 flex flex-row gap-2 items-start pt-[14px]">
                @if (!model.viewErrors) {
                    <span class="mor-dialog-label whitespace-nowrap">
                        File Type:
                    </span>
                    <p>
                        {{model.fileType == 'Multiple' ? model.fileType : 'MOR ' + model.fileType}}
                    </p>
                }
            </div> -->
            <div class="flex flex-row ml-4 gap-2 items-end">
                <span class="mor-dialog-label whitespace-nowrap">
                    Uploaded By:
                </span>
                <p>
                    {{model.uploadedBy}}
                </p>
            </div>
        </div>
    </div>
    <div class="content-grid-container">
        <ag-grid-angular
            class="ag-theme-balham ag-grid-container"
            [suppressPaginationPanel]="true"
            [pagination]="true"
            [getRowId]="getRowId"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>