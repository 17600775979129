<div class="flex h-full w-full">
  <div class="flex flex-auto justify-center items-center content-center">
    @if (assignToActive) {
      <button
        mat-flat-button class="primary-button"
        [disabled]="assignDisabled"
        [matTooltip]="mode == 'oppty-list' ? 'Assign Opportunities' : 'Assign Diagnosis Events'"
        [matTooltipClass]="'app-tooltip'"
        [matTooltipPosition]="'right'"
        (click)="onAssignButtonClick()"
      >Assign</button>
    }

    @if (!assignToActive) {
      <div
        [matTooltip]="mode == 'oppty-list' ? 'User must have the Assign Opportunities permission granted.' : 'User must have the Assign Diagnosis Events permission granted.'"
        [matTooltipClass]="'app-tooltip'"
        [matTooltipPosition]="'right'">
        <button
          mat-flat-button class="primary-button"
        [disabled]="true">Assign</button>
      </div>
    }
  </div>
</div>
