<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }
  <mat-select
    [multiple]="multiple"
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (closed)="onSelectionChange()">
    @if (multiple) {
      <mat-select-trigger>
        @if ((control.value?.length || 0) === 1) {
          <span>
            {{control.value?.[0].Description || ''}}
          </span>
        }
        @if (allOption.Selected) {
          <span>
            All
          </span>
        }
        @if (!allOption.Selected && (control.value?.length || 0) < options.length && (control.value?.length || 0) > 1) {
          <span>
            Multiple
          </span>
        }
        @if ((control.value?.length || 0) > 1) {
          <span>
            ({{getSelectCount()}})
          </span>
        }
      </mat-select-trigger>
    }

    @if (selectAllEnabled && multiple && filteredOptions?.length > 1) {
      <mat-option
        #allItem
        [value]="allOption"
      (click)="onToggleItem(allItem)">All</mat-option>
    }

    @for (option of filteredOptions; track option) {
      <mat-option
        #item
        [value]="option"
        (click)="onToggleItem(item)">
        {{option.Description}}
      </mat-option>
    }
  </mat-select>

  @if (control.hasError('required')) {
    <mat-error>Status is <strong>required</strong></mat-error>
  }
</mat-form-field>
