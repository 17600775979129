import { QueryDateRange } from "./diagnosis-event-query";
import { QueryMetadata } from "./query-metadata";

export class CmsScheduleQuery {
    RiskScoreRunYears?: string | null;
    RiskScoreRunTypes?: string | null;
    DatesOfServiceStart?: QueryDateRange | null;
    DatesOfServiceEnd?: QueryDateRange | null;
    DeadlineForSubmission?: QueryDateRange | null;
    AnticipatedPaymentMonth?: QueryDateRange | null;
    Metadata?: QueryMetadata | null;
}
