<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }
  <mat-select
    [multiple]="multiple"
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="getTooltipString()"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (closed)="onSelectionChange()">
    @if (multiple) {
      <mat-select-trigger>
        @if ((control.value?.length || 0) === 1) {
          <span>
            {{control.value?.[0].Version || control.value?.[0]}}
          </span>
        }
        @if ((control.value?.length || 0) > 1 && (control.value?.length - 1) == options.length) {
          <span>
            All
          </span>
        }
        @if ((control.value?.length || 0) > 1 && (control.value?.length - 1) < options.length) {
          <span>
            Multiple
          </span>
        }
        @if ((control.value?.length || 0) > 1) {
          <span>
            ({{getSelectCount()}})
          </span>
        }
      </mat-select-trigger>
    }

    @if (multiple) {
      <mat-option
        #allItem
        [value]="allOption"
      (click)="onToggleItem(allItem)">All</mat-option>
    }

    @for (option of options; track option) {
      <mat-option
        #item
        [value]="option"
        (click)="onToggleItem(item)">
        {{option.Version}}
      </mat-option>
    }
  </mat-select>
  @if (!multiple && control.value) {
    <mat-hint>Version Date Range: {{control.value.StartDate | date: 'M/d/yyyy'}} - {{control.value.EndDate | date: 'M/d/yyyy'}}</mat-hint>
  }
</mat-form-field>
