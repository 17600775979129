import { getState, patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { PayerItem } from "../models/payer-item";
import { computed } from "@angular/core";

type MorDataState = {
    uploadedFiles: File[];
    currentFile: File | null;
    selectedPayer: PayerItem | null;
}

const initialState: MorDataState = {
    uploadedFiles: [],
    currentFile: null,
    selectedPayer: null,
}

export const MorDataStore = signalStore(
    withState(initialState),
    withComputed((state) => ({
        formValid: computed(() => {
            const currentFile = state.currentFile();
            const selectedPayer = state.selectedPayer();
            const isValid = currentFile != null && selectedPayer != null;
            return isValid;
        }),
    })),
    withMethods((store) => ({
        setCurrentFile(input: File | null) {
            patchState(store, { currentFile: input });
        },
        setSelectedPayer(input: PayerItem) {
            patchState(store, { selectedPayer: input });
        },
        uploadCurrentFile(): string {
            //TODO: try/catch, API call that uploads the current file. Subscribe, then...
            const state = getState(store);
            const updatedUploadsList = state.uploadedFiles;
            if (state.currentFile) {
                updatedUploadsList.push(state.currentFile); // here, we'll push the result object returned from API call
            }
            patchState(store, { currentFile: null, uploadedFiles: updatedUploadsList, selectedPayer: null });
            return "Coming soon!"; //TODO: create informative message for display in toast re: success/failure.
        },
        resetState() {
            patchState(store, initialState);
        },
    })),
);