<div class="progress-allocation-payer-container">

  <div class="pap-header-row">
    <div class="pap-header-item grid grid-cols-5">
      <div class="justify-start items-center content-center"></div>

      <div class="progress-cell flex grow justify-end items-center content-center">
        <h3>Progress</h3>
      </div>

      @if (expanded) {
        <div class="closed-cell flex grow justify-end items-center content-center">
          <h3>Closed</h3>
        </div>
      }

      @if (expanded) {
        <div class="open-cell flex grow justify-end items-center content-center">
          <h3>Open</h3>
        </div>
      }

      <div class="total-cell flex grow justify-end items-center content-center">
        <h3>Total</h3>
      </div>
    </div>
  </div>

  @for (item of payerItems; track item) {
    <div class="pap-list-item grid grid-cols-5">
      <div class="progress-bar-cell flex flex-col grow justify-start items-start content-start" [routerLink]="'/opportunity-list'">
        <h3
          [matTooltip]="item.Payer.Name"
          [matTooltipClass]="'app-tooltip'"
          [matTooltipPosition]="'left'"
          [routerLink]="['/opportunity-list']"
          [queryParams]="{payer: item.Payer.Name}">
          {{item.Payer.Abbreviation}}
        </h3>
        <mat-progress-bar [value]="item.Progress * 100"></mat-progress-bar>
      </div>
      <div class="progress-cell flex grow justify-end items-center content-center">
        <h3>{{item.Progress | percent}}</h3>
      </div>
      @if (expanded) {
        <div class="closed-cell flex grow justify-end items-center content-center">
          <h3>{{item.Closed | number}}</h3>
        </div>
      }
      @if (expanded) {
        <div class="open-cell flex grow justify-end items-center content-center">
          <h3>{{item.Open | number}}</h3>
        </div>
      }
      <div class="total-cell flex grow justify-end items-center content-center">
        <h3>{{item.Total | number}}</h3>
      </div>
    </div>
  }

  <div class="pap-footer-row grid grid-cols-5">
    <div class="name-cell flex-auto justify-start items-center content-center">
      <strong>Total</strong>
    </div>

    <div class="progress-cell flex grow justify-end items-center content-center"></div>

    @if (expanded) {
      <div class="closed-cell flex grow justify-end items-center content-center">
        <h3>{{closedTotal | number}}</h3>
      </div>
    }

    @if (expanded) {
      <div class="open-cell flex grow justify-end items-center content-center">
        <h3>{{openTotal | number}}</h3>
      </div>
    }

    <div class="assigned-cell flex grow justify-end items-center content-center">
      <h3>{{grandTotal | number}}</h3>
    </div>
  </div>
</div>
