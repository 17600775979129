import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-ag-icon-renderer',
    templateUrl: './ag-icon-renderer.component.html',
    styleUrls: ['./ag-icon-renderer.component.scss'],
    standalone: true,
    imports: [MatIcon]
})
export class AgIconRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  icon = '';
  disableObject: any;

  disabled = false;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.icon = params['icon'];
    this.disableObject = params['disabledObject'];

    if(this.disableObject) {
      this.checkDisabled();
    }
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.params = params;
    this.icon = params['icon'];
    this.disableObject = params['disabledObject'];

    if(this.disableObject) {
      this.checkDisabled();
    }

    return true;
  }

  checkDisabled() {
    this.disabled = this.params.data[this.disableObject.field].includes(this.disableObject.value);
  }

}
