import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { ColDef, ColGroupDef, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, IServerSideDatasource, SelectionChangedEvent, SideBarDef, StatusPanelDef } from 'ag-grid-community';
import { ReplaySubject, Subscription } from 'rxjs';
import { ColumnConfigItem } from '../../../models/column-config-item';
import { FilterSelectItem } from '../../../models/filter-select-item';
import { DiagnosisEventItem } from '../../../models/diagnosis-event-item';
import { ToggleItem } from '../../../models/toggle-item';
import { DiagnosisEventService } from '../../../services/diagnosis-event/diagnosis-event.service';
import { SettingsKeys, SettingsService } from '../../../services/settings/settings.service';
import { EditIconRendererComponent } from '../../ag-grid-components/edit-icon-renderer/edit-icon-renderer.component';
import { PayerSelectComponent } from '../../field-components/payer-select/payer-select.component';
import { PreferredActionSelectComponent } from '../../field-components/preferred-action-select/preferred-action-select.component';
import { RootCauseSelectComponent } from '../../field-components/root-cause-select/root-cause-select.component';
import { StatusSelectComponent } from '../../field-components/status-select/status-select.component';
import { StatusStateSelectComponent } from '../../field-components/status-state-select/status-state-select.component';
import { UserSearchByPermissionComponent } from '../../field-components/user-search-by-permission/user-search-by-permission.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { AssignOpptyDialogComponent, AssignOpptyDialogModel } from '../../../dialogs/assign-oppty-dialog/assign-oppty-dialog.component';
import { DiagnosisEventQuery, QueryDateRange } from '../../../models/diagnosis-event-query';
import { DosSelectComponent } from '../../field-components/dos-select/dos-select.component';
import { EditOpptyDialogComponent, EditOpptyDialogData, EditOpptyDialogModel } from '../../../dialogs/edit-oppty-dialog/edit-oppty-dialog.component';
import { StringFormatter } from '../../../helpers/string-formatter';
import { DiagnosisEventType } from '../../../models/diagnosis-event-type';
import { DateQuarter } from '../../../models/date-quarter';
import { NotesRendererComponent } from '../../ag-grid-components/notes-renderer/notes-renderer.component';
import { DiagnosisEventNotesDialogComponent, DiagnosisEventNotesDialogModel } from '../../../dialogs/diagnosis-event-notes-dialog/diagnosis-event-notes-dialog.component';
import { ProgressAllocationUserComponent } from '../../sub-components/progress-allocation-user/progress-allocation-user.component';
import { AgPaginatorComponent, IAgPaginator } from '../../ag-grid-components/ag-paginator/ag-paginator.component';
import { AgAssignButtonComponent, IAgAssignButton } from '../../ag-grid-components/ag-assign-button/ag-assign-button.component';
import { AgExportButtonComponent, IAgExportButton } from '../../ag-grid-components/ag-export-button/ag-export-button.component';
import { DiagnosisEventFilterValues } from '../../../models/diagnosis-event-filter-values';
import { PermissionMap, PermissionService } from '../../../services/settings/permission.service';
import { Permission } from '../../../models/permission';
import { UserService } from '../../../services/user/user.service';
import { User } from '../../../models/user.model';
import { QueryMetadata, QuerySort } from '../../../models/query-metadata';
import { ExportService } from '../../../services/files/export.service';
import { AgNoRowsOverlayComponent } from '../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgRowSelectedCountComponent, IAgRowSelectedCount } from '../../ag-grid-components/ag-row-selected-count/ag-row-selected-count.component';
import { AgOpptyEditButtonComponent, IAgOpptyEditButton } from '../../ag-grid-components/ag-oppty-edit-button/ag-oppty-edit-button.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgHeaderCheckboxComponent, IAgHeaderCheckbox } from '../../ag-grid-components/ag-header-checkbox/ag-header-checkbox.component';
import { Comparitors } from '../../../helpers/comparitors';
import { ListMenuOption } from '../../../models/list-menu-option';
import { formatDate, NgClass } from '@angular/common';
import { AgLoadingOverlayComponent } from '../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { PayerItem } from '../../../models/payer-item';
import { FieldFilterService } from '../../../services/filters/field-filter.service';
import { FilterFieldSearchSelectComponent } from '../../field-components/filter-field-search-select/filter-field-search-select.component';
import { TemplateService } from '../../../services/settings/template.service';
import { TemplateConfigFieldItem } from '../../../models/template-config-field-item';
import { PreferredActionType } from '../../../models/preferred-action-type';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { ProgressAllocationTotalsComponent } from '../../sub-components/progress-allocation-totals/progress-allocation-totals.component';
import { ProgressAllocationPreferredActionComponent } from '../../sub-components/progress-allocation-preferred-action/progress-allocation-preferred-action.component';
import { ProgressAllocationPayerComponent } from '../../sub-components/progress-allocation-payer/progress-allocation-payer.component';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import _ from 'lodash';

@Component({
    selector: 'app-opportunity-list-page',
    templateUrl: './opportunity-list-page.component.html',
    styleUrls: ['./opportunity-list-page.component.scss'],
    standalone: true,
    imports: [
      NgClass, 
      MatSelectionList, 
      MatListOption, 
      MatTooltip, 
      MatIcon, 
      ProgressAllocationTotalsComponent, 
      ProgressAllocationUserComponent, 
      ProgressAllocationPreferredActionComponent, 
      ProgressAllocationPayerComponent, 
      MatChipListbox, 
      MatChipOption, 
      PreferredActionSelectComponent, 
      PayerSelectComponent, 
      StatusStateSelectComponent, 
      StatusSelectComponent, 
      RootCauseSelectComponent, 
      UserSearchByPermissionComponent, 
      MatFormField, 
      MatLabel, 
      MatInput, 
      FormsModule, 
      ReactiveFormsModule, 
      MatError, 
      DosSelectComponent, 
      FilterFieldSearchSelectComponent, 
      MatButton, 
      AgGridModule
    ]
})
export class OpportunityListPageComponent implements OnInit, AfterViewInit, OnDestroy {

  mode = 'oppty-list';
  sideMenuCollapsed = false;
  defaultChipSelection = null;
  currentChipSelection = '';
  isDosFilterToggled = false;
  defaultUser = null;
  defaultPayer = null;

  isGridReady = false;
  isPreferredActionReady = false;
  isPayerReady = false;
  isStatusStateReady = false;
  isStatusReady = false;
  isRootCauseReady = false;
  isPaymentYearReady = false;
  isAssignedReady = false;
  isDosReady = false;

  formValid = false;
  canQuickFilterUnselect = false;
  isFirstGenerate = true;

  currentUser: User;
  activeUserPerms: Permission[];
  editAllActive = false;
  assignToActive = false;
  exportActive = false;
  currentListSize = 0;
  batchEditLimit = 300;
  allSelected = new ReplaySubject<boolean>(1);
  allBatchEditEnabled = false;

  paTypeIDs = [PreferredActionType.Opportunity.valueOf()];

  currentPageRows = [];

  exportTooltip = 'Export Opportunities';

  opptyFilterChips: ToggleItem[] = [
    {Name: "Master", DisplayName: "Master", Selected: false},
    {Name: "99499", DisplayName: "99499", Selected: false},
    {Name: "Supplemental Feed", DisplayName: "Supp Feed", Selected: false},
    {Name: "Research EMR", DisplayName: "Research EMR", Selected: false}
  ];

  dxFilterChips: ToggleItem[] = [
    {Name: "Payer Validation", DisplayName: "Payer Validation", Selected: false},
    {Name: "Non-Actionable", DisplayName: "Non-Actionable", Selected: false}
  ];

  menuOptions: ListMenuOption[] = [
    {ID: 1, Name: "Allocation", Icon: "content_paste", IconOutlined: false, Disabled: false},
    {ID: 2, Name: "Users", Icon: "group", IconOutlined: false, Disabled: false},
    {ID: 3, Name: "Preferred Actions", Icon: "analytics", IconOutlined: true, Disabled: false},
    {ID: 4, Name: "Top Payers", Icon: "paid", IconOutlined: true, Disabled: false}
  ];

  frameworkComps = {
    editRenderer: EditIconRendererComponent,
    notesRenderer: NotesRendererComponent
  }

  sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnExpandAll: true
        }
      },
    ]
  };

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgRowSelectedCountComponent,
        key: 'row-selected-count',
        align: 'left'
      },
      {
        statusPanel: AgAssignButtonComponent,
        key: 'assign-btn',
        align: 'left'
      },
      {
        statusPanel: AgOpptyEditButtonComponent,
        key: 'edit-btn',
        align: 'left'
      },
      {
        statusPanel: AgPaginatorComponent,
        key: 'ag-paginator'
      },
      {
        statusPanel: AgExportButtonComponent,
        key: 'export-btn'
      },
    ],
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  checkboxColDef: ColDef = { 
    colId: 'checkbox', checkboxSelection: true, headerComponent: AgHeaderCheckboxComponent,
    suppressColumnsToolPanel: true, minWidth: 68, maxWidth: 68, pinned: 'left', lockPinned: true, sortable: false
  };

  editColDef: ColDef = { 
    colId: 'edit', headerName: 'Edit', minWidth: 68, maxWidth: 68, suppressColumnsToolPanel: true, 
    pinned: 'right', lockPinned: true, cellRenderer: 'editRenderer', sortable: false 
  };

  opptyColumnDefs: ColDef[] = [
    { colId: 'checkbox', checkboxSelection: true, headerComponent: AgHeaderCheckboxComponent,
    suppressColumnsToolPanel: true, minWidth: 68, maxWidth: 68, pinned: 'left', lockPinned: true, sortable: false },

    //OLD OPPTY FIELDS
    // { field: 'Status', headerName: 'Status', headerTooltip: 'Status', valueFormatter: this.filterOptionValue },
    // { field: 'Payer', headerName: 'Payer', headerTooltip: 'Payer', minWidth: 150, valueFormatter: this.payerValue, tooltipValueGetter: this.payerTooltip },
    // { field: 'AssignedTo', headerName: 'Assigned User', headerTooltip: 'Assigned User', minWidth: 150, valueFormatter: this.assignedUserValue, tooltipValueGetter: this.assingedUserTooltip },
    // { field: 'EMRPID', headerName: 'EMR PID', type: 'numericColumn', headerTooltip: 'EMR Patient ID' },
    // { field: 'DateOfService', headerName: 'DOS', type: 'numericColumn', headerTooltip: 'DOS' },
    // { field: 'VisitID', headerName: 'Visit ID', type: 'numericColumn', headerTooltip: 'Visit ID' },
    // { field: 'ICDCODE', headerName: 'ICD10', type: 'numericColumn', headerTooltip: 'ICD10' },
    // { field: 'PreferredAction', headerName: 'Preferred Action', headerTooltip: 'Preferred Action', minWidth: 150, valueFormatter: this.filterOptionValue },
    // { field: 'RootCause', headerName: 'Root Cause', headerTooltip: 'Root Cause', minWidth: 150, valueFormatter: this.filterOptionValue , tooltipValueGetter: this.filterTooltipValue },
    // { field: 'Notes', headerName: 'Notes', headerTooltip: 'Notes', minWidth: 150, cellRenderer: 'notesRenderer'},
    // { field: 'StatusState', headerName: 'Status State', headerTooltip: 'Status State', valueFormatter: this.filterOptionValue },
    // { field: 'SuperbillStatus', headerName: 'Superbill Status', headerTooltip: 'Superbill Status' },
    // { field: 'HCCCode', headerName: 'CMS HCC', type: 'numericColumn', headerTooltip: 'CMS HCC'},
    // { field: 'BillingChargePostingDate', headerName: 'Billing Charges Posting Date', type: 'numericColumn', headerTooltip: 'Billing Charges Posting Date' },
    // { field: 'EMRDocumentVersion', headerName: 'Doc. Version', headerTooltip: 'Doc. Version' },
    // { field: 'EMRDocumentVersionCreateDate', headerName: 'Doc. Create Date', type: 'numericColumn', headerTooltip: 'Doc. Create Date' },
    // { field: 'PatientFirstName', headerName: 'Patient First Name', headerTooltip: 'Patient First Name' },
    // { field: 'PatientLastName', headerName: 'Patient Last Name', headerTooltip: 'Patient Last Name' },
    // { field: 'PatientDateOfBirth', headerName: 'Patient DOB', type: 'numericColumn', headerTooltip: 'Patient DOB' },
    // { field: 'LastModifiedBy', headerName: 'Last Modified By', headerTooltip: 'Last Modified By', minWidth: 150, valueFormatter: this.assignedUserValue, tooltipValueGetter: this.assingedUserTooltip },
    // { field: 'LastModifiedDateTime', headerName: 'Last Modified Date', headerTooltip: 'Last Modified Date', minWidth: 150},
    // { field: 'CCN', headerName: 'CCN', type: 'numericColumn', headerTooltip: 'CCN' },

    { field: 'ID', headerName: 'ID', headerTooltip: 'ID' },
    { field: 'EventTypeName', headerName: 'Event Type', headerTooltip: 'Event Type' },
    { field: 'Status', headerName: 'Status', headerTooltip: 'Status', valueFormatter: this.filterOptionValue },
    { field: 'Payer', headerName: 'Payer', headerTooltip: 'Payer', minWidth: 150, valueFormatter: this.payerValue, tooltipValueGetter: this.payerTooltip },
    { field: 'AssignedTo', headerName: 'Assigned User', headerTooltip: 'Assigned User', minWidth: 150, valueFormatter: this.assignedUserValue, tooltipValueGetter: this.assingedUserTooltip },
    { field: 'EMRPID', headerName: 'EMR PID', type: 'numericColumn', headerTooltip: 'EMR Patient ID' },
    { field: 'DateOfService', headerName: 'DOS', type: 'numericColumn', headerTooltip: 'DOS' },
    { field: 'ServiceLocation', headerName: 'Service Location', headerTooltip: 'Service Location', tooltipValueGetter: this.tooltipValue },
    { field: 'VisitID', headerName: 'Visit ID', type: 'numericColumn', headerTooltip: 'Visit ID' },
    { field: 'ICDCODE', headerName: 'ICD10', type: 'numericColumn', headerTooltip: 'ICD10' },
    { field: 'PreferredAction', headerName: 'Preferred Action', headerTooltip: 'Preferred Action', minWidth: 150, valueFormatter: this.filterOptionValue },
    { field: 'RootCause', headerName: 'Root Cause', headerTooltip: 'Root Cause', minWidth: 150, valueFormatter: this.filterOptionValue , tooltipValueGetter: this.filterTooltipValue },
    { field: 'Notes', headerName: 'Notes', headerTooltip: 'Notes', minWidth: 150, cellRenderer: 'notesRenderer'},
    { field: 'StatusState', headerName: 'Status State', headerTooltip: 'Status State', valueFormatter: this.filterOptionValue },
    { field: 'SuperbillStatus', headerName: 'Superbill Status', headerTooltip: 'Superbill Status' },
    { field: 'HCCCode', headerName: 'CMS HCC', type: 'numericColumn', headerTooltip: 'CMS HCC'},
    { field: 'BillingChargePostingDate', headerName: 'Billing Charges Posting Date', type: 'numericColumn', headerTooltip: 'Billing Charges Posting Date' },
    { field: 'EMRDocumentVersion', headerName: 'Doc. Version', headerTooltip: 'Doc. Version' },
    { field: 'EMRDocumentVersionCreateDate', headerName: 'Doc. Create Date', type: 'numericColumn', headerTooltip: 'Doc. Create Date' },
    { field: 'PatientFirstName', headerName: 'Patient First Name', headerTooltip: 'Patient First Name' },
    { field: 'PatientLastName', headerName: 'Patient Last Name', headerTooltip: 'Patient Last Name' },
    { field: 'PatientDateOfBirth', headerName: 'Patient DOB', type: 'numericColumn', headerTooltip: 'Patient DOB' },
    { field: 'MemberMonthsPerPaymentYear', headerName: '# MM/Pmt Yr', type: 'numericColumn', headerTooltip: '# Member Months per Payment Year' },
    { field: 'IsActivePatient', headerName: 'Active Patient', headerTooltip: 'Active Patient', valueFormatter: this.booleanValue },
    { field: 'PaymentYear', headerName: 'Payment Year', type: 'numericColumn', headerTooltip: 'Payment Year' },
    { field: 'BillingPaymentPostingDate', headerName: 'Billing Payment Posting Date', headerTooltip: 'Billing Payment Posting Date', type: 'numericColumn', minWidth: 100 },
    { field: 'IsOnRaps', headerName: 'On RAPs', headerTooltip: 'On RAPs', valueFormatter: this.booleanValue },
    { field: 'IsOnMAO', headerName: 'On MAO', headerTooltip: 'On MAO', valueFormatter: this.booleanValue },
    { field: 'IsOnMOR', headerName: 'On MOR', headerTooltip: 'On MOR', valueFormatter: this.booleanValue },
    { field: 'IsOnSupplementalFeed', headerName: 'On Supp Feed', headerTooltip: 'On Supp Feed', valueFormatter: this.booleanValue },
    { field: 'IsDocumentInEMR', headerName: 'Is Doc In EMR', headerTooltip: 'Is Document In EMR', valueFormatter: this.booleanValue },
    { field: 'LastModifiedBy', headerName: 'Last Modified By', headerTooltip: 'Last Modified By', minWidth: 150, valueFormatter: this.assignedUserValue, tooltipValueGetter: this.assingedUserTooltip },
    { field: 'LastModifiedDateTime', headerName: 'Last Modified Date', headerTooltip: 'Last Modified Date', minWidth: 150},
    { field: 'CreatedDateTime', headerName: 'Event Create Date', headerTooltip: 'Event', minWidth: 150},
    { field: 'CCN', headerName: 'CCN', type: 'numericColumn', headerTooltip: 'CCN' },
    { field: 'PatientMiddleName', headerName: 'Patient Middle Name', headerTooltip: 'Patient Middle Name' },
    { field: 'PatientSuffix', headerName: 'Patient Suffix', headerTooltip: 'Patient Suffix' },
    { field: 'PatientAddress1', headerName: 'Patient Address 1', headerTooltip: 'Patient Address 1' },
    { field: 'PatientAddress2', headerName: 'Patient Address 2', headerTooltip: 'Patient Address 2' },
    { field: 'PatientAddressCity', headerName: 'Patient Address City', headerTooltip: 'Patient Address City' },
    { field: 'PatientAddressState', headerName: 'Patient Address State', headerTooltip: 'Patient Address State' },
    { field: 'PatientAddressCode', headerName: 'Patient Address Code', headerTooltip: 'Patient Address Code' },
    { field: 'PatientGender', headerName: 'Patient Gender', headerTooltip: 'Patient Gender' },
    { field: 'PatientPCPFirstName', headerName: 'Patient PCP First Name', headerTooltip: 'Patient PCP First Name' },
    { field: 'PatientPCPLastName', headerName: 'Patient PCP Last Name', headerTooltip: 'Patient PCP Last Name' },
    { field: 'RenderingProviderFirstName', headerName: 'Rendering Provider First Name', headerTooltip: 'Rendering Provider First Name' },
    { field: 'RenderingProviderLastName', headerName: 'Rendering Provider Last Name', headerTooltip: 'Rendering Provider Last Name' },
    { field: 'RenderingProviderNPI', headerName: 'Rendering Provider NPI', headerTooltip: 'Rendering Provider NPI' },
    { field: 'RenderingProviderTaxID', headerName: 'Rendering Provider Tax ID', headerTooltip: 'Rendering Provider Tax ID' },
    { field: 'RenderingProviderTaxonomyCode', headerName: 'Rendering Provider Taxonomy Code', headerTooltip: 'Rendering Provider Taxonomy Code' },
    { field: 'ProcedureCode', headerName: 'Procedure Code', headerTooltip: 'Procedure Code' },
    { field: 'PlaceOfService', headerName: 'PlaceOfService', headerTooltip: 'PlaceOfService' },
    { field: 'RCMPID', headerName: 'RCM PID', headerTooltip: 'RCM PID' },
    { field: 'BillableProviderNPI', headerName: 'Billable Provider NPI', headerTooltip: 'Billable Provider NPI' },
    { field: 'BillableProviderName', headerName: 'Billable Provider Name', headerTooltip: 'Billable Provider Name' },
    { field: 'PatientDeceasedDate', headerName: 'Patient Deceased Date', headerTooltip: 'Patient Deceased Date' },
    { field: 'PatientMedicareID', headerName: 'Patient Medicare ID', headerTooltip: 'Patient Medicare ID' },
    { field: 'PatientPolicyID', headerName: 'Patient Policy ID', headerTooltip: 'Patient Policy ID' },

    { colId: 'edit', headerName: 'Edit', minWidth: 68, maxWidth: 68, suppressColumnsToolPanel: true, 
    pinned: 'right', lockPinned: true, cellRenderer: 'editRenderer', sortable: false },
  ];

  dxColumnDefs: ColDef[] = [
    { colId: 'checkbox', checkboxSelection: true, headerComponent: AgHeaderCheckboxComponent,
    suppressColumnsToolPanel: true, minWidth: 68, maxWidth: 68, pinned: 'left', lockPinned: true, sortable: false },

    { field: 'ID', headerName: 'ID', headerTooltip: 'ID' },
    { field: 'EventTypeName', headerName: 'Event Type', headerTooltip: 'Event Type' },
    { field: 'Status', headerName: 'Status', headerTooltip: 'Status', valueFormatter: this.filterOptionValue },
    { field: 'Payer', headerName: 'Payer', headerTooltip: 'Payer', minWidth: 150, valueFormatter: this.payerValue, tooltipValueGetter: this.payerTooltip },
    { field: 'AssignedTo', headerName: 'Assigned User', headerTooltip: 'Assigned User', minWidth: 150, valueFormatter: this.assignedUserValue, tooltipValueGetter: this.assingedUserTooltip },
    { field: 'EMRPID', headerName: 'EMR PID', type: 'numericColumn', headerTooltip: 'EMR Patient ID' },
    { field: 'DateOfService', headerName: 'DOS', type: 'numericColumn', headerTooltip: 'DOS' },
    { field: 'ServiceLocation', headerName: 'Service Location', headerTooltip: 'Service Location', tooltipValueGetter: this.tooltipValue },
    { field: 'VisitID', headerName: 'Visit ID', type: 'numericColumn', headerTooltip: 'Visit ID' },
    { field: 'ICDCODE', headerName: 'ICD10', type: 'numericColumn', headerTooltip: 'ICD10' },
    { field: 'PreferredAction', headerName: 'Preferred Action', headerTooltip: 'Preferred Action', minWidth: 150, valueFormatter: this.filterOptionValue },
    { field: 'RootCause', headerName: 'Root Cause', headerTooltip: 'Root Cause', minWidth: 150, valueFormatter: this.filterOptionValue , tooltipValueGetter: this.filterTooltipValue },
    { field: 'Notes', headerName: 'Notes', headerTooltip: 'Notes', minWidth: 150, cellRenderer: 'notesRenderer'},
    { field: 'StatusState', headerName: 'Status State', headerTooltip: 'Status State', valueFormatter: this.filterOptionValue },
    { field: 'SuperbillStatus', headerName: 'Superbill Status', headerTooltip: 'Superbill Status' },
    { field: 'HCCCode', headerName: 'CMS HCC', type: 'numericColumn', headerTooltip: 'CMS HCC'},
    { field: 'BillingChargePostingDate', headerName: 'Billing Charges Posting Date', type: 'numericColumn', headerTooltip: 'Billing Charges Posting Date' },
    { field: 'EMRDocumentVersion', headerName: 'Doc. Version', headerTooltip: 'Doc. Version' },
    { field: 'EMRDocumentVersionCreateDate', headerName: 'Doc. Create Date', type: 'numericColumn', headerTooltip: 'Doc. Create Date' },
    { field: 'PatientFirstName', headerName: 'Patient First Name', headerTooltip: 'Patient First Name' },
    { field: 'PatientLastName', headerName: 'Patient Last Name', headerTooltip: 'Patient Last Name' },
    { field: 'PatientDateOfBirth', headerName: 'Patient DOB', type: 'numericColumn', headerTooltip: 'Patient DOB' },
    { field: 'MemberMonthsPerPaymentYear', headerName: '# MM/Pmt Yr', type: 'numericColumn', headerTooltip: '# Member Months per Payment Year' },
    { field: 'IsActivePatient', headerName: 'Active Patient', headerTooltip: 'Active Patient', valueFormatter: this.booleanValue },
    { field: 'PaymentYear', headerName: 'Payment Year', type: 'numericColumn', headerTooltip: 'Payment Year' },
    { field: 'BillingPaymentPostingDate', headerName: 'Billing Payment Posting Date', headerTooltip: 'Billing Payment Posting Date', type: 'numericColumn', minWidth: 100 },
    { field: 'IsOnRaps', headerName: 'On RAPs', headerTooltip: 'On RAPs', valueFormatter: this.booleanValue },
    { field: 'IsOnMAO', headerName: 'On MAO', headerTooltip: 'On MAO', valueFormatter: this.booleanValue },
    { field: 'IsOnMOR', headerName: 'On MOR', headerTooltip: 'On MOR', valueFormatter: this.booleanValue },
    { field: 'IsOnSupplementalFeed', headerName: 'On Supp Feed', headerTooltip: 'On Supp Feed', valueFormatter: this.booleanValue },
    { field: 'IsDocumentInEMR', headerName: 'Is Doc In EMR', headerTooltip: 'Is Document In EMR', valueFormatter: this.booleanValue },
    { field: 'LastModifiedBy', headerName: 'Last Modified By', headerTooltip: 'Last Modified By', minWidth: 150, valueFormatter: this.assignedUserValue, tooltipValueGetter: this.assingedUserTooltip },
    { field: 'LastModifiedDateTime', headerName: 'Last Modified Date', headerTooltip: 'Last Modified Date', minWidth: 150},
    { field: 'CreatedDateTime', headerName: 'Event Create Date', headerTooltip: 'Event', minWidth: 150},
    { field: 'CCN', headerName: 'CCN', type: 'numericColumn', headerTooltip: 'CCN' },
    { field: 'PatientMiddleName', headerName: 'Patient Middle Name', headerTooltip: 'Patient Middle Name' },
    { field: 'PatientSuffix', headerName: 'Patient Suffix', headerTooltip: 'Patient Suffix' },
    { field: 'PatientAddress1', headerName: 'Patient Address 1', headerTooltip: 'Patient Address 1' },
    { field: 'PatientAddress2', headerName: 'Patient Address 2', headerTooltip: 'Patient Address 2' },
    { field: 'PatientAddressCity', headerName: 'Patient Address City', headerTooltip: 'Patient Address City' },
    { field: 'PatientAddressState', headerName: 'Patient Address State', headerTooltip: 'Patient Address State' },
    { field: 'PatientAddressCode', headerName: 'Patient Address Code', headerTooltip: 'Patient Address Code' },
    { field: 'PatientGender', headerName: 'Patient Gender', headerTooltip: 'Patient Gender' },
    { field: 'PatientPCPFirstName', headerName: 'Patient PCP First Name', headerTooltip: 'Patient PCP First Name' },
    { field: 'PatientPCPLastName', headerName: 'Patient PCP Last Name', headerTooltip: 'Patient PCP Last Name' },
    { field: 'RenderingProviderFirstName', headerName: 'Rendering Provider First Name', headerTooltip: 'Rendering Provider First Name' },
    { field: 'RenderingProviderLastName', headerName: 'Rendering Provider Last Name', headerTooltip: 'Rendering Provider Last Name' },
    { field: 'RenderingProviderNPI', headerName: 'Rendering Provider NPI', headerTooltip: 'Rendering Provider NPI' },
    { field: 'RenderingProviderTaxID', headerName: 'Rendering Provider Tax ID', headerTooltip: 'Rendering Provider Tax ID' },
    { field: 'RenderingProviderTaxonomyCode', headerName: 'Rendering Provider Taxonomy Code', headerTooltip: 'Rendering Provider Taxonomy Code' },
    { field: 'ProcedureCode', headerName: 'Procedure Code', headerTooltip: 'Procedure Code' },
    { field: 'PlaceOfService', headerName: 'PlaceOfService', headerTooltip: 'PlaceOfService' },
    { field: 'RCMPID', headerName: 'RCM PID', headerTooltip: 'RCM PID' },
    { field: 'BillableProviderNPI', headerName: 'Billable Provider NPI', headerTooltip: 'Billable Provider NPI' },
    { field: 'BillableProviderName', headerName: 'Billable Provider Name', headerTooltip: 'Billable Provider Name' },
    { field: 'PatientDeceasedDate', headerName: 'Patient Deceased Date', headerTooltip: 'Patient Deceased Date' },
    { field: 'PatientMedicareID', headerName: 'Patient Medicare ID', headerTooltip: 'Patient Medicare ID' },
    { field: 'PatientPolicyID', headerName: 'Patient Policy ID', headerTooltip: 'Patient Policy ID' },


    { colId: 'edit', headerName: 'Edit', suppressColumnsToolPanel: true, 
    pinned: 'right', lockPinned: true, minWidth: 68, maxWidth: 68, cellRenderer: 'editRenderer', sortable: false },
  ];

  defaultColDef: ColDef = {
    sortable: true,
    menuTabs: [],
    minWidth: 100
  };

  defaultColumns = [
    'Status', 'Payer', 'EMRPID', 'AssignedTo', 'DateOfService', 
    'VisitID', 'ICDCODE', 'PreferredAction', 'RootCause', 'Notes'
  ];

  gridContext: any;

  rowData: any[] = [];
  selectedRows: DiagnosisEventItem[] = [];
  gridColumnConfig: ColumnConfigItem[] = [];

  form: FormGroup;
  emrControl = new FormControl('');
  icd10Control = new FormControl('');

  exportTemplateDisabled = true;

  subs: Subscription[] = [];

  query: DiagnosisEventQuery = new DiagnosisEventQuery();
  previousQuery: DiagnosisEventQuery = new DiagnosisEventQuery();
  selectedMenuOption: ListMenuOption = new ListMenuOption();

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @ViewChild(ProgressAllocationUserComponent) userAllocationComp: ProgressAllocationUserComponent;
  @ViewChild(PreferredActionSelectComponent) pActionComp: PreferredActionSelectComponent;
  @ViewChild(PayerSelectComponent) payerComp: PayerSelectComponent;
  @ViewChild(StatusSelectComponent) statusComp: StatusSelectComponent;
  @ViewChild(StatusStateSelectComponent) statusStateComp: StatusStateSelectComponent;
  @ViewChild(RootCauseSelectComponent) rootCauseComp: RootCauseSelectComponent;
  @ViewChild('assignOpptyList') assignedComp: UserSearchByPermissionComponent;
  @ViewChild(DosSelectComponent) dosComp: DosSelectComponent;
  @ViewChild('exportTemplate') exportTemplateSelect: FilterFieldSearchSelectComponent<FilterSelectItem>;
  serviceYear: number;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private opptyService: DiagnosisEventService,
    private userService: UserService,
    private settingsService: SettingsService,
    private permissionService: PermissionService,
    private exportService: ExportService,
    private filterService: FieldFilterService,
    private templateService: TemplateService,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar) {

      this.gridContext = { componentParent: this };
      this.query.Metadata = new QueryMetadata();
      this.query.Metadata.Sort = new QuerySort();

      if(this.router.url.includes('diagnosis-event')) {
        this.mode = 'dx-list';
      }
  }

  ngOnInit(): void {
    this.paTypeIDs = this.mode == 'oppty-list' ? [PreferredActionType.Opportunity.valueOf()] 
    : [PreferredActionType.Opportunity.valueOf(), PreferredActionType.Validation.valueOf()];

    this.defaultChipSelection = this.route.snapshot.paramMap.get('qfilter') || '';
    this.defaultUser = this.route.snapshot.queryParamMap.get('user') || '';
    this.defaultPayer = this.route.snapshot.queryParamMap.get('payer') || '';

    if(this.defaultChipSelection) {

      if(this.mode == 'oppty-list') {
        const index = this.opptyFilterChips.findIndex(c => c.Name == this.defaultChipSelection);
        this.opptyFilterChips[index].Selected = true;
      }

      if(this.mode == 'dx-list') {
        const index = this.dxFilterChips.findIndex(c => c.Name == this.defaultChipSelection);
        this.dxFilterChips[index].Selected = true;
      }

      this.currentChipSelection = this.route.snapshot.paramMap.get('qfilter');
    }

    this.form = this.fb.group({
      emr: this.emrControl,
      icd10: this.icd10Control
    });

    this.subs.push(this.userService.currentUser$.subscribe(result => {
      this.currentUser = result;
    }));

    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      if(result > -1) {
        this.serviceYear = result;
      }
    }));
  }

  ngAfterViewInit(): void {
    this.subs.push(this.form.statusChanges.subscribe(result => {
      if(result == 'INVALID') {
        this.formValid = false;
      }

      if(result == 'VALID') {
        this.formValid = true;
      }
    }));

    this.subs.push(this.permissionService.activeUserPermissions.subscribe(result => {
      this.activeUserPerms = result;

      this.activeUserPerms.forEach(p => {
        switch(p.PermissionID) {
          case PermissionMap.ViewAndEditAllDxEvents:
            this.editAllActive = true;
            break;

          case PermissionMap.AssignOpportunities:
            this.assignToActive = true;
            this.agGrid.api.getStatusPanel<IAgAssignButton>('assign-btn').updateAssignToActive(this.assignToActive);
            break;

          case PermissionMap.ExportDiagnosisEvents:
            this.exportActive = true;
            this.agGrid.api.getStatusPanel<IAgExportButton>('export-btn').updateExportActive(this.exportActive);
            break;

          default:
            break;
        }
      });

    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  //#region On Ready Event Functions

  filtersReadyCheck() {
    if(!this.isPreferredActionReady) {
      return;
    }

    if(!this.isPayerReady) {
      return;
    }

    if(!this.isRootCauseReady) {
      return;
    }

    if(!this.isStatusReady) {
      return;
    }

    if(!this.isStatusStateReady) {
      return;
    }

    if(!this.isAssignedReady) {
      return;
    }

    if(!this.isDosReady) {
      return;
    }

    if(!this.isGridReady) {
      return;
    }

    const isInitialLoad = (this.defaultChipSelection != '') || (this.defaultUser != '') || (this.defaultPayer != '');
    this.setFiltersByPreset(isInitialLoad);

  }

  onGridReady() {

    this.subs.push(this.settingsService.getDxEventColumnConfig(this.mode).subscribe(result => {

      this.isGridReady = true;

      this.agGrid.api.sizeColumnsToFit();

      this.filtersReadyCheck();

      if(!result) {

        if(this.mode == 'oppty-list') {
          this.opptyColumnDefs.forEach(c => {
            if(!c.field) {
              return;
            }
  
            this.agGrid.api.getColumn(c.field)?.setVisible(this.defaultColumns.includes(c.field) ? true : false);
          });
        }

        if(this.mode == 'dx-list') {
          this.dxColumnDefs.forEach(c => {
            if(!c.field) {
              return;
            }
  
            this.agGrid.api.getColumn(c.field)?.setVisible(this.defaultColumns.includes(c.field) ? true : false);
          });
        }

      } else {
        this.updateColumnConfig(result);
      }

      this.agGrid.api.getToolPanelInstance("columns").refresh();
    }));

    const paginatorPageSizeSetting = this.mode == 'oppty-list' ? SettingsKeys.OPPTY_LIST_PAGE_SIZE : SettingsKeys.DX_LIST_PAGE_SIZE;
    this.subs.push(this.settingsService.getPaginatorPageSize(paginatorPageSizeSetting).subscribe(result => {
      if(result == 0) {
        return;
      }

      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
    }));
  }

  onStatusStateReady(filterOptions: FilterSelectItem[]) {
    this.isStatusStateReady = true;
    const ids = filterOptions.map(o => o.ID);
    this.statusComp.getOptionData(ids);

    this.filtersReadyCheck();
  }

  onStatusReady(filterOptions: FilterSelectItem[]) {
    this.isStatusReady = true;
    const ids = filterOptions.map(o => o.ID);
    this.rootCauseComp.getOptionData(ids);

    this.filtersReadyCheck();
  }

  //#endregion

  //#region AG Grid Functions

  updateColumnConfig(items: ColumnConfigItem[]) {
    this.gridColumnConfig = items;

    items.forEach((c, index) => {
      this.agGrid.api.getColumn(c.Name)?.setVisible(c.Visible);
      this.agGrid.api.moveColumn(c.Name, index);
    });
  }

  setColumns(fields: TemplateConfigFieldItem[]) {
    const currentDefs = this.mode == 'oppty-list' ? _.cloneDeep(this.opptyColumnDefs) : _.cloneDeep(this.dxColumnDefs);
    const newDefs: ColDef[] = [];
    
    if(fields?.length > 0) {
      newDefs.push(this.checkboxColDef);
    }

    fields.forEach(item => {
      const foundDef = currentDefs?.find(d => d.field == item.Field);

      if(foundDef) {
        foundDef.headerName = item.Label;
        foundDef.suppressMovable = true;
        newDefs.push(foundDef);
      } else {
        const newCol: ColDef = {
          colId: item.Label,
          headerName: item.Label,
          headerTooltip: item.Label,
          valueGetter: () => {return item.DefaultValue},
          hide: false,
          suppressMovable: true
        }
        newDefs.push(newCol);
      }
    });

    if(fields?.length > 0) {
      newDefs.push(this.editColDef);
    }

    if (newDefs.length == 0) {
      currentDefs.forEach((def) => {
        const newDef = _.cloneDeep(def);
        if (newDef.field) {
          newDef.hide = this.gridColumnConfig.length == 0
            ? !this.defaultColumns.includes(newDef.field)
            : !this.gridColumnConfig.includes({
              Name: newDef.field,
              Visible: true
            });
        }
        newDefs.push(newDef);
      });
    }

    this.agGrid.api.setGridOption("columnDefs", newDefs);
  }

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {

        this.query.Metadata.PageSize = params.api.paginationGetPageSize();
        this.query.Metadata.PageNumber = params.api.paginationGetCurrentPage();

        const sortModel = params.request.sortModel;

        if(sortModel.length > 0) {
          this.query.Metadata.Sort = new QuerySort();
          this.query.Metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : null;
          this.query.Metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : null;
        }

        this.opptyService.getDiagnosisEvents(this.query).subscribe(result => {
          this.currentListSize = result.ListSize;
          this.currentPageRows = [];
          params.api.hideOverlay();

          params.success({
            rowData: result.Events,
            rowCount: result.ListSize,
          });

          result.Events.forEach(r => {
            this.currentPageRows.push(this.agGrid.api.getRowNode(r.ID.toString()));
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          params.api.sizeColumnsToFit();

          this.checkQueryChange();

          this.agGrid.api.getStatusPanel<IAgExportButton>('export-btn').updateExportDisabled(result.Events.length > 0 ? false : true);
          this.agGrid.api.getStatusPanel<IAgRowSelectedCount>('row-selected-count').updateTotalCount(this.currentListSize);
          this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').goToCurrentPage();

          this.checkAllSelected();
        },
        error => {
          params.fail();
        });

      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.ID;
  };

  onGenerateGridData() {
    
    this.query.EventTypeIDs = 
    this.mode == 'oppty-list' 
    ? DiagnosisEventType.Opportunity.toString() 
    : [DiagnosisEventType.Opportunity, DiagnosisEventType.Validation, DiagnosisEventType.Other].toString();

    this.query.StateIDs = StringFormatter.FilterObjectToString('ID', this.statusStateComp.getValues());
    this.query.StatusIDs = StringFormatter.FilterObjectToString('ID', this.statusComp.getValues());
    this.query.RootCauseIDs = StringFormatter.FilterObjectToString('ID', this.rootCauseComp.getValues());
    this.query.PreferredActionIDs = StringFormatter.FilterObjectToString('ID', this.pActionComp.getValues());
    this.query.PayerIDs = StringFormatter.FilterObjectToString('ID', this.payerComp.getValues());
    this.query.AssignedToIDs = StringFormatter.FilterObjectToString('user_id', this.assignedComp.getValues());
    this.query.EmrPID = this.emrControl?.value;
    this.query.IcdCodeID = this.icd10Control?.value;

    const quarters: DateQuarter[] = this.dosComp.getValues();
    const quarterRanges: QueryDateRange[] = [];

    if(quarters.length > 0) {
      quarters.forEach(q => {
        const range = new QueryDateRange();
        range.StartDate = q.StartDate.toLocaleDateString();
        range.EndDate = q.EndDate.toLocaleDateString();
        quarterRanges.push(range);
      });
    } else {
      const range = new QueryDateRange();
      range.StartDate = new Date(`1/1/${this.serviceYear}`).toLocaleDateString();
      range.EndDate = new Date(`12/31/${this.serviceYear}`).toLocaleDateString();
      quarterRanges.push(range);
    }

    this.query.DateRanges = quarterRanges;

    //Export Template Functionality
    if(!this.exportTemplateDisabled) {
      const templateID = this.exportTemplateSelect.control?.value?.ID || 0;

      if(templateID <= 0) {
        this.resetGridFromTemplate();
      } else {
        this.templateService.getTemplateConfigFieldItems(templateID).subscribe(fields => {
          if(fields) {
            this.setColumns(fields);
            this.agGrid.api.setSideBarVisible(false);
          }
        });
      }
    }

    this.checkEnableTemplateFieldStatus();

    if(this.isFirstGenerate || this.currentListSize == 0) {
      this.agGrid.api.setGridOption('serverSideDatasource', this.getServerSideDatasource());
      this.isFirstGenerate = false;
      return;
    }
    
    this.agGrid.api.refreshServerSide({purge: true});
    this.agGrid.api.deselectAll();
  }

  onGridColumnSelect(event) {
    if(event.source == "api") {
      return;
    }
    
    const gridCols = this.agGrid.api.getAllGridColumns();

    const columns: ColumnConfigItem[] = [];
    gridCols.forEach(c => {
      const colId = c.getColId();
      if(colId == 'checkbox' || colId == 'edit') {
        return;
      }

      const item = new ColumnConfigItem();
      item.Name = colId;
      item.Visible = c.isVisible();
      columns.push(item);

    });

    this.agGrid.api.sizeColumnsToFit();

    this.subs.push(this.settingsService.updateDxEventColumnConfig(columns, this.mode).subscribe(()=>{
      //TODO
    }));
  }

  onGridRowSelected(event) {
    if(!event || !event.node) {
      return;
    }

    if(this.selectedRows.length + 1 > this.batchEditLimit && event.node.selected) {
      this.agGrid.api.getRowNode(event.node.id).setSelected(false);
      this.snackbar.open("Max items selected", null, {duration: 4000});
      return;
    }

    this.updateSelectedRows(event.data, event.node.selected);

    this.checkAllSelected();

    const rowSelectedCountPanel = this.agGrid.api.getStatusPanel<IAgRowSelectedCount>('row-selected-count');
    rowSelectedCountPanel.updateSelectedCount(this.selectedRows.length);

    const assignStatusPanel = this.agGrid.api.getStatusPanel<IAgAssignButton>('assign-btn');
    assignStatusPanel.updateAssignDisabled(this.selectedRows?.length > 0 ? false : true);
  }

  checkAllSelected() {
    let all = true;
    this.currentPageRows.forEach(n => {
      if(!n.isSelected()) {
        all = false;
        return;
      }
    });

    this.allSelected.next(all);
    this.allBatchEditEnabled = all;
  }

  onGridGetContextMenu(params: GetContextMenuItemsParams) {

    if(params.column.getColId() == 'edit') {
      return [];
    }

    if(params.column.getColId() == 'checkbox') {
      return [];
    }

    const result = [
      {
        name: 'Copy',
        action: () => {
          const value = params.context.componentParent.getColumnValue(params.column.getColId(), params.value);
          params.context.componentParent.clipboard.copy(value);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>'
      }
    ];

    return result;
  }

  onCellKeyDown(event) {
    const kbEvent:KeyboardEvent = event.event;
    if (kbEvent.ctrlKey && kbEvent.key === "c") {
      const value = this.getColumnValue(event.column.getColId(), event.value);
      this.clipboard.copy(value);
    }
  }

  onListItemEditClick(item: DiagnosisEventItem) {
    const stateIDs = this.statusStateComp.options.map(o => o.ID);
    
    const data = new EditOpptyDialogData();
    data.items = [item];
    data.statusStateIDs = stateIDs;
    data.mode = 'single';
    data.parent = this.mode;

    const dialogRef = this.dialog.open(EditOpptyDialogComponent, new EditOpptyDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onGenerateGridData();
        this.userAllocationComp.updateData();
        this.selectedRows = [];
        this.agGrid.api.getStatusPanel<IAgRowSelectedCount>('row-selected-count').updateSelectedCount(this.selectedRows.length);
      }
    }));
  }

  onNoteCellClick(item: DiagnosisEventItem) {
    const dialogRef = this.dialog.open(DiagnosisEventNotesDialogComponent, new DiagnosisEventNotesDialogModel(item));
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.currentPageRows = [];
    this.selectedRows = [];
    this.agGrid.api.deselectAll();
    this.agGrid.api.getStatusPanel<IAgRowSelectedCount>('row-selected-count').updateSelectedCount(this.selectedRows.length);
    const paginatorPageSizeSetting = this.mode == 'oppty-list' ? SettingsKeys.OPPTY_LIST_PAGE_SIZE : SettingsKeys.DX_LIST_PAGE_SIZE;
    this.settingsService.updatePaginatorPageSize(pageSize, paginatorPageSizeSetting).subscribe(() => {
      //TODO
    });
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    //his.allSelected = (event.source == 'uiSelectAll' && !this.allSelected);
  }

  //#endregion

  //#region On Change Event Functions

  onChipFilterChange(chip: any) {
    if(!chip) {
      return;
    }

    if(chip.Name == this.currentChipSelection) {
      return;
    }

    if(this.canQuickFilterUnselect) {
      this.canQuickFilterUnselect = false;
    }

    if(this.mode == 'oppty-list') {
      const prevIndex = this.opptyFilterChips.findIndex(c => c.Name == this.currentChipSelection);

      if(prevIndex > -1) {
        this.opptyFilterChips[prevIndex].Selected = false;
      }
    }

    if(this.mode == 'dx-list') {
      const prevIndex = this.dxFilterChips.findIndex(c => c.Name == this.currentChipSelection);

      if(prevIndex > -1) {
        this.dxFilterChips[prevIndex].Selected = false;
      }
    }

    chip.Selected = true;

    this.currentChipSelection = chip.Name;

    this.setFiltersByPreset();
    
  }

  onQueryFilterChange(value: any) {

    if(this.canQuickFilterUnselect) {
      //TODO

      //TEMP FUNCTIONALITY
      this.currentChipSelection = '';

      if(this.mode == 'oppty-list') {
        this.opptyFilterChips.forEach(c => c.Selected = false);
      }
  
      if(this.mode == 'dx-list') {
        this.dxFilterChips.forEach(c => c.Selected = false);
      }
      
      this.canQuickFilterUnselect = false;
    }
  }

  onStatusStatesSelected(items: FilterSelectItem[]) {
    this.statusComp.control.reset();
    this.statusComp.control.markAsTouched();
    const ids = items.map(i => i.ID);
    this.statusComp.setValues([], ids);

    this.rootCauseComp.control.reset();
    const sids = items.map(i => i.ID);
    this.rootCauseComp.setValues([], sids);
  }

  onStatusSelected(items: FilterSelectItem[]) {
    this.rootCauseComp.control.reset();
    const sids = items.map(i => i.ID);
    this.rootCauseComp.setValues([], sids);
  }

  onSelectedMenuOption(option: ListMenuOption) {
    if(!option) {
      return;
    }

    if(option.ID == this.selectedMenuOption.ID) {
      this.sideMenuCollapsed = !this.sideMenuCollapsed ? true : false;
      return;
    }

    this.sideMenuCollapsed = true;
    this.selectedMenuOption = option;
  }

  checkEnableTemplateFieldStatus(queryValues?: boolean) {
    const paValues = this.pActionComp.getValues();

    //check preferred actions: oppty = supp feed; dx event = supp feed OR payer validation
    const paFound = this.mode == 'oppty-list' ? paValues?.find(i => i.ID == 4) : paValues?.find(i => i.ID == 4 || i.ID == 3);

    if(paValues?.length == 0) {
      this.resetGridFromTemplate();
      return;
    }

    if(!paFound || paValues?.length > 1) {
      this.resetGridFromTemplate();
      return;
    }

    if(paValues?.length == 1 && paValues[0]?.ID == 0) {
      this.resetGridFromTemplate();
      return;
    }

    //check selected payer length
    const payerValues = this.payerComp.getValues();

    if(payerValues?.length == 0 || payerValues?.length > 1) {
      this.resetGridFromTemplate();
      return;
    }

    if(payerValues?.length == 1 && payerValues[0]?.ID == 0) {
      this.resetGridFromTemplate();
      return;
    }

    if(!queryValues) {
      this.exportTemplateDisabled = false;
      return;
    }

    const typeID = paFound.ID == 4 ? '1' : '2';
    const payerID = StringFormatter.FilterObjectToString('ID', payerValues);

    this.filterService.getExportTemplatesByTypeAndPayer(typeID, payerID).subscribe(result => {
      this.exportTemplateDisabled = false;
      this.exportTemplateSelect.options = result;
    });
  }

  resetGridFromTemplate() {
    this.exportTemplateSelect.setToNone();
    this.setColumns([]);
    this.updateColumnConfig(this.gridColumnConfig);
    this.agGrid.api.setSideBarVisible(true);
    this.exportTemplateDisabled = true;
  }

  //#endregion

  //#region On Button Actions

  onAssignButtonClick() {
    const data = {
      items: this.selectedRows, 
      parent: this.mode, 
      allEnabled: this.allBatchEditEnabled, 
      queryResultsCount: this.currentListSize, 
      query: this.allBatchEditEnabled ? this.query : null
    };

    const dialogRef = this.dialog.open(AssignOpptyDialogComponent, new AssignOpptyDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.selectedRows = [];
        this.agGrid.api.getStatusPanel<IAgRowSelectedCount>('row-selected-count').updateSelectedCount(this.selectedRows.length);
        this.onGenerateGridData();
        this.userAllocationComp?.updateData();
      }
    }));
  }

  onExportClick() {
    const filterValues = new DiagnosisEventFilterValues();
    filterValues.State = StringFormatter.FilterObjectToString('Description', this.statusStateComp.getValues());
    filterValues.Status = StringFormatter.FilterObjectToString('Description', this.statusComp.getValues());
    filterValues.RootCause = StringFormatter.FilterObjectToString('Description', this.rootCauseComp.getValues());
    filterValues.PreferredAction = StringFormatter.FilterObjectToString('Description', this.pActionComp.getValues());
    filterValues.Payer = StringFormatter.FilterObjectToString('Abbreviation', this.payerComp.getValues());
    filterValues.Assigned = StringFormatter.UsersFullNameString(this.assignedComp.getValues());
    filterValues.EMRPID = this.query.EmrPID;
    filterValues.ICD10 = this.query.IcdCodeID;
    filterValues.DateRanges = this.query.DateRanges;

    const exportTemplateID = !this.exportTemplateDisabled ? (this.exportTemplateSelect.control?.value?.ID || 0) : 0;

    const sourceComp = this.mode == 'oppty-list' ? 'Opportunity' : 'DxEvents';
    this.exportService.exportDiagnosisEvents(this.query, sourceComp, "excel", exportTemplateID, filterValues);
  }

  onBatchEditClick() {
    const stateIDs = this.statusStateComp.options.map(o => o.ID);
    
    const data = new EditOpptyDialogData();
    data.items = this.selectedRows;
    data.statusStateIDs = stateIDs;
    data.mode = 'batch';
    data.parent = this.mode;
    data.allEnabled = this.allBatchEditEnabled;
    data.query = this.query;
    data.queryResultCount = this.currentListSize;
    data.exportEnabled = !this.exportTemplateDisabled && this.exportTemplateSelect.control?.value?.ID > 0;

    const dialogRef = this.dialog.open(EditOpptyDialogComponent, new EditOpptyDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.selectedRows = [];
        this.agGrid.api.getStatusPanel<IAgRowSelectedCount>('row-selected-count').updateSelectedCount(this.selectedRows.length);
        this.onGenerateGridData();
        this.userAllocationComp?.updateData();
      }

      if(result == 'export') {
        this.onExportClick();
      }
    }));
  }

  //#endregion

  setFiltersByPreset(isInitialLoad = false) {
    this.form.reset();

    const statusStateValue = this.statusStateComp.options.find(o => o.Description == 'Open');
    const newStatusValue = this.statusComp.options.find(o => o.Description == 'New');
    const statusStateClosedValue = this.statusStateComp.options.find(o => o.Description == 'Closed');
    const statusNonActionableValue = this.statusComp.options.find(o => o.Description == 'Non-Actionable');
    const statusValidationValue = this.statusComp.options.find(o => o.Description == 'Validation');

    if(this.mode == 'oppty-list') {
      this.statusStateComp.setValues([statusStateValue]);
      this.statusComp.setValues([newStatusValue], [statusStateValue.ID]);
      this.rootCauseComp.setValues([], [newStatusValue.ID]);
    }

    this.emrControl.setValue('');
    this.icd10Control.setValue('');
    this.dosComp.setValues([]);

    const foundUser = this.assignedComp.userOptions.find(u => u.user.given_name + ' ' + u.user.family_name == this.defaultUser);
    this.assignedComp.setSelectedValues(foundUser ? [foundUser.user] : []);

    const foundPayer = this.payerComp.options.find(p => p.Name == this.defaultPayer);
    this.payerComp.setSelectedValues(foundPayer ? [foundPayer] : []);

    switch(this.currentChipSelection) {

      case "Payer Validation":
        this.statusStateComp.setValues([statusStateClosedValue]);
        this.statusComp.setValues([statusValidationValue], [statusStateClosedValue.ID]);
        this.rootCauseComp.setValues([], [statusValidationValue.ID]);

        break;

      case "Non-Actionable":
        this.statusStateComp.setValues([statusStateClosedValue]);
        this.statusComp.setValues([statusNonActionableValue], [statusStateClosedValue.ID]);
        this.rootCauseComp.setValues([], [statusNonActionableValue.ID]);

        break;

      default:
        if(this.mode == 'dx-list') {
          this.statusStateComp.setValues([]);
          this.statusComp.setValues([]);
          this.rootCauseComp.setValues([]);
        }

        break;
    }

    const paItem = this.pActionComp.options.find(o => o.Description == this.currentChipSelection);
    this.pActionComp.setValues( paItem ? [paItem] : []);

    if(isInitialLoad) {
      this.onGenerateGridData();
      return;
    }

    this.checkQueryChange();

    setTimeout(() => {
      this.canQuickFilterUnselect = true;
    }, 500);
  }

  tooltipValue(item: any) {
    if(!item.value) {
      return;
    }

    return item.value;
  }

  filterTooltipValue(item: any) {
    if(!item.value) {
      return;
    }

    return item.value.Description;
  }

  assignedUserValue(item: any) {
    if(!item.value) {
      return '';
    }

    if(item.value.UserID == 0) {
      return '';
    }

    const firstName = item.value.FirstName;
    if(!firstName) {
      return '';
    }

    return firstName[0] + '. ' + item.value.LastName;
  }

  assingedUserTooltip(item: any) {
    if(!item.value) {
      return;
    }

    if(item.value.UserID == 0) {
      return;
    }


    return item.value.FirstName + ' ' + item.value.LastName;
  }

  payerValue(item: any) {
    if(!item.value) {
      return null;
    }

    return item.value.Abbreviation;
  }

  filterOptionValue(item: any) {
    if(!item.value) {
      return null;
    }

    return item.value.Description;
  }

  payerTooltip(item: any) {
    if(!item.value) {
      return;
    }

    return item.value.Name;
  }

  booleanValue(item: any) {
    if(!item.value) {
      return "No";
    }

    return "Yes";
  }

  dateTimeToLocalValue(item: any) {
    if(!item) {
      return;
    }

    return formatDate(new Date(item.value + ' UTC'), 'MM/dd/yyyy', 'en');
  }

  updateSelectedRows(item: DiagnosisEventItem, isAdd: boolean) {

    const found = this.selectedRows.find(o => o.ID == item.ID);

    if(isAdd && !found) {
      this.selectedRows.push(item);
    }

    if(!isAdd && found) {
      this.selectedRows = this.selectedRows.filter(o => o.ID != found.ID);
    }

    let disableEdit = false;
    this.selectedRows.forEach(i => {
      if(i.AssignedTo == null) {
        disableEdit = true;
        return;
      }
    });

    if(this.selectedRows.length == 0) {
      disableEdit = true;
    }
    
    this.agGrid.api.getStatusPanel<IAgOpptyEditButton>('edit-btn').updateDisabled(disableEdit);
  }

  getColumnValue(fieldName: string, value: any) {
    if(!value) {
      return null;
    }

    let result = '';

    switch(fieldName) {
      case "StatusState":
      case "Status":
      case "RootCause":
      case "PreferredAction":
        result = value.Description;
        break;

      case "AssignedTo":
      case "LastModifiedBy":
        result = value.FirstName + ' ' + value.LastName;
        break;

      case "Payer":
        result = value.Name;
        break;

      default:
        result = value;
        break;
    }

    return result;
  }

  checkQueryChange() {
    if(!Comparitors.DiagnosisEventQueryEquals(this.query, this.previousQuery)) {
      this.previousQuery = structuredClone(this.query);
      this.agGrid.api.deselectAll();
      this.selectedRows = [];
      this.agGrid.api.getStatusPanel<IAgRowSelectedCount>('row-selected-count').updateSelectedCount(0);
      this.agGrid.api.getStatusPanel<IAgOpptyEditButton>('edit-btn').updateDisabled(true);
      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').resetPaginator();
    }
  }

}
