<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div id="snav">
  <mat-nav-list appAccordion class="unselectable">
    @for (menuitem of []; track menuitem) {
      <mat-list-item appAccordionLink routerLinkActive="selected"
        group="{{ menuitem.state }}">
        @if (menuitem.type === 'link') {
          <a class="" appAccordionToggle [routerLink]="['/', menuitem.state]">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            @for (badge of menuitem.badge; track badge) {
              <span class="label label-{{ badge.type }}">{{ badge.value }}</span>
            }
          </a>
        }
        @if (menuitem.type === 'extLink') {
          <a class="" appAccordionToggle href="{{ menuitem.state }}">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            @for (badge of menuitem.badge; track badge) {
              <span class="label label-{{ badge.type }}">{{ badge.value }}</span>
            }
          </a>
        }
        @if (menuitem.type === 'extTabLink') {
          <a class="" appAccordionToggle href="{{ menuitem.state }}" target="_blank">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            @for (badge of menuitem.badge; track badge) {
              <span class="label label-{{ badge.type }}">{{ badge.value }}</span>
            }
          </a>
        }
        @if (menuitem.type === 'sub') {
          <a class="" appAccordionToggle href="javascript:;">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            @for (badge of menuitem.badge; track badge) {
              <span class="label label-{{ badge.type }}">{{ badge.value }}</span>
            }
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
          </a>
        }
        @if (menuitem.type === 'sub') {
          <mat-nav-list class="sub-item">
            @for (childitem of menuitem.children; track childitem) {
              <mat-list-item routerLinkActive="selected">
                <a [routerLink]="['/', menuitem.state, childitem.state]" class="relative" routerLinkActive="selected">{{
                childitem.name }}</a>
              </mat-list-item>
            }
          </mat-nav-list>
        }
        @if (menuitem.type === 'saperator') {
          <div class="saperator text-muted">
            <span>{{ menuitem.name }}</span>
          </div>
        }
      </mat-list-item>
    }
  </mat-nav-list>
</div>

@if (router.url === '/SomePage') {
  <mat-card appearance="outlined" class="unselectable">
    <mat-card-content>
      <br />
      <h3 class="filter-h3">Sub Items Example</h3>
      <section class="example-section">
        <mat-checkbox class="filter-radio-button" [(ngModel)]="showVerified">Confirmed
        </mat-checkbox>
        <mat-checkbox class="filter-radio-button" [(ngModel)]="showUnverified">Unconfirmed
        </mat-checkbox>
      </section>
      <br />
      <br />
    </mat-card-content>
  </mat-card>
}