import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../../services/settings/settings.service';
import { CalculationsService } from '../../../services/calculations/calculations.service';
import { HccSummaryCalculations } from '../../../models/hcc-summary-calculations';
import { PermissionMap, PermissionService } from '../../../services/settings/permission.service';
import { Permission } from '../../../models/permission';
import { ReportNames } from '../../../models/report-names';
import { MatCard, MatCardHeader, MatCardContent } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatIcon } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { NgClass, DecimalPipe, PercentPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-hcc-summary-info',
    templateUrl: './hcc-summary-info.component.html',
    styleUrls: ['./hcc-summary-info.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardHeader, RouterLink, MatTooltip, MatCardContent, MatGridList, MatGridTile, MatIcon, MatSlideToggle, FormsModule, NgClass, DecimalPipe, PercentPipe, DatePipe]
})
export class HccSummaryInfoComponent implements OnInit, OnDestroy {

  @Input() queryData = false;

  summaryDxSwitch = false;
  expandInfoSwitch = false;
  reportingEnabled = false;
  serviceYear = -1;
  data: HccSummaryCalculations = new HccSummaryCalculations();
  userPermissions: Permission[] = [];

  DIAGNOSIS_EVENTS_SUMMARY_NAME = ReportNames.DIAGNOSIS_EVENTS_SUMMARY_NAME;

  subs: Subscription[] = [];

  constructor(
    private settingsService: SettingsService,
    private calculationsService: CalculationsService,
    private permissionService: PermissionService
  ) { 
    //TODO
  }

  ngOnInit(): void {
    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      if(result > -1) {
        this.serviceYear = result;

        if(this.queryData) {
          this.calculationsService.getHccSummaryCalculations(this.serviceYear);
        }
      }
    }));

    this.subs.push(this.permissionService.activeUserPermissions.subscribe(result => {
      this.userPermissions = result;
      this.permissionsCheck();
    }));

    this.subs.push(this.calculationsService.hccSummaryCalculations.subscribe(result => {
      this.data = result;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getPercentValue(value: number) {
    if(value == 0) {
      return 0;
    }

    return value / 100;
  }

  permissionsCheck() {
    this.userPermissions.forEach(p => {
      if(p.PermissionID == PermissionMap.GenerateReportsDxEvents) {
        this.reportingEnabled = true;
      }
    })
  }

}
