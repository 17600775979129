<mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }
  <input matInput
    [formControl]="control"
    [placeholder]="placeholder"
    [required]="required">

    <span matPrefix>$&nbsp;</span>

    @if (control.hasError('required')) {
      <mat-error>Value is <strong>required</strong></mat-error>
    }
    @if (control.hasError('pattern')) {
      <mat-error>Value must be valid currency format</mat-error>
    }
  </mat-form-field>
