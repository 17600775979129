import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ColDef, ColGroupDef, RowClassRules } from 'ag-grid-enterprise';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { AuditAction } from '../../../../../models/audit-action';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { OpptyHccValuationReportItem } from '../../../../../models/oppty-hcc-valuation-report-item';
import { UtilityFunctions } from '../../../../../helpers/utility-functions';
import { FieldFilterService } from '../../../../../services/filters/field-filter.service';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

@Component({
    selector: 'app-oppty-hcc-valuation-report',
    templateUrl: './oppty-hcc-valuation-report.component.html',
    styleUrls: ['./oppty-hcc-valuation-report.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class OpptyHccValuationReportComponent implements OnInit, OnDestroy {
  data: OpptyHccValuationReportItem[] = [];

  columnDefs: (ColDef | ColGroupDef)[] = [];

  defaultColDef: ColDef = {
    menuTabs: [],
    minWidth: 150,
    maxWidth: 150,
    autoHeight: true
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  public rowClassRules: RowClassRules = {
    // row style function
    'parent-cell': (params) => {
      return params.data.DisplayName === this.calculationsService.hccValuationClosedRowName
      || params.data.DisplayName === this.calculationsService.hccValuationOpenRowName
      || params.data.DisplayName === this.calculationsService.hccValuationTotalRowName;
    },
  };

  excelStyles = DxsrExcelStyling.excelStyles2;

  gridContext: any;

  preferredActions = ["Grand Total All Actions"];
  gridReady = false;

  dxSwitch = false;

  currentYear = 0;

  currentRowHeight = 140;
  currentGridWidth = 282;

  totalSectionName = "Grand Total All Actions";

  subs: Subscription[] = [];

  preferredActionsSubject: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(AgGridAngular) grids!: QueryList<AgGridAngular>;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGridColumnsToFit();
  }

  constructor(
    private calculationsService: CalculationsService,
    private filterService: FieldFilterService,
    private loggingService: LoggingService) {
    this.gridContext = { componentParent: this };
  }

  ngOnInit(): void {

    this.filterService.getOpptyPreferredActionOptionsSubject();

    //Build type oppty preferred action grids
    this.subs.push(this.filterService.opptyPreferredActions.subscribe(result => {
      this.preferredActions = [this.totalSectionName];

      result?.map(p => p.Description).forEach(i => {
        this.preferredActions.push(i);
      });

      setTimeout(() => {
        this.showGridsLoadingOverlay();
        this.setGridColumnsToFit();
      }, 500);
    }));

    //Data sub
    this.subs.push(this.calculationsService.hccValuation.subscribe(result => {
      this.data = result;
      this.updateGridData();
      this.currentRowHeight = 64 + ((this.data.length + 3) * 29);
      this.currentGridWidth = 752;
      this.hideGridsLoadingOverlay();
      this.setExportEnable.emit(true);
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  updateServiceYear(serviceYear: number) {
    this.currentYear = serviceYear;
    this.calculationsService.getOpptyPreferredActionHccValuation(serviceYear);
    this.showGridsLoadingOverlay();
  }

  getColDef(sectionName: string): (ColDef | ColGroupDef)[] {

    const result: any = [
      {
        headerName: sectionName,
        headerTooltip: sectionName,
        children: [
          { field: 'DisplayName', headerName: '',
            minWidth: 250,
            maxWidth: 600,
            cellClassRules: {
              'dxsr-display-name-1': (params) => {
                return params.data.DisplayName === this.calculationsService.hccValuationClosedRowName
                || params.data.DisplayName === this.calculationsService.hccValuationOpenRowName
                || params.data.DisplayName === this.calculationsService.hccValuationTotalRowName;
              },
              'dxsr-display-name-4': (params) => {
                return  params.data.Parent != null && params.data.Parent != "";
              },
            }
          },
          { field: "TotalCount", headerName: 'DX Event Count', type: 'numericColumn',
            valueFormatter: AgGridFunctions.getNumberValue,
            cellClassRules: {
              'ag-parent-cell-left-border': (params) => {
                return params.data.DisplayName === this.calculationsService.hccValuationClosedRowName
                || params.data.DisplayName === this.calculationsService.hccValuationOpenRowName
                || params.data.DisplayName === this.calculationsService.hccValuationTotalRowName;
              },
              'ag-cell-left-border': (params) => {
                return params.data.Parent != null && params.data.Parent != "";
              }
            },
            cellClass: 'numberType'
          },
          { field: "HCCCount", headerName: 'Distinct HCC Count', type: 'numericColumn',
            valueFormatter: AgGridFunctions.getNumberValue,
            cellClassRules: {
              'ag-parent-cell-right-border': (params) => {
                return params.data.DisplayName === this.calculationsService.hccValuationClosedRowName
                || params.data.DisplayName === this.calculationsService.hccValuationOpenRowName
                || params.data.DisplayName === this.calculationsService.hccValuationTotalRowName;
              },
              'ag-cell-right-border': (params) => {
                return params.data.Parent != null && params.data.Parent != "";
              }
            },
            cellClass: 'numberType'
          },
          { field: "Valuation", headerName: 'HCC Est. $ Valuation', type: 'numericColumn',
            valueFormatter: AgGridFunctions.getCurrencyValue,
            minWidth: 200,
            maxWidth: 600,
            cellClassRules: {
              'ag-parent-cell-right-border': (params) => {
                return params.data.DisplayName === this.calculationsService.hccValuationClosedRowName
                || params.data.DisplayName === this.calculationsService.hccValuationOpenRowName
                || params.data.DisplayName === this.calculationsService.hccValuationTotalRowName;
              },
              'ag-cell-right-border': (params) => {
                return params.data.Parent != null && params.data.Parent != "";
              },
              'currency': () => {return true}
            }
          },
        ]
      }
    ];

    return result;
  }

  updateGridData() {
    this.grids?.forEach(g => {
      const gridName = g.api.getGridId();
      const items = gridName == this.totalSectionName ? this.data : this.data.filter(d => d.PreferredAction == gridName);
      const gridItems = gridName == this.totalSectionName ? this.calculationsService.getHccValuationGrandTotalItems(items) : this.calculationsService.getHccValuationRowItems(items || []);
      g.api.setGridOption('rowData', gridItems);
      g.api.setGridOption('columnDefs', this.getColDef(gridName));
    });
  }

  setGridColumnsToFit() {
    if(!document.getElementById(this.totalSectionName)) {
      return;
    }

    this.grids?.forEach(g => g.api.sizeColumnsToFit());
  }

  showGridsLoadingOverlay() {
    this.grids?.forEach(g => g.api.showLoadingOverlay());
  }

  hideGridsLoadingOverlay() {
    this.grids?.forEach(g => g.api.hideOverlay());
  }

  onExport() {
    const sheets = [];

    this.grids.forEach((g, index) => {
      const name = index == 0 ? 'HCC Valuation by Preferred Action' : `${this.preferredActions[index]} Valuation`
      sheets.push(g.api.getSheetDataForExcel({sheetName: name}));
    })

    const filename =`HCC_Valuation_by_Preferred_Action_${this.currentYear}`;

    const grid = this.grids.get(0);

    grid.api.exportMultipleSheetsAsExcel({
      data: sheets,
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: 'HCC Valuation by Preferred Action', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: HCC Valuation by Preferred Action, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }

}
