import { Subject } from '../Subject';
import { Subscription } from '../Subscription';
import { OuterSubscriber } from '../OuterSubscriber';
import { subscribeToResult } from '../util/subscribeToResult';
export function windowToggle(openings, closingSelector) {
  return source => source.lift(new WindowToggleOperator(openings, closingSelector));
}
class WindowToggleOperator {
  constructor(openings, closingSelector) {
    this.openings = openings;
    this.closingSelector = closingSelector;
  }
  call(subscriber, source) {
    return source.subscribe(new WindowToggleSubscriber(subscriber, this.openings, this.closingSelector));
  }
}
class WindowToggleSubscriber extends OuterSubscriber {
  constructor(destination, openings, closingSelector) {
    super(destination);
    this.openings = openings;
    this.closingSelector = closingSelector;
    this.contexts = [];
    this.add(this.openSubscription = subscribeToResult(this, openings, openings));
  }
  _next(value) {
    const {
      contexts
    } = this;
    if (contexts) {
      const len = contexts.length;
      for (let i = 0; i < len; i++) {
        contexts[i].window.next(value);
      }
    }
  }
  _error(err) {
    const {
      contexts
    } = this;
    this.contexts = null;
    if (contexts) {
      const len = contexts.length;
      let index = -1;
      while (++index < len) {
        const context = contexts[index];
        context.window.error(err);
        context.subscription.unsubscribe();
      }
    }
    super._error(err);
  }
  _complete() {
    const {
      contexts
    } = this;
    this.contexts = null;
    if (contexts) {
      const len = contexts.length;
      let index = -1;
      while (++index < len) {
        const context = contexts[index];
        context.window.complete();
        context.subscription.unsubscribe();
      }
    }
    super._complete();
  }
  _unsubscribe() {
    const {
      contexts
    } = this;
    this.contexts = null;
    if (contexts) {
      const len = contexts.length;
      let index = -1;
      while (++index < len) {
        const context = contexts[index];
        context.window.unsubscribe();
        context.subscription.unsubscribe();
      }
    }
  }
  notifyNext(outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    if (outerValue === this.openings) {
      let closingNotifier;
      try {
        const {
          closingSelector
        } = this;
        closingNotifier = closingSelector(innerValue);
      } catch (e) {
        return this.error(e);
      }
      const window = new Subject();
      const subscription = new Subscription();
      const context = {
        window,
        subscription
      };
      this.contexts.push(context);
      const innerSubscription = subscribeToResult(this, closingNotifier, context);
      if (innerSubscription.closed) {
        this.closeWindow(this.contexts.length - 1);
      } else {
        innerSubscription.context = context;
        subscription.add(innerSubscription);
      }
      this.destination.next(window);
    } else {
      this.closeWindow(this.contexts.indexOf(outerValue));
    }
  }
  notifyError(err) {
    this.error(err);
  }
  notifyComplete(inner) {
    if (inner !== this.openSubscription) {
      this.closeWindow(this.contexts.indexOf(inner.context));
    }
  }
  closeWindow(index) {
    if (index === -1) {
      return;
    }
    const {
      contexts
    } = this;
    const context = contexts[index];
    const {
      window,
      subscription
    } = context;
    contexts.splice(index, 1);
    window.complete();
    subscription.unsubscribe();
  }
}
