import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { ListMenuOption } from '../../../models/list-menu-option';
import { TreeMenuComponent } from '../../sub-components/tree-menu/tree-menu.component';
import { CmsScheduleConfigComponent } from '../../sub-components/cms-schedule-config/cms-schedule-config.component';
import { DataRefreshConfigComponent } from '../../sub-components/data-refresh-config/data-refresh-config.component';
import { HccConfigComponent } from '../../sub-components/hcc-config/hcc-config.component';
import { HccModelVersionConfigComponent } from '../../sub-components/hcc-model-version-config/hcc-model-version-config.component';
import { PayerConfigComponent } from '../../sub-components/payer-config/payer-config.component';
import { TemplateConfigComponent } from '../../sub-components/template-config/template-config.component';

@Component({
    selector: 'app-config-page',
    templateUrl: './config-page.component.html',
    styleUrls: ['./config-page.component.scss'],
    standalone: true,
    imports: [TreeMenuComponent, CmsScheduleConfigComponent, DataRefreshConfigComponent, HccConfigComponent, HccModelVersionConfigComponent, PayerConfigComponent, TemplateConfigComponent]
})
export class ConfigPageComponent implements OnInit, AfterViewInit {

  sideContainerExpanded = false;
  currentPageID = 1;
  pageIDConfigKey = "VC-Config-Current-Page";

  configMenuOptions: ListMenuOption[] = [
    {ID: 1, Name: "CMS Schedule", Icon: "today", IconOutlined: false, Disabled: false},
    {ID: 2, Name: "Data Refresh Log", Icon: "cached", IconOutlined: false, Disabled: false},
    {
      ID: 3, Name: "HCCs", Icon: "settings_applications", IconOutlined: false, Disabled: false,
      Children: [
        {ID: 4, Name: "Estimated Valuation", Icon: undefined, IconOutlined: false, Disabled: false},
        {ID: 5, Name: "HCC / ICD10 Model", Icon: undefined, IconOutlined: false, Disabled: false},
      ]
    },
    {ID: 6, Name: "Payers", Icon: "build", IconOutlined: false, Disabled: false},
    {ID: 7, Name: "Templates", Icon: "description", IconOutlined: true, Disabled: false}
  ];

  selectedOption: ListMenuOption = new ListMenuOption();

  @ViewChild(TreeMenuComponent) treeMenu: TreeMenuComponent;

  constructor() {
    this.currentPageID = JSON.parse(sessionStorage.getItem(this.pageIDConfigKey) || '1');
  }

  ngOnInit(): void {
    //TODO
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.treeMenu.setSelectedOption(this.currentPageID);
    }, 300);
  }

  onSelectedConfigOption(option: ListMenuOption) {
    this.selectedOption = option;
    sessionStorage.setItem(this.pageIDConfigKey, JSON.stringify(option.ID));
  }

}
