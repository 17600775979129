import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { HccService } from '../../services/hcc/hcc.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HccModelVersion } from '../../models/hcc-model-version';
import { OrgHccModelVersionItem } from '../../models/org-hcc-model-version-item';
import { HccModelCategory } from '../../models/hcc-model-category';
import { HccModelVersionSelectComponent } from '../../components/field-components/hcc-model-version-select/hcc-model-version-select.component';
import { HccModelCategorySelectComponent } from '../../components/field-components/hcc-model-category-select/hcc-model-category-select.component';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { PaymentYearSelectComponent } from '../../components/field-components/payment-year-select/payment-year-select.component';
import { MatButton } from '@angular/material/button';

export class AddUpdateHccModelVersionDialogModel {
  readonly component: ComponentType<any> = AddUpdateHccModelVersionDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  data?: any;
  minWidth?: string = '35dvw';
  minHeight?: string = '40dvh';
  disableClose? = true;

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
    selector: 'app-add-update-hcc-model-version-dialog',
    templateUrl: './add-update-hcc-model-version-dialog.component.html',
    styleUrls: ['./add-update-hcc-model-version-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIcon, CdkScrollable, MatDialogContent, PaymentYearSelectComponent, HccModelCategorySelectComponent, HccModelVersionSelectComponent, MatDialogActions, MatButton]
})
export class AddUpdateHccModelVersionDialogComponent implements OnInit {

  item: OrgHccModelVersionItem;
  mode: string;
  invalidServiceYears: number[];

  form: FormGroup;

  serviceYearControlName = 'service-year';
  categoryControlName = 'category';
  versionControlName = 'version';

  selectedVersion = new HccModelVersion();

  @ViewChild(HccModelCategorySelectComponent) categorySelect: HccModelCategorySelectComponent;
  @ViewChild(HccModelVersionSelectComponent) versionSelect: HccModelVersionSelectComponent;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateHccModelVersionDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hccService: HccService,
    private fb: FormBuilder,
  ) {
    this.mode = data.mode;
    this.item = data.item;
    this.invalidServiceYears = data.invalidServiceYears;
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    //TODO
  }

  ngAfterViewInit(): void {
    if(this.mode == 'edit') {
      setTimeout(() => {
        this.form.get(this.serviceYearControlName).setValue(this.item.ServiceYear);
      }, 200);
    }
  }

  onCloseClick() {
    this.dialogRef.close(null);
  }

  onSaveClick() {
    if(this.mode == 'add') {
      this.item = new OrgHccModelVersionItem();
    }

    this.item.ServiceYear = this.form.get(this.serviceYearControlName).value;

    const category = this.categorySelect.control.value as HccModelCategory;
    const version = this.versionSelect.control.value as HccModelVersion;

    this.item.ModelCategoryID = category.ID;
    this.item.ModelCategoryName = category.Category;
    this.item.VersionID = version.ID;
    this.item.VersionName = version.Version;
    this.item.IsEdit = this.mode == 'edit';

    this.hccService.addUpdateOrgHccModelVersionItem(this.item).subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  onCategoryReady() {
    if(this.mode == 'edit') {
      this.categorySelect.setSingleModeValueByID(this.item.ModelCategoryID);
      this.versionSelect.control.enable();
      this.versionSelect.getOptionData([this.item.ModelCategoryID]);
    }
  }

  onCategoryChanged(item: HccModelCategory) {
    this.versionSelect.getOptionData([item.ID]);
  }

  onVersionReady() {
    this.versionSelect.control.enable();
    if(this.mode == 'edit') {
      this.versionSelect.setSingleModeValueByID(this.item.VersionID);
    }
  }

  onVersionChanged(item: HccModelVersion) {
    this.selectedVersion = item;
  }
}
