import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { MorDataTableValuesRequest } from "../../models/mor-data-table-values-request";
import { ServiceFormatter } from "../../helpers/service-formatter";
import { MorFileProcessedDataRequest } from "../../models/mor-file-processed-data-request";

@Injectable({
    providedIn: 'root'
})
export class MorDataService {
    private endpoint = ServiceFormatter.endpoint;

    constructor(private http: HttpClient) {}

    getMORDataTableItems(query: MorDataTableValuesRequest) {
        const params: HttpParams = new HttpParams()
        .set("payerIds", query.payerIds)
        .set("thresholdDateString", query.thresholdDateString);
        return this.http.get<any>(this.endpoint + "MORData/mor-data-table-items", ServiceFormatter.getHttpOptions(params));
    }

    uploadMORFile(morFileForm: FormData) {
      const standardHttpOptions = ServiceFormatter.getHttpOptions();
      return this.http.post<any>(this.endpoint + "MORData/mor-upload", morFileForm, {
        headers: standardHttpOptions.headers,
        params: standardHttpOptions.params,
        observe: 'events',
        reportProgress: true
      });
    }

    getMORFileDetails(id: number) {
      const params: HttpParams = new HttpParams()
      .set("id", id);
      return this.http.get<any>(
        this.endpoint + "MORData/get-file-details", 
        ServiceFormatter.getHttpOptions(params)
      );
    }

    getProcessedRecordDataByMorQueueID(request: MorFileProcessedDataRequest) {
      return this.http.post<any>(
        this.endpoint + "MORData/get-processed-records-by-file",
        request,
        ServiceFormatter.getHttpOptions()
      );
    }
}
