"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var operators_1 = require("rxjs/operators");
/**
 * @return {ConnectableObservable<T>}
 * @method publishLast
 * @owner Observable
 */
function publishLast() {
  //TODO(benlesh): correct type-flow through here.
  return operators_1.publishLast()(this);
}
exports.publishLast = publishLast;
