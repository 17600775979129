<div class="dx-trend-row report-grid-alt" 
[ngStyle]="{'--row-height': currentRowHeight + 'px', '--row-width': currentGridWidth + 'px'}">
    <ag-grid-angular
    class="ag-theme-balham w-full h-full"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [getRowId]="getRowId"
    [tooltipShowDelay]="0"
    [statusBar]="statusBar"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [context]="gridContext"
    [components]="frameworkComps"
    [excelStyles]="excelStyles"
    [suppressCopyRowsToClipboard]="true"
    [suppressPaginationPanel]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    [suppressHorizontalScroll]="true"
    (gridReady)="onGridReady()"
    (columnResized)="AgGridFunctions.statusPanelResizeWithColumn($event, statusBar)"></ag-grid-angular>
</div>
