import { Subscription } from '../Subscription';
import { subscribeToResult } from '../util/subscribeToResult';
import { OuterSubscriber } from '../OuterSubscriber';
export function bufferToggle(openings, closingSelector) {
  return function bufferToggleOperatorFunction(source) {
    return source.lift(new BufferToggleOperator(openings, closingSelector));
  };
}
class BufferToggleOperator {
  constructor(openings, closingSelector) {
    this.openings = openings;
    this.closingSelector = closingSelector;
  }
  call(subscriber, source) {
    return source.subscribe(new BufferToggleSubscriber(subscriber, this.openings, this.closingSelector));
  }
}
class BufferToggleSubscriber extends OuterSubscriber {
  constructor(destination, openings, closingSelector) {
    super(destination);
    this.closingSelector = closingSelector;
    this.contexts = [];
    this.add(subscribeToResult(this, openings));
  }
  _next(value) {
    const contexts = this.contexts;
    const len = contexts.length;
    for (let i = 0; i < len; i++) {
      contexts[i].buffer.push(value);
    }
  }
  _error(err) {
    const contexts = this.contexts;
    while (contexts.length > 0) {
      const context = contexts.shift();
      context.subscription.unsubscribe();
      context.buffer = null;
      context.subscription = null;
    }
    this.contexts = null;
    super._error(err);
  }
  _complete() {
    const contexts = this.contexts;
    while (contexts.length > 0) {
      const context = contexts.shift();
      this.destination.next(context.buffer);
      context.subscription.unsubscribe();
      context.buffer = null;
      context.subscription = null;
    }
    this.contexts = null;
    super._complete();
  }
  notifyNext(outerValue, innerValue) {
    outerValue ? this.closeBuffer(outerValue) : this.openBuffer(innerValue);
  }
  notifyComplete(innerSub) {
    this.closeBuffer(innerSub.context);
  }
  openBuffer(value) {
    try {
      const closingSelector = this.closingSelector;
      const closingNotifier = closingSelector.call(this, value);
      if (closingNotifier) {
        this.trySubscribe(closingNotifier);
      }
    } catch (err) {
      this._error(err);
    }
  }
  closeBuffer(context) {
    const contexts = this.contexts;
    if (contexts && context) {
      const {
        buffer,
        subscription
      } = context;
      this.destination.next(buffer);
      contexts.splice(contexts.indexOf(context), 1);
      this.remove(subscription);
      subscription.unsubscribe();
    }
  }
  trySubscribe(closingNotifier) {
    const contexts = this.contexts;
    const buffer = [];
    const subscription = new Subscription();
    const context = {
      buffer,
      subscription
    };
    contexts.push(context);
    const innerSubscription = subscribeToResult(this, closingNotifier, context);
    if (!innerSubscription || innerSubscription.closed) {
      this.closeBuffer(context);
    } else {
      innerSubscription.context = context;
      this.add(innerSubscription);
      subscription.add(innerSubscription);
    }
  }
}
