import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'app-payment-year-select',
    templateUrl: './payment-year-select.component.html',
    styleUrls: ['./payment-year-select.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatOption, MatError]
})
export class PaymentYearSelectComponent implements OnInit {

  private _defaultValue;
  @Input() set defaultValue(value: number) {
    this._defaultValue = value;
    this.control?.setValue(value);
    this.defaultValueChanged.emit(value);
    //this.setFormControl(value);
  }

  get defaultValue(): number {
    return this._defaultValue;
  }

  private _omitValues;
  @Input() set omitValues(values: number[]) {
    this._omitValues = values;
  }

  get omitValues(): number[] {
    return this._omitValues;
  }

  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() label = 'Year';
  @Input() placeholder = '';
  @Input() controlName = 'payment-year';
  @Input() toolTipPosition = 'above';
  @Input() required = false;
  @Input() minYear = 2019;
  @Input() maxYear = new Date().getFullYear();
  @Input() maxYearAddition = 2;
  @Input() noneEnabled = false;
  @Input() descSort = false;
  @Input() disabled = false;

  options = [];
  control: FormControl = new FormControl('');

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();
  @Output() defaultValueChanged = new EventEmitter();

  constructor() { 
    //TODO
  }

  ngOnInit(): void {
    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.setControl(this.controlName, this.control);
    }

    if(this.disabled) {
      this.control.disable();
    }

    this.getOptionData();
  }

  getOptionData() {
    this.options = [];

    for(let i = this.minYear; i <= this.maxYear + this.maxYearAddition; i++) {
      this.options.push(i);
    }

    if(this.descSort) {
      this.options = this.options.sort((a, b) => b - a);
    }

    if(this.omitValues) {
      this.options = this.options.filter(o => !this.omitValues.includes(o));
    }

    this.ready.emit();
  }

  setValue(value: number) {
    this.control.setValue(value);
  }

  onSelectionChange() {
    this.valueChanged.emit(this.control?.value);
  }

}
