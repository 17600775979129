<div class="flex flex-auto flex-row gap-[3px]">
    <div class="ag-grid-outer-container allocation-grid ag-grid-header-alt" [ngStyle]="{'--row-height': averageGridHeight + 'px', '--row-width': averageGridWidth + 'px'}">
        <ag-grid-angular
        #averageGrid
        class="ag-theme-balham w-full h-full"
        [context]="gridContext"
        [columnDefs]="averageColumnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="averageItems"
        [suppressContextMenu]="true"
        (gridReady)="averageGridReady = true; onGridReady($event)"></ag-grid-angular>
    </div>
    <div class="ag-grid-outer-container allocation-grid ag-grid-header-alt" [ngStyle]="{'--row-height': hccGridHeight + 'px', '--row-width': hccGridWidth + 'px'}">
        <ag-grid-angular
        #hccGrid
        class="ag-theme-balham w-full h-full"
        [context]="gridContext"
        [columnDefs]="columnDefs"
        [excelStyles]="excelStyles"
        [defaultColDef]="defaultColDef"
        [rowData]="items"
        [suppressContextMenu]="true"
        [rowClassRules]="rowClassRules"
        [autoGroupColumnDef]="autoColumnGroupDef"
        [suppressAggFuncInHeader]="true"
        [groupDefaultExpanded]="1"
        [loadingOverlayComponent]="loadingOverlayComponent"
        (gridReady)="hccGridReady = true; onGridReady($event)"></ag-grid-angular>
    </div>
</div>
