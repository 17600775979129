import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { HccValuationItem } from '../../models/hcc-valuation-item';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { HccService } from '../../services/hcc/hcc.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { PaymentYearSelectComponent } from '../../components/field-components/payment-year-select/payment-year-select.component';
import { CurrencyInputComponent } from '../../components/field-components/currency-input/currency-input.component';
import { MatButton } from '@angular/material/button';

export class AddUpdateHccValuationDialogModel {
  readonly component: ComponentType<any> = AddUpdateHccValuationDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  data?: any;
  width?: string = '30dvw';
  disableClose? = true;

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
    selector: 'app-add-update-hcc-valuation-dialog',
    templateUrl: './add-update-hcc-valuation-dialog.component.html',
    styleUrls: ['./add-update-hcc-valuation-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIcon, CdkScrollable, MatDialogContent, PaymentYearSelectComponent, CurrencyInputComponent, MatDialogActions, MatButton]
})
export class AddUpdateHccValuationDialogComponent implements OnInit, AfterViewInit {

  item: HccValuationItem;
  mode: string;

  form: FormGroup;

  serviceYearControlName = 'service-year';
  currencyControlName = 'currency';

  isServiceYearReady = false;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateHccValuationDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hccService: HccService,
    private fb: FormBuilder,
  ) {
    this.mode = data.mode;
    this.item = data.item;
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    //TODO
  }

  ngAfterViewInit(): void {
    if(this.mode == 'edit') {
      setTimeout(() => {
        this.form.get(this.serviceYearControlName).setValue(this.item.ServiceYear);
        this.form.get(this.currencyControlName).setValue(this.item.ValuationAmount);
      }, 200);
    }
  }

  onCloseClick() {
    this.dialogRef.close(null);
  }

  onServiceYearReady() {
    this.isServiceYearReady = true;
  }

  onSaveClick() {
    if(this.mode == 'add') {
      this.item = new HccValuationItem();
    }

    this.item.ServiceYear = this.form.get(this.serviceYearControlName).value;
    this.item.ValuationAmount = this.form.get(this.currencyControlName).value;

    this.hccService.addUpdateHccValuationItem(this.item).subscribe(() => {
      this.dialogRef.close(true);
    });
  }

}
