<div class="flex basis-[7%] flex-row">
    <div class="flex basis-3/4 justify-start items-center content-center">
        <h1>HCC / ICD10 Model Version</h1>
    </div>

    <div class="flex basis-1/4 justify-end items-center content-center">
        <button mat-flat-button class="primary-button-longtext"
        [matTooltip]="'Add Entry'"
        [matTooltipClass]="'app-tooltip'"
        (click)="onAddClick()">Add Entry</button>
    </div>
</div>

<div class="grid-container w-[800px]" [style]="'--grid-height: ' + currentRowHeight + 'px'">
    <!-- AG Grid Angular Component -->
    <ag-grid-angular
    class="ag-theme-balham w-full h-full"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowModelType]="'serverSide'"
    [getRowId]="getRowId"
    [pagination]="true"
    [tooltipShowDelay]="0"
    [components]="frameworkComps"
    [statusBar]="statusBar"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [context]="gridContext"
    [getContextMenuItems]="onGridGetContextMenu"
    [suppressCopyRowsToClipboard]="true"
    [suppressPaginationPanel]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    [suppressHorizontalScroll]="true"
    (gridReady)="onGridReady()"
    (cellKeyDown)="onCellKeyDown($event)"></ag-grid-angular>
</div>
