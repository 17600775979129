<div class="flex basis-[7%] flex-row">
    <div class="flex basis-[85%] justify-start items-center content-center">
        <h1>CMS Risk Adjustment Schedule</h1>
    </div>

    <div class="flex flex-auto flex-row justify-end items-center content-center">
        <button mat-flat-button class="primary-button-longtext"
        [matTooltip]="'Add Entry'"
        [matTooltipClass]="'app-tooltip'"
        (click)="onAddClick()">+ Add Entry</button>
    </div>
</div>

<app-cms-timeline 
class="cms-timeline flex flex-auto pb-[40px]"></app-cms-timeline>

<div class="grid grid-cols-5 gap-3 pt-3 pb-3">

    <app-payment-year-select
    #serviceYear
    [form]="form"
    [controlName]="'service-year'"
    [label]="'Service Year'"
    [appearance]="'outline'"
    [required]="true"
    [minYear]="2021"
    [defaultValue]="-1"
    [noneEnabled]="true"
    [maxYearAddition]="3"></app-payment-year-select>


    <div class="pt-3">
        <button mat-flat-button class="primary-button"
        [disabled]="form.invalid"
        [matTooltip]="'Generate'"
        [matTooltipClass]="'app-tooltip'"
        (click)="onGenerateGridData()">Generate</button>
    </div>

</div>

<div class="grid-section flex flex-[20_1_auto] flex-col">
    <!-- AG Grid Angular Component -->
    <ag-grid-angular
    class="ag-theme-balham w-full h-full"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowModelType]="'serverSide'"
    [getRowId]="getRowId"
    [pagination]="true"
    [tooltipShowDelay]="0"
    [components]="frameworkComps"
    [statusBar]="statusBar"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [context]="gridContext"
    [getContextMenuItems]="onGridGetContextMenu"
    [suppressCopyRowsToClipboard]="true"
    [suppressPaginationPanel]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    (gridReady)="onGridReady()"
    (firstDataRendered)="onFirstGridLoad()"
    (cellKeyDown)="onCellKeyDown($event)"></ag-grid-angular>
</div>
