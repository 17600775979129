import { ExcelStyle } from "ag-grid-enterprise";

export class DxsrExcelStyling {
    public static excelBorderColor = '#b3b3b3';
    public static excelSubrowIndent = 1;
    public static excelTrendsHeaderGroupBgColor = '#425C8B';
    public static excelParentCellColor = '#ffffff';
    public static excelIndent1BgColor = '#e0e0e0';
  
    public static excelStyles: ExcelStyle[] = [
        {
            id: 'header',
            alignment: {
                horizontal: 'Center'
            },
            borders: {
                borderTop: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
                borderBottom: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
                borderLeft: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
                borderRight: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                }
            },
            interior: {
                color: '#c5d7ed',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-display-name-1',
            font: {
            bold: true
            },
            borders: {
            borderTop: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-display-name-2',
            font: {
            bold: true
            },
            borders: {
            borderTop: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-display-name-3',
            alignment: {
            indent: this.excelSubrowIndent
            },
            borders: {
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-display-name-4',
            alignment: {
            indent: this.excelSubrowIndent
            },
            borders: {
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-display-name-5',
            alignment: {
            indent: this.excelSubrowIndent
            },
            borders: {
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-values-1',
            numberFormat: {
                format:'#,##'
            },
            font: {
            bold: true
            },
            borders: {
            borderTop: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-values-2',
            numberFormat: {
                format:'#,##'
            },
            font: {
            bold: true
            },
            borders: {
            borderTop: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-values-3',
            numberFormat: {
                format:'#,##'
            },
            borders: {
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-values-4',
            numberFormat: {
                format:'#,##'
            },
            borders: {
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-values-5',
            numberFormat: {
                format:'#,##'
            },
            borders: {
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-percent-1',
            font: {
            bold: true
            },
            numberFormat: {
                format: '#,##0.0%'
            },
            borders: {
            borderTop: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-percent-2',
            font: {
            bold: true
            },
            numberFormat: {
                format: '#,##0.0%'
            },
            borders: {
            borderTop: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-percent-3',
            numberFormat: {
                format: '#,##0.0%'
            },
            borders: {
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-percent-4',
            numberFormat: {
                format: '#,##0.0%'
            },
            borders: {
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-percent-5',
            numberFormat: {
                format: '#,##0.0%'
            },
            borders: {
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
    ];

    public static excelStylesTrends: ExcelStyle[] = [
        {
            id: 'headerGroup',
            font: {
              bold: true,
              color: '#ffffff'
            },
            alignment: {
                horizontal: 'Center'
            },
            interior: {
                color: this.excelTrendsHeaderGroupBgColor,
                pattern: 'Solid'
            }
        },
        {
            id: 'header',
            alignment: {
                horizontal: 'Center'
            },
            font: {
                bold: true
            },
            borders: {
                borderBottom: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                }
            },
        },
        {
            id: 'numberType',
            numberFormat: {
                format:'#,##'
            }
        },
        {
            id: 'dxsr-trend-header-right',
            borders: {
                borderRight: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                }
            },
        },
        {
            id: 'dxsr-trend-right-border',
            borders: {
                borderRight: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
            },
        },
        {
            id: 'dxsr-percent-1',
            numberFormat: {
                format: '#,##0.0%'
            }
        },
        {
            id: 'dxsr-percent-4',
            numberFormat: {
                format: '#,##0.0%'
            }
        },
    ];

    public static excelStyles2: ExcelStyle[] = [
        {
            id: 'headerGroup',
            font: {
              bold: true,
              color: '#ffffff'
            },
            alignment: {
                horizontal: 'Center'
            },
            interior: {
                color: this.excelTrendsHeaderGroupBgColor,
                pattern: 'Solid'
            }
        },
        {
            id: 'header',
            font: {
              bold: true,
              color: '#000000'
            },
            alignment: {
                horizontal: 'Center'
            },
        },
        {
            id: 'indent-1',
            dataType: 'String',
            alignment: {
                horizontal: 'Left'
            },
            font: {
                bold: true,
                color: '#000000'
            },
            interior: {
                color: this.excelIndent1BgColor,
                pattern: 'Solid'
            }
        },
        {
            id: 'status-label',
            font: {
                bold: true,
                color: '#000000'
            },
        },
        {
            id: 'numberType',
            numberFormat: {
                format:'#,##0'
            }
        },
        {
            id: 'currency',
            numberFormat: {
                format: '$#,##0.00'
            }
        },
        {
            id: 'percent',
            numberFormat: {
                format: '#,##0.0%'
            }
        },
        {
            id: 'summation',
            alignment: {
                horizontal: 'Right'
            },
            font: {
                bold: true,
                color: '#000000'
            },
            numberFormat: {
                format:'#,##0'
            }
        },
        {
            id: 'ag-parent-cell-right-border',
            font: {
            bold: true
            },
                borders: {
                borderTop: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
                borderBottom: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
                borderRight: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
            },
            interior: {
                color: this.excelParentCellColor,
                pattern: 'Solid'
            }
        },
        {
            id: 'ag-parent-cell-left-border',
            font: {
            bold: true
            },
            borders: {
                borderTop: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
                borderBottom: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
                borderLeft: {
                    color: this.excelBorderColor,
                    lineStyle: "Continuous",
                    weight: 2
                },
            },
            interior: {
                color: this.excelParentCellColor,
                pattern: 'Solid'
            }
        },
        {
            id: 'ag-cell-right-border',
            borders: {
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'ag-cell-left-border',
            borders: {
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-display-name-1',
            font: {
            bold: true
            },
            borders: {
            borderTop: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderBottom: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: this.excelParentCellColor,
                pattern: 'Solid'
            }
        },
        {
            id: 'dxsr-display-name-4',
            alignment: {
            indent: this.excelSubrowIndent
            },
            borders: {
            borderLeft: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            borderRight: {
                color: this.excelBorderColor,
                lineStyle: "Continuous",
                weight: 2
            },
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid'
            }
        },
    ]
}
