import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";

@Component({
    selector: "app-confirm-dialog",
    templateUrl: "./confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.css"],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatInput, FormsModule, MatDialogActions, MatButton]
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  textData: string;
  confirmKey: string;
  additionalMessages: string[];

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.confirmKey = data.confirmKey;
    this.additionalMessages = data.additionalMessages;
  }

  ngOnInit() {}

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  isConfirmDisabled() {
    if (this.confirmKey == "") {
      return false;
    } else {
      if (this.confirmKey == this.textData) {
        return false;
      } else {
        return true;
      }
    }
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  constructor(public title: string, public message: string, public confirmKey: string, public additionalMessages: string[] = []) {}
}
