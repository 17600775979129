import { FilterSelectItem } from "./filter-select-item";

export class PayerConfigItem {
    PayerID: number;
    PayerName: string;
    Abbreviation: string;
    Aliases: string;
    RiskAdjustmentPolicy: PayerRiskAdjustmentPolicy;
    Status: boolean;
}

export class PayerRiskAdjustmentPolicy {
    PolicyID: number;
    PayerID: number;
    ServiceYearStart: string;
    ServiceYearEnd: string;
    HasRiskAdjustment: boolean;
    HasManualOmit: boolean;
    PreferredActionSubmissionType: FilterSelectItem
    LastModifiedDate: string;
    Note: string;
    SuppFeedTemplateID?: number;
    SuppFeedTemplateName?: string;
    PayerValidationTemplateID?: number;
    PayerValidationTemplateName?: string;
}

export class PayerRiskAdjustmentPolicyUpdate {
    PolicyID?: number;
    PayerID: number;
    ServiceYearStart: string;
    ServiceYearEnd: string;
    HasRiskAdjustment?: boolean;
    HasManualOmit?: boolean;
    PreferredActionSubmissionTypeID?: string;
    PreferredActionName?: string;
    Note?: string;
    SuppFeedTemplateID?: number;
    PayerValidationTemplateID?: number;
}
