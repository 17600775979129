<div class="timeline-container flex flex-auto flex-col">
  <div class="flex flex-auto flex-row">
    @if (serviceYear < 0) {
      <label class="current-date-label flex flex-auto justify-center items-center content-center">
        Today - {{currentDate | date: 'shortDate'}}
      </label>
    }
  </div>

  <div class="flex flex-auto flex-row">
    <div
      [matTooltip]="simpleDisplay ? getTooltip(node1) : ''"
      [matTooltipClass]="'multi-line-tooltip'">

      <button mat-mini-fab color="primary"
        [disabled]="!node1.DeadlineForSubmission">
        @if (node1.DeadlineForSubmission) {
          <mat-icon>done</mat-icon>
        }
        @if (!node1.DeadlineForSubmission) {
          <mat-icon>schedule</mat-icon>
        }
      </button>

    </div>

    <mat-progress-bar [value]="bar1Value"></mat-progress-bar>

    <div
      [matTooltip]="simpleDisplay ? getTooltip(node2) : ''"
      [matTooltipClass]="'multi-line-tooltip'">

      <button mat-mini-fab color="primary" [disabled]="bar1Value < 100">
        @if (bar1Value == 100) {
          <mat-icon>done</mat-icon>
        }
        @if (bar1Value == 0) {
          <mat-icon>schedule</mat-icon>
        }
        @if (bar1Value > 0 && bar1Value < 100) {
          <mat-icon>timelapse</mat-icon>
        }
      </button>

    </div>

    <mat-progress-bar [value]="bar2Value"></mat-progress-bar>

    <div
      [matTooltip]="simpleDisplay ? getTooltip(node3) : ''"
      [matTooltipClass]="'multi-line-tooltip'">

      <button mat-mini-fab color="primary" [disabled]="bar2Value < 100">
        @if (bar2Value == 100) {
          <mat-icon>done</mat-icon>
        }
        @if (bar2Value == 0) {
          <mat-icon>schedule</mat-icon>
        }
        @if (bar2Value > 0 && bar2Value < 100) {
          <mat-icon>timelapse</mat-icon>
        }
      </button>

    </div>

    <mat-progress-bar [value]="bar3Value"></mat-progress-bar>

    <div
      [matTooltip]="simpleDisplay ? getTooltip(node4) : ''"
      [matTooltipClass]="'multi-line-tooltip'">

      <button mat-mini-fab color="primary" [disabled]="bar3Value < 100">
        @if (bar3Value == 100) {
          <mat-icon>done</mat-icon>
        }
        @if (bar3Value == 0) {
          <mat-icon>schedule</mat-icon>
        }
        @if (bar3Value > 0 && bar3Value < 100) {
          <mat-icon>timelapse</mat-icon>
        }
      </button>

    </div>
  </div>

  <div class="flex flex-auto flex-row">
    @if (node1.DeadlineForSubmission) {
      <div class="flex basis-[17%] flex-col justify-center">
        <span><h3>{{node1.DeadlineForSubmission}}</h3></span>
        @if (!simpleDisplay) {
          <span>{{node1.RiskScoreRunYear}} {{node1.RiskScoreRunTypeName}}</span>
        }
        @if (!simpleDisplay) {
          <span class="dos-range-label">{{node1.DatesOfServiceStart}} - {{node1.DatesOfServiceEnd}}</span>
        }
      </div>
    }
    @if (!node1.DeadlineForSubmission) {
      <div class="flex basis-[17%] flex-col justify-center">
        <span><h3>No Data</h3></span>
      </div>
    }

    @if (node2.DeadlineForSubmission) {
      <div class="flex flex-auto flex-col justify-center text-center">
        <span><h3>{{node2.DeadlineForSubmission}}</h3></span>
        @if (!simpleDisplay) {
          <span>{{node2.RiskScoreRunYear}} {{node2.RiskScoreRunTypeName}}</span>
        }
        @if (!simpleDisplay) {
          <span class="dos-range-label">{{node2.DatesOfServiceStart}} - {{node2.DatesOfServiceEnd}}</span>
        }
      </div>
    }
    @if (!node2.DeadlineForSubmission) {
      <div class="flex flex-auto flex-col justify-center text-center">
        <span><h3>No Data</h3></span>
      </div>
    }

    @if (node3.DeadlineForSubmission) {
      <div class="flex flex-auto flex-col justify-center text-center">
        <span><h3>{{node3.DeadlineForSubmission}}</h3></span>
        @if (!simpleDisplay) {
          <span>{{node3.RiskScoreRunYear}} {{node3.RiskScoreRunTypeName}}</span>
        }
        @if (!simpleDisplay) {
          <span class="dos-range-label">{{node3.DatesOfServiceStart}} - {{node3.DatesOfServiceEnd}}</span>
        }
      </div>
    }
    @if (!node3.DeadlineForSubmission) {
      <div class="flex flex-auto flex-col justify-center text-center">
        <span><h3>No Data</h3></span>
      </div>
    }

    @if (node4.DeadlineForSubmission) {
      <div class="flex basis-[17%] flex-col justify-center text-right">
        <span><h3>{{node4.DeadlineForSubmission}}</h3></span>
        @if (!simpleDisplay) {
          <span>{{node4.RiskScoreRunYear}} {{node4.RiskScoreRunTypeName}}</span>
        }
        @if (!simpleDisplay) {
          <span class="dos-range-label">{{node4.DatesOfServiceStart}} - {{node4.DatesOfServiceEnd}}</span>
        }
      </div>
    }
    @if (!node4.DeadlineForSubmission) {
      <div class="flex basis-[17%] flex-col justify-center text-right">
        <span><h3>No Data</h3></span>
      </div>
    }
  </div>
</div>
