import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { FilterSelectItem } from '../../../models/filter-select-item';
import { FieldFilterService } from '../../../services/filters/field-filter.service';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-status-state-select',
    templateUrl: './status-state-select.component.html',
    styleUrls: ['./status-state-select.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatTooltip, MatSelectTrigger, MatOption, MatError]
})
export class StatusStateSelectComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() multiple = true;
  @Input() controlName = 'status-state';
  @Input() toolTipPosition = 'above';
  @Input() selectAllEnabled = true;
  @Input() required = false;

  options: FilterSelectItem[] = [];
  allOption: FilterSelectItem = {ID: 0, Description: 'All', LinkAssociations: [], Selected: false};
  control = new FormControl<any[]>(null);

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;

  constructor(private filterService: FieldFilterService) { 
    //TODO
  }

  ngOnInit(): void {

    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }

    this.getOptionData();
  }

  getOptionData() {
    this.filterService.getStatusStateOptions().subscribe(result => {
      const items = result?.sort((a, b) => {
        return a.Description.toLowerCase().localeCompare(b.Description.toLowerCase());
      });

      this.options = items;

      this.ready.emit(this.options);
    });
  }

  setValues(values: FilterSelectItem[]) {
    if(!values) {
      return;
    }

    if(values.length == 0) {
      this.control.setValue([this.allOption]);
      this.onToggleItem(this.select.options.get(0));
      return;
    }

    this.control.setValue(values);
  }

  getValues() {
    if(this.allOption.Selected) {
      return [this.allOption];
    }

    return this.control?.value;
  }

  onToggleItem(item: MatOption) {
    if(item.value == this.allOption) {
      if(item.selected) {
        this.allOption.Selected = true;
        this.select.options.forEach(o => o.select());
        return;
      }

      this.allOption.Selected = false;
      this.select.options.forEach(o => o.deselect());

    } else {
      
      const allItem = this.select.options.get(0);

      if(!item.selected && allItem.selected) {
        this.allOption.Selected = false;
        allItem.deselect();
        return;
      }

      if(item.selected && !allItem.selected) {
        const check = this.checkOptionAllStatus();
        if(check) {
          this.allOption.Selected = true;
          allItem.select();
        }
      }
    }
  }

  onSelectionChange() {
    const options = this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;
    this.valueChanged.emit(options);
  }

  checkOptionAllStatus() {
    let result = true;
    this.select.options.forEach(o => {
      if(o.value != this.allOption && !o.selected) {
        result = false;
      }
    });

    return result;
  }

  getTooltipString() {
    let result = '';
    const options = this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;

    options.forEach((o, index) => {
      result += o.Description;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getSelectCount() {
    return (this.control.value?.length <= this.options.length) 
      ? this.control.value?.length 
      : this.control.value?.length - 1;
  }
}
