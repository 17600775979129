import { patchState, signalStore, withMethods, withState } from "@ngrx/signals";

type AppState = {
    filterForTargetedHCC: boolean;
}

const initialState: AppState = {
    filterForTargetedHCC: true,
}

export const AppStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((store) => ({
        setFilterForTargetedHCC(value: boolean): void {
            patchState(store, { filterForTargetedHCC: value });
        }
    })),
);