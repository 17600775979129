import { Component, inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MorFileDetailsResult } from '../../models/mor-file-details-result';
import { ComponentType } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import { MorDataStore } from '../../store/mor-data.store';
import { Subscription } from 'rxjs';
import { ViewProcessedRecordsDialogComponent, ViewProcessedRecordsDialogModel } from './view-processed-records-dialog/view-processed-records-dialog.component';
import { MatIcon } from '@angular/material/icon';

export class MorDataViewDetailsDialogModel {
  readonly component: ComponentType<MorDataViewDetailsDialogModel> = MorDataViewDetailsDialogModel;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  width?: '40dvw';
  maxHeight?: '80dvw';
  data;
  disableClose? = true;

  constructor(data) {
    this.data = data;
  }
}

export class MorDataViewDetailsHelperModel {
  recordType: string;
  uploadDateTime: string | null;
  processedCount: number = 0;
  errorsCount: number = 0;
  totalCount: number = 0;

  constructor(details: MorFileDetailsResult, datePipe: DatePipe) {
    if (details.recordTypeCounts.length == 0) {
      this.recordType = '';
    } else if (details.recordTypeCounts.length > 1) {
      this.recordType = 'Multiple';
    } else {
      this.recordType = details.recordTypeCounts[0].RecordType;
    }

    this.uploadDateTime = datePipe.transform(details.uploadDate, "MM/dd/yyyy hh:mm a");
    
    for (const rtc of details.recordTypeCounts) {
      this.processedCount += rtc.Count.Processed;
      this.errorsCount += rtc.Count.Errors;
      this.totalCount += rtc.Count.Processed + rtc.Count.Errors;
    }
  }
}

@Component({
  selector: 'app-mor-data-view-details-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatIcon
  ],
  providers: [DatePipe],
  templateUrl: './mor-data-view-details-dialog.component.html',
  styleUrl: './mor-data-view-details-dialog.component.scss'
})
export class MorDataViewDetailsDialogComponent implements OnDestroy {
  readonly data = inject(MAT_DIALOG_DATA);
  readonly datePipe = inject(DatePipe);
  readonly store = inject(MorDataStore);
  private dialog = inject(MatDialog);
  private subs: Subscription[] = [];

  model: MorDataViewDetailsHelperModel;

  constructor(
    public dialogRef: MatDialogRef<MorDataViewDetailsDialogComponent>
  ) {
    this.model = new MorDataViewDetailsHelperModel(this.data, this.datePipe);
  }
  handleClose() {
    this.dialogRef.close();
  }

  onClickProcessed() {
    const model = {
      uploadDate: this.model.uploadDateTime,
      uploadedBy: this.data.uploadedBy,
      fileType: this.model.recordType,
      payerAlias: this.data.payerAlias,
      id: this.data.id
    }
    const dialogRef = this.dialog.open(ViewProcessedRecordsDialogComponent, new ViewProcessedRecordsDialogModel(model));
    
    this.subs.push(dialogRef.afterClosed().subscribe());
  }

  onOpenDialog(viewErrors: boolean) {
    const model = {
      uploadDate: this.model.uploadDateTime,
      uploadedBy: this.data.uploadedBy,
      fileType: this.model.recordType,
      payerAlias: this.data.payerAlias,
      id: this.data.id,
      listSize: viewErrors ? this.model.errorsCount : this.model.processedCount,
      viewErrors: viewErrors
    }
    const dialogRef = this.dialog.open(ViewProcessedRecordsDialogComponent, new ViewProcessedRecordsDialogModel(model));
    
    this.subs.push(dialogRef.afterClosed().subscribe());
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
