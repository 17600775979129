import { Component, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-enterprise';

@Component({
    selector: 'app-ag-no-rows-overlay',
    templateUrl: './ag-no-rows-overlay.component.html',
    styleUrls: ['./ag-no-rows-overlay.component.scss'],
    standalone: true
})
export class AgNoRowsOverlayComponent implements INoRowsOverlayAngularComp {

  public params!: INoRowsOverlayParams & { noRowsMessageFunc: () => string };

  agInit(
    params: INoRowsOverlayParams & { noRowsMessageFunc: () => string }
  ): void {
    this.params = params;
  }

}
