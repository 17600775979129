<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container vc-theme">

  <div class="topbar">
    @if (auth.isAuthenticated()) {
      <mat-toolbar style="background-color:#fff">
        <div class="navbar-header">
          <div id="logo" class="navbar-brand" href="https://dev.valuecycle.app">
            <img src="/assets/images/ValueCycleLogo.svg"/>
          </div>
        </div>
        <span class="grow"></span>
        <span class="org-name">{{ this.auth.orgName }}</span>
        <app-header></app-header>
      </mat-toolbar>
    }

    @if (auth.isAuthenticated()) {
      <nav mat-tab-nav-bar class="nav-bar-container mat-elevation-z3" [tabPanel]="navTabPanel" backgroundColor="primary" fitInkBarToContent="true">
        <div class="nav-links flex basis-3/4 justify-start align-middle">
          @for (link of navLinks; track link) {
            <div
              class="link-container"
              [matTooltip]="link.disabled ? link.disabledTooltip : ''"
              [matTooltipClass]="'app-tooltip'">
              <a mat-tab-link
                [routerLink]="link.link"
                [disabled]="link.disabled"
              [active]="activeLink.includes(link.link)"> {{link.label}} </a>
            </div>
          }
        </div>
        <div class="flex basis-1/4 justify-end">
          @if (activeLink.includes('/opportunity-list') || activeLink.includes('/diagnosis-event')) {
            <div class="flex items-center pb-1">
              <mat-slide-toggle 
                class="whitespace-nowrap" 
                id="targeted-hcc-toggle" 
                labelPosition="before"
                [checked]="appStore.filterForTargetedHCC()"
                (change)="appStore.setFilterForTargetedHCC($event.checked)"><h3 class="pr-1">Targeted Events</h3></mat-slide-toggle>
            </div>
          }
          
          @if (!activeLink.includes('/reporting') && !router.url.includes('/configuration')) {
            <div class="whitespace-nowrap service-icon flex px-[28px] items-center">
              @if (cmsSubmissionWindow) {
                <mat-icon
                  [ngClass]="
                  {
                    'cms-icon-base' : cmsSubmissionWindow > 30,
                    'cms-icon-approaching' : 0 <= cmsSubmissionWindow && cmsSubmissionWindow <= 30,
                    'cms-icon-past-due' : cmsSubmissionWindow < 0
                  }"
                  [matTooltip]="cmsSubmissionWindow + ' days until final submission deadline'"
                  [matTooltipClass]="'app-tooltip'"
                >schedule</mat-icon>
              }
              <app-service-year-icon [selectedYear]="selectedYear"></app-service-year-icon>
            </div>
          }
        </div>
      </nav>
    }

    <mat-tab-nav-panel #navTabPanel></mat-tab-nav-panel>
  </div>

  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{ minisidebar: minisidebar }">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
      [opened]="false" [disableClose]="mobileQuery.matches">
      <app-sidebar></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content [ngClass]="auth.isAuthenticated() ? 'page-wrapper' : 'login-page-wrapper'">
      <div class="page-content">
        <router-outlet>
        </router-outlet>
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>

</div>