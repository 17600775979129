import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgCartesianChartOptions, AgChartInstance, AgCharts } from 'ag-charts-community';
import { Subscription } from 'rxjs';
import { HccSummaryCalculations } from '../../../../../models/hcc-summary-calculations';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AuditAction } from '../../../../../models/audit-action';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatCard, MatCardHeader, MatCardContent } from '@angular/material/card';
import { MatTooltip } from '@angular/material/tooltip';
import { AgChartsAngularModule } from 'ag-charts-angular';

@Component({
    selector: 'app-payer-validation-trend-graphs',
    templateUrl: './payer-validation-trend-graphs.component.html',
    styleUrls: ['./payer-validation-trend-graphs.component.scss'],
    standalone: true,
    imports: [MatSlideToggle, FormsModule, MatCard, MatCardHeader, MatTooltip, MatCardContent, AgChartsAngularModule]
})
export class PayerValidationTrendGraphsComponent implements OnInit, OnDestroy {

  data: HccSummaryCalculations[] = [];
  nonActionableData = [];

  currentRefreshDate = '';

  graphOptions: AgCartesianChartOptions;

  parentDiv = document.getElementById('snav');
  chartsColor1 = '';
  accentColor = '';

  dxSwitch = false;
  percentSwitch = false;

  graphTitle = {text: 'Payer Validation: HCCs', fontSize: 12, enabled: true};

  subs: Subscription[] = [];

  constructor(
    private caclulationsService: CalculationsService,
    private loggingService: LoggingService) {
    this.chartsColor1 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-1');
    this.accentColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-accent');
    this.setChartOptions();
  }

  ngOnInit(): void {
    this.subs.push(this.caclulationsService.hccSummaryCalculationsRange.subscribe(result => {
      if(!result) {
        return;
      }

      this.data = result;
      this.currentRefreshDate = this.data && this.data.length > 0 ? this.data[0].DateUpdated : '';
      this.setChartOptions();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  setChartOptions() {
    const prefix = this.dxSwitch ? 'Dx' : 'HCC';
    const suffix = this.percentSwitch ? 'Percent' : '';
    const dateRefreshed = `Data Refreshed ${this.currentRefreshDate ? formatDate(this.currentRefreshDate, 'MM/dd/YYYY', 'en') : 'N/A'}`;

    const title = this.dxSwitch ? 'Payer Validation: Diagnosis Events' : 'Payer Validation: HCCs';
    this.graphTitle.text = title;

    this.graphOptions = {
      autoSize: true,
      title: this.graphTitle,
      data: this.data,
      series: [
        {
          type: 'bar',
          fill: this.chartsColor1,
          stroke: this.chartsColor1,
          xKey: 'ServiceYear',
          yKey: `${prefix}PayerValidation${suffix}`,
          yName: 'Payer Validation',
          tooltip: {
            renderer: AgGridFunctions.graphTooltipFn
          },
          label: {
            fontWeight: 'bold',
            color: 'black',
            formatter: (params) => this.valueFormatFn(params, suffix == 'Percent'),
            placement: 'outside'
          },
        }
      ],
      theme: {
        overrides: {
          bar: {
            series: {
              highlightStyle: {
                item: {
                  fill: this.accentColor,
                  stroke: this.chartsColor1,
                  strokeWidth: 3
                }
              }
            },
            axes: {
              category: {
                groupPaddingInner: 0.2
              }
            }
          }
        }
      },
      axes: [
        {
          type: 'category',
          position: 'bottom',
          title: {
            text: ''
          }
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: ''
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter(params.value).replace('0y', '0')
          },
          min: 0
        }
      ],
      legend: {
        position: 'bottom'
      },
      footnote: {
        text: dateRefreshed,
        fontStyle: 'italic'
      },
    };
  }

  onDownload(instance: AgChartInstance) {
    const startYear = this.data.length > 0 ? this.data[0].ServiceYear : '_';
    const endYear = this.data.length > 1 ? this.data[this.data.length - 1].ServiceYear : '_';
    const instanceName = instance.getOptions().title.text;
    const filename = `${startYear}-${endYear}_${instanceName}`;

    AgCharts.download(instance, {
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: 'Payer Validation Trend Graphs', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: Payer Validation Trend Graphs, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });

  }

  valueFormatFn(params: any, isPercent: boolean) {
    const formatter = Intl.NumberFormat('en', {minimumSignificantDigits: 1});

    return `${formatter.format(params.datum[params.yKey])}${isPercent ? '%' : ''}`;
  }
}
