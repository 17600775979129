import { Subscriber } from '../Subscriber';
import { Subject } from '../Subject';
export function windowCount(windowSize, startWindowEvery = 0) {
  return function windowCountOperatorFunction(source) {
    return source.lift(new WindowCountOperator(windowSize, startWindowEvery));
  };
}
class WindowCountOperator {
  constructor(windowSize, startWindowEvery) {
    this.windowSize = windowSize;
    this.startWindowEvery = startWindowEvery;
  }
  call(subscriber, source) {
    return source.subscribe(new WindowCountSubscriber(subscriber, this.windowSize, this.startWindowEvery));
  }
}
class WindowCountSubscriber extends Subscriber {
  constructor(destination, windowSize, startWindowEvery) {
    super(destination);
    this.destination = destination;
    this.windowSize = windowSize;
    this.startWindowEvery = startWindowEvery;
    this.windows = [new Subject()];
    this.count = 0;
    destination.next(this.windows[0]);
  }
  _next(value) {
    const startWindowEvery = this.startWindowEvery > 0 ? this.startWindowEvery : this.windowSize;
    const destination = this.destination;
    const windowSize = this.windowSize;
    const windows = this.windows;
    const len = windows.length;
    for (let i = 0; i < len && !this.closed; i++) {
      windows[i].next(value);
    }
    const c = this.count - windowSize + 1;
    if (c >= 0 && c % startWindowEvery === 0 && !this.closed) {
      windows.shift().complete();
    }
    if (++this.count % startWindowEvery === 0 && !this.closed) {
      const window = new Subject();
      windows.push(window);
      destination.next(window);
    }
  }
  _error(err) {
    const windows = this.windows;
    if (windows) {
      while (windows.length > 0 && !this.closed) {
        windows.shift().error(err);
      }
    }
    this.destination.error(err);
  }
  _complete() {
    const windows = this.windows;
    if (windows) {
      while (windows.length > 0 && !this.closed) {
        windows.shift().complete();
      }
    }
    this.destination.complete();
  }
  _unsubscribe() {
    this.count = 0;
    this.windows = null;
  }
}
