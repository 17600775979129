import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, ColGroupDef, RowGroupingDisplayType } from 'ag-grid-enterprise';
import { Subscription } from 'rxjs';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { AuditAction } from '../../../../../models/audit-action';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { ProgressAllocationService } from '../../../../../services/progress/progress-allocation.service';
import { CreateRangeChartParams, FirstDataRenderedEvent } from 'ag-grid-community';
import { ProgressAllocationSnapshotRequest } from '../../../../../models/progress-allocation-snapshot-request';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { NgStyle } from '@angular/common';

export interface ProgressAllocationSnapshotReportOptions {
  mode?: "user" | "preferred-action" | "payer";
}

@Component({
    selector: 'app-progress-allocation-snapshot-report',
    templateUrl: './progress-allocation-snapshot-report.component.html',
    styleUrls: ['./progress-allocation-snapshot-report.component.scss'],
    standalone: true,
    imports: [NgStyle, AgGridModule]
})
export class ProgressAllocationSnapshotReportComponent implements OnInit, OnDestroy {

  @Input() options = {mode: "user"};

  data: any[] = [];
  currentRequest: ProgressAllocationSnapshotRequest = new ProgressAllocationSnapshotRequest();

  exportTitle = '';

  columnDefs: (ColDef | ColGroupDef)[] = [];

  defaultColDef: ColDef = {
    menuTabs: [],
    minWidth: 100,
    maxWidth: 100,
    autoHeight: true
  };

  groupDisplayType: RowGroupingDisplayType = 'groupRows';

  autoGroupColumnDef: ColDef = {
    minWidth: 200,
    cellClass: 'indent-1'
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  excelStyles = DxsrExcelStyling.excelStyles2;

  gridContext: any;

  rowItems: any[] = [];
  gridReady = false;

  dxSwitch = false;

  currentStartYear = 0;
  currentEndYear = 0;

  currentRowHeight = 140;
  currentGridWidth = 282;

  subs: Subscription[] = [];

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('grid') agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
  }

  constructor(
    private allocationService: ProgressAllocationService,
    private loggingService: LoggingService) {
    this.gridContext = { componentParent: this };
  }

  ngOnInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getUserColDef(): (ColDef | ColGroupDef)[] {

    const result: any = [
      {
        headerName: `Service Year ${this.currentRequest.ServiceYear}`,
        children: [
          { field: 'User', headerName: 'User', rowGroup: true, hide: true, cellDataType: false,
            keyCreator: params => params.value.FirstName + " " + params.value.LastName, 
            tooltipValueGetter: AgGridFunctions.assingedUserTooltip
          },
          { field: 'SnapshotDate', headerName: 'Snapshot Date', valueFormatter: AgGridFunctions.dateTimeToDateValue, cellClass: 'center-cell', maxWidth: 2000},
          { field: 'Progress', headerName: 'Progress', type: 'numericColumn', valueFormatter: (params) => AgGridFunctions.getPercentValue(params, 1), cellClass: 'percent', maxWidth: 150},
          { field: 'Closed', headerName: 'Closed', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
          { field: 'Open', headerName: 'Open', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
          { field: 'Assigned', headerName: 'Assigned', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
        ]
      }
    ];

    return result;
  }

  getPreferredActionColDef(): (ColDef | ColGroupDef)[] {

    const result: any = [
      {
        headerName: `Service Year ${this.currentRequest.ServiceYear}`,
        children: [
          { field: 'Name', headerName: 'Action', rowGroup: true, hide: true, minWidth: 250},
          { field: 'SnapshotDate', headerName: 'Snapshot Date', valueFormatter: AgGridFunctions.dateTimeToDateValue, cellClass: 'center-cell', maxWidth: 2000},
          { field: 'Progress', headerName: 'Progress', type: 'numericColumn', valueFormatter: (params) => AgGridFunctions.getPercentValue(params, 1), cellClass: 'percent', maxWidth: 150},
          { field: 'Closed', headerName: 'Closed', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
          { field: 'Open', headerName: 'Open', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
          { field: 'Total', headerName: 'Total', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150}
        ]
      }
    ];

    return result;
  }

  getPayerColDef(): (ColDef | ColGroupDef)[] {

    const result: any = [
      {
        headerName: `Service Year ${this.currentRequest.ServiceYear}`,
        children: [
          { field: 'Payer', headerName: 'Payer', rowGroup: true, hide: true, 
            keyCreator: params => params.value.Abbreviation,
            tooltipValueGetter: AgGridFunctions.payerTooltip
          },
          { field: 'SnapshotDate', headerName: 'Snapshot Date', valueFormatter: AgGridFunctions.dateTimeToDateValue, cellClass: 'center-cell', maxWidth: 2000},
          { field: 'Progress', headerName: 'Progress', type: 'numericColumn', valueFormatter: (params) => AgGridFunctions.getPercentValue(params, 1), cellClass: 'percent', maxWidth: 150},
          { field: 'Closed', headerName: 'Closed', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
          { field: 'Open', headerName: 'Open', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
          { field: 'Total', headerName: 'Total', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
        ]
      }
    ];

    return result;
  }

  connectUser() {
    this.subs.push(this.allocationService.userAllocation.subscribe(result => {
      const items = result || [];
      const currentListSize = items.length;
      const height = currentListSize == 0 ? 300 : 107 + (currentListSize * 28);
      const width = 752;

      this.agGrid.api.setGridOption('columnDefs', this.getUserColDef());

      this.updateGrid(items, width, height);

      this.setExportEnable.emit(true);
    }));
  }

  connectPreferredActions() {
    this.subs.push(this.allocationService.preferredActions.subscribe(result => {
      const items = result || [];
      const currentListSize = items.length;
      const height = currentListSize == 0 ? 300 : 107 + ((currentListSize + 3) * 28);
      const width = 752;

      this.agGrid.api.setGridOption('columnDefs', this.getPreferredActionColDef());

      this.updateGrid(items, width, height);

      this.setExportEnable.emit(true);
    }));
  }

  connectPayer() {
    this.subs.push(this.allocationService.payerAllocation.subscribe(result => {
      const items = result || [];
      const currentListSize = items.length;
      const height = currentListSize == 0 ? 300 : 107 + (currentListSize * 28);
      const width = 752;

      this.agGrid.api.setGridOption('columnDefs', this.getPayerColDef());

      this.updateGrid(items, width, height);

      this.setExportEnable.emit(true);
    }));
  }

  onGridReady(event) {
    this.ready.emit(true);

    switch(this.options.mode) {
      case 'user':
        this.connectUser();
        this.exportTitle = 'User Trend'
        break;

      case 'preferred-action':
        this.connectPreferredActions();
        this.exportTitle = 'Preferred Action Trend';
        break;

      case 'payer':
        this.connectPayer();
        this.exportTitle = 'Payer Trend';
        break;

      default:
        break;
    }
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    const createRangeChartParams: CreateRangeChartParams = {
      cellRange: {
        columns: ['Name', 'SnapshotDate', 'Open', 'Closed', 'Total'],
      },
      suppressChartRanges: true,
      chartType: 'line',
      chartContainer: document.querySelector('#chart1') as any,
    };
    params.api.createRangeChart(createRangeChartParams);
  }

  onGenerateGridData(request: ProgressAllocationSnapshotRequest) {
    
    this.currentRequest = request;

    switch(this.options.mode) {
      case 'user':
        this.allocationService.getUsersSnapshotItems(request);
        break;

      case 'preferred-action':
        this.allocationService.getPreferredActionSnapshotItems(request);
        break;

      case 'payer':
        this.allocationService.getPayerSnapshotItems(request);
        break;

      default:
        break;
    }

  }

  updateGrid(data: any[], width: number, height: number) {
    this.data = data;
    this.currentRowHeight = height;
    this.currentGridWidth = width;
    this.agGrid.api.setGridOption('rowData', data);

    setTimeout(() => {this.agGrid.api.sizeColumnsToFit();}, 200);
  }

  onExport() {
    const filename =`${this.exportTitle.replace(' ', '')}_${this.currentRequest.StartDate}-${this.currentRequest.EndDate}`;

    this.agGrid.api.exportDataAsExcel({
      sheetName: this.exportTitle,
      fileName: filename,
      processCellCallback(params) {
        if(params.column.getColId() == 'Progress' && params.value < 0) {
          return '';
        }

        return params.value;
      },
    });

    const reportJSON = JSON.stringify({ReportName: this.exportTitle, Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: ${this.exportTitle}, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }

}
