import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { AgNoRowsOverlayComponent } from '../../components/ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgIconRendererComponent } from '../../components/ag-grid-components/ag-icon-renderer/ag-icon-renderer.component';
import { ColDef, GetRowIdFunc, GetRowIdParams } from 'ag-grid-enterprise';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { ColGroupDef, RowClassRules, RowDragEndEvent, RowEditingStoppedEvent } from 'ag-grid-community';
import { TemplateService } from '../../services/settings/template.service';
import { TemplateConfigFieldItem } from '../../models/template-config-field-item';
import { FieldFilterService } from '../../services/filters/field-filter.service';
import { Subscription } from 'rxjs';
import { TemplateConfigItem } from '../../models/template-config-item';
import { FilterSelectComponent } from '../../components/field-components/filter-select/filter-select.component';
import { BooleanSelectComponent } from '../../components/field-components/static/boolean-select/boolean-select.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgHeaderInfoIconRendererComponent } from '../../components/ag-grid-components/ag-header-info-icon-renderer/ag-header-info-icon-renderer.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { AgLoadingOverlayComponent } from '../../components/ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDivider } from '@angular/material/divider';
import { MatButton } from '@angular/material/button';
import { TemplateConfigStore } from '../../store/template-config.store';
import { FilterSelectItem } from '../../models/filter-select-item';

export class AddUpdateTemplateConfigDialogModel {
  readonly component: ComponentType<any> = AddUpdateTemplateConfigDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  data?: any;
  disableClose? = true;
  maxWidth? = '95dvw';

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
    selector: 'app-add-update-template-config-dialog',
    templateUrl: './add-update-template-config-dialog.component.html',
    styleUrls: ['./add-update-template-config-dialog.component.scss'],
    standalone: true,
    imports: [
      MatDialogTitle, 
      CdkScrollable, 
      MatDialogContent, 
      MatFormField, 
      MatInput, 
      FormsModule, 
      ReactiveFormsModule, 
      MatError, 
      BooleanSelectComponent, 
      FilterSelectComponent, 
      MatDivider, 
      AgGridModule, 
      MatDialogActions, 
      MatButton
    ],
    providers: [TemplateConfigStore]
})
export class AddUpdateTemplateConfigDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => {
      return 'No Entries Found'
    },
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  frameworkComps = {
    iconRenderer: AgIconRendererComponent,
    headerInfoRenderer: AgHeaderInfoIconRendererComponent
  }


  selectedGridColDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Selected Fields',
      children: [
        { rowDrag: true, maxWidth: 50, 
          tooltipValueGetter(params) {
            return 'Move'
          }, 
        },
        { 
          cellRenderer: 'iconRenderer',
          cellRendererParams: {
            icon: 'edit'
          },
          tooltipValueGetter(params) {
            return 'Edit'
          }, 
          onCellClicked: this.onEditRow,
          maxWidth: 50
        },
        { field: 'SortID', headerName: 'Order', maxWidth: 75},
        { field: 'Label', headerName: 'Label', editable: true},
        { field: 'Field', headerName: 'Field'},
        { field: 'DefaultValue', headerName: 'Prefill', 
          editable: (params) => {
            return params.data.FieldID == 1
          },
          headerComponent: 'headerInfoRenderer',
          headerComponentParams: {
            iconTooltip: `If field is of type "<Blank>", enter the text that should auto populate.`
          }
        },
        { 
          cellRenderer: 'iconRenderer',
          cellRendererParams: {
            icon: 'delete'
          },
          tooltipValueGetter(params) {
            return 'Delete'
          }, 
          onCellClicked: this.onRemoveRow,
          maxWidth: 50
        }
      ]
    },
  ];

  availableGridColDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Available Fields',
      children: [
        { rowDrag: true, maxWidth: 50, 
          tooltipValueGetter(params) {
            return 'Move'
          }, 
        },
        { field: 'Field', headerName: 'Field' },
        { field: 'Category', headerName: 'Category', maxWidth: 145 },
      ]
    }
  ];

  defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  selectedGridDefaultColDef: ColDef = {
    editable: false,
    sortable: false,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  selectedGridOptions = {
    onRowDragEnd: this.onRowDragEnd.bind(this),
    onGridReady: this.onSelectedGridReady.bind(this),
    onRowEditingStopped: this.onRowEditingStopped.bind(this)
  };

  public rowClassRules: RowClassRules = {
    // row style function
    'danger-cell-2': (params) => {
      let invalid = !params.data.Label;

      //Grid check for create button disable
      params.api.forEachNode(n => {
        if(!n.data.Label) {
          params.context.componentParent.invalidSelectedRows = true;
        }

        /// IF UNIQUE LABEL ///
        if(n.rowIndex != params.rowIndex && n.data.FieldID == params.data.FieldID && n.data.Label == params.data.Label) {
          params.context.componentParent.invalidSelectedRows = true;
          invalid = true;
        }
      });

      params.context.componentParent.checkGridValidation();

      return invalid;
    }
  };

  gridContext: any;

  actionType = '';
  templateID = 0;
  types: FilterSelectItem[] = [];

  invalidSelectedRows = false;
  currentSelectedCount = 0;

  form: FormGroup;
  nameControl = new FormControl('', [Validators.required]);
  notesControl = new FormControl('');

  nameControlName = 'name';
  notesControlName = 'notes';
  activeControlName = 'active';

  availableItems: TemplateConfigFieldItem[] = [];

  invalidTemplateNames: string[] = [];

  subs: Subscription[] = [];
  
  readonly store = inject(TemplateConfigStore);
  readonly data = inject(MAT_DIALOG_DATA);
  selectedItems: TemplateConfigFieldItem[];

  @ViewChild('selectedFields') selectedGrid!: AgGridAngular;
  @ViewChild('availableFields') availableGrid!: AgGridAngular;
  @ViewChild('type') typeSelect: FilterSelectComponent;
  @ViewChild('activeSelect') activeSelect: BooleanSelectComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.selectedGrid?.api.sizeColumnsToFit();
    this.availableGrid?.api.sizeColumnsToFit();
  }

  constructor(
    public dialogRef: MatDialogRef<AddUpdateTemplateConfigDialogComponent>,
    private dialog: MatDialog,
    private templateService: TemplateService,
    private filterService: FieldFilterService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar
  ) {
    this.gridContext = { componentParent: this };
    this.form = this.fb.group({});
    this.actionType = this.data?.actionType;
    this.templateID = this.data?.item?.ID || 0;
  }

  ngOnInit(): void {
    this.form.addControl(this.nameControlName, this.nameControl);
    this.form.addControl(this.notesControlName, this.notesControl);
    
    this.subs.push(this.filterService.templateConfigTypes.subscribe(result => {
      this.types = result;
    }));

    this.subs.push(this.nameControl.valueChanges.subscribe(input => {
      if(!input) {
        this.nameControl.setErrors({required: true});
        return;
      }

      const invalidName = this.invalidTemplateNames.some(name => input?.toLowerCase().includes(name.toLowerCase()));
      if(invalidName) {
        this.nameControl.setErrors({invalidName: true});
        return;
      }
      const updatedTemplate = this.store.currentTemplate();
      updatedTemplate.Name = input;
      this.store.setCurrentTemplate(updatedTemplate).then(() => {
        this.nameControl.setErrors(null);
      });
    }));
  }

  ngAfterViewInit(): void {
    if(this.actionType == 'edit') {
      this.store.setIsNew(false);
      this.store.setOriginalTemplate(this.data.item as TemplateConfigItem);
      const value = this.typeSelect?.options.find(i => i.ID == this.data.item.TypeID);
      if (value) {
        this.typeSelect.setSingleModeValue(value);
      }

      this.nameControl.setValue(this.data.item.Name);
      this.notesControl.setValue(this.data.item.Notes);
      this.activeSelect.setValue(this.data.item.Enabled);
      this.form.markAsTouched();
    } else {
      this.store.setIsNew(true);
      this.store.setOriginalTemplate(new TemplateConfigItem());
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  loadData() {
    this.templateService.getAvailableFields("1").subscribe(result => {
      this.availableItems = result;
      this.availableGrid.api.sizeColumnsToFit();
    });
    this.selectedGrid.api.sizeColumnsToFit();
  }

  onSelectedGridReady(params) {
    this.addGridDropZone();
  }

  onAvailableGridReady(params) {
    this.loadData();
  }

  getAvailableGridRowID: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.FieldID;
  };

  getSelectedGridRowID: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.SortID;
  };

  addGridDropZone() {
    const dropZoneParams = this.selectedGrid.api.getRowDropZoneParams({});

    this.availableGrid.api.addRowDropZone(dropZoneParams);
  }

  updateSelectedSortOrder(params, index, isRemove: boolean = false, isReorder?: boolean) {
    if (isRemove) {
      this.store.addRemoveFieldItems(index);
    } else {
      this.store.addRemoveFieldItems(index, params.node.data, isReorder);
    }
  }

  onEditRow(params) {
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: 'Label',
    });
  }

  onRowEditingStopped(params: RowEditingStoppedEvent) {
    this.store.updateFieldItem(params.data as TemplateConfigFieldItem);
  }

  onRemoveRow(params) {
    params.context.componentParent.updateSelectedSortOrder(params, params.node.rowIndex, true);
  }

  onSaveClick(closeAfter: boolean) {
    const name = this.nameControl.value;
    const notes = this.notesControl.value;
    const active = this.activeSelect.getSelectedValueAsBoolean();
    const toSave = this.store.currentTemplate();
    toSave.Enabled = active ?? false;
    toSave.Name = name ?? "";
    toSave.Notes = notes ?? "";
    this.store.setCurrentTemplate(toSave).then(() => {
      try {
        this.store.saveCurrentTemplate();
        this.snackbar.open("Template Saved", undefined, {duration: 3000});
      } catch (error) {
        if(error == "InvalidName") {
          this.invalidTemplateNames.push(this.nameControl.value);
          this.nameControl.setErrors({invalidName: true, required: false});
          this.nameControl.markAsTouched();
        }
        this.snackbar.open("Failed to Save", undefined, {duration: 3000});
      }
      if (closeAfter) {
        this.dialogRef.close(true);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  openCancelConfirm() {
    const message = 'Clicking Cancel will not save any changes. Do you want to continue to close and not save?';
    const title = 'Warning';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: new ConfirmDialogModel(title, message, '')
    });

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.revertToOriginalTemplate();
        this.dialogRef.close(false);
      }
    }));
  }

  checkGridValidation() {
    let result = false;
    this.selectedGrid?.api.forEachNode(n => {
      if(!n.data.Label) {
        result = true;
      }
    })

    this.invalidSelectedRows = result;
  }

  onTemplateTypeChanged(value: any) {
    const updatedTemplate = this.store.currentTemplate();
    updatedTemplate.TypeID = value.ID;
    this.store.setCurrentTemplate(updatedTemplate).then(() => {
      //todo
    });
  }

  isValid() {
    return !this.form.invalid && !this.invalidSelectedRows && this.store?.fieldItems()?.length > 0
  }

  onRowDragEnd(event: RowDragEndEvent) {
    let index;
    if (event.overIndex == -1) {
      index = this.store.fieldItems() && this.store.fieldItems().length > 0 ? this.store.fieldItems().length : 0;
    } else if (event.overIndex != null && event.overIndex != undefined) {
      index = event.overIndex;
    } else {
      index = this.store.fieldItems().findIndex(item => item.ID == event.node.data.ID);
    }
    console.log(index);
    const itemInState = this.store.fieldItems()?.find((item) => item?.ID == event.node?.data?.ID);
    this.updateSelectedSortOrder(event, index, false, itemInState != null && itemInState != undefined);
  }

}
