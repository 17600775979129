import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { SettingsKeys, SettingsService } from '../../../services/settings/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { AgPaginatorComponent, IAgPaginator } from '../../ag-grid-components/ag-paginator/ag-paginator.component';
import { ColDef, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, IServerSideDatasource, StatusPanelDef } from 'ag-grid-enterprise';
import { Clipboard } from '@angular/cdk/clipboard';
import { AgNoRowsOverlayComponent } from '../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgGridFunctions } from '../../../helpers/ag-grid-functions';
import { GridQuery } from '../../../models/grid-query';
import { HccService } from '../../../services/hcc/hcc.service';
import { HccValuationItem } from '../../../models/hcc-valuation-item';
import { AgEditIconRendererComponent } from '../../ag-grid-components/ag-edit-icon-renderer/ag-edit-icon-renderer.component';
import { AddUpdateHccModelVersionDialogComponent, AddUpdateHccModelVersionDialogModel } from '../../../dialogs/add-update-hcc-model-version-dialog/add-update-hcc-model-version-dialog.component';
import { OrgHccModelVersionItem } from '../../../models/org-hcc-model-version-item';
import { AgLoadingOverlayComponent } from '../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-hcc-model-version-config',
    templateUrl: './hcc-model-version-config.component.html',
    styleUrls: ['./hcc-model-version-config.component.scss'],
    standalone: true,
    imports: [MatButton, MatTooltip, AgGridModule]
})
export class HccModelVersionConfigComponent implements OnInit, OnDestroy {

  frameworkComps = {
    editRenderer: AgEditIconRendererComponent
  }

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgPaginatorComponent,
        key: 'ag-paginator'
      }
    ],
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [
    { field: 'ServiceYear', headerName: 'Service Year'},
    { field: 'ModelCategoryName', headerName: 'Model Category'},
    { field: 'VersionName', headerName: 'Version'},
    { field: 'LastModifiedBy', headerName: 'Modified By', valueFormatter: AgGridFunctions.userItemValue, tooltipValueGetter: AgGridFunctions.assingedUserTooltip },
    { field: 'LastModifiedDateTime', headerName: 'Modified Date', type: 'numericColumn', valueFormatter: AgGridFunctions.dateTimeToDateValue },

    { colId: 'edit', minWidth: 68, maxWidth: 68, headerName: 'Edit', suppressColumnsToolPanel: true, cellRenderer: 'editRenderer', sortable: false},
  ];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  gridContext: any;
  currentListSize = 0;
  currentRowHeight = 0;
  isFirstGenerate = true;

  isGridReady = false;

  query: GridQuery = new GridQuery();

  items: OrgHccModelVersionItem[];
  invalidServiceYears = [];

  subs: Subscription[] = [];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
  }

  constructor(
    private settingsService: SettingsService,
    private hccService: HccService,
    private dialog: MatDialog,
    private clipboard: Clipboard
  ) { 
    this.gridContext = { componentParent: this };
  }

  ngOnInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onGridReady() {
    this.onGenerateGridData();

    this.settingsService.getPaginatorPageSize(SettingsKeys.ORG_HCC_MODEL_VERSION_PAGE_SIZE).subscribe(result => {
      if(result == 0) {
        return;
      }

      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
    });
  }


  //#region AG Grid Functions

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {

        this.query.Metadata = AgGridFunctions.getServerSideQueryMetadata(params);

        this.hccService.getOrgHccModelVersionItems(this.query).subscribe(result => {
          this.currentListSize = result.ListSize;
          const startHeight = this.currentListSize == 0 ? 300 : 102;
          this.currentRowHeight = startHeight + (this.currentListSize * 28);
          
          this.items = result.Items;
          params.success({
            rowData: result.Items,
            rowCount: result.ListSize,
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          params.api.sizeColumnsToFit();
          this.updateInvalidServiceYears();
        },
        error => {
          params.fail();
        });

      },
    };
  }

  updateInvalidServiceYears() {
    this.invalidServiceYears = this.items?.map(i => i.ServiceYear);
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.ServiceYear;
  };

  onGenerateGridData() {

    if(this.isFirstGenerate || this.currentListSize == 0) {
      this.agGrid.api.setGridOption('serverSideDatasource', this.getServerSideDatasource());
      this.isFirstGenerate = false;
      return;
    }
    
    this.agGrid.api.refreshServerSide({purge: true});
    this.agGrid.api.deselectAll();
  }

  onGridGetContextMenu(params: GetContextMenuItemsParams) {

    if(params.column.getColId() == 'edit') {
      return [];
    }

    const result = [
      {
        name: 'Copy',
        action: () => {
          const value = params.context.componentParent.getColumnValue(params.column.getColId(), params.value);
          params.context.componentParent.clipboard.copy(value);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>'
      }
    ];

    return result;
  }

  onCellKeyDown(event) {
    const kbEvent:KeyboardEvent = event.event;
    if (kbEvent.ctrlKey && kbEvent.key === "c") {
      const value = AgGridFunctions.getColumnValue(event.column.getColId(), event.value);
      this.clipboard.copy(value);
    }
  }

  onListItemEditClick(item: HccValuationItem) {
    this.invalidServiceYears = this.invalidServiceYears.filter(y => y != item.ServiceYear);
    const data = {mode: 'edit', item: item, invalidServiceYears: this.invalidServiceYears}
    const dialogRef = this.dialog.open(AddUpdateHccModelVersionDialogComponent, new AddUpdateHccModelVersionDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onGenerateGridData();
      } else {
        this.updateInvalidServiceYears();
      }
    }));
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.ORG_HCC_MODEL_VERSION_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }

  //#endregion

  onAddClick() {
    const data = {mode: 'add', item: null, invalidServiceYears: this.invalidServiceYears}
    const dialogRef = this.dialog.open(AddUpdateHccModelVersionDialogComponent, new AddUpdateHccModelVersionDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onGenerateGridData();
      }
    }));
  }
}
