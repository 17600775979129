import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/authentication/auth.service';
import { matchToOtherControlValidator } from '../../helpers/custom-validators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

export class ResetPasswordDialogModel {
  readonly component: ComponentType<any> = ResetPasswordDialogComponent;
  panelClass?: string | string[] = ['dialog-container', 'dialog-container-no-padding'];
  autoFocus?: boolean = false;
  data?: any;
  width?: string = '30dvw';
  disableClose? = true;
}

@Component({
    selector: 'app-reset-password-dialog',
    templateUrl: './reset-password-dialog.component.html',
    styleUrls: ['./reset-password-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatInput, FormsModule, ReactiveFormsModule, MatError, MatButton]
})
export class ResetPasswordDialogComponent implements OnInit {

  form: FormGroup;

  passwordControlName = 'password';
  confirmPwControlName = 'confirm-password';

  password = new FormControl('', [Validators.required]);
  confirmPassword = new FormControl('');

  orgPolicyDays = -1;

  hasError = false;
  errorMessage = '';

  isDupeErrorName = 'Duplicate Password';

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {

    this.form = this.fb.group({});
    this.form.addControl(this.passwordControlName, this.password);
    this.form.addControl(this.confirmPwControlName, this.confirmPassword);
  }

  ngOnInit(): void {
    const orgParam = this.route.snapshot.queryParamMap.get("orgPolicyDays");
    this.orgPolicyDays = orgParam ? parseInt(orgParam) : -1;
    this.confirmPassword.addValidators([Validators.required, matchToOtherControlValidator(this.passwordControlName)]);
  }

  getPasswordErrorMessage() {
    if (this.password.hasError('required')) {
      return 'You must enter a value';
    }

    if (this.password.value.length < 7) {
      return 'Must be at least 7 characters'
    }

  }

  getconfirmPasswordErrorMessage() {
    return this.confirmPassword.hasError('required') ? 'You must enter a value' :
      this.confirmPassword.hasError('notMatching') ? 'Passwords must match' :
        '';
  }

  goToLogin(): void {
    this.router.navigate(["/login"]);
  }

  resetPassword(): void {
    const newPW = this.password.value;
    const key = this.router.routerState.snapshot.root.queryParams["key"];

    this.auth.submitNewPassword(key, newPW)
      .subscribe(data => {
        this.snackBar.open('Password Changed! ', '', { duration: 8000 });
        this.dialogRef.close();
      }
      , error => {
        this.hasError = true;
        this.errorMessage = error == this.isDupeErrorName 
        ? 'Password cannot match your current password'
        : 'Something went wrong updating new password'
      }
    );
  }

}
