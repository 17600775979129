<div class="flex flex-row flex-wrap gap-0">
  @for (action of preferredActions; track action) {
    <div class="p-[5px]">
      <ag-grid-angular
        [id]="action"
        [gridId]="action"
        class="ag-theme-balham ag-theme-dx-event-summary-report w-[752px] h-[255px]"
        [context]="gridContext"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowItems"
        [loadingOverlayComponent]="loadingOverlayComponent"
        [excelStyles]="excelStyles"
      [suppressContextMenu]="true"></ag-grid-angular>
    </div>
  }
</div>
