import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { ColDef, ColGroupDef, GetRowIdFunc, GetRowIdParams, IServerSideDatasource, StatusPanelDef } from 'ag-grid-enterprise';
import { Subscription } from 'rxjs';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgPaginatorComponent, IAgPaginator } from '../../../../ag-grid-components/ag-paginator/ag-paginator.component';
import { AuditLogQuery, UserAllocationQuery } from '../../../../../models/grid-query';
import { QueryMetadata, QuerySort } from '../../../../../models/query-metadata';
import { ProgressAllocationService } from '../../../../../services/progress/progress-allocation.service';
import { SettingsKeys, SettingsService } from '../../../../../services/settings/settings.service';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { ProgressCellRendererComponent } from '../../../../ag-grid-components/progress-cell-renderer/progress-cell-renderer.component';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { ProgressAllocationUserListItem } from '../../../../../models/progress-allocation-user-list-item';
import { AgSummationStatusPanelComponent, IAgSummationPanel } from '../../../../ag-grid-components/ag-summation-status-panel/ag-summation-status-panel.component';
import { AgLabelStatusPanelComponent } from '../../../../ag-grid-components/ag-label-status-panel/ag-label-status-panel.component';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AuditAction } from '../../../../../models/audit-action';
import { AuditLogItem } from '../../../../../models/audit-log-item';
import { NotesRendererComponent } from '../../../../ag-grid-components/notes-renderer/notes-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import { OkDialogComponent, OkDialogModel } from '../../../../../dialogs/ok-dialog/ok-dialog.component';
import { ExportService } from '../../../../../services/files/export.service';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

@Component({
    selector: 'app-org-audit-log-report',
    templateUrl: './org-audit-log-report.component.html',
    styleUrls: ['./org-audit-log-report.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class OrgAuditLogReportComponent implements OnInit {

  isGridReady = false;

  formValid = false;
  isFirstGenerate = true;
  currentListSize = 0;

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgPaginatorComponent,
        key: 'ag-paginator'
      },
    ],
  };

  frameworkComps = {
    notesRenderer: NotesRendererComponent
  }

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 150
  };

  excelStyles = DxsrExcelStyling.excelStyles2;

  gridContext: any;
  query: AuditLogQuery = new AuditLogQuery();

  data: AuditLogItem[] = [];

  currentRowHeight = 140;
  currentGridWidth = 282;

  subs: Subscription[] = [];

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
  }

  constructor(
    private settingsService: SettingsService,
    private loggingService: LoggingService,
    private exportService: ExportService,
    private dialog: MatDialog
  ) { 
    this.gridContext = { componentParent: this };
    this.query.Metadata = new QueryMetadata();
    this.query.Metadata.Sort = new QuerySort();
  }

  ngOnInit(): void {
    //TODO
  }

  onGridReady() {

    this.isGridReady = true;

    this.agGrid.api.sizeColumnsToFit();

    this.subs.push(this.settingsService.getPaginatorPageSize(SettingsKeys.ORG_AUDIT_LOG_QUERY_PAGE_SIZE).subscribe(result => {
      if(result == 0) {
        return;
      }

      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
    }));

    this.ready.emit(true);
  }

  //#region AG Grid Functions

  getColDef(): (ColDef | ColGroupDef)[] {

    const result: any = [
      {
        headerName: `Logs: ${this.query.StartDate} - ${this.query.EndDate}`,
        children: [
          { field: 'DateTime', headerName: 'Date/Time', headerTooltip: 'Date/Time', maxWidth: 150},
          { field: 'User', headerName: 'User', valueFormatter: AgGridFunctions.userItemValue, tooltipValueGetter: AgGridFunctions.assingedUserTooltip, maxWidth: 200},
          { field: 'ActionName', headerName: 'Action', maxWidth: 200},
          { field: 'Detail', headerName: 'Detail', cellRenderer: 'notesRenderer'}
        ]
      }
    ];

    return result;
  }

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {
        this.query.Metadata.PageSize = params.api.paginationGetPageSize();
        this.query.Metadata.PageNumber = params.api.paginationGetCurrentPage();

        const sortModel = params.request.sortModel;

        if(sortModel.length > 0) {
          this.query.Metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : null;
          this.query.Metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : null;
        }

        this.loggingService.getOrgAuditLogs(this.query).subscribe(result => {
          params.api.hideOverlay();
          this.currentListSize = result?.ListSize || 0;

          this.agGrid.api.setGridOption('columnDefs', this.getColDef());

          params.success({
            rowData: result?.Items || [],
            rowCount: result?.ListSize || 0,
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').goToCurrentPage();

          params.api.sizeColumnsToFit();
          this.setExportEnable.emit(true);
        },
        error => {
          params.fail();
        });

      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.ID;
  };

  onGenerateGridData(startDate: string, endDate: string, actionIDs: string) {
    
    this.query.StartDate = startDate;
    this.query.EndDate = endDate;
    this.query.ActionIDs = actionIDs;

    this.query.Metadata.PageSize = 0;
    this.query.Metadata.PageNumber = 0;

    if(this.isFirstGenerate || this.currentListSize == 0) {
      this.agGrid.api.setServerSideDatasource(this.getServerSideDatasource());
      this.isFirstGenerate = false;
      return;
    }
    
    this.agGrid.api.refreshServerSide({purge: true});
  }

  onNoteCellClick(data: any) {
    const ref = this.dialog.open(OkDialogComponent, new OkDialogModel("Detail", [data.Detail]));
  }

  onExport() {
    this.exportService.exportAuditLogs(this.query);
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.ORG_AUDIT_LOG_QUERY_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }

}
