import { Subject } from '../Subject';
import { SimpleOuterSubscriber, innerSubscribe, SimpleInnerSubscriber } from '../innerSubscribe';
export function window(windowBoundaries) {
  return function windowOperatorFunction(source) {
    return source.lift(new WindowOperator(windowBoundaries));
  };
}
class WindowOperator {
  constructor(windowBoundaries) {
    this.windowBoundaries = windowBoundaries;
  }
  call(subscriber, source) {
    const windowSubscriber = new WindowSubscriber(subscriber);
    const sourceSubscription = source.subscribe(windowSubscriber);
    if (!sourceSubscription.closed) {
      windowSubscriber.add(innerSubscribe(this.windowBoundaries, new SimpleInnerSubscriber(windowSubscriber)));
    }
    return sourceSubscription;
  }
}
class WindowSubscriber extends SimpleOuterSubscriber {
  constructor(destination) {
    super(destination);
    this.window = new Subject();
    destination.next(this.window);
  }
  notifyNext() {
    this.openWindow();
  }
  notifyError(error) {
    this._error(error);
  }
  notifyComplete() {
    this._complete();
  }
  _next(value) {
    this.window.next(value);
  }
  _error(err) {
    this.window.error(err);
    this.destination.error(err);
  }
  _complete() {
    this.window.complete();
    this.destination.complete();
  }
  _unsubscribe() {
    this.window = null;
  }
  openWindow() {
    const prevWindow = this.window;
    if (prevWindow) {
      prevWindow.complete();
    }
    const destination = this.destination;
    const newWindow = this.window = new Subject();
    destination.next(newWindow);
  }
}
