import { ComponentType } from '@angular/cdk/portal';
import { Component, inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { MorDataStore } from '../../../store/mor-data.store';
import { ColDef, GetRowIdFunc, GetRowIdParams, GridOptions, IServerSideDatasource } from 'ag-grid-community';
import { AgPaginatorComponent, IAgPaginator } from '../../../components/ag-grid-components/ag-paginator/ag-paginator.component';
import { MorFileProcessedDataRequest } from '../../../models/mor-file-processed-data-request';
import { QueryMetadata, QuerySort } from '../../../models/query-metadata';
import { MatIcon } from '@angular/material/icon';

export class ViewProcessedRecordsDialogModel {
  readonly component: ComponentType<ViewProcessedRecordsDialogModel> = ViewProcessedRecordsDialogModel;
  panelClass?: string | string[] = ['grid-dialog-container','dialog-container'];
  autoFocus?: boolean = false;
  data;
  disableClose? = true;

  constructor(data) {
    this.data = data;
  }
}

export class ViewProcessedRecordsHeader {
  uploadDate: string;
  payerAlias: string;
  fileType: string;
  uploadedBy: string;
  id: number;
  listSize: number;
}

export class ViewProcessedRecordsGridConfig {
  columnDefs: ColDef[];
  rowData: any[];
}

@Component({
  selector: 'app-view-processed-records-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    AgGridModule,
    MatIcon
  ],
  templateUrl: './view-processed-records-dialog.component.html',
  styleUrl: './view-processed-records-dialog.component.scss'
})
export class ViewProcessedRecordsDialogComponent {
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  readonly data = inject(MAT_DIALOG_DATA);
  readonly store = inject(MorDataStore);
  model: ViewProcessedRecordsHeader;
  gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true,
      suppressMenu: true,
      suppressMovable: true
    },
    columnDefs: [],
    rowModelType: 'serverSide',
    statusBar: {
      statusPanels: [
        {
          statusPanel: AgPaginatorComponent,
          key: "ag-paginator",
        },
      ],
    },
    context: {
      componentParent: this, 
      pageSizeOptions: [1000,5000,10000,20000], 
      pageSize: 5000
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ViewProcessedRecordsDialogComponent>
  ) {
    this.model = this.data;
  }

  onGridReady(params: any) {
    params.api.setGridOption("serverSideDatasource", this.getServerSideDatasource());
    
    setTimeout(() => {
      this.agGrid.api.refreshServerSide({ purge: true });
    }, 200); // Small delay to ensure datasource is attached
  }

  handleClose() {
    this.dialogRef.close();
  }

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {
        const metadata = new QueryMetadata();
        metadata.PageSize = params.api.paginationGetPageSize();
        metadata.PageNumber = params.api.paginationGetCurrentPage();

        const sortModel = params.request.sortModel;

        if(sortModel.length > 0) {
          metadata.Sort = new QuerySort();
          metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : "";
          metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : "";
        }

        const request = {
          MorQueueId: this.model.id,
          Metadata: metadata
        } as MorFileProcessedDataRequest;

        this.store.getProcessedRecordData(request)
          .then((config) => {
            params.api.hideOverlay();

            params.api?.setGridOption("columnDefs", config?.columnDefs);

            params.success({
              rowData: config?.rowData ?? [],
              rowCount: this.model.listSize
            });

            if (this.model.listSize === 0) {
              params.api.showNoRowsOverlay();
            }

            params.api?.getStatusPanel<IAgPaginator>('ag-paginator')?.goToCurrentPage();
          }).catch(
            (err) => { console.log(err) }
          );
      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };

  onPaginatorPageSizeChange(pageSize: number) {
    //todo
  }
}
