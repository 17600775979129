import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../../services/settings/settings.service';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-service-year-icon',
    templateUrl: './service-year-icon.component.html',
    styleUrls: ['./service-year-icon.component.scss'],
    standalone: true,
    imports: [MatTooltip, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem]
})
export class ServiceYearIconComponent implements OnInit, OnDestroy {

  private _selectedYear: number;
  @Input() set selectedYear(value: number) {
    this._selectedYear = value;
  }

  get selectedYear(): number {
    return this._selectedYear;
  }

  minYear = 2019;
  maxYear = new Date().getFullYear();
  years: number[] = [];

  subs: Subscription[] = []

  constructor(private settingsService: SettingsService) {
    this.selectedYear = this.maxYear - 1;
  }

  ngOnInit(): void {
    for(let i = this.minYear; i <= this.maxYear; i++) {
      this.years.push(i);
    }

    this.years.sort((a, b) => b - a);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onYearSelected(year: number) {
    this.settingsService.updateServiceYear(year);
  }

  getTooltip() {
    const result = 
    `Service Year:    ${this.selectedYear}\nPayment Year: ${this.selectedYear + 1}`;

    return result;
  }

}
