import { ComponentType } from '@angular/cdk/portal';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { PayerConfigItem, PayerRiskAdjustmentPolicy, PayerRiskAdjustmentPolicyUpdate } from '../../models/payer-config-item';
import { PayerAliasSelectComponent } from '../../components/field-components/payer-alias-select/payer-alias-select.component';
import { UpdatePayerRequest } from '../../models/update-payer-request';
import { PayerConfigService } from '../../services/payer/payer-config.service';
import { ColDef } from 'ag-grid-enterprise';
import { AgNoRowsOverlayComponent } from '../../components/ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, RowClassRules } from 'ag-grid-community';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { PayerConfigEditRendererComponent } from '../../components/ag-grid-components/payer-config-edit-renderer/payer-config-edit-renderer.component';
import { AddUpdateRiskPolicyDialogComponent, AddUpdateRiskPolicyDialogModel } from '../add-update-risk-policy-dialog/add-update-risk-policy-dialog.component';
import { Subscription } from 'rxjs';
import { AgGridFunctions } from '../../helpers/ag-grid-functions';
import { AgLoadingOverlayComponent } from '../../components/ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDivider } from '@angular/material/divider';
import { MatButton } from '@angular/material/button';

export class AddUpdatePayerDialogModel {
  readonly component: ComponentType<any> = AddUpdatePayerDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  data?: any;
  disableClose? = true;
  maxWidth? = '95dvw';

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
    selector: 'app-add-update-payer-dialog',
    templateUrl: './add-update-payer-dialog.component.html',
    styleUrls: ['./add-update-payer-dialog.component.scss'],
    standalone: true,
    imports: [
      MatDialogTitle, 
      CdkScrollable, 
      MatDialogContent, 
      MatFormField, 
      MatInput, 
      FormsModule, 
      ReactiveFormsModule, 
      MatError, 
      PayerAliasSelectComponent, 
      MatDivider, 
      MatButton, 
      AgGridModule, 
      MatDialogActions, 
      MatDialogClose
    ]
})
export class AddUpdatePayerDialogComponent implements OnInit, OnDestroy {

  actionType: string;
  payerItem: PayerConfigItem;

  payerForm: FormGroup;

  nameControlName = 'editPayerName';
  abbrevControlName = 'editPayerAbbreviation';
  riskPolicyControlName = 'editPayerRiskPolicy';
  contractEffectiveDateControlName = 'editPayerContractEffectiveDate';
  contractEndDateControlName = 'editPayerContractEndDate';
  omitPayerControlName = 'editPayerOmitPayer';
  omitEffectiveDateControlName = 'editPayerOmitEffectiveDate';
  omitEndDateControlName = 'editPayerOmitEndDate';
  preferredActionControlName = 'editPayerPreferredAction';

  nameControl = new FormControl('', [Validators.required]);
  abbreviationControl = new FormControl('', [Validators.required]);
  contractEffectiveDateControl = new FormControl(null, [Validators.required]);
  contractEndDateControl = new FormControl(null);
  omitEffectiveDateControl = new FormControl(null, [Validators.required]);
  omitEndDateControl = new FormControl(null);

  isPayerAliasReady = false;

  isFirstGenerate = true;

  request: UpdatePayerRequest = new UpdatePayerRequest();

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => {
      return this.actionType == 'add' ? 'Add New Policy' : 'No results returned'
    },
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  frameworkComps = {
    editRenderer: PayerConfigEditRendererComponent
  }


  columnDefs: ColDef[] = [];

  defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  public rowClassRules: RowClassRules = {
    // row style function
    'danger-cell': (params) => {
      let validDate = true;
      params.api.forEachNode(n => {
        if(n.data.PolicyID == params.data.PolicyID) {
          return;
        }

        const serviceDateStart = new Date(params.data.ServiceYearStart);
        const serviceDateEnd = new Date(params.data.ServiceYearEnd);
        const nodeDateStart = new Date(n.data.ServiceYearStart);
        const nodeDateEnd = new Date(n.data.ServiceYearEnd);

        if(serviceDateStart <= nodeDateEnd && serviceDateEnd >= nodeDateStart) {
          validDate = false;
          return;
        }
      });

      const emptyFields = (!params.data.ServiceYearStart || !params.data.ServiceYearEnd) || 
                          (params.data.HasRiskAdjustment && !params.data.HasManualOmit && !params.data.PreferredActionSubmissionType);
      params.context.componentParent.emptyPolicyFields = emptyFields;
      params.context.componentParent.invalidServiceYear = !validDate;

      const result = !validDate || emptyFields
      params.context.componentParent.invalidPolicyRows = result;

      return result;
    }
  };

  gridContext: any;

  invalidPolicyRows = false;
  invalidServiceYear = false;
  emptyPolicyFields = false;

  subs: Subscription[] = [];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @ViewChild(PayerAliasSelectComponent) aliasSelect: PayerAliasSelectComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
  }

  constructor(
    public dialogRef: MatDialogRef<AddUpdatePayerDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private configService: PayerConfigService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    
    this.gridContext = { componentParent: this };
    this.actionType = this.data.actionType;
    this.payerItem = this.data.payerItem || new PayerConfigItem();

    this.payerForm = this.fb.group({});
    this.payerForm.addControl(this.nameControlName, this.nameControl);
    this.payerForm.addControl(this.abbrevControlName, this.abbreviationControl);

    this.columnDefs = [

      { 
        field: 'ServiceYearStart', headerName: 'Service Year Start Date', 
        type: 'numericColumn', minWidth: 100
      },
      { 
        field: 'ServiceYearEnd', headerName: 'Service Year End Date', 
        type: 'numericColumn', minWidth: 100
      },
      { 
        field: 'HasRiskAdjustment', headerName: 'Risk Adj. Policy?',
        minWidth: 100, maxWidth: 100,
        cellDataType: false,
        valueFormatter: AgGridFunctions.booleanValue
      },
      { 
        field: 'HasManualOmit', headerName: 'Override Policy to Manually Omit?',
        minWidth: 100, maxWidth: 100,
        cellDataType: false,
        valueFormatter: this.manualOmitValue
      },
      { 
        field: 'PreferredActionSubmissionType', headerName: 'Preferred Action Submission Type', 
        maxWidth: 140, minWidth: 140,
        valueFormatter: this.filterOptionValue
      },
      {
        colId: 'TemplateCount', headerName: 'Template Count', headerTooltip: 'Template Count',
        type: 'numericColumn', maxWidth: 125, minWidth: 100,
        valueFormatter: (params) => {
          let count = 0;

          if(params.data.SuppFeedTemplateID > 0) {
            count++;
          }

          if(params.data.PayerValidationTemplateID > 0) {
            count++;
          }

          return count.toString();
        },
        tooltipValueGetter: (params) => {
          let result = '';

          if(params.data.SuppFeedTemplateID > 0) {
            result += `${params.data.SuppFeedTemplateName}`;
          }

          if(params.data.PayerValidationTemplateID > 0) {
            if(params.data.SuppFeedTemplateID > 0) {
              result += ', ';
            }

            result += `${params.data.PayerValidationTemplateName}`;
          }

          return result;
        },
      },
      { field: 'LastModifiedDate', headerName: 'Modified Date'},

      { colId: 'edit', minWidth: 68, maxWidth: 68, headerName: 'Edit', suppressColumnsToolPanel: true, cellRenderer: 'editRenderer', sortable: false},
  
    ];
  }

  ngOnInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onAddClick() {
    //TODO
  }

  onSaveClick() {
    this.payerItem.PayerName = this.nameControl.value ?? "";
    this.payerItem.Abbreviation = this.abbreviationControl.value ?? "";

    this.request.Item = this.payerItem;

    this.request.AliasIDs = this.aliasSelect.getAliasIDsForPayer().toString();
    this.request.AliasNames = this.aliasSelect.getAliasNamesForPayer().toString();

    this.configService.addUpdatePayer(this.request).subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  filterReadyCheck() {
    if(!this.isPayerAliasReady) {
      return;
    }

    if(this.actionType == 'edit') {
      this.setFilters();
    }
  }

  setFilters() {
    this.nameControl.setValue(this.payerItem.PayerName);
    this.abbreviationControl.setValue(this.payerItem.Abbreviation);

    const aliases = this.payerItem.Aliases?.split(", ");
    this.aliasSelect.setSelectedValuesByName(aliases);
  }

  onGridReady(params: GridReadyEvent) {

    params.api.sizeColumnsToFit();

    if(this.actionType == 'add') {
      params.api.showNoRowsOverlay();
      return;
    }

    this.refreshGridData(params.api);
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return `PolicyID:${params.data.PolicyID}, StartDate:${params.data.ServiceYearStart}, EndDate:${params.data.ServiceYearEnd}`;
  };

  onPreferredActionChanged(value: string) {
    //TODO
  }

  onAddRiskPolicyRow(item: PayerRiskAdjustmentPolicy) {
    item.PayerID = this.payerItem.PayerID;
    this.agGrid.api.applyTransaction({ add: [item] });
  }

  manualOmitValue(item: any) {
    if(item.data.HasRiskAdjustment == false) {
      return "----";
    }

    return item.value ? "Yes" : "No";
  }

  filterOptionValue(item: any) {
    if(!item.value) {
      return "----";
    }

    if(item.data.HasRiskAdjustment == false || item.data.HasManualOmit == true) {
      return "----";
    }

    return item.value.Description;
  }

  onAddPolicyClick() {
    const data = {mode: 'add', item: null}
    const dialogRef = this.dialog.open(AddUpdateRiskPolicyDialogComponent, new AddUpdateRiskPolicyDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onAddRiskPolicyRow(result);
      }
    }));
  }

  onEditPayerClick(item: PayerRiskAdjustmentPolicy) {
    const data = {mode: 'edit', item: item}
    const dialogRef = this.dialog.open(AddUpdateRiskPolicyDialogComponent, new AddUpdateRiskPolicyDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //this.agGrid.api.applyTransaction({ update: [result] });
        this.refreshGridData(this.agGrid.api);
      }
    }));
  }

  refreshGridData(api: GridApi) {
    this.configService.getPayerRiskPolicies(this.payerItem.PayerID).subscribe(result => {

      api.setGridOption('rowData', result);

      if(result.length == 0) {
        api.showNoRowsOverlay();
      }

      api.sizeColumnsToFit();

    });
  }

}
