
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { APIService } from './services/api/api.service';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from './services/authentication/auth.service';
import { AutoLogoutService } from './services/autologout/auto-logout.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {
  profile: any;

  constructor(private autoLogoutService: AutoLogoutService, public auth: AuthService, private api: APIService, public router: Router, private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {

    this.matIconRegistry.addSvgIcon(
      `vc-calendar-icon-default`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/svg/vc-calendar-icon-default.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `vc-calendar-icon-active`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/svg/vc-calendar-icon-active.svg`)
    );

  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {

    //TODO

  }
}
