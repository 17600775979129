<div class="flex basis-[7%] flex-row">
    <div class="flex basis-3/4 justify-start items-center content-center">
        <h1>Template Configuration</h1>
    </div>

    <div class="flex basis-1/4 flex-row justify-end items-center content-center">
        <button mat-flat-button class="primary-button-longtext"
        [matTooltip]="'Create Template'"
        [matTooltipClass]="'app-tooltip'"
        (click)="onCreateClick()">+ Create Template</button>
    </div>
</div>

<div class="filter-section grid grid-cols-5 gap-3">

    <app-filter-select
    [form]="form"
    [multiple]="true"
    [label]="'Template Type'"
    [placeholder]="'Select Type(s)'"
    [selectAllEnabled]="true"
    (ready)="onTypeFilterReady()"></app-filter-select>

    <app-boolean-select
    #templateActiveSelect
    [form]="form"
    [controlName]="activeFilterControlName"
    [label]="'Status'"
    [trueValue]="'Active'"
    [falseValue]="'Inactive'"
    [defaultValue]="['All', 'Active', 'Inactive']"
    [multiSelect]="true"
    [selectAllEnabled]="true"></app-boolean-select>

    <div class="pt-3">
        <button mat-flat-button class="primary-button"
        [disabled]="false"
        [matTooltip]="'Search'"
        [matTooltipClass]="'app-tooltip'"
        (click)="onGenerateGridData()">Search</button>
    </div>

</div>

<div class="grid-section flex flex-[20_1_0]">
    <ag-grid-angular
    class="ag-theme-balham w-full h-full"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="items"
    [getRowId]="getRowId"
    [pagination]="true"
    [tooltipShowDelay]="0"
    [components]="frameworkComps"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [context]="gridContext"
    [statusBar]="statusBar"
    [getContextMenuItems]="onGridGetContextMenu"
    [suppressCopyRowsToClipboard]="true"
    [suppressPaginationPanel]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    (gridReady)="onGridReady()"
    (cellKeyDown)="onCellKeyDown($event)"></ag-grid-angular>
</div>
