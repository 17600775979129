<div class="flex h-full w-full">
  <div class="flex flex-auto justify-center items-center content-center pl-3 pr-3">
    @if (!disabled) {
      <button
        mat-flat-button class="primary-button"
        [matTooltip]="mode == 'oppty-list' ? 'Edit Opportunities' : 'Edit Diagnosis Events'"
        [matTooltipClass]="'app-tooltip'"
        [matTooltipPosition]="'right'"
        (click)="onEditButtonClick()"
      >Edit</button>
    }

    @if (disabled) {
      <div
        [matTooltip]="mode == 'oppty-list' ? 'Selected Opportunities must have an assigned user.' : 'Selected DX Events must have an assigned user.'"
        [matTooltipClass]="'app-tooltip'"
        [matTooltipPosition]="'right'">
        <button
          mat-flat-button class="primary-button"
        [disabled]="true">Edit</button>
      </div>
    }
  </div>
</div>