import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingsKeys, SettingsService } from '../../../services/settings/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { ColDef, GetRowIdFunc, GetRowIdParams } from 'ag-grid-enterprise';
import { ToggleItem } from '../../../models/toggle-item';
import { Subscription } from 'rxjs';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { PayerSelectComponent } from '../../field-components/payer-select/payer-select.component';
import { FirstDataRenderedEvent, GetContextMenuItemsParams, IServerSideDatasource, StatusPanelDef } from 'ag-grid-community';
import { Clipboard } from '@angular/cdk/clipboard';
import { AgPaginatorComponent, IAgPaginator } from '../../ag-grid-components/ag-paginator/ag-paginator.component';
import { PayerConfigQuery } from '../../../models/payer-config-query';
import { PayerConfigService } from '../../../services/payer/payer-config.service';
import { BooleanSelectComponent } from '../../field-components/static/boolean-select/boolean-select.component';
import { PreferredActionSelectComponent } from '../../field-components/preferred-action-select/preferred-action-select.component';
import { StringFormatter } from '../../../helpers/string-formatter';
import { AddUpdatePayerDialogComponent, AddUpdatePayerDialogModel } from '../../../dialogs/add-update-payer-dialog/add-update-payer-dialog.component';
import { PayerConfigItem } from '../../../models/payer-config-item';
import { PayerConfigEditRendererComponent } from '../../ag-grid-components/payer-config-edit-renderer/payer-config-edit-renderer.component';
import { QueryMetadata, QuerySort } from '../../../models/query-metadata';
import { ExportService } from '../../../services/files/export.service';
import { PayerConfigFilterValues } from '../../../models/payer-config-filter-values';
import { AgExportButtonComponent, IAgExportButton } from '../../ag-grid-components/ag-export-button/ag-export-button.component';
import { AgNoRowsOverlayComponent } from '../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { PaymentYearSelectComponent } from '../../field-components/payment-year-select/payment-year-select.component';
import { AgLoadingOverlayComponent } from '../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';

@Component({
    selector: 'app-payer-config',
    templateUrl: './payer-config.component.html',
    styleUrls: ['./payer-config.component.scss'],
    standalone: true,
    imports: [MatButton, MatTooltip, MatChipListbox, MatChipOption, PayerSelectComponent, BooleanSelectComponent, PreferredActionSelectComponent, PaymentYearSelectComponent, AgGridModule]
})
export class PayerConfigComponent implements OnInit, AfterViewInit, OnDestroy {

  isGridReady = false;
  isPayerReady = false;
  isStatusReady = false;
  isRiskAdjReady = false;
  isOmitPayerReady = false;
  isPreferredActionReady = false;
  isServiceYearStartReady = false;
  isServiceYearEndReady = false;

  formValid = false;
  canQuickFilterUnselect = false;
  isFirstGenerate = true;
  currentChipSelection = 'Master';
  currentListSize = 0;

  filterChips: ToggleItem[] = [
    {Name: "Master", DisplayName: "Master", Selected: true}
  ];

  frameworkComps = {
    editRenderer: PayerConfigEditRendererComponent
  }

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgPaginatorComponent,
        key: 'ag-paginator'
      },
      {
        statusPanel: AgExportButtonComponent,
        key: 'export-btn'
      },
    ],
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [

    { field: 'PayerName', headerName: 'Payer Name', minWidth: 250},
    { field: 'Abbreviation', headerName: 'Abbreviation',minWidth: 100, maxWidth: 100},
    { field: 'Aliases', headerName: 'Aliases', minWidth: 250, valueFormatter: this.filterOptionValue , tooltipValueGetter: this.tooltipValue},
    { field: 'RiskAdjustmentPolicy.ServiceYearStart', headerName: 'Service Year Start', type: 'numericColumn', minWidth: 100, maxWidth: 100 },
    { field: 'RiskAdjustmentPolicy.ServiceYearEnd', headerName: 'Service Year End', type: 'numericColumn', minWidth: 100, maxWidth: 100 },
    { field: 'RiskAdjustmentPolicy.HasRiskAdjustment', headerName: 'Risk Adj. Policy',minWidth: 100, maxWidth: 100, valueFormatter: this.booleanValue, tooltipValueGetter: this.tooltipValue},
    { field: 'RiskAdjustmentPolicy.HasManualOmit', headerName: 'Omit Payer',minWidth: 100, maxWidth: 100, valueFormatter: this.manualOmitValue },
    { field: 'RiskAdjustmentPolicy.PreferredActionSubmissionType', headerName: 'Preferred Action', minWidth: 250, maxWidth: 250, valueFormatter: this.paFilterOptionValue },
    { 
      colId: 'TemplateCount', headerName: 'Template Count', headerTooltip: 'Template Count',
      type: 'numericColumn', maxWidth: 125, minWidth: 100,
      valueGetter(params) {
        let value = 0;

        if(params.data.RiskAdjustmentPolicy.SuppFeedTemplateName) {
          value++;
        }

        if(params.data.RiskAdjustmentPolicy.PayerValidationTemplateName) {
          value++;
        }

        return value;
      },
      tooltipValueGetter(params) {
        let result = '';

        if(params.data.RiskAdjustmentPolicy.SuppFeedTemplateID > 0) {
          result += `${params.data.RiskAdjustmentPolicy.SuppFeedTemplateName}`;
        }

        if(params.data.RiskAdjustmentPolicy.PayerValidationTemplateID > 0) {
          if(params.data.RiskAdjustmentPolicy.SuppFeedTemplateID > 0) {
            result += ', ';
          }

          result += `${params.data.RiskAdjustmentPolicy.PayerValidationTemplateName}`;
        }

        return result;
      },
    },
    { field: 'Status', headerName: 'Status', minWidth: 150, maxWidth: 150, valueFormatter: this.statusValue },

    { colId: 'edit', minWidth: 68, maxWidth: 68, headerName: 'Edit', suppressColumnsToolPanel: true, cellRenderer: 'editRenderer', sortable: false},
  ];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true
  };

  gridContext: any;
  query: PayerConfigQuery = new PayerConfigQuery();

  form: FormGroup;

  subs: Subscription[] = [];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @ViewChild(PayerSelectComponent) payerComp: PayerSelectComponent;
  @ViewChild('configStatus') statusComp: BooleanSelectComponent;
  @ViewChild('configRiskPolicy') riskPolicyComp: BooleanSelectComponent;
  @ViewChild('configOmitPayer') omitPayerComp: BooleanSelectComponent;
  @ViewChild(PreferredActionSelectComponent) paSelectComp: PreferredActionSelectComponent;
  @ViewChild('startYear') policyStartYearComp: PaymentYearSelectComponent;
  @ViewChild('endYear') policyEndYearComp: PaymentYearSelectComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
  }

  constructor(
    private fb: FormBuilder,
    private configService: PayerConfigService,
    private settingsService: SettingsService,
    private exportService: ExportService,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private cd: ChangeDetectorRef) {

      this.gridContext = { componentParent: this };
      this.query.Metadata = new QueryMetadata();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
    });
  }

  ngAfterViewInit(): void {
    this.subs.push(this.form.statusChanges.subscribe(result => {
      if(result == 'INVALID') {
        this.formValid = false;
      }

      if(result == 'VALID') {
        this.formValid = true;
      }
    }));

    this.agGrid.api.getStatusPanel<IAgExportButton>('export-btn').updateExportActive(true);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  //#region On Ready Event Functions

  filtersReadyCheck() {
    if(!this.isPayerReady) {
      return;
    }

    if(!this.isStatusReady) {
      return;
    }

    if(!this.isRiskAdjReady) {
      return;
    }

    if(!this.isOmitPayerReady) {
      return;
    }

    if(!this.isPreferredActionReady) {
      return;
    }

    if(!this.isGridReady) {
      return;
    }

    this.setFiltersByPreset(true);

  }

  setFiltersByPreset(isInitialLoad = false) {
    this.form.reset();

    this.payerComp.setSelectedValues([]);
    this.statusComp.setValue(false);
    this.riskPolicyComp.setValue(true);
    this.omitPayerComp.setValue(false);
    this.paSelectComp.setValues([]);
    this.policyStartYearComp.control.setValue('');
    this.policyEndYearComp.control.setValue('');

    if(isInitialLoad) {
      this.onGenerateGridData();
      this.canQuickFilterUnselect = true;
      return;
    }


    setTimeout(() => {
      this.canQuickFilterUnselect = true;
    }, 500);
  }

  onGridReady() {

    this.isGridReady = true;

    this.agGrid.api.sizeColumnsToFit();

    this.filtersReadyCheck();

    this.subs.push(this.settingsService.getPaginatorPageSize(SettingsKeys.PAYER_CONFIG_PAGE_SIZE).subscribe(result => {
      if(result == 0) {
        return;
      }

      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
    }));
  }

  onPreferredActionReady() {
    this.isPreferredActionReady = true;

    setTimeout(() => {
      this.filtersReadyCheck();
    }, 200);
  }

  //#region AG Grid Functions

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {
        this.query.Metadata.PageSize = params.api.paginationGetPageSize();
        this.query.Metadata.PageNumber = params.api.paginationGetCurrentPage();

        const sortModel = params.request.sortModel;

        if(sortModel.length > 0) {
          this.query.Metadata.Sort = new QuerySort();
          this.query.Metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : null;
          this.query.Metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : null;
        }

        this.configService.getPayerConfigs(this.query).subscribe(result => {
          this.currentListSize = result.ListSize;

          params.success({
            rowData: result.Items,
            rowCount: result.ListSize,
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          params.api.sizeColumnsToFit();

          this.agGrid.api.getStatusPanel<IAgExportButton>('export-btn').updateExportDisabled(result.Items.length > 0 ? false : true);

        },
        error => {
          params.fail();
        });

      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return `ID:${params.data.PayerID} PolicyID:${params.data.RiskAdjustmentPolicy.PolicyID}`;
  };

  onGenerateGridData() {
    
    this.query.PayerIDs = StringFormatter.FilterObjectToString('ID', this.payerComp.getValues());
    this.query.ConfigStatuses = this.statusComp.getSelectedValues();
    this.query.RiskAdjustmentPolicy = this.riskPolicyComp.getSelectedValues();
    this.query.OmitPayer = this.omitPayerComp.getSelectedValues();
    this.query.PreferredActionIDs = StringFormatter.FilterObjectToString('ID', this.paSelectComp.getValues());
    this.query.PolicyServiceYearStart = this.policyStartYearComp.control.value;
    this.query.PolicyServiceYearEnd = this.policyEndYearComp.control.value;

    if(this.isFirstGenerate || this.currentListSize == 0) {
      this.agGrid.api.setGridOption('serverSideDatasource', this.getServerSideDatasource());
      this.isFirstGenerate = false;
      return;
    }

    this.agGrid.api.refreshServerSide({purge: true});
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    
    //params.columnApi.autoSizeColumns(this.autosizeColumns);

    //params.api.sizeColumnsToFit();
  }

  onGridPaginationChange() {
    if(!this.isGridReady) {
      return;
    }

    this.onGenerateGridData();
  }

  onGridGetContextMenu(params: GetContextMenuItemsParams) {

    if(params.column.getColId() == 'edit') {
      return [];
    }

    const result = [
      {
        name: 'Copy',
        action: () => {
          const value = params.context.componentParent.getColumnValue(params.column.getColId(), params.value);
          params.context.componentParent.clipboard.copy(value);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>'
      }
    ];

    return result;
  }

  onCellKeyDown(event) {
    const kbEvent:KeyboardEvent = event.event;
    if (kbEvent.ctrlKey && kbEvent.key === "c") {
      const value = this.getColumnValue(event.column.getColId(), event.value);
      this.clipboard.copy(value);
    }
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.PAYER_CONFIG_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }

  onEditPayerClick(item: PayerConfigItem, rowID?: string) {
    const paValues = JSON.parse(JSON.stringify(this.paSelectComp.options));
    paValues.push({ID: 0, Description: '----', Selected: false, LinkAssociations: []});
    const data = {actionType: 'edit', payerItem: item, preferredActionValues: paValues}
    const dialogRef = this.dialog.open(AddUpdatePayerDialogComponent, new AddUpdatePayerDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onGenerateGridData();
      }
    }));
  }

  //#endregion

  //#region On Change Event

  onChipFilterChange(chip: any) {
    if(!chip) {
      return;
    }

    if(chip.Name == this.currentChipSelection) {
      return;
    }

    if(this.canQuickFilterUnselect) {
      this.canQuickFilterUnselect = false;
    }

    const prevIndex = this.filterChips.findIndex(c => c.Name == this.currentChipSelection);

    if(prevIndex > -1) {
      this.filterChips[prevIndex].Selected = false;
    }

    chip.Selected = true;

    this.currentChipSelection = chip.Name;

    this.setFiltersByPreset();
    
  }

  onQueryFilterChange(value: any) {
    if(this.canQuickFilterUnselect) {
      //TODO

      //TEMP FUNCTIONALITY
      this.currentChipSelection = '';
      this.filterChips.forEach(c => c.Selected = false);
      this.canQuickFilterUnselect = false;
    }
  }

  //#endregion

  //#region Click Events

  onAddPayerClick() {
    const paValues = this.paSelectComp.options;
    const data = {actionType: 'add', payerItem: null, preferredActionValues: paValues}
    const dialogRef = this.dialog.open(AddUpdatePayerDialogComponent, new AddUpdatePayerDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onGenerateGridData();
      }
    }));
  }

  onExportClick() {
    const filterValues = new PayerConfigFilterValues();
    filterValues.Payer = StringFormatter.FilterObjectToString('Name', this.payerComp.getValues());
    filterValues.Status = this.statusComp.getSelectedValueAsLabel();
    filterValues.RiskAdjustmentPolicy = this.riskPolicyComp.getSelectedValueAsLabel();
    filterValues.OmitPayer = this.omitPayerComp.getSelectedValueAsLabel();
    filterValues.PreferredActionSubmissionType = StringFormatter.FilterObjectToString('Description', this.paSelectComp.getValues());
    filterValues.PolicyServiceYearStart = this.policyStartYearComp.control.value;
    filterValues.PolicyServiceYearEnd = this.policyEndYearComp.control.value;

    this.exportService.exportPayerConfig(this.query, filterValues);
  }

  onServiceYearReady() {
    this.isServiceYearStartReady = true;
  }

  onComparisonYearReady() {
    this.isServiceYearEndReady = true;
  }

  onServiceYearChanged(value: number) {
    this.onQueryFilterChange(value);
  }

  onComparisonYearDefaultChange(value) {
    this.onQueryFilterChange(value);
  }

  //#endregion

  tooltipValue(item: any) {
    if(!item.value) {
      return;
    }

    if (item.value.length > 20) {
      return item.value;
    }
    return null;
  }

  statusValue(item: any) {
    if(!item.value) {
      return "Active";
    }

    return "Inactive";
  }

  filterOptionValue(item: any) {
    if(!item.value) {
      return;
    }

    return item.value.Description;
  }

  booleanValue(item: any) {
    return item.value ? "Yes" : "No";
  }

  manualOmitValue(item: any) {
    if(item.data.RiskAdjustmentPolicy.HasRiskAdjustment == false) {
      return "----";
    }

    return item.value ? "Yes" : "No";
  }

  paFilterOptionValue(item: any) {
    if(!item.value) {
      return "----";
    }

    if(item.data.RiskAdjustmentPolicy.HasRiskAdjustment == false || item.data.RiskAdjustmentPolicy.HasManualOmit == true) {
      return "----";
    }

    return item.value.Description;
  }

  getColumnValue(fieldName: string, value: any) {
    if(!value) {
      return null;
    }

    let result = '';

    switch(fieldName) {
      case "Status":
        result = value == true ? "Active" : "Inactive";
        break;

      case "OmitPayer":
      case "RiskAdjustmentPolicy":
        result = value == true ? "Yes" : "No";
        break;

      case "PreferredAction":
        result = value.Description;
        break;

      default:
        result = value;
        break;
    }

    return result;
  }

}
