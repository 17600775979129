<h1 mat-dialog-title>Assign</h1>

<div mat-dialog-content>
  <div class="content-container flex flex-col gap-3">
    <div class="flex flex-auto">
      <label>
        Assign the @if (!allEnabled) {
        <span class="oppty-count">{{items.length}}</span>
        } selected {{parent == 'oppty-list' ? 'Opportunities' : 'DX Events'}} to the selected user.
      </label>
    </div>

    @if (allEnabled) {
      <div class="flex flex-auto">
        <mat-radio-group [formControl]="modeControl" class="flex flex-col gap-[5px]">
          <mat-radio-button [value]="'default'"><strong>{{items.length | number}}</strong> records from the page</mat-radio-button>
          <mat-radio-button [value]="'all'"><strong>{{queryResultsCount | number}}</strong> records from results</mat-radio-button>
        </mat-radio-group>
      </div>
    }

    <div class="flex flex-auto w-full">
      <div class="flex-row flex-auto">
        <app-user-search-by-permission
          #assignSearchDialog
          [form]="assignDialogForm"
          [controlName]="controlName"
          [label]="''"
          [placeholder]="'Make a Selection'"
          [multiple]="false"
        [required]="true"></app-user-search-by-permission>
      </div>
    </div>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-flat-button class="secondary-button" mat-dialog-close>Cancel</button>
  <button mat-flat-button class="primary-button" [disabled]="assignDialogForm.invalid" (click)="onAssignClick()">Assign</button>
</mat-dialog-actions>
