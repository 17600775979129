import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { LicenseManager } from "ag-grid-enterprise";
import { AgCharts } from 'ag-charts-community';
import { AuthService } from './app/services/authentication/auth.service';
import { APIService } from './app/services/api/api.service';
import { AuthGuard } from './app/services/authentication/guards/auth-guard.service';
import { AutoLogoutService } from './app/services/autologout/auto-logout.service';
import { ClientStorage } from './app/storage/client-storage.lib';
import { WebClientStorage } from './libs/storage/web-client-storage.lib';
import { appInitializer } from './app/services/authentication/app-initializer';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { UnauthorizedInterceptor } from './app/authGuard/interceptor';
import { ClientToastController } from './app/toasts/client-toast.lib';
import { WebClientToast } from './libs/toast/web-client-toast.lib';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { AgPaginatorIntl } from './app/components/ag-grid-components/ag-paginator/ag-paginator.component';
import { BrowserModule, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideRouter } from '@angular/router';
import { AppRoutes } from './app/app.routing';
import { AgGridModule } from 'ag-grid-angular';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AppComponent } from './app/app.component';
import { provideNativeDateAdapter } from '@angular/material/core';
import { UtcDateTimeFormatter } from './app/helpers/utc-datetime-formatter';

const agGridLicense = "CompanyName=ValueCycle,LicensedApplication=ValueCycle,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-039014,SupportServicesEnd=5_March_2024_[v2]_MTcwOTU5NjgwMDAwMA==f68ad64d238f6330d20b78207dd52fba";
const agChartsLicense = "Using_this_{AG_Charts}_Enterprise_key_{AG-053984}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ValueCycle}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ValueCycle}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{ValueCycle}_need_to_be_licensed___{ValueCycle}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts}_Enterprise_versions_released_before_{22_January_2025}____[v3]_[02]_MTczNzUwNDAwMDAwMA==4bcda74ca1bae964980d322c4e5a53f0";
LicenseManager.setLicenseKey(agGridLicense);
AgCharts.setLicenseKey(agChartsLicense);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, DragDropModule, FormsModule, ReactiveFormsModule, HammerModule, AgGridModule, NgxMatSelectSearchModule, AgChartsAngularModule),
        AuthService, APIService, AuthGuard, AutoLogoutService,
        {
            provide: ClientStorage,
            useClass: WebClientStorage
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true,
        },
        {
            provide: ClientToastController,
            useClass: WebClientToast,
        },
        {
            provide: MatPaginatorIntl,
            useClass: AgPaginatorIntl
        },
        provideNativeDateAdapter(),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(AppRoutes),
        UtcDateTimeFormatter
    ]
})
  .catch(err => console.log(err));
