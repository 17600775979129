import { QueryMetadata } from "./query-metadata";

export class DiagnosisEventQuery {
    EventTypeIDs: string;
    StateIDs: string;
    StatusIDs: string;
    PreferredActionIDs: string;
    RootCauseIDs: string;
    PayerIDs: string;
    AssignedToIDs: string;
    EmrPID: string;
    IcdCodeID: string;
    DateRanges: QueryDateRange[];
    Metadata: QueryMetadata;
    IsOpptyQuery: boolean;
    FilterForTargetedHCC: boolean;
}

export class QueryDateRange {
    StartDate: string;
    EndDate: string;
}
