import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { User } from '../../../models/users';
import { APIService } from '../../../services/api/api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateUser } from '../../../models/createUser';
import { AuthService } from '../../../services/authentication/auth.service';
import { Organization } from '../../../models/organization.model';
import { Subscription } from 'rxjs';
import { OrganizationService } from '../../../services/organization/organization.service';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { ColDef, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, IServerSideDatasource, StatusPanelDef } from 'ag-grid-enterprise';
import { AgNoRowsOverlayComponent } from '../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgPaginatorComponent, IAgPaginator } from '../../ag-grid-components/ag-paginator/ag-paginator.component';
import { formatDate } from '@angular/common';
import { SettingsKeys, SettingsService } from '../../../services/settings/settings.service';
import { UserQuery } from '../../../models/user-query';
import { QueryMetadata, QuerySort } from '../../../models/query-metadata';
import { Clipboard } from '@angular/cdk/clipboard';
import { UserListActionIconRendererComponent } from '../../ag-grid-components/user-list-action-icon-renderer/user-list-action-icon-renderer.component';
import { UserRoleSelectComponent } from '../../field-components/user-role-select/user-role-select.component';
import { ComponentType } from '@angular/cdk/portal';
import { ListMenuOption } from '../../../models/list-menu-option';
import { MatSelectionListChange, MatSelectionList, MatListOption } from '@angular/material/list';
import { PermissionService } from '../../../services/settings/permission.service';
import { Permission } from '../../../models/permission';
import { UserService } from '../../../services/user/user.service';
import { AgLoadingOverlayComponent } from '../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AutofocusDirective } from '../../../directives/auto-focus.directive';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDivider } from '@angular/material/divider';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css'],
    standalone: true,
    imports: [MatButton, MatTooltip, MatIcon, MatFormField, MatLabel, MatInput, FormsModule, AutofocusDirective, ReactiveFormsModule, MatSlideToggle, AgGridModule]
})
export class UsersComponent implements OnInit, OnDestroy {

  frameworkComps = {
    actionRenderer: UserListActionIconRendererComponent
  }

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgPaginatorComponent,
        key: 'ag-paginator'
      }
    ],
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [

    { field: 'family_name', headerName: 'Name', valueFormatter: this.getNameValue, tooltipValueGetter: this.getNameValue },
    { field: 'email', headerName: 'Email (Username)' },
    { field: 'app_metadata.role', headerName: 'Role' },
    { field: 'enabled', headerName: 'Status', valueGetter: this.statusValue, cellStyle: params => params.data.enabled == '0' ? {color: 'red'} : {color: 'black'} },
    { field: 'created_at', headerName: 'Created', type: 'numericColumn', valueFormatter: this.getDateValue },
    { field: 'last_login', headerName: 'Last Login', type: 'numericColumn', valueFormatter: this.getDateValue },
    { field: 'failedLogins', headerName: 'Failed Logins', type: 'numericColumn', cellStyle: params => params.value > 0 ? {color: 'red'} : {color: 'black'} },

    { colId: 'action', minWidth: 68, maxWidth: 68, headerName: 'Action', 
    suppressColumnsToolPanel: true, 
    cellRenderer: 'actionRenderer', 
    cellRendererParams: {
      items: [
        'Change Password',
        'Disable User',
        'Edit User',
        'Make Site Admin',
        'Make Standard User',
        'Unblock User'
      ]
    }, sortable: false},
  ];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  form: FormGroup;
  nameControl = new FormControl('');
  emailControl = new FormControl('');

  nameControlName = 'userNameControl';
  emailControlName = 'userEmailControl';

  gridContext: any;
  currentListSize = 0;
  isFirstGenerate = true;

  query: UserQuery = new UserQuery();

  subscriptions: Subscription[] = [];

  loadingGrid = false;
  orgName = '';

  currentUser: User;

  public config: Organization;

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @ViewChild(UserRoleSelectComponent) rolesSelect: UserRoleSelectComponent;

  constructor(
    public auth: AuthService,
    private settingsService: SettingsService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private api: APIService,
    private userService: UserService,
    private orgService: OrganizationService,
    private clipboard: Clipboard,
    private fb: FormBuilder) { 

      this.gridContext = { componentParent: this };
      this.query.Metadata = new QueryMetadata();
    }

  public siteUsers: User[];

  ngOnInit() {
    this.form = this.fb.group({});
    this.form.addControl(this.nameControlName, this.nameControl);
    this.form.addControl(this.emailControlName, this.emailControl);

    this.subscriptions.push(this.orgService.organization$.subscribe(response => {
      this.config = response;
    }
    ));

    this.subscriptions.push(this.userService.currentUser$.subscribe(result => {
      this.currentUser = result;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onGridReady() {
    this.LoadUsers();

    this.settingsService.getPaginatorPageSize(SettingsKeys.USERS_CONFIG_PAGE_SIZE).subscribe(result => {
      if(result == 0) {
        return;
      }

      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
      
    });
  }


  //#region AG Grid Functions

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {

        this.query.Metadata.PageSize = params.api.paginationGetPageSize();
        this.query.Metadata.PageNumber = params.api.paginationGetCurrentPage();

        const sortModel = params.request.sortModel;

        if(sortModel.length > 0) {
          this.query.Metadata.Sort = new QuerySort();
          this.query.Metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : null;
          this.query.Metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : null;
        }

        this.api.getUsersByQuery(this.query).subscribe(result => {
          this.currentListSize = result.ListSize;
          params.success({
            rowData: result.Items,
            rowCount: result.ListSize,
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          params.api.sizeColumnsToFit();
        },
        error => {
          params.fail();
        });

      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.user_id;
  };

  onGridGetContextMenu(params: GetContextMenuItemsParams) {

    if(params.column.getColId() == 'edit') {
      return [];
    }

    const result = [
      {
        name: 'Copy',
        action: () => {
          const value = params.context.componentParent.getColumnValue(params.column.getColId(), params.value);
          params.context.componentParent.clipboard.copy(value);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>'
      }
    ];

    return result;
  }

  onCellKeyDown(event) {
    const kbEvent:KeyboardEvent = event.event;
    if (kbEvent.ctrlKey && kbEvent.key === "c") {
      const value = this.getColumnValue(event.column.getColId(), event.value);
      this.clipboard.copy(value);
    }
  }

  onActionItemClick(result: any) {
    if(!result) {
      return;
    }

    switch(result.action) {
      case "Change Password":
        this.ResetPassword(result.item.email);
        break;
      
      case "Set User to Inactive":
        this.DeleteUser(result.item.email);
        break;

      case "Set User to Active":
        this.EditUser(result.item);
        break;

      case "Edit User":
        this.Edit(result.item);
        break;

      case "Make Site Admin":
        this.MakeSiteAdmin(result.item.email);
        break;

      case "Make Standard User":
        this.MakePlainUser(result.item.email);
        break;

      case "Unblock User":
        this.UnblockUser(result.item.email);
        break;

      default:
        break;
    }
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.USERS_CONFIG_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }

  getColumnValue(fieldName: string, value: any) {
    if(!value) {
      return null;
    }

    let result = '';

    switch(fieldName) {

      //object cases here

      default:
        result = value;
        break;
    }

    return result;
  }

  getDateValue(item: any) {
    if(!item || !item.value) {
      return;
    }

    return formatDate(item.value, "MM/dd/YYYY", "en");
  }

  getNameValue(item: any) {
    if(!item.data) {
      return '';
    }

    return item.data.given_name + ' ' + item.data.family_name;
  }

  statusValue(item: any) {
    if(!item.data) {
      return "Inactive";
    }

    return item.data.enabled == '1' ? "Active" : "Inactive";
  }

  //#endregion

  LoadUsers(): void {
    this.query.Name = this.nameControl.value ? this.nameControl.value.toLowerCase() : null;
    this.query.Email = this.emailControl.value ?? null;
    //this.query.Role = StringFormatter.FilterObjectToStringWithSingleQuotes('Description', this.rolesSelect.getValues());

    if(this.isFirstGenerate || this.currentListSize == 0) {
      this.agGrid.api.setGridOption('serverSideDatasource', this.getServerSideDatasource());
      this.isFirstGenerate = false;
      return;
    }
    
    this.agGrid.api.refreshServerSide({purge: true});
    this.agGrid.api.deselectAll();
  }

  fillUsers(users: User[]) {
    this.siteUsers = users;
  }

  DeleteUser(userId: string) {
    this.api.deleteUser(userId).subscribe(data => {
      this.snackBar.open('User has been set to Inactive', '', { duration: 3000 });
      this.LoadUsers();
    });
  }

  private checkValidDate(date: Date): number {
    if (date != null) {
      return date.valueOf();
    } else {
      return 0;
    }
  }

  VerifyEmail(userId: string) {
    this.api.sendVerifyEmail(userId)
      // tslint:disable-next-line:max-line-length
      .subscribe(data => this.snackBar.open('We just sent an email to the address with a verify email link! If there is no email in a few minutes please check your junk mail folder. ', '', { duration: 3000 })
        , error => this.snackBar.open('Error sending verification email!', '', { duration: 3000 })
      );
  }

  private getNumberOfAdmins(): number {
    let numAdmins = 0;
    for (const user of this.siteUsers) {
      const isAdminUser = user.app_metadata.role == "admin";
      if (isAdminUser) {
        numAdmins++;
      }
    }
    return numAdmins;
  }



  MakeSiteAdmin(userId: string) {
   

      this.api.makeSiteAdmin(userId)
        // tslint:disable-next-line:max-line-length
        .subscribe(data => this.LoadUsers()
          , error => this.snackBar.open('Error promoting to admin!', '', { duration: 3000 })
        );
   
  }



  MakePlainUser(email: string) {
    this.api.makePlainUser(email)
      // tslint:disable-next-line:max-line-length
      .subscribe(data => this.LoadUsers()
        , error => this.snackBar.open('Error reverting to user!', '', { duration: 3000 })
      );
  }




  MakeReadOnlyUser(email: string) {
    this.api.makeReadOnlyUser(email)
      // tslint:disable-next-line:max-line-length
      .subscribe(data => this.LoadUsers()
        , error => this.snackBar.open('Error making user read only!', '', { duration: 3000 })
      );
  }


  AddUser() {
    const data = { mode: "add", title: "Add User", readOnly: false, userData: null };
    const dialogRef = this.dialog.open(DialogAddUserComponent, new AddUpdateUserDialogModel(data));

    dialogRef.afterClosed().subscribe(result => {
      this.LoadUsers();
    });
  }

  GetUserRoleDescription(user: User): string {
    if (user.app_metadata.systemAdmin == "true") {
      return "System Admin";
    }
    else if (user.app_metadata.role == "admin") {
      return "Admin";
    } else if (user.app_metadata.role == "readonlyuser") {
      return "Read Only";
    } else {
      return this.toTitleCase(user.app_metadata.role);
    }
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }


  Edit(user: User) {
    const data = { mode: "edit", title: "Edit User", readOnly: user.app_metadata.role == 'readonlyuser', userData: user };
    const dialogRef = this.dialog.open(DialogAddUserComponent, new AddUpdateUserDialogModel(data));

    dialogRef.afterClosed().subscribe(result => {
      this.LoadUsers();
    });
  }

  EditUser(user: User) {
    const readOnly = user.app_metadata ? user.app_metadata.role == "readonlyuser" : false;
    const newUser = new CreateUser(user.user_id, user.email,
      user.family_name, user.given_name, readOnly, false,
      user.limitedUser, 0);

    this.api.editUser(newUser).subscribe(result => {
      this.LoadUsers();
    });
  }


  UnblockUser(userId: string) {
    this.api.unblockUser(userId)
      .subscribe(data => {
        this.snackBar.open('User has been unblocked!', '', { duration: 3000 });
        this.LoadUsers();
      }
        , error => this.snackBar.open('Error sending unblocking user!', '', { duration: 3000 })
      );
  }

  ResetPassword(email: string) {
    this.auth.forgotPassword(email)
      // tslint:disable-next-line:max-line-length
      .subscribe(data => this.snackBar.open('We just sent a password reset email to the address with a verify email link! If there is no email in a few minutes please check your junk mail folder. ', '', { duration: 3000 })
        , error => this.snackBar.open('Error sending verification email!', '', { duration: 3000 })
      );
  }


  handleNullDate(strDate: string): string {
    if (strDate === '0001-01-01T00:00:00') {
      return '';
    }
    else {
      return strDate;
    }

  }

  getOrgName() {
    this.subscriptions.push(
      this.orgService.organization$.subscribe(response => {
        if (response) {
          this.orgName = response.orgName;
        }

      }
      ));

  }

  onKeyPress(event: KeyboardEvent) {
    if(event.key == 'Enter') {
      this.LoadUsers();
    }
  }



}

@Component({
    selector: 'app-dialog-overview-example-dialog',
    templateUrl: 'dialog-overview-example-dialog.html',
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ],
})
export class DialogOverviewExampleDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialogComponent>,
  ) { }


  onNoClick(): void {
    this.dialogRef.close();
  }



}

export class AddUpdateUserDialogModel {
  readonly component: ComponentType<any> = DialogAddUserComponent;
  panelClass?: string | string[] = 'side-menu-dialog';
  autoFocus?: boolean = false;
  data?: any;
  minWidth?: string = '50dvw';
  disableClose? = true;

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
    selector: 'app-add-user-dialog',
    templateUrl: 'dialog-add-user.html',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatIcon,
        MatSelectionList,
        MatListOption,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatError,
        MatButton,
        MatCheckbox,
        MatTooltip,
        MatDivider,
        MatIconButton,
        MatSuffix,
    ],
})
export class DialogAddUserComponent {
  tempUser = false;
  readOnly = false;
  mobileOnly = false;
  limitedUser = false;
  email = new UntypedFormControl({value: '', disabled: true}, [Validators.required, Validators.email]);
  lastname = new UntypedFormControl({value: '', disabled: true}, [Validators.required]);
  firstname = new UntypedFormControl({value: '', disabled: true}, [Validators.required]);
  expiresAfter = new UntypedFormControl('0', [Validators.required]);

  form: UntypedFormGroup;

  sideMenuOptions: ListMenuOption[] = [
    {ID: 1, Name: "Profile", Icon: "person", IconOutlined: false, Disabled: false},
    {ID: 2, Name: "Permissions", Icon: "security", IconOutlined: false, Disabled: true},
    {ID: 3, Name: "Authentication", Icon: "lock_person", IconOutlined: true, Disabled: true}
  ];

  selectedOption: ListMenuOption;

  permissions: Permission[] = [];

  permissionsEnabled = false;
  permissionSaveEnabled = false;
  
  editEnabled = false;

  exportPassword = '';
  hidePw = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private snackBar: MatSnackBar, 
    private api: APIService,
    private auth: AuthService,
    private permissionService: PermissionService,
    public dialogRef: MatDialogRef<DialogAddUserComponent>,
    private fb: UntypedFormBuilder
  ) {

    this.form = this.fb.group({
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email
    });

    if (data.mode === 'edit' || data.mode === 'readonly') {
      this.email.setValue(data.userData.email);
      this.firstname.setValue(data.userData.given_name);
      this.lastname.setValue(data.userData.family_name);

      this.tempUser = data.userData.tempUser;
      this.readOnly = data.readOnly;

      this.sideMenuOptions[1].Disabled = false;
      this.sideMenuOptions[2].Disabled = false;

      this.getPermissions(data.userData.user_id);

      this.api.getExportPassword(this.data.userData.user_id).subscribe(result => {
        this.exportPassword = result.Result;
      });
    }

    if(data.mode == 'add' || data.mode === 'edit') {
      this.editEnabled = true;
      this.email.enable();
      this.firstname.enable();
      this.lastname.enable();
    }

    this.selectedOption = this.sideMenuOptions[0];
  }

  getEmailErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      this.email.hasError('email') ? 'Not a valid email' :
        '';
  }

  getLastNameErrorMessage() {
    return this.lastname.hasError('required') ? 'You must enter a value' :
      '';
  }

  getFirstNameErrorMessage() {
    return this.firstname.hasError('required') ? 'You must enter a value' :
      '';
  }

  onCloseClick() {
    this.dialogRef.close(null);
  }

  addUser(): void {

    const newUser = new CreateUser('0', this.email.value,
      this.lastname.value, this.firstname.value, this.readOnly, this.mobileOnly, this.limitedUser, this.expiresAfter.value);

    this.api.addUser(newUser)
      .subscribe(userID => {

        this.snackBar.open('We just sent an invitation email to the address with the portal link! If there is no email in a few minutes please check your junk mail folder. ', '', { duration: 8000 });

        this.data.mode = 'edit';
        this.data.userData = new User();
        this.data.userData.user_id = userID;
        this.sideMenuOptions[1].Disabled = false;
        this.sideMenuOptions[2].Disabled = false;

        this.getPermissions(userID);
        this.api.getExportPassword(userID).subscribe(result => {
          this.exportPassword = result.Result;
        });

        this.selectedOption = this.sideMenuOptions[1];
      }

        , error => {
          this.snackBar.open('Error creating user!' + error, '', { duration: 6000 });
        }
      );


  }

  editUser(): void {

    const newUser = new CreateUser(this.data.userData.user_id, this.email.value,
      this.lastname.value, this.firstname.value, this.readOnly, this.mobileOnly,
      this.limitedUser, this.expiresAfter.value);

    this.api.editUser(newUser)
      .subscribe(user => {
        this.snackBar.open('User Updated', '', { duration: 8000 });
      }

        , error => {
          this.snackBar.open('Error saving user!' + error, '', { duration: 6000 });
        }
      );


  }

  onSelectedSideMenuOption(event: MatSelectionListChange) {
    if(!event.options) {
      return;
    }

    const option = event.options[0].value;

    if(option.ID == this.selectedOption.ID) {
      return;
    }

    this.selectedOption = option;
  }

  getPermissions(userID: string) {
    this.permissionService.getPermissionsByUser(userID).subscribe(result => {
      this.permissions = result;
    })
  }

  updatePermissions() {
    this.permissionService.updateUserPermissions(this.data.userData, this.permissions).subscribe(result => {
      this.snackBar.open('User Permissions Updated', '', { duration: 8000 });
    });
  }

  sendPasswordResetLink() {
    this.auth.changePassword(this.data.userData.email);
  }

}
