import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SchedulesService } from '../../../services/settings/schedules.service';
import { EditIconRendererComponent } from '../../ag-grid-components/edit-icon-renderer/edit-icon-renderer.component';
import { ColDef, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, IServerSideDatasource, StatusPanelDef } from 'ag-grid-enterprise';
import { AgPaginatorComponent, IAgPaginator } from '../../ag-grid-components/ag-paginator/ag-paginator.component';
import { MatDialog } from '@angular/material/dialog';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { SettingsKeys, SettingsService } from '../../../services/settings/settings.service';
import { CmsScheduleQuery } from '../../../models/cms-schedule-query';
import { CmsScheduleItem } from '../../../models/cms-schedule-item';
import { Clipboard } from '@angular/cdk/clipboard';
import { formatDate } from '@angular/common';
import { QueryMetadata, QuerySort } from '../../../models/query-metadata';
import { CMSTimelineComponent } from '../cms-timeline/cms-timeline.component';
import { AddUpdateCmsScheduleDialogComponent, AddUpdateCmsScheduleDialogModel } from '../../../dialogs/add-update-cms-schedule-dialog/add-update-cms-schedule-dialog.component';
import { Subscription } from 'rxjs';
import { AgNoRowsOverlayComponent } from '../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { PaymentYearSelectComponent } from '../../field-components/payment-year-select/payment-year-select.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QueryDateRange } from '../../../models/diagnosis-event-query';
import { AgLoadingOverlayComponent } from '../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-cms-schedule-config',
    templateUrl: './cms-schedule-config.component.html',
    styleUrls: ['./cms-schedule-config.component.scss'],
    standalone: true,
    imports: [MatButton, MatTooltip, CMSTimelineComponent, PaymentYearSelectComponent, AgGridModule]
})
export class CmsScheduleConfigComponent implements OnInit, OnDestroy {

  frameworkComps = {
    editRenderer: EditIconRendererComponent
  }

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgPaginatorComponent,
        key: 'ag-paginator'
      }
    ],
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [

    { field: 'RiskScoreRunYear', headerName: 'Run Year' },
    { field: 'RiskScoreRunTypeName', headerName: 'Run Type'},
    { field: 'DatesOfServiceStart', headerName: 'Date Of Service Start', type: 'numericColumn'},
    { field: 'DatesOfServiceEnd', headerName: 'Date Of Service End', type: 'numericColumn'},
    { field: 'DeadlineForSubmission', headerName: 'Deadline for Submission', type: 'numericColumn'},
    { field: 'AnticipatedPaymentMonth', headerName: 'Anticipated Payment Month', type: 'numericColumn', valueFormatter: this.getPaymentMonthValue},

    { colId: 'edit', minWidth: 68, maxWidth: 68, headerName: 'Edit', suppressColumnsToolPanel: true, cellRenderer: 'editRenderer', sortable: false},
  ];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  gridContext: any;
  currentListSize = 0;
  isFirstGenerate = true;
  editAllActive = true;

  form: FormGroup;
  query: CmsScheduleQuery = new CmsScheduleQuery();

  subs: Subscription[] = [];

  @ViewChild('serviceYear') serviceYearComp: PaymentYearSelectComponent;

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @ViewChild(CMSTimelineComponent) timeline: CMSTimelineComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
  }

  constructor(
    private schedulesService: SchedulesService,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private fb: FormBuilder
  ) { 

    this.gridContext = { componentParent: this };
    this.query.Metadata = new QueryMetadata();
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onGridReady() {
    this.onGenerateGridData();

    this.settingsService.getPaginatorPageSize(SettingsKeys.CMS_CONFIG_PAGE_SIZE).subscribe(result => {
      if(result == 0) {
        return;
      }

      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
    });
  }


  //#region AG Grid Functions

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {

        this.query.Metadata.PageSize = params.api.paginationGetPageSize();
        this.query.Metadata.PageNumber = params.api.paginationGetCurrentPage();

        const sortModel = params.request.sortModel;

        if(sortModel.length > 0) {
          this.query.Metadata.Sort = new QuerySort();
          this.query.Metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : null;
          this.query.Metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : null;
        }

        const serviceYear = this.serviceYearComp?.control.value >= 0 ? this.serviceYearComp.control.value : -1;
        const dosStart: QueryDateRange = serviceYear >= 0 ? {StartDate: `1/1/${serviceYear}`, EndDate: `12/31/${serviceYear}`} : null;
        const dosEnd: QueryDateRange = serviceYear >= 0 ? {StartDate: `1/1/${serviceYear}`, EndDate: `12/31/${serviceYear}`} : null;

        this.query.DatesOfServiceStart = dosStart;
        this.query.DatesOfServiceEnd = dosEnd;

        this.schedulesService.getCMSConfigItems(this.query).subscribe(result => {
          this.currentListSize = result.ListSize;

          params.success({
            rowData: result.Items,
            rowCount: result.ListSize,
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          params.api.sizeColumnsToFit();
        },
        error => {
          params.fail();
        });

      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.ID;
  };

  onGenerateGridData() {

    if(this.isFirstGenerate || this.currentListSize == 0) {
      this.agGrid.api.setGridOption('serverSideDatasource', this.getServerSideDatasource());
      this.isFirstGenerate = false;
      return;
    }
    
    this.agGrid.api.refreshServerSide({purge: true});
    this.agGrid.api.deselectAll();
    this.timeline.getData(this.serviceYearComp?.control.value);
  }

  onFirstGridLoad() {
    if(!this.schedulesService.isCMSTimelineLoaded) {
      this.timeline.getData(this.serviceYearComp?.control.value);
    }
  }

  onGridGetContextMenu(params: GetContextMenuItemsParams) {

    if(params.column.getColId() == 'edit') {
      return [];
    }

    const result = [
      {
        name: 'Copy',
        action: () => {
          const value = params.context.componentParent.getColumnValue(params.column.getColId(), params.value);
          params.context.componentParent.clipboard.copy(value);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>'
      }
    ];

    return result;
  }

  onCellKeyDown(event) {
    const kbEvent:KeyboardEvent = event.event;
    if (kbEvent.ctrlKey && kbEvent.key === "c") {
      const value = this.getColumnValue(event.column.getColId(), event.value);
      this.clipboard.copy(value);
    }
  }

  onListItemEditClick(item: CmsScheduleItem) {
    const data = {actionType: 'edit', item: item}
    const dialogRef = this.dialog.open(AddUpdateCmsScheduleDialogComponent, new AddUpdateCmsScheduleDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onGenerateGridData();
      }
    }));
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.CMS_CONFIG_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }

  //#endregion

  //#region On Action Events

  onAddClick() {
    const newItem = new CmsScheduleItem();
    newItem.ID = 0;
    const data = {actionType: 'add', item: newItem}
    const dialogRef = this.dialog.open(AddUpdateCmsScheduleDialogComponent, new AddUpdateCmsScheduleDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onGenerateGridData();
      }
    }));
  }

  //#endregion

  getColumnValue(fieldName: string, value: any) {
    if(!value) {
      return null;
    }

    let result = '';

    switch(fieldName) {

      //object cases here

      default:
        result = value;
        break;
    }

    return result;
  }

  getDateValue(item: any) {
    if(!item || !item.value) {
      return;
    }

    return formatDate(item.value, "MM/dd/YYYY", "en");
  }

  getPaymentMonthValue(item: any) {
    if(!item || !item.value) {
      return;
    }

    return formatDate(item.value, "MM/YYYY", "en");
  }

}
