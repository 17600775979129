<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->

<div class="header-container">
  @if (isSystemAdmin || isAdmin) {
    <div class="settings-icon">
      <mat-icon
        [color]="'primary'"
        [inline]="true"
        [matMenuTriggerFor]="adminSettings"
        [matTooltip]="'Settings'"
        [matTooltipClass]="'app-tooltip'"
      aria-label="Settings">settings</mat-icon>
    </div>
  }

  <div class="container" [matMenuTriggerFor]="profile">
    <div
      class="avatar"
      aria-label="User"
      [matTooltip]="'Profile'"
      [matTooltipClass]="'app-tooltip'">
      {{initials}}
    </div>
  </div>
</div>

<mat-menu #profile="matMenu">
  <button mat-menu-item (click)="openUserProfile()">
    View Profile
  </button>

  <button (click)="auth.logout()" mat-menu-item>
    Sign Out
  </button>
</mat-menu>

<mat-menu #adminSettings="matMenu">
  <button mat-menu-item (click)="goToConfigPage()">
    Configuration
  </button>

  <button mat-menu-item (click)="goToFileManagement()">
    File Management
  </button>

  <button mat-menu-item (click)="goToUsers()">
    User Management
  </button>

  @if (isSystemAdmin) {
    <div class="p-4">
      <mat-divider></mat-divider>
    </div>
  }

  @if (isSystemAdmin) {
    <button mat-menu-item (click)="goToOrgs()">
      Organization
    </button>
  }
</mat-menu>