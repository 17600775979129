<div class="tree-menu">

  <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true" (selectionChange)="onSelectedConfigOption($event)">
    <mat-tree [dataSource]="data" [treeControl]="treeControl">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let option" matTreeNodeToggle>
        <mat-list-option [value]="option" [selected]="selectedOption.ID == option.ID">
          <div class="flex flex-auto">
            @if (option.Icon) {
              <div class="tree-item-icon">
                @if (!option.IconOutlined) {
                  <mat-icon>{{option.Icon}}</mat-icon>
                }
                @if (option.IconOutlined) {
                  <mat-icon fontSet="material-icons-outlined">{{option.Icon}}</mat-icon>
                }
              </div>
            }

            <div class="flex flex-auto justify-start items-center content-center">
              {{option.Name}}
            </div>
          </div>
        </mat-list-option>
      </mat-tree-node>

      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let option; when: hasChild">
        <mat-list-option [ngClass]="option.Children ? 'parent-node' : ''" [value]="option" [selected]="selectedOption.ID == option.ID">
          <div class="mat-tree-node" class="flex flex-auto" matTreeNodeToggle>
            <div class="tree-item-icon">
              @if (!option.IconOutlined) {
                <mat-icon>{{option.Icon}}</mat-icon>
              }
              @if (option.IconOutlined) {
                <mat-icon fontSet="material-icons-outlined">{{option.Icon}}</mat-icon>
              }
            </div>

            <div class="flex flex-auto justify-start items-center content-center">
              {{option.Name}}
            </div>
          </div>
        </mat-list-option>
        @if (treeControl.isExpanded(option)) {
          <div role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        }
      </mat-nested-tree-node>
    </mat-tree>
  </mat-selection-list>

</div>