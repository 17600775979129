@if (!disabled) {
  <mat-icon>
    {{icon}}
  </mat-icon>
}

@if (disabled) {
  <mat-icon class="icon-disabled" (click)="$event.stopImmediatePropagation()">
    {{icon}}
  </mat-icon>
}