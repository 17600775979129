<div class="side-menu-page-container">

  <div class="side-menu-page-menu-container flex-auto flex-col">
    <div class="side-menu-page-menu-header flex basis-[5%] flex-row">
      <div class="side-menu-page-menu-title flex justify-start items-center content-center">
        <h2>Configuration</h2>
      </div>
    </div>

    <!-- <mat-selection-list [multiple]="false" (selectionChange)="onSelectedConfigOption($event)">
    <mat-list-option *ngFor="let option of configMenuOptions" [value]="option" [selected]="selectedOption.ID == option.ID">
      <div fxLayout="row">
        <div fxFlex="15" fxLayoutAlign="start center">
          <mat-icon *ngIf="!option.IconOutlined">{{option.Icon}}</mat-icon>
          <mat-icon fontSet="material-icons-outlined" *ngIf="option.IconOutlined">{{option.Icon}}</mat-icon>
        </div>

        <div fxFlex fxLayoutAlign="start center">
          {{option.Name}}
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list> -->

  <app-tree-menu
    [data]="configMenuOptions"
  (optionChange)="onSelectedConfigOption($event)"></app-tree-menu>
</div>

@if (selectedOption.ID == 1) {
  <app-cms-schedule-config class="side-menu-page-content-container flex flex-col"></app-cms-schedule-config>
}
<!-- @if (selectedOption.ID == 2) {
  <app-data-refresh-config class="side-menu-page-content-container flex flex-col gap-[10px]"></app-data-refresh-config>
} -->
@if (selectedOption.ID == 4) {
  <app-hcc-config class="side-menu-page-content-container flex flex-col gap-[10px]"></app-hcc-config>
}
@if (selectedOption.ID == 5) {
  <app-hcc-model-version-config class="side-menu-page-content-container flex flex-col gap-[10px]"></app-hcc-model-version-config>
}
@if (selectedOption.ID == 6) {
  <app-payer-config class="side-menu-page-content-container flex flex-col"></app-payer-config>
}
@if (selectedOption.ID == 7) {
  <app-template-config class="side-menu-page-content-container flex flex-col"></app-template-config>
}

</div>