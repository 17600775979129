import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ClientToastController } from '../../../toasts/client-toast.lib';

@Component({
  selector: 'app-file-upload-dropzone',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIcon,
    MatProgressBar,
  ],
  templateUrl: './file-upload-dropzone.component.html',
  styleUrl: './file-upload-dropzone.component.scss'
})
export class FileUploadDropzoneComponent {
  @Input() multiple: boolean = true;
  @Input() trackUploads: boolean = true;
  @Input() dropzoneLabel: string = "";
  @Input() browseButtonLabel: string = "";
  @Output() filesDropped = new EventEmitter<File[]>();
  @Output() filesDeleted = new EventEmitter<File[]>();
  disabled: boolean = false;
  files: File[] = [];
  progressBars: number[] = [];

  constructor(
    private toastController: ClientToastController,
  ) {}

  ngOnInit() {
    //TODO
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFiles(Array.from(input.files));
    }
    input.value = '';
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.files && !this.disabled) {
      const newFiles = Array.from(event.dataTransfer.files);
      if (!this.multiple && newFiles.length > 1) {
        this.toastController.displayToast("Error on upload: Only one file may be uploaded at a time.", 3000);
      } else {
        this.addFiles(newFiles);
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.currentTarget instanceof HTMLElement) {
      event.currentTarget.classList.add('dragover');
    }
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.currentTarget instanceof HTMLElement) {
      event.currentTarget.classList.remove('dragover');
    }
  }

  deleteFile(index: number) {
    const deletedFile = this.files[index];
    this.files.splice(index, 1);
    if (this.trackUploads) {
      this.progressBars.splice(index, 1);
    }
    this.filesDeleted.emit([deletedFile]);
  }

  reset() {
    this.files = [];
    this.progressBars = [];
  }

  private addFiles(newFiles: File[]): void {
    newFiles.forEach((file: File) => {
      this.files.push(file);
      if (this.trackUploads) {
        this.progressBars.push(0);
        this.incrementProgressBar(this.progressBars.length - 1, (toastMessage: string) => {
          if (newFiles[newFiles.length - 1] == file) {
            this.toastController.displayToast(toastMessage, 3000);
          }
        });
      } else {
        this.toastController.displayToast(`File(s) added for upload: ${file.name}`, 3000);
      }
    });
    this.filesDropped.emit(newFiles);
  }

  private incrementProgressBar(index: number, callback) {
    const interval = setInterval(() => {
      while (this.progressBars[index] < 100) {
        this.progressBars[index] += 10;
      }
      if (this.progressBars[index] == 100) {
        clearInterval(interval);
        callback("File(s) added for upload.");
      }
    }, 1000);
  }

  private formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }
}
