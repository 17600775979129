<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
  <mat-label>Date of Service</mat-label>
  <mat-select
    [multiple]="multiple"
    [formControl]="control"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (closed)="onSelectionChange()">
    <mat-select-trigger>
      @if ((control.value?.length || 0) === 1) {
        <span>
          {{control.value?.[0].Name || control.value?.[0]}}
        </span>
      }
      @if ((control.value?.length || 0) > 1 && (control.value?.length - 1) == options.length) {
        <span>
          All
        </span>
      }
      @if ((control.value?.length || 0) > 1 && (control.value?.length - 1) < options.length) {
        <span>
          Multiple
        </span>
      }
      @if ((control.value?.length || 0) > 1) {
        <span>
          ({{getSelectCount()}})
        </span>
      }
    </mat-select-trigger>

    @if (selectAllEnabled) {
      <mat-option
        #allItem
        [value]="allOption"
      (click)="onToggleItem(allItem)">All</mat-option>
    }

    @for (option of options; track option) {
      <mat-option
        #item
        [value]="option"
        (click)="onToggleItem(item)">
        {{option.Name}}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
