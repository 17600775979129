import { formatCurrency, formatDate, formatNumber } from "@angular/common";
import { ColumnResizedEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { QueryMetadata, QuerySort } from "../models/query-metadata";
import { AgCartesianSeriesTooltipRendererParams } from "ag-charts-community";
import { IAgSummationPanel } from "../components/ag-grid-components/ag-summation-status-panel/ag-summation-status-panel.component";

export class AgGridFunctions {
    
  static tooltipValue(item: any) {
    if(!item.value) {
      return;
    }

    return item.value;
  }
  
  static filterTooltipValue(item: any) {
    if(!item.value) {
      return;
    }

    return item.value.Description;
  }

  static userItemValue(item: any) {
    if(!item.value) {
        return;
    }

    if(item.value.UserID == 0) {
        return;
    }

    return item.value.FirstName + ' ' + item.value.LastName;
  }
  
  static assignedUserValue(item: any) {
    if(!item.value) {
      return '';
    }

    if(item.value.UserID == 0) {
      return '';
    }

    const firstName = item.value.FirstName;
    if(!firstName) {
      return '';
    }

    return firstName[0] + '. ' + item.value.LastName;
  }
  
  static assingedUserTooltip(item: any) {
    if(!item.value) {
      return;
    }

    if(item.value.UserID == 0) {
      return;
    }


    return item.value.FirstName + ' ' + item.value.LastName;
  }
  
  static payerValue(item: any) {
    if(!item.value) {
      return null;
    }

    return item.value.Abbreviation;
  }
  
  static filterOptionValue(item: any) {
    if(!item.value) {
      return null;
    }

    return item.value.Description;
  }
  
  static payerTooltip(item: any) {
    if(!item.value) {
      return;
    }

    return item.value.Name;
  }
  
  static booleanValue(item: any) {
    if(!item.value) {
      return "No";
    }

    return "Yes";
  }
  
  static dateTimeToLocalValue(item: any) {
    if(!item) {
      return;
    }

    return formatDate(new Date(item.value + ' UTC'), 'MM/dd/yyyy', 'en');
  }

  static dateTimeValue(item: any) {
    if(!item || !item.value) {
      return;
    }

    return formatDate(item.value, "MM/dd/YYYY HH:mm:ss", "en");
  }

  static dateTimeToDateValue(item: any) {
    if(!item || !item.value) {
      return;
    }

    return formatDate(item.value, "MM/dd/YYYY", "en");
  }

  static currencyValue(item: any) {
    return formatCurrency(item.value, 'en', "$", 'USD');
  }

  static getNumberValue(item: any) {
    if(!item.value) {
      return '0';
    }

    if(item.value == -1) {
      return;
    }

    return formatNumber(item.value, 'en', '1.0-2').toString();
  }

  static getNumberValueNullZero(item: any) {
    if(!item.value) {
      return;
    }

    return formatNumber(item.value, 'en').toString();
  }

  static getPercentValue(item: any, decimalCount?: number) {
    const decimalMax = decimalCount || 0;
    if(!item.value) {
      return '0%';
    }

    if(item.value == -1) {
      return '';
    }

    return formatNumber(item.value * 100, 'en', `1.0-${decimalMax}`) + "%";
  }

  static getCurrencyValue(item: any) {
    if(!item.value) {
      return '$0.00';
    }

    return formatCurrency(item.value, 'en', '$','USD', '1.0-2');
  }

  static getColumnValue(fieldName: string, value: any) {
    if(!value) {
      return null;
    }

    let result = '';

    switch(fieldName) {

      //object cases here

      default:
        result = value;
        break;
    }

    return result;
  }

  static getServerSideQueryMetadata(getRowsParams: IServerSideGetRowsParams) {
    const metadata = new QueryMetadata();
    metadata.PageSize = getRowsParams.api.paginationGetPageSize();
    metadata.PageNumber = getRowsParams.api.paginationGetCurrentPage();

    const sortModel = getRowsParams.request.sortModel;

    if(sortModel.length > 0) {
      metadata.Sort = new QuerySort();
      metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : null;
      metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : null;
    }

    return metadata;
  }

  static graphValueFormatFn(params: any, isPercent: boolean) {
    const formatter = Intl.NumberFormat('en', {minimumSignificantDigits: 1});

    return `${formatter.format(params.datum[params.yKey])}${isPercent ? '%' : ''}`;
  }

  static graphTooltipFn(params: any) {
    const contentValue = formatNumber(params.datum[params.yKey], 'en');
    const isPercent = params.yKey.includes('Percent');
    return {
      content: `${contentValue}${isPercent ? '%' : ''}`,
      title: `${params.datum[params.xKey]} ${params.yName}`
    }
  }

  static statusPanelResizeWithColumn(params: ColumnResizedEvent, statusBar) {
    statusBar.statusPanels.forEach(panel => {
      const matchingColumn = params.api.getColumn(panel.statusPanelParams.col);
      if (matchingColumn && panel.key) {
        params.api.getStatusPanel<IAgSummationPanel>(panel.key)?.updatePanelWidth(matchingColumn.getActualWidth());
      }
    });
  }
}
