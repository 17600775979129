@if (iconEnabled) {
  <mat-icon (click)="onIconClick($event)">
    {{iconName}}
  </mat-icon>
}

@if (!iconEnabled) {
  <mat-icon class="edit-icon-disabled" (click)="$event.stopImmediatePropagation()">
    {{iconName}}
  </mat-icon>
}
