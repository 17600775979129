import { Subscription } from '../Subscription';
import { SimpleOuterSubscriber, innerSubscribe, SimpleInnerSubscriber } from '../innerSubscribe';
export function bufferWhen(closingSelector) {
  return function (source) {
    return source.lift(new BufferWhenOperator(closingSelector));
  };
}
class BufferWhenOperator {
  constructor(closingSelector) {
    this.closingSelector = closingSelector;
  }
  call(subscriber, source) {
    return source.subscribe(new BufferWhenSubscriber(subscriber, this.closingSelector));
  }
}
class BufferWhenSubscriber extends SimpleOuterSubscriber {
  constructor(destination, closingSelector) {
    super(destination);
    this.closingSelector = closingSelector;
    this.subscribing = false;
    this.openBuffer();
  }
  _next(value) {
    this.buffer.push(value);
  }
  _complete() {
    const buffer = this.buffer;
    if (buffer) {
      this.destination.next(buffer);
    }
    super._complete();
  }
  _unsubscribe() {
    this.buffer = undefined;
    this.subscribing = false;
  }
  notifyNext() {
    this.openBuffer();
  }
  notifyComplete() {
    if (this.subscribing) {
      this.complete();
    } else {
      this.openBuffer();
    }
  }
  openBuffer() {
    let {
      closingSubscription
    } = this;
    if (closingSubscription) {
      this.remove(closingSubscription);
      closingSubscription.unsubscribe();
    }
    const buffer = this.buffer;
    if (this.buffer) {
      this.destination.next(buffer);
    }
    this.buffer = [];
    let closingNotifier;
    try {
      const {
        closingSelector
      } = this;
      closingNotifier = closingSelector();
    } catch (err) {
      return this.error(err);
    }
    closingSubscription = new Subscription();
    this.closingSubscription = closingSubscription;
    this.add(closingSubscription);
    this.subscribing = true;
    closingSubscription.add(innerSubscribe(closingNotifier, new SimpleInnerSubscriber(this)));
    this.subscribing = false;
  }
}
