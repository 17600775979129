<div class="side-menu-page-container">

  <div class="side-menu-page-menu-container flex-auto flex-col">
    <div class="side-menu-page-menu-header flex basis-[5%] flex-row">
      <div class="side-menu-page-menu-title flex justify-start items-center content-center">
        <h2>Categories</h2>
      </div>
    </div>

    <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true">
      @for (option of menuOptions; track option) {
        <mat-list-option [value]="option" [selected]="selectedOption.ID == option.ID" (click)="onSelectedConfigOption(option)">
          <div class="flex flex-row">
            @if (option.Icon) {
              <div class="flex basis-[15%] justify-start items-center content-center">
                @if (!option.IconOutlined) {
                  <mat-icon>{{option.Icon}}</mat-icon>
                }
                @if (option.IconOutlined) {
                  <mat-icon fontSet="material-icons-outlined">{{option.Icon}}</mat-icon>
                }
              </div>
            }
            <div class="flex-auto justify-start items-center content-center pr-[5px]">
              {{option.Name}}
            </div>
          </div>
        </mat-list-option>
      }
    </mat-selection-list>
  </div>

  <!--Report Lists-->
  @if (!selectedReport) {
    <app-all-reports class="side-menu-page-content-container flex flex-col"
      [reportMenuOption]="selectedOption"
      (reportClicked)="onReportClicked($event)"
    ></app-all-reports>
  }

  <!--Report Generator-->
  @if (selectedReport) {
    <app-reports-generation class="side-menu-page-content-container flex flex-col" [reportName]="selectedReport"></app-reports-generation>
  }

</div>
