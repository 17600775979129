import { Component, OnInit, ViewChild } from '@angular/core';
import { RefreshBatchQuery } from '../../../models/refresh-batch-query';
import { Subscription } from 'rxjs';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { SchedulesService } from '../../../services/settings/schedules.service';
import { SettingsKeys, SettingsService } from '../../../services/settings/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { QueryMetadata, QuerySort } from '../../../models/query-metadata';
import { AgPaginatorComponent, IAgPaginator } from '../../ag-grid-components/ag-paginator/ag-paginator.component';
import { ColDef, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, IServerSideDatasource, StatusPanelDef } from 'ag-grid-enterprise';
import { RefreshBatchItem } from '../../../models/refresh-batch-item';
import { Clipboard } from '@angular/cdk/clipboard';
import { formatDate, formatNumber } from '@angular/common';
import { AgNoRowsOverlayComponent } from '../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { DataRefreshErrorDetailsDialogComponent, DataRefreshErrorDetailsDialogModel } from '../../../dialogs/data-refresh-error-details-dialog/data-refresh-error-details-dialog.component';
import { AgLoadingOverlayComponent } from '../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

@Component({
    selector: 'app-data-refresh-config',
    templateUrl: './data-refresh-config.component.html',
    styleUrls: ['./data-refresh-config.component.scss'],
    standalone: true,
    imports: [AgGridModule]
})
export class DataRefreshConfigComponent implements OnInit {

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgPaginatorComponent,
        key: 'ag-paginator'
      }
    ],
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [
    { field: 'RefreshBatchID', headerName: 'Refresh ID'},
    { field: 'DateOfFresh', headerName: 'Refresh Date/Time', type: 'numericColumn', valueFormatter: this.getDateTimeValue },
    { field: 'TargetYear', headerName: 'PaymentYear', type: 'numericColumn'},
    { field: 'DXEventCount', headerName: 'DX Event Count', type: 'numericColumn', valueFormatter: this.numberValue },
    { field: 'UnmappedPayerCount', headerName: 'Unmapped Payer Transactions', type: 'numericColumn', valueFormatter: this.numberValue },
    { field: 'ErrorCount', headerName: 'Error Count', type: 'numericColumn', valueFormatter: this.numberValue },
    { colId: 'ErrorDetails', headerName: 'Error Details',
      type: 'numericColumn', 
      suppressColumnsToolPanel: true,
      cellStyle: { cursor: 'pointer' },
      valueFormatter: params => {
        return params.data.ErrorCount > 0 ? 'View Details' : ''
      },
      onCellClicked: this.onErrorColumnClick
    }
    //{ field: 'RefreshedBy', headerName: 'RefreshedBy', valueFormatter: this.userValue, tooltipValueGetter: this.userTooltip },
  ];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  gridContext: any;
  currentListSize = 0;
  isFirstGenerate = true;
  editAllActive = true;

  isGridReady = false;
  isServiceYearReady = false;

  query: RefreshBatchQuery = new RefreshBatchQuery();

  subs: Subscription[] = [];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(
    private schedulesService: SchedulesService,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private clipboard: Clipboard
  ) { 

    this.gridContext = { componentParent: this };
    this.query.Metadata = new QueryMetadata();
  }

  ngOnInit(): void {
    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      //this.query.TargetYear = result;
      this.isServiceYearReady = true;
      this.onReadyCheck();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onReadyCheck() {
    if(!this.isGridReady) {
      return;
    }

    if(!this.isServiceYearReady) {
      return;
    }

    this.onGenerateGridData();
  }

  onGridReady() {

    this.isGridReady = true;

    this.onReadyCheck();

    this.settingsService.getPaginatorPageSize(SettingsKeys.DATA_REFRESH_LOG_PAGE_SIZE).subscribe(result => {
      if(result == 0) {
        return;
      }

      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
    });
  }


  //#region AG Grid Functions

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {

        this.query.TargetYear = 0;
        this.query.Metadata.PageSize = params.api.paginationGetPageSize();
        this.query.Metadata.PageNumber = params.api.paginationGetCurrentPage();

        const sortModel = params.request.sortModel;

        if(sortModel.length > 0) {
          this.query.Metadata.Sort = new QuerySort();
          this.query.Metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : null;
          this.query.Metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : null;
        }


        this.schedulesService.getRefreshBatchItems(this.query).subscribe(result => {
          this.currentListSize = result.ListSize;
          params.success({
            rowData: result.Items,
            rowCount: result.ListSize,
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          params.api.sizeColumnsToFit();
        },
        error => {
          params.fail();
        });

      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.RefreshBatchID;
  };

  onGenerateGridData() {

    if(this.isFirstGenerate || this.currentListSize == 0) {
      this.agGrid.api.setGridOption('serverSideDatasource', this.getServerSideDatasource());
      this.isFirstGenerate = false;
      return;
    }
    
    this.agGrid.api.refreshServerSide({purge: true});
    this.agGrid.api.deselectAll();
  }

  onGridGetContextMenu(params: GetContextMenuItemsParams) {

    if(params.column.getColId() == 'edit') {
      return [];
    }

    const result = [
      {
        name: 'Copy',
        action: () => {
          const value = params.context.componentParent.getColumnValue(params.column.getColId(), params.value);
          params.context.componentParent.clipboard.copy(value);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>'
      }
    ];

    return result;
  }

  onCellKeyDown(event) {
    const kbEvent:KeyboardEvent = event.event;
    if (kbEvent.ctrlKey && kbEvent.key === "c") {
      const value = this.getColumnValue(event.column.getColId(), event.value);
      this.clipboard.copy(value);
    }
  }

  onListItemEditClick(item: RefreshBatchItem) {
    //TODO
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.DATA_REFRESH_LOG_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }

  onErrorColumnClick(params) {
    if(!params.data || params.data.ErrorCount == 0) {
      return;
    }

    const dialogRef = params.context.componentParent.dialog.open(DataRefreshErrorDetailsDialogComponent, new DataRefreshErrorDetailsDialogModel(params.data));

    // params.context.componentParent.subs.push(dialogRef.afterClosed().subscribe(result => {
    //   //TODO
    // }));
  }

  //#endregion

  getColumnValue(fieldName: string, value: any) {
    if(!value) {
      return null;
    }

    let result = '';

    switch(fieldName) {

      //object cases here

      default:
        result = value;
        break;
    }

    return result;
  }

  getDateTimeValue(item: any) {
    if(!item || !item.value) {
      return;
    }

    return formatDate(item.value, "MM/dd/YYYY HH:mm:ss", "en");
  }

  userValue(item: any) {
    if(!item.value) {
      return '';
    }

    if(item.value.UserID == 0) {
      return '';
    }

    const firstName = item.value.FirstName;
    if(!firstName) {
      return '';
    }

    return firstName[0] + '. ' + item.value.LastName;
  }

  userTooltip(item: any) {
    if(!item.value) {
      return;
    }

    if(item.value.UserID == 0) {
      return;
    }


    return item.value.FirstName + ' ' + item.value.LastName;
  }

  numberValue(item: any) {
    if(!item.value) {
      return '0';
    }

    return formatNumber(item.value, 'en');
  }

}
