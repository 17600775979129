<div class="service-year-container flex flex-row items-center"
  #serviceYearMenuTrigger="matMenuTrigger"
  [matTooltip]="getTooltip()"
  [matTooltipClass]="'multi-line-tooltip'"
  [matMenuTriggerFor]="serviceYearMenu">

  <div class="lable-container flex items-center">
    <h3>Service Year: {{selectedYear}}</h3>
    @if (!serviceYearMenuTrigger.menuOpen) {
      <mat-icon>expand_more</mat-icon>
    }
    @if (serviceYearMenuTrigger.menuOpen) {
      <mat-icon>expand_less</mat-icon>
    }
  </div>

</div>

<mat-menu #serviceYearMenu="matMenu" class="service-year-menu-panel" xPosition="before">
  <div class="year-picker">
    @for (year of years; track year) {
      <button mat-menu-item (click)="onYearSelected(year)">
        {{year}}
      </button>
    }
  </div>
</mat-menu>
