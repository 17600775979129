"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var rxjs_1 = require("rxjs");
var internal_compatibility_1 = require("rxjs/internal-compatibility");
var operators_1 = require("rxjs/operators");
function windowTime(windowTimeSpan) {
  var scheduler = rxjs_1.asyncScheduler;
  var windowCreationInterval = null;
  var maxWindowSize = Number.POSITIVE_INFINITY;
  if (internal_compatibility_1.isScheduler(arguments[3])) {
    scheduler = arguments[3];
  }
  if (internal_compatibility_1.isScheduler(arguments[2])) {
    scheduler = arguments[2];
  } else if (internal_compatibility_1.isNumeric(arguments[2])) {
    maxWindowSize = Number(arguments[2]);
  }
  if (internal_compatibility_1.isScheduler(arguments[1])) {
    scheduler = arguments[1];
  } else if (internal_compatibility_1.isNumeric(arguments[1])) {
    windowCreationInterval = Number(arguments[1]);
  }
  return operators_1.windowTime(windowTimeSpan, windowCreationInterval, maxWindowSize, scheduler)(this);
}
exports.windowTime = windowTime;
