<h1 mat-dialog-title>Error Details</h1>

<div mat-dialog-content [overlayLoading]="loading">
  <div class="flex flex-auto flex-col">
    <mat-list>
      <h3>Batch Info</h3>
      <mat-list-item>Refresh Batch ID: <span style="padding-left: 10px"><strong>{{data.RefreshBatchID}}</strong></span></mat-list-item>
      <mat-list-item>Payment Year: <span style="padding-left: 10px"><strong>{{data.TargetYear}}</strong></span></mat-list-item>
      <mat-list-item>Refresh Date: <span style="padding-left: 10px"><strong>{{data.DateOfFresh}}</strong></span></mat-list-item>
      <mat-list-item>Total Error Count: <span style="padding-left: 10px"><strong>{{data.ErrorCount | number}}</strong></span></mat-list-item>
      <mat-divider></mat-divider>
      <h3>Reasons</h3>
      @if (items.length == 0) {
        <mat-list-item>No Reasons Found</mat-list-item>
      }
      @for (item of items; track item) {
        <mat-list-item>
          {{item.Reason}}: <span style="padding-left: 10px"><strong>{{item.ReasonCount | number}}</strong></span>
        </mat-list-item>
      }
    </mat-list>
  </div>
</div>

<mat-dialog-actions align="end">

  <button mat-flat-button class="primary-button" mat-dialog-close>OK</button>

</mat-dialog-actions>
