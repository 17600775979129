import { Component, OnInit } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-ag-loading-overlay',
    templateUrl: './ag-loading-overlay.component.html',
    styleUrls: ['./ag-loading-overlay.component.scss'],
    standalone: true,
    imports: [MatProgressSpinner]
})
export class AgLoadingOverlayComponent implements ILoadingOverlayAngularComp {
  params: ILoadingOverlayParams<any, any>;

  agInit(params: ILoadingOverlayParams<any, any>): void {
    this.params = params;
  }
}
