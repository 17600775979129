import { getState, patchState, signalStore, withMethods, withState } from "@ngrx/signals";
import { PayerConfigItem, PayerRiskAdjustmentPolicy } from "../models/payer-config-item"
import { PayerConfigService } from "../services/payer/payer-config.service";
import { inject } from "@angular/core";
import { PayerConfigQuery } from "../models/payer-config-query";
import { PayerConfigQueryResult } from "../models/payer-config-query-result";
import { map } from "rxjs/operators";
import { PayerMapFileSubTypesResult } from "../models/payer-map-file-sub-types-result";

type PayerConfigState = {
    currentPayerItem: PayerConfigItem;
    riskAdjustmentPolicies: PayerRiskAdjustmentPolicy[];
    payerMapFileSubTypes: PayerMapFileSubTypesResult[];
}

const initialState: PayerConfigState = {
    currentPayerItem: new PayerConfigItem(),
    riskAdjustmentPolicies: [],
    payerMapFileSubTypes: [],
}

export const PayerConfigStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((store, configService = inject(PayerConfigService)) => ({
        setCurrentPayerItem(input: PayerConfigItem) {
            return new Promise<void>((resolve) => {
                patchState(store, { currentPayerItem: input });
                resolve();
            });
        },
        setCurrentPayerItemById(id: number) {
            const query = new PayerConfigQuery();
            query.PayerIDs = id + "";
            return configService.getPayerConfigs(query).pipe(
                map((result: PayerConfigQueryResult) => {
                    patchState(store, { currentPayerItem: result.Items[0] });
                })
            )
        },
        setRiskAdjustmentPolicies(): boolean | void {
            const state = getState(store);
            try {
                configService.getPayerRiskPolicies(state.currentPayerItem.PayerID).subscribe((result) => {
                    patchState(store, { riskAdjustmentPolicies: result });
                    if (result.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                });
            } catch (error) {
                throw new Error(`Error getting Risk Adjustment Policy list for payer: ${error}`);
            }
        },
        setMapFileSubTypes(payerId?: number) {
            const statePayerId = getState(store).currentPayerItem.PayerID;
            return new Promise<void>((resolve) => {
                if (!payerId && statePayerId < 1) {
                    resolve();
                    return;
                }
                configService.getMapFileSubTypesByPayer(payerId ?? statePayerId)
                .subscribe((results) => {
                    const currentSubTypes = getState(store).payerMapFileSubTypes ?? [];
                    patchState(store, { payerMapFileSubTypes: [...currentSubTypes, ...results] });
                    resolve();
                });
            });
        },
        clearMapFileSubTypes() {
            patchState(store, { payerMapFileSubTypes: [] });
        },
        clearState() {
            patchState(store, initialState);
        },
    })),
);