import { Component, OnInit } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-enterprise';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';

export interface IAgExportButton {
  updateExportDisabled(value: boolean): void;
  updateExportActive(value: boolean): void;
}

@Component({
    selector: 'app-ag-export-button',
    templateUrl: './ag-export-button.component.html',
    styleUrls: ['./ag-export-button.component.scss'],
    standalone: true,
    imports: [MatButton, MatTooltip]
})
export class AgExportButtonComponent implements IStatusPanelAngularComp {

  params: IStatusPanelParams<any, any>;
  exportDisabled = true;
  exportActive = false;
  exportTooltip = '';

  agInit(params: IStatusPanelParams<any, any>): void {
    this.params = params;
    this.exportTooltip = this.params.context.componentParent.exportTooltip;
  }

  updateExportDisabled(value: boolean) {
    this.exportDisabled = value;
  }

  updateExportActive(value: boolean) {
    this.exportActive = value;
  }

  onExportClick() {
    this.params.context.componentParent.onExportClick();
  }
}
