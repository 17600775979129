<div class="progress-allocation-pa-container">

  <div class="paa-header-row grid grid-cols-5">
    <div class="justify-start items-center content-center"></div>

    <div class="progress-cell flex grow justify-end items-center content-center">
      <h3>Progress</h3>
    </div>

    @if (expanded) {
      <div class="closed-cell flex grow justify-end items-center content-center">
        <h3>Closed</h3>
      </div>
    }

    @if (expanded) {
      <div class="open-cell flex grow justify-end items-center content-center">
        <h3>Open</h3>
      </div>
    }

    <div class="total-cell flex grow justify-end items-center content-center">
      <h3>Total</h3>
    </div>
  </div>

  @for (item of actionItems; track item) {
    <div class="paa-list-item grid grid-cols-5">
      <div
        class="progress-bar-cell flex flex-col grow justify-start items-start content-start"
        [routerLink]="['/opportunity-list', {qfilter: item.Name}]">
        <h3>{{getLabel(item.Name)}}</h3>
        <mat-progress-bar [value]="item.Progress * 100"></mat-progress-bar>
      </div>
      <div class="progress-cell flex grow justify-end items-center content-center">
        <h3>{{item.Progress | percent}}</h3>
      </div>
      @if (expanded) {
        <div class="closed-cell flex grow justify-end items-center content-center">
          <h3>{{item.Closed | number}}</h3>
        </div>
      }
      @if (expanded) {
        <div class="open-cell flex grow justify-end items-center content-center">
          <h3>{{item.Open | number}}</h3>
        </div>
      }
      <div class="total-cell flex grow justify-end items-center content-center">
        <h3>{{item.Total | number}}</h3>
      </div>
    </div>
  }

  <div class="paa-footer-row grid grid-cols-5">
    <div class="name-cell flex grow justify-start items-center content-center">
      <h3>Total</h3>
    </div>

    <div class="progress-cell flex grow justify-end items-center content-center"></div>

    @if (expanded) {
      <div class="closed-cell flex grow justify-end items-center content-center">
        <h3>{{closedTotal | number}}</h3>
      </div>
    }

    @if (expanded) {
      <div class="open-cell flex grow justify-end items-center content-center">
        <h3>{{openTotal | number}}</h3>
      </div>
    }

    <div class="assigned-cell flex grow justify-end items-center content-center">
      <h3>{{grandTotal | number}}</h3>
    </div>
  </div>
</div>