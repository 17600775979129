import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PayerConfigQueryResult } from '../../models/payer-config-query-result';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PayerConfigQuery } from '../../models/payer-config-query';
import { PayerAliasItem } from '../../models/payer-alias-item';
import { UpdatePayerRequest } from '../../models/update-payer-request';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { AddUpdatePayerRiskAdjustmentPoliciesRequest } from '../../models/add-update-payer-risk-adjustment-policies-request';
import { PayerRiskAdjustmentPolicy, PayerRiskAdjustmentPolicyUpdate } from '../../models/payer-config-item';
import { PayerMapFileSubTypesResult } from '../../models/payer-map-file-sub-types-result';

@Injectable({
  providedIn: 'root'
})
export class PayerConfigService {

  private endpoint = `${environment.baseURL}/api/`;

  constructor(private http: HttpClient) { 
    //TODO
  }

  getPayerConfigs(query: PayerConfigQuery) {
    return this.http.post<PayerConfigQueryResult>(this.endpoint + "payer/config-list", query, ServiceFormatter.getHttpOptions());
  }

  getOrgPayerAliases() {
    return this.http.get<PayerAliasItem[]>(this.endpoint + "payer/org-aliases", ServiceFormatter.getHttpOptions());
  }

  addUpdatePayer(request: UpdatePayerRequest) {
    return this.http.post(this.endpoint + "payer/add-update", request, ServiceFormatter.getHttpOptions());
  }

  getPayerRiskPolicies(payerID: number) {
    const params = new HttpParams().set("payerID", payerID)
    return this.http.get<PayerRiskAdjustmentPolicy[]>(this.endpoint + "payer/risk-adjustment-policy", ServiceFormatter.getHttpOptions(params));
  }

  addUpdatePayerRiskAdjustmentPolicies(request: AddUpdatePayerRiskAdjustmentPoliciesRequest) {
    return this.http.post(this.endpoint + "payer/add-update-risk-policies", request, ServiceFormatter.getHttpOptions());
  }

  getMapFileSubTypesByPayer(payerId: number) {
    const params: HttpParams = new HttpParams()
        .set("payerId", payerId);
    return this.http.get<any>(this.endpoint + "payer/get-payer-maps-list", ServiceFormatter.getHttpOptions(params));
  }
}
