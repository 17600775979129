import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HccService } from '../../../../../services/hcc/hcc.service';
import { AverageHCCPerPatientItem, HccPerPatientTrendItem } from '../../../../../models/hcc-per-patient-trend-item';
import { Subscription } from 'rxjs';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { ColDef, ColGroupDef, RowClassRules } from 'ag-grid-enterprise';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { UtilityFunctions } from '../../../../../helpers/utility-functions';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AuditAction } from '../../../../../models/audit-action';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'app-average-hcc-per-patient-trend-report',
    templateUrl: './average-hcc-per-patient-trend-report.component.html',
    styleUrls: ['./average-hcc-per-patient-trend-report.component.scss'],
    standalone: true,
    imports: [NgStyle, AgGridModule]
})
export class AverageHccPerPatientTrendReportComponent implements OnInit, OnDestroy {
  defaultColDef: ColDef = {
    menuTabs: [],
    minWidth: 150,
    maxWidth: 150
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  public rowClassRules: RowClassRules = {
    // row style function
    'parent-cell': (params) => {
      return params.node.group;
    },
  };

  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'ServiceYear', headerName: 'Service Year', rowGroup: true, hide: true,
    },
    { 
      field: 'HCCCount', headerName: 'HCC Count', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValueNullZero,
      cellClass: 'numberType'
    },
    { 
      field: 'PatientCount', headerName: 'Patient Count', type: 'numericColumn', aggFunc: 'sum', valueFormatter: AgGridFunctions.getNumberValue,
      cellClass: 'numberType'
    }
  ];

  public averageColumnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'ServiceYear', headerName: 'Service Year'
    },
    { 
      field: 'AverageHCCs', headerName: 'Avg HCC/Patient', headerTooltip: 'Average HCC Per Patient', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue,
      cellClass: 'numberType'
    },
  ];

  autoColumnGroupDef = {
    headerName: 'Service Year',
    cellRendererParams: {
      suppressCount: true
    }
  }

  gridContext: any;
  hccGridWidth = 282;
  hccGridHeight = 140;
  averageGridWidth = 302;
  averageGridHeight = 140;
  defaultGridHeight = 66;
  rowMultiplier = 29;

  parentDiv = document.getElementById('snav');
  chartsColorPrimary = '';
  chartsColor2 = '';

  hccGridReady = false;
  averageGridReady = false;

  items: HccPerPatientTrendItem[] = [];
  averageItems: AverageHCCPerPatientItem[] = [];
  startYear = 0;
  endYear = 0;

  subs: Subscription[] = [];

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('hccGrid') hccGrid!: AgGridAngular;
  @ViewChild('averageGrid') averageGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGridColumnsToFit();
  }

  constructor(private hccService: HccService, private loggingService: LoggingService) { 
    this.gridContext = { componentParent: this };
    this.chartsColorPrimary = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-1');
    this.chartsColor2 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-2');
  }

  ngOnInit(): void {
    this.subs.push(this.hccService.hccPerPatientTrend.subscribe(result => {
      this.items = result;
      this.hccGridHeight = this.defaultGridHeight + ((this.items.length) * this.rowMultiplier) + 10;
      this.hccGridWidth = 452;
      this.hccGrid?.api.hideOverlay();
    }));

    this.subs.push(this.hccService.averageHccPerPatient.subscribe(result => {
      this.averageItems = result;
      this.averageGridHeight = this.defaultGridHeight + ((this.averageItems.length) * this.rowMultiplier);
      this.averageGridWidth = 302;
      this.averageGrid?.api.hideOverlay();
      this.setExportEnable.emit(true);
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this.hccService.hccPerPatientTrend.next([]);
    this.hccService.averageHccPerPatient.next([]);
  }

  onGridReady(event) {
    if(!this.hccGridReady) {
      return;
    }

    if(!this.averageGridReady) {
      return;
    }

    this.ready.emit(true);
  }

  updateServiceYearRange(startYear: number, endYear: number) {
    this.startYear = startYear;
    this.endYear = endYear;
    this.hccService.getHccPerPatientTrend(startYear, endYear);
    this.hccService.getAverageHccPerPatientTrend(startYear, endYear);
    this.toggleLoading(true);
  }

  setGridColumnsToFit() {
    if(!document.getElementById('hccGrid')) {
      return;
    }

    if(!document.getElementById('averageGrid')) {
      return;
    }

    this.hccGrid?.api.sizeColumnsToFit();
    this.averageGrid?.api.sizeColumnsToFit();
  }

  toggleLoading(value: boolean) {
    value ? this.hccGrid?.api.showLoadingOverlay() : this.hccGrid?.api.hideOverlay();
    value ? this.averageGrid?.api.showLoadingOverlay() : this.averageGrid?.api.hideOverlay();
  }

  onExport() {
    const sheets = [
      this.hccGrid.api.getSheetDataForExcel(
        {
          sheetName: "HCC Count Per Patient Trend",
        }
      ),
      this.averageGrid.api.getSheetDataForExcel(
        {
          sheetName: "Average HCC Per Patient"
        }
      )
    ];

    const filename =`HCC_Count_Per_Patient_Trend_${this.startYear}_${this.endYear}`;

    this.hccGrid.api.exportMultipleSheetsAsExcel({
      data: sheets,
      fileName: filename,
    });

    const reportJSON = JSON.stringify({ReportName: 'HCC Count Per Patient Trend', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: HCC Count Per Patient Trend, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }

}
