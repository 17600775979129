<mat-icon [matMenuTriggerFor]="actionItemMenu">more_horiz</mat-icon>

<mat-menu #actionItemMenu="matMenu">
  @if (currentUser.app_metadata.systemAdmin == 'true' || item.app_metadata.systemAdmin != 'true') {
    <button mat-menu-item
      (click)="onItemClick('Change Password')">
      <span>Change Password</span>
    </button>
  }

  @if ((currentUser.app_metadata.systemAdmin == 'true' && item.app_metadata.systemAdmin != 'true' && item.enabled == '1') || item.app_metadata.systemAdmin != 'true' && item.enabled == '1') {
    <button mat-menu-item
      (click)="onItemClick('Set User to Inactive')">
      <span>Set User to Inactive</span>
    </button>
  }

  @if ((currentUser.app_metadata.systemAdmin == 'true' && item.app_metadata.systemAdmin != 'true' && item.enabled == '0') || item.app_metadata.systemAdmin != 'true' && item.enabled == '0') {
    <button mat-menu-item
      (click)="onItemClick('Set User to Active')">
      <span>Set User to Active</span>
    </button>
  }

  @if (currentUser.app_metadata.systemAdmin == 'true' || item.app_metadata.systemAdmin != 'true') {
    <button mat-menu-item
      (click)="onItemClick('Edit User')">
      <span>Edit User</span>
    </button>
  }

  @if (item.app_metadata.role =='user' || item.app_metadata.role =='readonlyuser') {
    <button mat-menu-item
      (click)="onItemClick('Make Site Admin')">
      <span>Make Site Admin</span>
    </button>
  }

  @if (item.app_metadata.role =='admin' || item.app_metadata.role =='readonlyuser') {
    <button mat-menu-item
      (click)="onItemClick('Make Standard User')">
      <span>Make Standard User</span>
    </button>
  }

  @if (item.blocked == true) {
    <button mat-menu-item
      (click)="onItemClick('Unblock User')">
      <span>Unblock User</span>
    </button>
  }
</mat-menu>
