<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }
  <mat-select
    [multiple]="multiSelect"
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="tooltipPosition"
    [required]="required"
    (closed)="onSelectionChange()">
    @if (multiSelect) {
      <mat-select-trigger>
        @if ((control.value?.length || 0) === 1) {
          <span>
            {{control.value?.[0] || ''}}
          </span>
        }
        @if (selectAllEnabled && allToggled) {
          <span>
            All
          </span>
        }
      </mat-select-trigger>
    }

    @if (selectAllEnabled && multiSelect) {
      <mat-option
        #allItem
        [value]="allValue"
      (click)="onToggleItem(allItem)">All</mat-option>
    }

    <mat-option #trueItem [value]="trueValue" (click)="onToggleItem(trueItem)">
      {{trueValue}}
    </mat-option>

    <mat-option #falseItem [value]="falseValue" (click)="onToggleItem(falseItem)">
      {{falseValue}}
    </mat-option>
  </mat-select>

  @if (control.hasError('required')) {
    <mat-error>Selection is <strong>required</strong></mat-error>
  }
</mat-form-field>
