export class CmsScheduleItem {
    ID: number;
    OrgID: number;
    RiskScoreRunYear: number;
    RiskScoreRunType: number;
    RiskScoreRunTypeName: string;
    DatesOfServiceStart: string;
    DatesOfServiceEnd: string;
    DeadlineForSubmission: string;
    AnticipatedPaymentMonth: string;
}
