<mat-card appearance="outlined" class="vc-card mat-elevation-z5">
  <mat-card-header>
    <div class="title-container flex">
      <h2 class="basis-4/5">Dx Events Summary</h2>
      <div class="flex grow justify-end align-middle items-center">
        @if (reportingEnabled) {
          <span class="material-symbols-outlined report-icon"
            [routerLink]="['/reporting']" [queryParams]="{report: DIAGNOSIS_EVENTS_SUMMARY_NAME, year: serviceYear}"
            [matTooltip]="'Generate Report'"
            [matTooltipClass]="'app-tooltip'"
          [matTooltipPosition]="'above'">export_notes</span>
        }

        @if (!reportingEnabled) {
          <span class="material-symbols-outlined report-icon" aria-disabled="true"
            [matTooltip]="'Contact Admin for Access to Generate Report'"
            [matTooltipClass]="'app-tooltip'"
          [matTooltipPosition]="'above'">export_notes</span>
        }
      </div>
    </div>
  </mat-card-header>

  <mat-card-content class="vc-card-summary-grid">
    <mat-grid-list cols="6" rowHeight="40px" gutterSize="0">

      <mat-grid-tile class="header-tile" [colspan]="4">
        <div class="summary-expand-icon basis-[7%] md:basis-[10%] justify-start align-middle">
          @if (!expandInfoSwitch) {
            <mat-icon (click)="expandInfoSwitch = true">expand_more</mat-icon>
          }
          @if (expandInfoSwitch) {
            <mat-icon (click)="expandInfoSwitch = false">expand_less</mat-icon>
          }
        </div>

        <div class="toggle-container grow">
          <mat-slide-toggle color="accent" [(ngModel)]="summaryDxSwitch" [hideIcon]="true">
            <span>DX View</span>
          </mat-slide-toggle>
        </div>
      </mat-grid-tile>

      <mat-grid-tile class="header-tile" [colspan]="1">
        <div class="flex grow justify-end align-middle">
          @if (!summaryDxSwitch) {
            <h2>HCCs</h2>
          }
          @if (summaryDxSwitch) {
            <h2>DXs</h2>
          }
        </div>
      </mat-grid-tile>

      <mat-grid-tile class="header-tile">
        <div class="flex grow justify-end align-middle">
          <h2>%</h2>
        </div>
      </mat-grid-tile>

      <!--EMR Captured-->
      <mat-grid-tile [colspan]="3">
        <div class="flex grow justify-start align-middle">
          <h3>Documented Dx Events</h3>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="2">
        <div class="flex grow justify-end align-middle">
          @if (!summaryDxSwitch) {
            <h3>{{data.HCCDocumentedDiagnosisEvents | number}}</h3>
          }
          @if (summaryDxSwitch) {
            <h3>{{data.DxDocumentedDiagnosisEvents | number}}</h3>
          }
        </div>
      </mat-grid-tile>

      <mat-grid-tile></mat-grid-tile>

      <!--Post/Supp Feed-->
      <mat-grid-tile [colspan]="4">
        <div class="flex grow justify-start align-middle">
          <h3>Posted Via Claim or Supp Feed</h3>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1">
        <div class="flex grow justify-end align-middle">
          @if (!summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.HCCPostedViaClaimOrSuppFeed | number}}</p>
          }
          @if (summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.DxPostedViaClaimOrSuppFeed | number}}</p>
          }
        </div>
      </mat-grid-tile>

      <mat-grid-tile>
        <div class="flex grow justify-end align-middle">
          @if (!summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.HCCPostedViaClaimOrSuppFeedPercent) | percent}}</p>
          }
          @if (summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.DxPostedViaClaimOrSuppFeedPercent) | percent}}</p>
          }
        </div>
      </mat-grid-tile>

      <!--Post/Supp Feed Expanded-->
      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="4">
          <div class="flex grow justify-start align-middle">
            <p>Billing Charge Posted on Claim</p>
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="1">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{data.HCCPostedOnClaim | number}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{data.DxPostedOnClaim | number}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{getPercentValue(data.HCCPostedOnClaimPercent) | percent}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{getPercentValue(data.DxPostedOnClaimPercent) | percent}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="4">
          <div class="flex grow justify-start align-middle">
            <p>Billing Charge Posted on Supp Feed</p>
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="1">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{data.HCCPostedOnSuppFeed | number}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{data.DxPostedOnSuppFeed | number}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{getPercentValue(data.HCCPostedOnSuppFeedPercent) | percent}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{getPercentValue(data.DxPostedOnSuppFeedPercent) | percent}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      <!--Non-Actionable-->
      <mat-grid-tile [colspan]="4">
        <div class="flex grow justify-start align-middle">
          <h3>NonActionable</h3>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1">
        <div class="flex grow justify-end align-middle">
          @if (!summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.HCCNonActionable | number}}</p>
          }
          @if (summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.DxNonActionable | number}}</p>
          }
        </div>
      </mat-grid-tile>

      <mat-grid-tile>
        <div class="flex grow justify-end align-middle">
          @if (!summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.HCCNonActionablePercent) | percent}}</p>
          }
          @if (summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.DxNonActionablePercent) | percent}}</p>
          }
        </div>
      </mat-grid-tile>

      <!--Non-Actionable Expanded-->
      @if (expandInfoSwitch) {
        <div>
          @for (item of data.NonActionables; track item) {
            <div>
              <mat-grid-tile class="expanded-tile"  [colspan]="4">
                <div class="flex grow justify-start align-middle">
                  <p>{{item.RootCauseDescription}}</p>
                </div>
              </mat-grid-tile>
              <mat-grid-tile class="expanded-tile" [colspan]="1">
                <div class="flex grow justify-end align-middle">
                  @if (!summaryDxSwitch) {
                    <p>{{item.HCCCount | number}}</p>
                  }
                  @if (summaryDxSwitch) {
                    <p>{{item.DXCount | number}}</p>
                  }
                </div>
              </mat-grid-tile>
              <mat-grid-tile class="expanded-tile">
                <div class="flex grow justify-end align-middle">
                  @if (!summaryDxSwitch) {
                    <p>{{getPercentValue(item.HCCPercent) | percent}}</p>
                  }
                  @if (summaryDxSwitch) {
                    <p>{{getPercentValue(item.DXPercent) | percent}}</p>
                  }
                </div>
              </mat-grid-tile>
            </div>
          }
        </div>
      }

      <!--Raps/MAO or MOR-->
      <mat-grid-tile [colspan]="4">
        <div class="flex grow justify-start align-middle">
          <h3>On RAPs/MAO or MOR</h3>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1">
        <div class="flex grow justify-end align-middle">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.HCCOnRapsOrMOR | number}}</p>
            }
            @if (summaryDxSwitch) {
              <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.DxOnRapsOrMOR | number}}</p>
            }
          </div>
        </div>
      </mat-grid-tile>

      <mat-grid-tile>
        <div class="flex grow justify-end align-middle">
          @if (!summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.HCCOnRapsOrMORPercent) | percent}}</p>
          }
          @if (summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.DxOnRapsOrMORPercent) | percent}}</p>
          }
        </div>
      </mat-grid-tile>

      <!--Raps/MAO or MOR Expanded-->
      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="4">
          <div class="flex grow justify-start align-middle">
            <p>RAPs/MAO</p>
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="1">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{data.HCCOnRAPS | number}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{data.DxOnRAPS | number}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{getPercentValue(data.HCCOnRAPSPercent) | percent}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{getPercentValue(data.DxOnRAPSPercent) | percent}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="4">
          <div class="flex grow justify-start align-middle">
            <p>MOR</p>
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="1">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{data.HCCOnMOR | number}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{data.DxOnMOR| number}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{getPercentValue(data.HCCOnMORPercent) | percent}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{getPercentValue(data.DxOnMORPercent) | percent}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      <!--Posted Raps/MAO or MOR-->
      <mat-grid-tile [colspan]="4">
        <div class="flex grow justify-start align-middle">
          <h3>Posted and on RAPs/MAO or MOR</h3>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1">
        <div class="flex grow justify-end align-middle">
          @if (!summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.HCCPostedAndOnRapsOrMOR | number}}</p>
          }
          @if (summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.DxPostedAndOnRapsOrMOR | number}}</p>
          }
        </div>
      </mat-grid-tile>

      <mat-grid-tile>
        <div class="flex grow justify-end align-middle">
          @if (!summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.HCCPostedAndOnRapsOrMORPercent) | percent}}</p>
          }
          @if (summaryDxSwitch) {
            <p [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.DxPostedAndOnRapsOrMORPercent) | percent}}</p>
          }
        </div>
      </mat-grid-tile>

      <!--Posted Raps/MAO or MOR Expanded-->
      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="4">
          <div class="flex grow justify-start align-middle">
            <p>Posted: Not on RAPs/MAO or MOR</p>
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="1">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{data.HCCPostedNotOnRapsOrMOR | number}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{data.DxPostedNotOnRapsOrMOR | number}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{getPercentValue(data.HCCPostedNotOnRapsOrMORPercent) | percent}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{getPercentValue(data.DxPostedNotOnRapsOrMORPercent) | percent}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="4">
          <div class="flex grow justify-start align-middle">
            <p>Not Posted: On RAPs/MAO or MOR</p>
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile" [colspan]="1">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{data.HCCNotPostedButOnRapsOrMOR | number}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{data.DxNotPostedButOnRapsOrMOR | number}}</p>
            }
          </div>
        </mat-grid-tile>
      }

      @if (expandInfoSwitch) {
        <mat-grid-tile class="expanded-tile">
          <div class="flex grow justify-end align-middle">
            @if (!summaryDxSwitch) {
              <p>{{getPercentValue(data.HCCNotPostedButOnRapsOrMORPercent) | percent}}</p>
            }
            @if (summaryDxSwitch) {
              <p>{{getPercentValue(data.DxNotPostedButOnRapsOrMORPercent) | percent}}</p>
            }
          </div>
        </mat-grid-tile>
      }
    </mat-grid-list>

    @if (data.DateUpdated) {
      <label class="data-refresh-label">Data Refreshed {{data.DateUpdated | date: 'MM/dd/YYYY'}}</label>
    }
  </mat-card-content>
</mat-card>
