import { Component, inject, OnDestroy } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MorDataStore } from '../../../store/mor-data.store';
import { MorDataTableRow } from '../../sub-components/mor-data/mor-data-table/mor-data-table.component';
import { MatDialog } from '@angular/material/dialog';
import { MorDataViewDetailsDialogComponent, MorDataViewDetailsDialogModel } from '../../../dialogs/mor-data-view-details-dialog/mor-data-view-details-dialog.component';
import { Subscription } from 'rxjs';
import { LoadingDialogComponent } from '../../../dialogs/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-view-icon-renderer',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltipModule,
  ],
  providers: [],
  templateUrl: './view-icon-renderer.component.html',
  styleUrl: './view-icon-renderer.component.scss'
})
export class ViewIconRendererComponent implements ICellRendererAngularComp, OnDestroy {
  isDisabled: boolean = false;
  readonly morDataStore = inject(MorDataStore);
  private dialog = inject(MatDialog);
  private params: ICellRendererParams<any, any>;
  private subs: Subscription[] = [];

  constructor() {}

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    this.isDisabled = 
      params.data?.status.toLowerCase() != 'success' &&
      params.data?.status.toLowerCase() != 'failed';
  }

  refresh(params: ICellRendererParams<any, any>) {
    this.params = params;
    return true;
  }

  onClick() {
    const spinner = this.dialog.open(
      LoadingDialogComponent, 
      {
        disableClose: true,
        panelClass: 'custom-spinner-dialog'
      }
    );
    this.morDataStore.getSelectedFileDetails(this.params.data as MorDataTableRow)
      .then((details) => {
        if (details) {
          spinner.close();
          const dialogRef = this.dialog.open(MorDataViewDetailsDialogComponent, new MorDataViewDetailsDialogModel(details));

          this.subs.push(dialogRef.afterClosed().subscribe());
        }
      })
      .catch((error) => {
        spinner.close();
        console.error('Error retrieving file details:', error);
      });
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
