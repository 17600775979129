<div class="flex basis-[7%] flex-row">
  <div class="flex basis-3/4 justify-start items-center content-center">
    <h1>Payer Configuration</h1>
  </div>

  <div class="flex basis-1/4 flex-row justify-end items-center content-center gap-3">
    <button mat-flat-button class="primary-button-longtext"
      [matTooltip]="'Add Payer'"
      [matTooltipClass]="'app-tooltip'"
    (click)="onAddPayerClick()">+ Add Payer</button>

    <div>
      <mat-chip-listbox #chipFilters>
        @for (chip of filterChips; track chip) {
          <mat-chip-option
            [value]="chip.Name"
            [selected]="chip.Selected"
            [class.mat-chip-selected]="chip.Selected"
            (click)="onChipFilterChange(chip)">
            {{chip.Name}}
          </mat-chip-option>
        }
      </mat-chip-listbox>
    </div>
  </div>
</div>

<div class="filter-section mat-elevation-z3 grid grid-cols-5 gap-3 p-3">

  <div>
    <app-payer-select
      [form]="form"
      [appearance]="'outline'"
      [queryOptions]="true"
      (ready)="isPayerReady = true; filtersReadyCheck();"
    (valueChanged)="onQueryFilterChange($event)"></app-payer-select>
  </div>

  <div>
    <app-boolean-select
      #configStatus
      [form]="form"
      [controlName]="'config-status'"
      [appearance]="'outline'"
      [label]="'Status'"
      [trueValue]="'Inactive'"
      [falseValue]="'Active'"
      [multiSelect]="true"
      [selectAllEnabled]="true"
      (ready)="isStatusReady = true; filtersReadyCheck();"
    (valueChanged)="onQueryFilterChange($event)"></app-boolean-select>
  </div>

  <div>
    <app-boolean-select
      #configRiskPolicy
      [form]="form"
      [controlName]="'config-risk-policy'"
      [label]="'Risk Adj. Policy'"
      [appearance]="'outline'"
      [multiSelect]="true"
      [selectAllEnabled]="true"
      (ready)="isRiskAdjReady = true; filtersReadyCheck();"
    (valueChanged)="onQueryFilterChange($event)"></app-boolean-select>
  </div>

  <div>
    <app-boolean-select
      #configOmitPayer
      [form]="form"
      [controlName]="'config-omit-payer'"
      [label]="'Omit Payer'"
      [appearance]="'outline'"
      [multiSelect]="true"
      [selectAllEnabled]="true"
      (ready)="isOmitPayerReady = true; filtersReadyCheck();"
    (valueChanged)="onQueryFilterChange($event)"></app-boolean-select>
  </div>

  <div>
    <app-preferred-action-select
      [form]="form"
      [controlName]="'config-preferred-action'"
      [label]="'Preferred Action Submission Type'"
      [appearance]="'outline'"
      [onlyShowOptions]="['99499', 'Supplemental Feed']"
      (ready)="onPreferredActionReady()"
    (valueChanged)="onQueryFilterChange($event)"></app-preferred-action-select>
  </div>

  <div>
    <app-payment-year-select
      #startYear
      [form]="form"
      [controlName]="'service-year-start'"
      [label]="'Start Service Year'"
      [appearance]="'outline'"
      [minYear]="2021"
      [maxYearAddition]="2"
      (ready)="onServiceYearReady()"
    (valueChanged)="onServiceYearChanged($event)"></app-payment-year-select>
  </div>

  <div>
    <app-payment-year-select
      #endYear
      [form]="form"
      [controlName]="'service-year-end'"
      [label]="'End Service Year'"
      [appearance]="'outline'"
      [minYear]="2021"
      [maxYearAddition]="2"
      (ready)="onComparisonYearReady()"
    (defaultValueChanged)="onComparisonYearDefaultChange($event)"></app-payment-year-select>
  </div>

  <div class="col-start-5 justify-self-end items-end content-end pb-3">
    <button mat-flat-button class="primary-button"
      [disabled]="!formValid"
      [matTooltip]="'Search Payer Configs'"
      [matTooltipClass]="'app-tooltip'"
    (click)="onGenerateGridData()">Search</button>
  </div>

</div>

<div class="grid-section flex flex-auto">
  <!-- AG Grid Angular Component -->
  <ag-grid-angular
    class="ag-theme-balham w-full h-full"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowModelType]="'serverSide'"
    [getRowId]="getRowId"
    [pagination]="true"
    [tooltipShowDelay]="0"
    [components]="frameworkComps"
    [statusBar]="statusBar"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [context]="gridContext"
    [getContextMenuItems]="onGridGetContextMenu"
    [suppressCopyRowsToClipboard]="true"
    [suppressPaginationPanel]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    (gridReady)="onGridReady()"
    (firstDataRendered)="onFirstDataRendered($event)"
  (cellKeyDown)="onCellKeyDown($event)"></ag-grid-angular>
</div>