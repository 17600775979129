import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProgressAllocationService } from '../../../../../services/progress/progress-allocation.service';
import { LoggingService } from '../../../../../services/api/logging.service';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { ProgressAllocationSnapshotReportOptions } from '../../reports/progress-allocation-snapshot-report/progress-allocation-snapshot-report.component';
import { AgCartesianChartOptions, AgChartInstance, AgCharts } from 'ag-charts-community';
import { UtilityFunctions } from '../../../../../helpers/utility-functions';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { AuditAction } from '../../../../../models/audit-action';
import { takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCard, MatCardHeader, MatCardContent } from '@angular/material/card';
import { MatTooltip } from '@angular/material/tooltip';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-progress-allocation-snapshot-graphs',
    templateUrl: './progress-allocation-snapshot-graphs.component.html',
    styleUrls: ['./progress-allocation-snapshot-graphs.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatOption, NgxMatSelectSearchModule, MatCard, MatCardHeader, MatTooltip, MatCardContent, AgChartsAngularModule, AsyncPipe]
})
export class ProgressAllocationSnapshotGraphsComponent implements OnInit, OnDestroy {
  
  @Input() options: ProgressAllocationSnapshotReportOptions = {mode: "user"};

  protected _onDestroy = new Subject<void>();

  data: any[] = [];
  filterOptions: string[] = [];
  filterOptionsBySearch: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  filterLabel = 'Filter';

  graphOptionsList: AgCartesianChartOptions[] = [];

  parentDiv = document.getElementById('snav');
  chartsColor1 = '';
  chartsColor2 = '';
  chartsColor3 = '';
  chartsColor4 = '';
  chartsColor5 = '';
  accentColor = '';

  filterControl: FormControl<string[]> = new FormControl<string[]>([]);
  filterSearchControl: FormControl = new FormControl('');

  indeterminateToggle = false;
  allToggled = false;

  subs: Subscription[] = [];

  @ViewChild(MatSelect) select: MatSelect;

  constructor(
    private allocationService: ProgressAllocationService,
    private loggingService: LoggingService) {
      this.chartsColor1 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-1');
      this.chartsColor2 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-2');
      this.chartsColor3 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-3');
      this.chartsColor4 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-4');
      this.chartsColor5 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-5');
      this.accentColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-accent');
  }

  ngOnInit(): void {
    switch(this.options.mode) {
      case 'user':
        this.connectUser();
        this.filterLabel = 'User'
        break;

      case 'preferred-action':
        this.connectPreferredActions();
        this.filterLabel = 'Preferred Action';
        break;

      case 'payer':
        this.connectPayer();
        this.filterLabel = 'Payer';
        break;

      default:
        break;
    }

    this.filterSearchControl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterMulti();
      this.setAllToggleState();
    });

    this.filterControl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.setAllToggleState();
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  connectUser() {
    this.subs.push(this.allocationService.userAllocation.subscribe(result => {
      this.data = result || [];
      this.filterControl.reset();
      const users = this.data.map(d => {return d.User.FirstName + ' ' + d.User.LastName});
      this.filterOptions = [...new Set(users)].filter(f => f != "Grand Total");
      this.filterOptionsBySearch.next(this.filterOptions);
      this.setUserChartOptions();
    }));
  }

  connectPreferredActions() {
    this.subs.push(this.allocationService.preferredActions.subscribe(result => {
      this.data = result || [];
      this.filterControl.reset();
      this.filterOptions = UtilityFunctions.distinct(this.data, "Name").map(i => i.Name).filter(f => f != "Grand Total");
      this.filterControl = new FormControl(this.filterOptions);
      this.filterOptionsBySearch.next(this.filterOptions);
      this.setPreferredActionChartOptions();
    }));
  }

  connectPayer() {
    this.subs.push(this.allocationService.payerAllocation.subscribe(result => {
      this.data = result || [];
      this.filterControl.reset();
      const payers = this.data.map(d => {return d.Payer.Abbreviation});
      this.filterOptions = [...new Set(payers)].filter(f => f != "Grand Total");
      this.filterOptionsBySearch.next(this.filterOptions);
      this.setPayerChartOptions();
    }));
  }

  setPreferredActionChartOptions() {
    this.graphOptionsList = [];

    const values = JSON.parse(JSON.stringify(this.filterControl.value || []));
    values.push("Grand Total");

    values.forEach(v => {
      const datasubset = this.data.filter(d => d.Name == v);

      this.graphOptionsList.push({
        autoSize: true,
        title: {text: `${v} Progress`, fontSize: 12, enabled: true},
        data: datasubset,
        series: [
          {
            type: 'bar',
            fill: this.chartsColor1,
            stroke: this.chartsColor1,
            xKey: 'SnapshotDate',
            yKey: `Closed`,
            yName: 'Closed',
            tooltip: {
              renderer: AgGridFunctions.graphTooltipFn
            },
            stacked: true
          },
          {
            type: 'bar',
            fill: this.chartsColor2,
            stroke: this.chartsColor2,
            xKey: 'SnapshotDate',
            yKey: `Open`,
            yName: 'Open',
            tooltip: {
              renderer: AgGridFunctions.graphTooltipFn
            },
            stacked: true
          }
        ],
        theme: {
          overrides: {
            bar: {
              series: {
                highlightStyle: {
                  item: {
                    fill: this.accentColor,
                    stroke: this.chartsColor1,
                    strokeWidth: 3
                  }
                }
              },
              axes: {
                category: {
                  groupPaddingInner: 0.2
                }
              }
            }
          }
        },
        axes: [
          {
            type: 'category',
            position: 'bottom',
            title: {
              text: ''
            }
          },
          {
            type: 'number',
            position: 'left',
            title: {
              text: ''
            },
            label: {
              format: '~s',
              formatter: (params) => params.formatter(params.value).replace('0y', '0')
            },
            min: 0
          }
        ],
        legend: {
          position: 'bottom'
        }
      })
    })
  }

  setUserChartOptions() {
    this.graphOptionsList = [];

    const values = JSON.parse(JSON.stringify(this.filterControl.value || []));
    values.push("Grand Total");

    values.forEach(v => {
      const datasubset = this.data.filter(d => d.User.FirstName + ' ' + d.User.LastName == v);

      this.graphOptionsList.push({
        autoSize: true,
        title: {text: `${v} Progress`, fontSize: 12, enabled: true},
        data: datasubset,
        series: [
          {
            type: 'bar',
            fill: this.chartsColor1,
            stroke: this.chartsColor1,
            xKey: 'SnapshotDate',
            yKey: `Closed`,
            yName: 'Closed',
            tooltip: {
              renderer: AgGridFunctions.graphTooltipFn
            },
            stacked: true
          },
          {
            type: 'bar',
            fill: this.chartsColor2,
            stroke: this.chartsColor2,
            xKey: 'SnapshotDate',
            yKey: `Open`,
            yName: 'Open',
            tooltip: {
              renderer: AgGridFunctions.graphTooltipFn
            },
            stacked: true
          }
        ],
        theme: {
          overrides: {
            bar: {
              series: {
                highlightStyle: {
                  item: {
                    fill: this.accentColor,
                    stroke: this.chartsColor1,
                    strokeWidth: 3
                  }
                }
              },
              axes: {
                category: {
                  groupPaddingInner: 0.2
                }
              }
            }
          }
        },
        axes: [
          {
            type: 'category',
            position: 'bottom',
            title: {
              text: ''
            }
          },
          {
            type: 'number',
            position: 'left',
            title: {
              text: ''
            },
            label: {
              format: '~s',
              formatter: (params) => params.formatter(params.value).replace('0y', '0')
            },
            min: 0
          }
        ],
        legend: {
          position: 'bottom'
        }
      })
    })
  }

  setPayerChartOptions() {
    this.graphOptionsList = [];

    const values = JSON.parse(JSON.stringify(this.filterControl.value || []));
    values.push("Grand Total");

    values.forEach(v => {
      const datasubset = this.data.filter(d => d.Payer.Abbreviation == v);

      this.graphOptionsList.push({
        autoSize: true,
        title: {text: `${v} Progress`, fontSize: 12, enabled: true},
        data: datasubset,
        series: [
          {
            type: 'bar',
            fill: this.chartsColor1,
            stroke: this.chartsColor1,
            xKey: 'SnapshotDate',
            yKey: `Closed`,
            yName: 'Closed',
            tooltip: {
              renderer: AgGridFunctions.graphTooltipFn
            },
            stacked: true
          },
          {
            type: 'bar',
            fill: this.chartsColor2,
            stroke: this.chartsColor2,
            xKey: 'SnapshotDate',
            yKey: `Open`,
            yName: 'Open',
            tooltip: {
              renderer: AgGridFunctions.graphTooltipFn
            },
            stacked: true
          }
        ],
        theme: {
          overrides: {
            bar: {
              series: {
                highlightStyle: {
                  item: {
                    fill: this.accentColor,
                    stroke: this.chartsColor1,
                    strokeWidth: 3
                  }
                }
              },
              axes: {
                category: {
                  groupPaddingInner: 0.2
                }
              }
            }
          }
        },
        axes: [
          {
            type: 'category',
            position: 'bottom',
            title: {
              text: ''
            }
          },
          {
            type: 'number',
            position: 'left',
            title: {
              text: ''
            },
            label: {
              format: '~s',
              formatter: (params) => params.formatter(params.value).replace('0y', '0')
            },
            min: 0
          }
        ],
        legend: {
          position: 'bottom'
        }
      })
    })
  }

  onDownload(instance: AgChartInstance) {
    const startDate = this.data.length > 0 ? this.data[0].SnapshotDate : '_';
    const endDate = this.data.length > 1 ? this.data[this.data.length - 1].SnapshotDate : '_';
    const instanceName = instance.getOptions().title.text;
    const filename = `${startDate}-${endDate}_${instanceName}`;

    AgCharts.download(instance, {
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: 'Progress Allocation Snapshot Graph', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: Progress Allocation Snapshot Graph, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });

  }

  onFilterChange() {
    switch(this.options.mode) {
      case 'user':
        this.setUserChartOptions();
        break;

      case 'preferred-action':
        this.setPreferredActionChartOptions();
        break;

      case 'payer':
        this.setPayerChartOptions();
        break;

      default:
        break;
    }
  }

  filterAllOptionClicked(isChecked: boolean) {

    this.select.options.forEach((o, index) => {
      if(index == 0) {
        return;
      }
      
      isChecked ? o.select() : o.deselect();
    });

  }

  filterMulti() {
    if (!this.filterOptions) {
      return;
    }

    let search = this.filterSearchControl?.value;

    if (!search) {
      this.filterOptionsBySearch.next(this.filterOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    const values = this.filterOptions.filter(o => o.toLowerCase().includes(search));
    this.filterOptionsBySearch.next(values);
  }

  setAllToggleState() {
    let filteredLength = 0;
    const listSize = this.filterOptions.length;
    if(this.filterControl?.value) {
      this.filterOptions.forEach(o => {
        if(this.filterControl?.value.indexOf(o) > -1) {
          filteredLength++;
        }
      });

      this.indeterminateToggle = filteredLength > 0 && filteredLength < listSize;
      this.allToggled = filteredLength > 0 && filteredLength === listSize;
    }
  }

}
