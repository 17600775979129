import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { NgStyle, DecimalPipe } from '@angular/common';

export interface IAgSummationPanel {
  updateSum(): void;
  getSum(): number;
  updatePanelWidth(width: number): void;
}

@Component({
    selector: 'app-ag-summation-status-panel',
    templateUrl: './ag-summation-status-panel.component.html',
    styleUrls: ['./ag-summation-status-panel.component.scss'],
    standalone: true,
    imports: [NgStyle, DecimalPipe]
})
export class AgSummationStatusPanelComponent implements IStatusPanelAngularComp {
  params: IStatusPanelParams;
  label: string;
  col: string;
  width: number;

  sum = 0;

  agInit(params: IStatusPanelParams<any, any> & {label: string, col: string, width?: number}): void {
    this.params = params;
    this.label = params.label;
    this.col = params.col || '';
    this.width = params.width;
    this.updateSum();
  }

  updateSum() {
    this.sum = 0;
    this.params.api.forEachNode(n => {
      this.sum += n.data[this.col];
    });
  }

  getSum() {
    return this.sum;
  }

  updatePanelWidth(width: number) {
    this.width = width;
  }

}
