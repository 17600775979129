import { Subscriber } from '../Subscriber';
export function every(predicate, thisArg) {
  return source => source.lift(new EveryOperator(predicate, thisArg, source));
}
class EveryOperator {
  constructor(predicate, thisArg, source) {
    this.predicate = predicate;
    this.thisArg = thisArg;
    this.source = source;
  }
  call(observer, source) {
    return source.subscribe(new EverySubscriber(observer, this.predicate, this.thisArg, this.source));
  }
}
class EverySubscriber extends Subscriber {
  constructor(destination, predicate, thisArg, source) {
    super(destination);
    this.predicate = predicate;
    this.thisArg = thisArg;
    this.source = source;
    this.index = 0;
    this.thisArg = thisArg || this;
  }
  notifyComplete(everyValueMatch) {
    this.destination.next(everyValueMatch);
    this.destination.complete();
  }
  _next(value) {
    let result = false;
    try {
      result = this.predicate.call(this.thisArg, value, this.index++, this.source);
    } catch (err) {
      this.destination.error(err);
      return;
    }
    if (!result) {
      this.notifyComplete(false);
    }
  }
  _complete() {
    this.notifyComplete(true);
  }
}
