import { getState, patchState, signalStore, withMethods, withState } from "@ngrx/signals";
import { PayerConfigItem, PayerRiskAdjustmentPolicy } from "../models/payer-config-item"
import { PayerConfigService } from "../services/payer/payer-config.service";
import { inject } from "@angular/core";
import { PayerConfigQuery } from "../models/payer-config-query";
import { PayerConfigQueryResult } from "../models/payer-config-query-result";
import { map } from "rxjs/operators";

type PayerConfigState = {
    currentPayerItem: PayerConfigItem;
    riskAdjustmentPolicies: PayerRiskAdjustmentPolicy[];
}

const initialState: PayerConfigState = {
    currentPayerItem: new PayerConfigItem(),
    riskAdjustmentPolicies: [],
}

export const PayerConfigStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((store, configService = inject(PayerConfigService)) => ({
        setCurrentPayerItem(input: PayerConfigItem) {
            patchState(store, { currentPayerItem: input });
        },
        setCurrentPayerItemById(id: number) {
            const query = new PayerConfigQuery();
            query.PayerIDs = id + "";
            return configService.getPayerConfigs(query).pipe(
                map((result: PayerConfigQueryResult) => {
                    patchState(store, { currentPayerItem: result.Items[0] });
                })
            )
        },
        setRiskAdjustmentPolicies(): boolean | void {
            const state = getState(store);
            try {
                configService.getPayerRiskPolicies(state.currentPayerItem.PayerID).subscribe((result) => {
                    patchState(store, { riskAdjustmentPolicies: result });
                    if (result.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                });
            } catch (error) {
                throw new Error(`Error getting Risk Adjustment Policy list for payer: ${error}`);
            }
        },
        clearState() {
            patchState(store, initialState);
        },
    })),
);