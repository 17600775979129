@if (configLoaded) {
  <div class="grid-layout">
    <!-- Header Row-->
    <div class="insights-header-container flex">
      <div class="flex basis-1/2 justify-start align-middle pt-2">
        <h3>Insights based on {{serviceYear}} DOS</h3>
      </div>
      <div class="flex basis-1/2 justify-end align-middle">
        <button
          mat-flat-button
          class="insights-customize-btn"
          #configMenuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="configMenu">
          Customize
        </button>
        <mat-menu #configMenu="matMenu" class="config-menu-panel">
          <div class="config-menu-title">
            <h2>Select Components</h2>
          </div>
          @for (item of insightsConfig; track item) {
            <div mat-menu-item>
              <mat-checkbox
                color="primary"
                [(ngModel)]="item.Activated"
              (click)="$event.stopPropagation()">{{ item.Name }}</mat-checkbox>
            </div>
          }
          <div class="config-actions">
            <button mat-flat-button class="secondary-button" (click)="onConfigMenuCancel()">Cancel</button>
            <button mat-flat-button class="primary-button" (click)="onConfigMenuSave()">Save</button>
          </div>
        </mat-menu>
      </div>
    </div>
    <!-- Summary Column -->
    <div class="hcc-info-container">
      @if (summaryActive) {
        <app-hcc-summary-info></app-hcc-summary-info>
      }
      @if (opportunityActive) {
        <app-opportunity-submission-summary></app-opportunity-submission-summary>
      }
      @if (validationActive) {
        <app-validation-summary></app-validation-summary>
      }
    </div>
    <!-- Progress Column -->
    <div class="progress-allocation-container">
      @if (progressUserActive) {
        <mat-card appearance="outlined" class="vc-card mat-elevation-z5">
          <mat-card-header>
            <div class="title-container">
              <h2 class="basis-4/5">Progress: User</h2>
              <div class="flex grow justify-end align-middle items-center">
                @if (reportingEnabled) {
                  <span class="material-symbols-outlined report-icon"
                    [routerLink]="['/reporting']" [queryParams]="{report: SUBMISSION_OPPTY_USER_ALLOCATION_NAME, year: serviceYear}"
                    [matTooltip]="'Generate Report'"
                    [matTooltipClass]="'app-tooltip'"
                  [matTooltipPosition]="'above'">export_notes</span>
                }
                @if (!reportingEnabled) {
                  <span class="material-symbols-outlined report-icon" aria-disabled="true"
                    [matTooltip]="'Contact Admin for Access to Generate Report'"
                    [matTooltipClass]="'app-tooltip'"
                  [matTooltipPosition]="'above'">export_notes</span>
                }
              </div>
            </div>
          </mat-card-header>
          <mat-card-content class="progress-card-content">
            <app-progress-allocation-user [expanded]="true"></app-progress-allocation-user>
          </mat-card-content>
          <div class="progress-card-bottom"></div>
        </mat-card>
      }
      @if (progressPreferredActionActive) {
        <mat-card appearance="outlined" class="vc-card mat-elevation-z5">
          <mat-card-header>
            <div class="title-container">
              <h2 class="basis-4/5">Progress: Preferred Action</h2>
              <div class="flex grow justify-end align-middle items-center">
                @if (reportingEnabled) {
                  <span class="material-symbols-outlined report-icon"
                    [routerLink]="['/reporting']" [queryParams]="{report: SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME, year: serviceYear}"
                    [matTooltip]="'Generate Report'"
                    [matTooltipClass]="'app-tooltip'"
                  [matTooltipPosition]="'above'">export_notes</span>
                }
                @if (!reportingEnabled) {
                  <span class="material-symbols-outlined report-icon" aria-disabled="true"
                    [matTooltip]="'Contact Admin for Access to Generate Report'"
                    [matTooltipClass]="'app-tooltip'"
                  [matTooltipPosition]="'above'">export_notes</span>
                }
              </div>
            </div>
          </mat-card-header>
          <mat-card-content class="progress-card-content">
            <app-progress-allocation-preferred-action [expanded]="true"></app-progress-allocation-preferred-action>
          </mat-card-content>
        </mat-card>
      }
      @if (progressPayorActive) {
        <mat-card appearance="outlined" class="vc-card mat-elevation-z5">
          <mat-card-header>
            <div class="title-container">
              <h2 class="basis-4/5">Progress: Payer</h2>
              <div class="flex grow justify-end align-middle items-center">
                @if (reportingEnabled) {
                  <span class="material-symbols-outlined report-icon"
                    [routerLink]="['/reporting']" [queryParams]="{report: SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME, year: serviceYear}"
                    [matTooltip]="'Generate Report'"
                    [matTooltipClass]="'app-tooltip'"
                  [matTooltipPosition]="'above'">export_notes</span>
                }
                @if (!reportingEnabled) {
                  <span class="material-symbols-outlined report-icon" aria-disabled="true"
                    [matTooltip]="'Contact Admin for Access to Generate Report'"
                    [matTooltipClass]="'app-tooltip'"
                  [matTooltipPosition]="'above'">export_notes</span>
                }
              </div>
            </div>
          </mat-card-header>
          <mat-card-content class="progress-card-content">
            <app-progress-allocation-payer [expanded]="true"></app-progress-allocation-payer>
          </mat-card-content>
          <div class="progress-card-bottom"></div>
        </mat-card>
      }
    </div>
    <div class="charts-column-container">
      @if (cmsTimelineActive) {
        <mat-card appearance="outlined" class="vc-card mat-elevation-z5">
          <mat-card-header>
            <div class="title-container">
              <h2>CMS Schedule</h2>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div class="pt-5">
              <app-cms-timeline
                [queryData]="true"
                [serviceYear]="serviceYear"
              [simpleDisplay]="true"></app-cms-timeline>
            </div>
          </mat-card-content>
        </mat-card>
      }
      @if (documentedDxChartActive) {
        <mat-card appearance="outlined" class="vc-card vc-card-charts mat-elevation-z5">
          <mat-card-header>
            <div class="title-container flex">
              <h2>Documented Dx Events Trend</h2>
            </div>
          </mat-card-header>
          <mat-card-content class="charts-card-content">
            <app-documented-dx-event-graph #dxEventTrendChart [showChartTitle]="false" [queryOnLoad]="true"></app-documented-dx-event-graph>
            @if (lastDataRefreshDate) {
              <label class="data-refresh-label" style="padding: 0 0 0 15px">
                Data Refreshed {{lastDataRefreshDate | date: 'MM/dd/YYYY'}}
              </label>
            }
          </mat-card-content>
        </mat-card>
      }
      @if (hccPatientTrendActive) {
        <mat-card appearance="outlined" class="vc-card vc-card-charts mat-elevation-z5">
          <mat-card-header>
            <div class="title-container flex">
              <h2>HCC Patient Trend</h2>
            </div>
          </mat-card-header>
          <mat-card-content>
            <app-documented-dx-event-graph
              #patientTrendChart
              [showChartTitle]="false"
              [queryOnLoad]="true"
              [yAxisName]="'Patients'"
            [fields]="[{Name: 'HCCPatientCount', DisplayName: 'Patients'}]"></app-documented-dx-event-graph>
            @if (lastDataRefreshDate) {
              <label class="data-refresh-label" style="padding: 0 0 0 15px">
                Data Refreshed {{lastDataRefreshDate | date: 'MM/dd/YYYY'}}
              </label>
            }
          </mat-card-content>
        </mat-card>
      }
    </div>
  </div>
}


