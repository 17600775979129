import { User } from "../models/users";

export class StringFormatter {

    public static FilterObjectToString(field: string, items: any[]) {
        if(!items) {
            return '';
        }

        const filterObjectLabelFields = [
            'Description',
            'Name',
            'Abbreviation'
        ]

        if(filterObjectLabelFields.includes(field)) {
            return items.map(i => {
                if(i.ID == 0) {
                    return 'All'
                }

                return i[field];
            }).toString();
        }

        return items.map(i => i[field]).toString();
    }

    public static FilterObjectToStringWithSingleQuotes(field: string, items: any[]) {
        if(!items) {
            return '';
        }

        return items.map(i => `'${i[field]}'`).toString();
    }

    public static UsersFullNameString(users: User[]) {
        if(!users) {
            return '';
        }

        return users.map(u => {
            if(u.user_id == '0') {
                return 'All';
            }

            if(u.user_id == '-1') {
                return 'Unassigned'
            }

            return u.given_name + ' ' + u.family_name;
        }).toString();
    }

    // e.g. 1563698 becomes 1,563,698
    public static FormatNumberWithCommas(num: number) {
        if (isNaN(num)) return ""; // Handle invalid numbers

        const [integerPart, decimalPart] = num.toString().split("."); // Convert number to string and split on decimal to account for partials

        // Apply comma formatting to the integer part
        const formattedInteger = integerPart
            .split("")
            .reverse()
            .map((digit, index) => (index > 0 && index % 3 === 0 ? digit + "," : digit))
            .reverse()
            .join("");

        return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
      }
}
