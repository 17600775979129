<div class="flex basis-[5%] flex-row">
  <div class="flex flex-auto justify-start items-center content-center">
    <h2>{{reportName}}</h2>
  </div>
</div>

<div class="filter-section grid grid-cols-5 gap-3 p-3">

  @if (serviceYearEnabled) {
    <div>
      <app-payment-year-select
        #serviceYear
        [form]="form"
        [controlName]="'service-year'"
        [label]="'Service Year'"
        [required]="true"
        [minYear]="2020"
        [maxYearAddition]="3"
        [defaultValue]="appServiceYear - serviceYearDefaultSubtract"
        (ready)="onServiceYearReady()"
      (valueChanged)="onServiceYearChanged($event)"></app-payment-year-select>
    </div>
  }

  @if (comparisonYearEnabled) {
    <div>
      <app-payment-year-select
        #comparisonYear
        [form]="form"
        [controlName]="'comparison-year'"
        [label]="'Comparison Year'"
        [required]="true"
        [minYear]="2020"
        [maxYearAddition]="3"
        [defaultValue]="appServiceYear"
        (ready)="onComparisonYearReady()"
      (defaultValueChanged)="onComparisonYearDefaultChange($event)"></app-payment-year-select>
    </div>
  }

  @if (dateRangeEnabled) {
    <div>
      <app-date-range
        #dateRange
        [form]="form"
        [required]="true"
      (ready)="onDateRangeReady()"></app-date-range>
    </div>
  }

  @if (timeIntervalCategoryEnabled) {
    <div>
      <mat-form-field appearance="outline" color="primary" floatLabel="always">
        <mat-label>{{timeIntervalCategoryLabel}}</mat-label>
        <mat-select [formControl]="timeIntervalCategoryControl" required="true">
          <mat-option [value]="1">Daily</mat-option>
          <mat-option [value]="7">Weekly</mat-option>
          <mat-option [value]="30">Monthly</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  }

  @if (userByPermissionEnabled) {
    <div>
      <app-user-search-by-permission
        #assignOpptyList
        [form]="form"
        [label]="'Assigned User'"
        [queryOptions]="true"
        [unassignedEnabled]="false"
        [required]="true"
      (ready)="onUserPermissionFieldReady()"></app-user-search-by-permission>
    </div>
  }

  @if (payersEnabled) {
    <div>
      <app-payer-select
        [form]="form"
        [queryOptions]="true"
        [required]="true"
      (ready)="onPayersFieldReady()"></app-payer-select>
    </div>
  }

  @if (auditActionsEnabled) {
    <div>
      <app-admin-audit-action-select
        [form]="form"
        [queryOnInit]="true"
      (ready)="onAuditActionReady()"></app-admin-audit-action-select>
    </div>
  }

  <div class="pt-3">
    <button mat-flat-button class="primary-button"
      [disabled]="generateDisabled"
      [matTooltip]="generateTooltip"
      [matTooltipClass]="'app-tooltip'"
    (click)="onGenerateReport()">Generate</button>
  </div>

</div>

<mat-divider></mat-divider>

<div class="flex flex-[20_1_auto] flex-col">
  <mat-tab-group class="reports-mat-tab flex flex-auto" [mat-stretch-tabs]="false" (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="Results" class="flex flex-auto">
      <div class="flex flex-auto">
        <!-- Diagnosis Events Summary -->
        @if (reportName == DIAGNOSIS_EVENTS_SUMMARY_NAME) {
          <app-diagnosis-events-summary-report
            class="w-full h-full"
            [serviceYear]="serviceYearValue"
          (setExportEnable)="exportEnabled = $event"></app-diagnosis-events-summary-report>
        }

        @if (reportName == DIAGNOSIS_EVENTS_SUMMARY_TREND_NAME) {
          <app-diagnosis-events-summary-trend-report
            class="w-full h-full"
            (setExportEnable)="exportEnabled = $event;"
          (ready)="checkTrendReportReady()"></app-diagnosis-events-summary-trend-report>
        }

        @if (reportName == SUBMISSION_OPPTY_TREND_NAME) {
          <app-submission-opportunity-trend-report
            class="w-full h-full"
            (setExportEnable)="exportEnabled = $event;"
          (ready)="checkTrendReportReady()"></app-submission-opportunity-trend-report>
        }

        @if (reportName == PAYER_VALIDATION_TREND_NAME) {
          <app-payer-validation-trend-report
            class="w-full h-full"
            (setExportEnable)="exportEnabled = $event;"
          (ready)="checkTrendReportReady()"></app-payer-validation-trend-report>
        }

        @if (reportName == HCC_PER_PATIENT_TREND_NAME) {
          <app-average-hcc-per-patient-trend-report
            class="w-full h-full"
          (setExportEnable)="exportEnabled = $event;"></app-average-hcc-per-patient-trend-report>
        }

        @if (reportName == SUBMISSION_OPPTY_USER_ALLOCATION_NAME) {
          <app-submission-oppty-user-progress-report
            class="w-full h-full"
          (setExportEnable)="exportEnabled = $event;"></app-submission-oppty-user-progress-report>
        }

        @if (reportName == SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME) {
          <app-submission-oppty-payer-progress-report
            class="w-full h-full"
          (setExportEnable)="exportEnabled = $event;"></app-submission-oppty-payer-progress-report>
        }

        @if (reportName == SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME) {
          <app-submission-oppty-preferred-action-progress-report
            class="w-full h-full"
          (setExportEnable)="exportEnabled = $event;"></app-submission-oppty-preferred-action-progress-report>
        }

        @if (reportName == ROOT_CAUSE_TREND_YTD_NAME) {
          <app-root-cause-trend-report
            class="w-full h-full"
            (setExportEnable)="exportEnabled = $event;"
          (ready)="checkTrendReportReady()"></app-root-cause-trend-report>
        }

        @if (reportName == SUBMISSION_OPPTY_HCC_VALUATION_BY_STATUS_NAME) {
          <app-oppty-hcc-valuation-report
            class="w-full h-full"
            [serviceYear]="serviceYearValue"
          (setExportEnable)="exportEnabled = $event"></app-oppty-hcc-valuation-report>
        }

        @if (reportName == HCC_PATIENT_DISTRIBUTION_BY_SERVICE_YEAR_NAME) {
          <app-hcc-patient-distribution-report
            class="w-full h-full"
            [serviceYear]="serviceYearValue"
          (setExportEnable)="exportEnabled = $event"></app-hcc-patient-distribution-report>
        }

        @if (reportName == PREFERRED_ACTION_SNAPSHOT_TREND_NAME) {
          <app-progress-allocation-snapshot-report
            class="w-full h-full"
            [serviceYear]="serviceYearValue"
            [options]="{mode: 'preferred-action'}"
          (setExportEnable)="exportEnabled = $event"></app-progress-allocation-snapshot-report>
        }

        @if (reportName == USER_PROGRESS_SNAPSHOT_TREND_NAME) {
          <app-progress-allocation-snapshot-report
            class="w-full h-full"
            [serviceYear]="serviceYearValue"
            [options]="{mode: 'user'}"
          (setExportEnable)="exportEnabled = $event"></app-progress-allocation-snapshot-report>
        }

        @if (reportName == PAYER_PROGRESS_SNAPSHOT_TREND_NAME) {
          <app-progress-allocation-snapshot-report
            class="w-full h-full"
            [serviceYear]="serviceYearValue"
            [options]="{mode: 'payer'}"
          (setExportEnable)="exportEnabled = $event"></app-progress-allocation-snapshot-report>
        }

        @if (reportName == ORG_ADMIN_AUDIT_LOG_NAME) {
          <app-org-audit-log-report
            class="w-full h-full"
          (setExportEnable)="exportEnabled = $event"></app-org-audit-log-report>
        }

        @if (reportName == OPPORTUNITY_DATA_INGESTION) {
          <app-oppty-data-ingestion-report
            class="w-full h-full"
          (setExportEnable)="exportEnabled = $event"></app-oppty-data-ingestion-report>
        }
      </div>
    </mat-tab>

    @if (graphsEnabled) {
      <mat-tab label="Graphs" class="flex flex-auto">
        <div class="flex flex-auto">
          @if (reportName == DIAGNOSIS_EVENTS_SUMMARY_TREND_NAME) {
            <app-diagnosis-events-summary-trend-graphs></app-diagnosis-events-summary-trend-graphs>
          }
          @if (reportName == SUBMISSION_OPPTY_TREND_NAME) {
            <app-submission-opportunity-trend-graphs></app-submission-opportunity-trend-graphs>
          }
          @if (reportName == PAYER_VALIDATION_TREND_NAME) {
            <app-payer-validation-trend-graphs></app-payer-validation-trend-graphs>
          }
          @if (reportName == HCC_PER_PATIENT_TREND_NAME) {
            <app-average-hcc-per-patient-trend-graphs></app-average-hcc-per-patient-trend-graphs>
          }
          @if (reportName == PREFERRED_ACTION_SNAPSHOT_TREND_NAME) {
            <app-progress-allocation-snapshot-graphs
            [options]="{mode: 'preferred-action'}"></app-progress-allocation-snapshot-graphs>
          }
          @if (reportName == USER_PROGRESS_SNAPSHOT_TREND_NAME) {
            <app-progress-allocation-snapshot-graphs
            [options]="{mode: 'user'}"></app-progress-allocation-snapshot-graphs>
          }
          @if (reportName == PAYER_PROGRESS_SNAPSHOT_TREND_NAME) {
            <app-progress-allocation-snapshot-graphs
            [options]="{mode: 'payer'}"></app-progress-allocation-snapshot-graphs>
          }
          @if (reportName == SUBMISSION_OPPTY_HCC_VALUATION_BY_STATUS_NAME) {
            <app-oppty-hcc-valuation-graphs></app-oppty-hcc-valuation-graphs>
          }
        </div>
      </mat-tab>
    }
  </mat-tab-group>
</div>

@if (exportVisible) {
  <div class="flex flex-auto flex-row justify-end items-end content-end">
    <button mat-flat-button class="primary-button"
      [disabled]="!exportEnabled"
      [matTooltip]="''"
      [matTooltipClass]="'app-tooltip'"
    (click)="onExportClick()">Export</button>
  </div>
}
