<div class="side-menu-page-container">

    <div class="side-menu-page-menu-container flex-auto flex-col">
      <div class="side-menu-page-menu-header flex basis-[5%] flex-row">
        <div class="side-menu-page-menu-title flex justify-start items-center content-center">
          <h2>File Management</h2>
        </div>
      </div>
  
    <app-tree-menu
      [data]="fileManagementMenuOptions"
    (optionChange)="onSelectedOption($event)"></app-tree-menu>
  </div>
  
  @if (selectedOption.ID == 1) {
    <app-data-refresh-config class="side-menu-page-content-container flex flex-col gap-[10px]"></app-data-refresh-config>
  }
  @if (selectedOption.ID == 2) {
    <app-mor-data class="side-menu-page-content-container flex flex-col"></app-mor-data>
  }
  
  </div>