import { Component, ViewChild, OnInit, inject, effect } from '@angular/core';
import {
  GridOptions,
  ITooltipParams,
} from 'ag-grid-community';
import { 
  AgGridAngular,
  AgGridModule 
} from 'ag-grid-angular';
import { ViewIconRendererComponent } from '../../../ag-grid-components/view-icon-renderer/view-icon-renderer.component';
import { AgPaginatorComponent } from '../../../ag-grid-components/ag-paginator/ag-paginator.component';
import { MorDataStore } from '../../../../store/mor-data.store';
import { DatePipe } from '@angular/common';

export class MorDataTableRow {
  id: number;
  uploadDate: Date;
  payerAlias: string;
  uploadedBy: string;
  fileName: string;
  status: string;
}

@Component({
  selector: "app-mor-data-table",
  standalone: true,
  imports: [AgGridModule],
  templateUrl: "./mor-data-table.component.html",
  styleUrl: "./mor-data-table.component.scss",
})
export class MorDataTableComponent implements OnInit {
  readonly store = inject(MorDataStore);
  readonly datePipe = inject(DatePipe);
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  gridOptions: GridOptions = {
    defaultColDef: {
      suppressMovable: true,
      suppressMenu: true
    },
    columnDefs: [
      { 
        field: "uploadDate", 
        headerName: "Upload Date/Time", 
        flex: 1,
        valueFormatter: (params) => { return this.datePipe.transform(params.data?.uploadDate, "MM/dd/yyyy hh:mm a") ?? ""; }
      },
      { field: "payerAlias", headerName: "Payer Alias", flex: 1, suppressMovable: true, suppressMenu: true },
      { field: "uploadedBy", headerName: "Uploaded By", flex: 1, suppressMovable: true, suppressMenu: true },
      {
        field: "fileName",
        headerName: "File Name",
        flex: 2,
        tooltipValueGetter: (params: ITooltipParams) => params.value as string,
      },
      {
        field: "status",
        headerName: "File Status",
        flex: 1,
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 84,
        maxWidth: 84,
        cellRenderer: "viewRenderer",
        sortable: false,
      },
    ],
    statusBar: {
      statusPanels: [
        {
          statusPanel: AgPaginatorComponent,
          key: "ag-paginator",
        },
      ],
    },
    paginationPageSize: 25,
    paginationPageSizeSelector: [25, 50, 100, 150, 300],
  };

  gridComponents = {
    viewRenderer: ViewIconRendererComponent,
  };

  constructor() {
    effect(() => {
      this.agGrid.api.setGridOption("rowData", this.store.tableData());
    });
  }

  ngOnInit() {
    // TODO
  }
}