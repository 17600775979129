@if (actionType == 'add') {
  <h1 mat-dialog-title>Add CMS Schedule</h1>
}
@if (actionType == 'edit') {
  <h1 mat-dialog-title>Edit CMS Schedule</h1>
}

@if (hasDuplicateError) {
  <div class="error-banner">
    <h3>Risk Score Run Type and Run Year combination already exist.</h3>
  </div>
}

<div mat-dialog-content>
  <div class="content-container flex flex-col">
    <div class="flex basis-[5%] flex-row">
      <div class="flex basis-[40%] justify-start items-center content-center">
        <h3>Risk Score Run Year *</h3>
      </div>

      <div class="flex basis-[60%]">
        <app-payment-year-select
          [form]="form"
          [controlName]="yearControlName"
          [label]="''"
          [placeholder]="'Make a Selection'"
          (ready)="isYearReady = true; filtersReadyCheck();"
        (valueChanged)="onYearSelectionChange()"></app-payment-year-select>
      </div>
    </div>

    <div class="flex basis-[5%] flex-row">
      <div class="flex basis-[40%] justify-start items-center content-center">
        <h3>Risk Score Run Type *</h3>
      </div>

      <div class="flex basis-[60%]">
        <app-risk-score-run-type-select
          [form]="form"
          [label]="''"
          [multiple]="false"
          [selectAllEnabled]="false"
          [noneEnabled]="false"
          [placeholder]="'Make a Selection'"
          [controlName]="typeControlName"
          (ready)="isRiskTypeReady = true; filtersReadyCheck();"
        (valueChanged)="onTypeSelectionChange()"></app-risk-score-run-type-select>
      </div>
    </div>

    <div class="flex basis-[5%] flex-row">
      <div class="flex basis-[40%] justify-start items-start content-start">
        <h3>Dates of Service *</h3>
      </div>

      <div class="flex basis-[60%] flex-row">
        <div class="flex basis-[45%]">
          <mat-form-field appearance="outline" color="primary">
            <input matInput [formControl]="dosStartControl" [matDatepicker]="dosPicker" placeholder="Effective Date *" [required]="true">
            <mat-datepicker-toggle matSuffix [for]="dosPicker"></mat-datepicker-toggle>
            <mat-datepicker #dosPicker></mat-datepicker>
            <mat-error>Effective Date is <strong>required</strong>.</mat-error>
            @if (dosStartControl.hasError('required')) {
              <mat-error>Date is <strong>required</strong></mat-error>
            }
          </mat-form-field>
        </div>

        <div class="to-label flex basis-[10%] justify-center items-start content-start">
          <label>to</label>
        </div>

        <div class="flex basis-[45%]">
          <mat-form-field appearance="outline" color="primary">
            <input matInput [formControl]="dosEndControl" [matDatepicker]="dosPicker2" placeholder="End Date">
            <mat-datepicker-toggle matSuffix [for]="dosPicker2"></mat-datepicker-toggle>
            <mat-datepicker #dosPicker2></mat-datepicker>
            <mat-error>End Date is <strong>required</strong>.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="flex basis-[5%] flex-row">
      <div class="flex basis-[40%] justify-start items-start content-start">
        <h3>Deadline for Submission *</h3>
      </div>

      <div class="flex basis-[60%]">
        <div>
          <mat-form-field appearance="outline" color="primary">
            <input matInput [formControl]="deadlineControl" [matDatepicker]="deadlinePicker" placeholder="Enter/Select Date *" [required]="true">
            <mat-datepicker-toggle matSuffix [for]="deadlinePicker"></mat-datepicker-toggle>
            <mat-datepicker #deadlinePicker></mat-datepicker>
            <mat-error>Effective Date is <strong>required</strong>.</mat-error>
            @if (deadlineControl.hasError('required')) {
              <mat-error>Date is <strong>required</strong></mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="flex basis-[5%] flex-row">
      <div class="flex basis-[40%] justify-start items-start content-start">
        <h3>Anticipated Payment Month</h3>
      </div>

      <div class="flex basis-[60%]">
        <app-month-year-select
          [form]="form"
          [controlName]="anticipatedMonthControlName"
          [label]="''"
        [placeholder]="'Enter/Select Date'"></app-month-year-select>
      </div>
    </div>

  </div>
</div>

<mat-dialog-actions>
  @if (actionType == 'edit') {
    <div class="left-aligned-buttons">
      <button
        mat-flat-button
        class="primary-button delete-button"
        (click)="onDeleteClick()">
        Delete
      </button>
    </div>
  }
  <div class="right-aligned-buttons">
    <button mat-flat-button class="secondary-button" (click)="onCancelClick()">Cancel</button>
    @if (actionType == 'edit') {
      <button
        mat-flat-button
        class="primary-button"
        [disabled]="form.invalid"
        (click)="onSaveClick()">
        Save
      </button>
    }

    @if (actionType == 'add') {
      <button
        mat-flat-button
        class="primary-button"
        [disabled]="form.invalid"
        (click)="onSaveClick()">
        Add
      </button>
    }
  </div>
</mat-dialog-actions>
