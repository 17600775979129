<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }

  <mat-select
    #userSelect
    [id]="'user-search-' + controlName"
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? displayTooltip() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [multiple]="multiple"
    [required]="required">

    @if (multiple) {
      <mat-select-trigger>
        @if ((control.value?.length || 0) === 1 && !unassignedOption.Selected) {
          <span>
            {{control.value?.[0].user.given_name + ' ' + control.value?.[0].user.family_name || ''}}
          </span>
        }
        @if ((control.value?.length || 0) === 1 && unassignedOption.Selected) {
          <span>
            {{control.value?.[0].Description || ''}}
          </span>
        }
        @if ((control.value?.length || 0) > 1 && (control.value?.length - displayOptionLengthFeatureSubtract) == userOptions.length) {
          <span>
            All
          </span>
        }
        @if ((control.value?.length || 0) > 1 && (control.value?.length - displayOptionLengthFeatureSubtract) < userOptions.length) {
          <span>
            Multiple
          </span>
        }
        @if ((control.value?.length || 0) > 1) {
          <span>
            ({{getSelectCount()}})
          </span>
        }
      </mat-select-trigger>
    }

    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="search..."
        noEntriesFoundLabel="No Users Found"
        [id]="'filter-' + controlName"
        [formControl]="filterControl"
        [showToggleAllCheckbox]="multiple"
        [toggleAllCheckboxChecked]="allToggled"
        [toggleAllCheckboxIndeterminate]="indeterminateToggle"
      (toggleAll)="allOptionClicked($event)"></ngx-mat-select-search>
    </mat-option>

    @if (unassignedEnabled && (viewEditPermActive || assignPermActive)) {
      <mat-option
        #unassignedItem
        [value]="unassignedOption"
        (click)="unassignedOption.selected = unassignedItem.selected">
        {{unassignedOption.user.given_name}} {{unassignedOption.user.family_name}}
      </mat-option>
    }

    @for (option of filteredOptions | async; track option) {
      <mat-option
        #selectItem
        [value]="option"
        (click)="toggleSelection(selectItem, option)">
        {{option.user.given_name}} {{option.user.family_name}}
      </mat-option>
    }

  </mat-select>

  @if (control.hasError('required')) {
    <mat-error>Selection is <strong>required</strong></mat-error>
  }
</mat-form-field>
