<mat-form-field color="primary" [appearance]="appearance" floatLabel="always">
  @if (label) {
    <mat-label>{{label}}</mat-label>
  }
  <mat-select
    [formControl]="control"
    [placeholder]="placeholder"
    [required]="required"
    (selectionChange)="onSelectionChange()">

    @if (noneEnabled) {
      <mat-option
      [value]="-1">All</mat-option>
    }

    @for (option of options; track option) {
      <mat-option
        [value]="option">
        {{option}}
      </mat-option>
    }

  </mat-select>
  @if (control.hasError('required')) {
    <mat-error>{{label}} is <strong>required</strong></mat-error>
  }
  @if (control.hasError('markedDuplicate')) {
    <mat-error>Make a different selection</mat-error>
  }
</mat-form-field>
