import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, OnInit } from '@angular/core';
import { DataRefreshErrorDetailItem } from '../../models/data-refresh-error-detail-item';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { SchedulesService } from '../../services/settings/schedules.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { OverlayLoadingDirective } from '../../directives/overlay-loading.directive';
import { MatList, MatListItem } from '@angular/material/list';
import { MatDivider } from '@angular/material/divider';
import { MatButton } from '@angular/material/button';
import { DecimalPipe } from '@angular/common';

export class DataRefreshErrorDetailsDialogModel {
  readonly component: ComponentType<any> = DataRefreshErrorDetailsDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  width?: string = '35dvw';
  maxHeight?: string = '70dvh';
  data?: any;

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
    selector: 'app-data-refresh-error-details-dialog',
    templateUrl: './data-refresh-error-details-dialog.component.html',
    styleUrls: ['./data-refresh-error-details-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, OverlayLoadingDirective, MatList, MatListItem, MatDivider, MatDialogActions, MatButton, MatDialogClose, DecimalPipe]
})
export class DataRefreshErrorDetailsDialogComponent implements OnInit {

  items: DataRefreshErrorDetailItem[] = [];

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading: Observable<boolean>;

  constructor
  (
    public dialogRef: MatDialogRef<DataRefreshErrorDetailsDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scheduleService: SchedulesService
  ) { 
    this.loading = this.loadingSubject.asObservable();
  }

  ngOnInit(): void {

    this.loadingSubject.next(true);

    this.scheduleService.getRefreshBatchItemErrorDetails(this.data.RefreshBatchID).subscribe(result => {
      this.items = result;
      this.loadingSubject.next(false);
    });
  }

}
