"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var rxjs_1 = require("rxjs");
/* tslint:enable:max-line-length */
/**
 * @param observables
 * @return {Observable<R>}
 * @method zip
 * @owner Observable
 */
function zipProto() {
  var observables = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    observables[_i] = arguments[_i];
  }
  return this.lift.call(rxjs_1.zip.apply(void 0, [this].concat(observables)));
}
exports.zipProto = zipProto;
