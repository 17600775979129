import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PaymentYearSelectComponent } from '../../../field-components/payment-year-select/payment-year-select.component';
import { SettingsService } from '../../../../services/settings/settings.service';
import { Subscription } from 'rxjs';
import { DiagnosisEventsSummaryReportComponent } from '../reports/diagnosis-events-summary-report/diagnosis-events-summary-report.component';
import { ReportNames } from '../../../../models/report-names';
import { CalculationsService } from '../../../../services/calculations/calculations.service';
import { DiagnosisEventsSummaryTrendReportComponent } from '../reports/diagnosis-events-summary-trend-report/diagnosis-events-summary-trend-report.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup, MatTab } from '@angular/material/tabs';
import { SubmissionOpportunityTrendReportComponent } from '../reports/submission-opportunity-trend-report/submission-opportunity-trend-report.component';
import { PayerValidationTrendReportComponent } from '../reports/payer-validation-trend-report/payer-validation-trend-report.component';
import { UserSearchByPermissionComponent } from '../../../field-components/user-search-by-permission/user-search-by-permission.component';
import { SubmissionOpptyUserProgressReportComponent } from '../reports/submission-oppty-user-progress-report/submission-oppty-user-progress-report.component';
import { StringFormatter } from '../../../../helpers/string-formatter';
import { ActivatedRoute } from '@angular/router';
import { SubmissionOpptyPayerProgressReportComponent } from '../reports/submission-oppty-payer-progress-report/submission-oppty-payer-progress-report.component';
import { SubmissionOpptyPreferredActionProgressReportComponent } from '../reports/submission-oppty-preferred-action-progress-report/submission-oppty-preferred-action-progress-report.component';
import { RootCauseTrendReportComponent } from '../reports/root-cause-trend-report/root-cause-trend-report.component';
import { OpptyHccValuationReportComponent } from '../reports/oppty-hcc-valuation-report/oppty-hcc-valuation-report.component';
import { HccPatientDistributionReportComponent } from '../reports/hcc-patient-distribution-report/hcc-patient-distribution-report.component';
import { ProgressAllocationService } from '../../../../services/progress/progress-allocation.service';
import { ProgressAllocationSnapshotReportComponent } from '../reports/progress-allocation-snapshot-report/progress-allocation-snapshot-report.component';
import { DateRangeComponent } from '../../../field-components/date-range/date-range.component';
import { ProgressAllocationSnapshotRequest } from '../../../../models/progress-allocation-snapshot-request';
import { PayerSelectComponent } from '../../../field-components/payer-select/payer-select.component';
import { OrgAuditLogReportComponent } from '../reports/org-audit-log-report/org-audit-log-report.component';
import { AdminAuditActionSelectComponent } from '../../../field-components/admin-audit-action-select/admin-audit-action-select.component';
import { FilterSelectItem } from '../../../../models/filter-select-item';
import { OpptyDataIngestionReportComponent } from '../reports/oppty-data-ingestion-report/oppty-data-ingestion-report.component';
import { AverageHccPerPatientTrendReportComponent } from '../reports/average-hcc-per-patient-trend-report/average-hcc-per-patient-trend-report.component';
import { AverageHccPerPatientTrendGraphsComponent } from '../graphs/average-hcc-per-patient-trend-graphs/average-hcc-per-patient-trend-graphs.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDivider } from '@angular/material/divider';
import { DiagnosisEventsSummaryTrendGraphsComponent } from '../graphs/diagnosis-events-summary-trend-graphs/diagnosis-events-summary-trend-graphs.component';
import { SubmissionOpportunityTrendGraphsComponent } from '../graphs/submission-opportunity-trend-graphs/submission-opportunity-trend-graphs.component';
import { PayerValidationTrendGraphsComponent } from '../graphs/payer-validation-trend-graphs/payer-validation-trend-graphs.component';
import { ProgressAllocationSnapshotGraphsComponent } from '../graphs/progress-allocation-snapshot-graphs/progress-allocation-snapshot-graphs.component';
import { OpptyHccValuationGraphsComponent } from '../graphs/oppty-hcc-valuation-graphs/oppty-hcc-valuation-graphs.component';

@Component({
    selector: 'app-reports-generation',
    templateUrl: './reports-generation.component.html',
    styleUrls: ['./reports-generation.component.scss'],
    standalone: true,
    imports: [PaymentYearSelectComponent, DateRangeComponent, MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatOption, UserSearchByPermissionComponent, PayerSelectComponent, AdminAuditActionSelectComponent, MatButton, MatTooltip, MatDivider, MatTabGroup, MatTab, DiagnosisEventsSummaryReportComponent, DiagnosisEventsSummaryTrendReportComponent, SubmissionOpportunityTrendReportComponent, PayerValidationTrendReportComponent, AverageHccPerPatientTrendReportComponent, SubmissionOpptyUserProgressReportComponent, SubmissionOpptyPayerProgressReportComponent, SubmissionOpptyPreferredActionProgressReportComponent, RootCauseTrendReportComponent, OpptyHccValuationReportComponent, HccPatientDistributionReportComponent, ProgressAllocationSnapshotReportComponent, OrgAuditLogReportComponent, OpptyDataIngestionReportComponent, DiagnosisEventsSummaryTrendGraphsComponent, SubmissionOpportunityTrendGraphsComponent, PayerValidationTrendGraphsComponent, AverageHccPerPatientTrendGraphsComponent, ProgressAllocationSnapshotGraphsComponent, OpptyHccValuationGraphsComponent]
})
export class ReportsGenerationComponent implements OnInit, AfterViewInit, OnDestroy {

  private _reportName;
  @Input() set reportName(value: string) {
    this._reportName = value;
    //do stuff to load new grids
    this.setEnabledFeatures(value);
  }

  get reportName(): string {
    return this._reportName;
  }

  form: FormGroup;

  DIAGNOSIS_EVENTS_SUMMARY_NAME = ReportNames.DIAGNOSIS_EVENTS_SUMMARY_NAME;
  DIAGNOSIS_EVENTS_SUMMARY_TREND_NAME = ReportNames.DIAGNOSIS_EVENTS_SUMMARY_TREND_NAME;
  SUBMISSION_OPPTY_TREND_NAME = ReportNames.SUBMISSION_OPPTY_TREND_NAME;
  PAYER_VALIDATION_TREND_NAME = ReportNames.PAYER_VALIDATION_TREND_NAME;
  SUBMISSION_OPPTY_USER_ALLOCATION_NAME = ReportNames.SUBMISSION_OPPTY_USER_ALLOCATION_NAME;
  SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME = ReportNames.SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME;
  SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME = ReportNames.SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME;
  ROOT_CAUSE_TREND_YTD_NAME = ReportNames.ROOT_CAUSE_TREND_YTD_NAME;
  SUBMISSION_OPPTY_HCC_VALUATION_BY_STATUS_NAME = ReportNames.SUBMISSION_OPPTY_HCC_VALUATION_BY_STATUS_NAME;
  HCC_PATIENT_DISTRIBUTION_BY_SERVICE_YEAR_NAME = ReportNames.HCC_PATIENT_DISTRIBUTION_BY_SERVICE_YEAR_NAME;
  PREFERRED_ACTION_SNAPSHOT_TREND_NAME = ReportNames.PREFERRED_ACTION_SNAPSHOT_TREND_NAME;
  USER_PROGRESS_SNAPSHOT_TREND_NAME = ReportNames.USER_PROGRESS_SNAPSHOT_TREND_NAME;
  PAYER_PROGRESS_SNAPSHOT_TREND_NAME = ReportNames.PAYER_PROGRESS_SNAPSHOT_TREND_NAME;
  ORG_ADMIN_AUDIT_LOG_NAME = ReportNames.ORG_ADMIN_AUDIT_LOG_NAME;
  OPPORTUNITY_DATA_INGESTION = ReportNames.OPPORTUNITY_DATA_INGESTION;
  HCC_PER_PATIENT_TREND_NAME = ReportNames.HCC_PER_PATIENT_TREND_NAME;

  loadInReports = [];

  appServiceYear = 0;
  generateDisabled = true;
  graphsEnabled = false;
  serviceYearEnabled = false;
  comparisonYearEnabled = false;
  userByPermissionEnabled = false;
  payersEnabled = false;
  dateRangeEnabled = false;
  timeIntervalCategoryEnabled = false;
  auditActionsEnabled = false;

  timeIntervalCategoryLabel = 'Interval';

  isServiceYearReady = false;
  isComparisonYearReady = false;

  serviceYearValue = 2022;
  serviceYearDefaultSubtract = 0;
  generateTooltip = '';
  timeIntervalEndMaxDate = new Date().setDate(new Date().getDate() - 1);

  exportEnabled = false;
  exportVisible = true;

  timeIntervalCategories = [
    'Weeks',
    'Months',
    'Years'
  ];

  enabledFields = [];

  subs: Subscription[] =[];

  timeIntervalCategoryControl: FormControl = new FormControl(7);

  @ViewChild('serviceYear') serviceYearComp: PaymentYearSelectComponent;
  @ViewChild('comparisonYear') comparisonYearComp: PaymentYearSelectComponent;
  @ViewChild(UserSearchByPermissionComponent) usersByPermissionComp: UserSearchByPermissionComponent;
  @ViewChild('dateRange') dateRangeComp: DateRangeComponent;
  @ViewChild(PayerSelectComponent) payerSelectComp: PayerSelectComponent;
  @ViewChild(AdminAuditActionSelectComponent) auditActionSelect: AdminAuditActionSelectComponent;

  @ViewChild(DiagnosisEventsSummaryReportComponent) dxEventSummaryComp: DiagnosisEventsSummaryReportComponent;
  @ViewChild(DiagnosisEventsSummaryTrendReportComponent) dxEventSummaryTrendComp: DiagnosisEventsSummaryTrendReportComponent;
  @ViewChild(SubmissionOpportunityTrendReportComponent) submissionOpptyTrendComp: SubmissionOpportunityTrendReportComponent;
  @ViewChild(PayerValidationTrendReportComponent) validationTrendComp: PayerValidationTrendReportComponent;
  @ViewChild(SubmissionOpptyUserProgressReportComponent) userProgressComp: SubmissionOpptyUserProgressReportComponent;
  @ViewChild(SubmissionOpptyPayerProgressReportComponent) payerProgressComp: SubmissionOpptyPayerProgressReportComponent;
  @ViewChild(SubmissionOpptyPreferredActionProgressReportComponent) paProgressComp: SubmissionOpptyPreferredActionProgressReportComponent;
  @ViewChild(RootCauseTrendReportComponent) rootCauseTrendComp: RootCauseTrendReportComponent;
  @ViewChild(OpptyHccValuationReportComponent) submissionOpptyHCCValuationComp: OpptyHccValuationReportComponent;
  @ViewChild(HccPatientDistributionReportComponent) patientDistributionComp: HccPatientDistributionReportComponent;
  @ViewChild(ProgressAllocationSnapshotReportComponent) allocationSnapshotComp: ProgressAllocationSnapshotReportComponent;
  @ViewChild(OrgAuditLogReportComponent) orgAuditLogComp: OrgAuditLogReportComponent;
  @ViewChild(OpptyDataIngestionReportComponent) ingestionBreakdownComp: OpptyDataIngestionReportComponent;
  @ViewChild(AverageHccPerPatientTrendReportComponent) averageHccPerPatientTrendComp: AverageHccPerPatientTrendReportComponent;
  @ViewChild(AverageHccPerPatientTrendGraphsComponent) averageHccPerPatientGraphComp: AverageHccPerPatientTrendGraphsComponent;

  constructor(
    private settingsService: SettingsService,
    private calculationsService: CalculationsService,
    private allocationService: ProgressAllocationService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({});
    this.loadInReports = [
      this.SUBMISSION_OPPTY_USER_ALLOCATION_NAME,
      this.SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME,
      this.SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME,
      this.ROOT_CAUSE_TREND_YTD_NAME,
      this.SUBMISSION_OPPTY_HCC_VALUATION_BY_STATUS_NAME,
      this.HCC_PATIENT_DISTRIBUTION_BY_SERVICE_YEAR_NAME,
      this.PREFERRED_ACTION_SNAPSHOT_TREND_NAME,
      this.PAYER_PROGRESS_SNAPSHOT_TREND_NAME,
      this.USER_PROGRESS_SNAPSHOT_TREND_NAME,
      this.ORG_ADMIN_AUDIT_LOG_NAME,
      this.HCC_PER_PATIENT_TREND_NAME
    ]
  }

  ngOnInit(): void {
    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      this.appServiceYear = result;
      this.setFilterValues();
    }));

    this.subs.push(this.form.statusChanges.subscribe(s => {
      this.generateDisabled = s != 'VALID';
      this.cd.detectChanges();
    }));
  }

  ngAfterViewInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.calculationsService.hccSummaryCalculationsRange.next(null);
    this.calculationsService.rootCauseTrendCalculations.next(null);
    this.allocationService.preferredActions.next(null);
    this.allocationService.userAllocation.next(null);
    this.allocationService.payerAllocation.next(null);
    this.subs.forEach(s => s.unsubscribe());
  }

  setEnabledFeatures(name: string) {
    this.serviceYearEnabled = false;
    this.comparisonYearEnabled = false;
    this.generateTooltip = `Generate ${name}`;
    this.enabledFields = [];

    switch(name) {
      case this.DIAGNOSIS_EVENTS_SUMMARY_NAME:
      case this.SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME:
      case this.SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME:
      case this.HCC_PATIENT_DISTRIBUTION_BY_SERVICE_YEAR_NAME:
      case this.OPPORTUNITY_DATA_INGESTION:
        this.serviceYearEnabled = true;
        this.enabledFields.push({Name: 'ServiceYear', Ready: false});
        break;

      case this.DIAGNOSIS_EVENTS_SUMMARY_TREND_NAME:
      case this.SUBMISSION_OPPTY_TREND_NAME:
      case this.PAYER_VALIDATION_TREND_NAME:
      case this.HCC_PER_PATIENT_TREND_NAME:
        this.serviceYearDefaultSubtract = 1;
        this.serviceYearEnabled = true;
        this.comparisonYearEnabled = true;
        this.graphsEnabled = true;
        this.enabledFields.push({Name: 'ServiceYear', Ready: false});
        this.enabledFields.push({Name: 'ComparisonYear', Ready: false});
        break;

      case this.ROOT_CAUSE_TREND_YTD_NAME:
        this.serviceYearDefaultSubtract = 1;
        this.serviceYearEnabled = true;
        this.comparisonYearEnabled = true;
        this.enabledFields.push({Name: 'ServiceYear', Ready: false});
        this.enabledFields.push({Name: 'ComparisonYear', Ready: false});
        break;

      case this.SUBMISSION_OPPTY_USER_ALLOCATION_NAME:
        this.serviceYearEnabled = true;
        this.userByPermissionEnabled = true;
        this.enabledFields.push({Name: 'ServiceYear', Ready: false});
        this.enabledFields.push({Name: 'User', Ready: false});
        break;

      case this.PREFERRED_ACTION_SNAPSHOT_TREND_NAME:
        this.serviceYearEnabled = true;
        this.dateRangeEnabled = true;
        this.timeIntervalCategoryEnabled = true;
        this.graphsEnabled = true;
        this.enabledFields.push({Name: 'ServiceYear', Ready: false});
        this.enabledFields.push({Name: 'DateRange', Ready: false});
        this.enabledFields.push({Name: 'TimeInterval', Ready: true});
        break;

      case this.USER_PROGRESS_SNAPSHOT_TREND_NAME:
        this.serviceYearEnabled = true;
        this.dateRangeEnabled = true;
        this.timeIntervalCategoryEnabled = true;
        this.userByPermissionEnabled = true;
        this.graphsEnabled = true;
        this.enabledFields.push({Name: 'ServiceYear', Ready: false});
        this.enabledFields.push({Name: 'DateRange', Ready: false});
        this.enabledFields.push({Name: 'TimeInterval', Ready: true});
        this.enabledFields.push({Name: 'User', Ready: false});
        break;

      case this.PAYER_PROGRESS_SNAPSHOT_TREND_NAME:
        this.serviceYearEnabled = true;
        this.dateRangeEnabled = true;
        this.timeIntervalCategoryEnabled = true;
        this.payersEnabled = true;
        this.graphsEnabled = true;
        this.enabledFields.push({Name: 'ServiceYear', Ready: false});
        this.enabledFields.push({Name: 'DateRange', Ready: false});
        this.enabledFields.push({Name: 'TimeInterval', Ready: true});
        this.enabledFields.push({Name: 'Payer', Ready: false});
        break;

      case this.ORG_ADMIN_AUDIT_LOG_NAME:
        this.dateRangeEnabled = true;
        this.auditActionsEnabled = true;
        this.enabledFields.push({Name: 'DateRange', Ready: false});
        this.enabledFields.push({Name: 'AuditAction', Ready: false});
        break;

      case this.SUBMISSION_OPPTY_HCC_VALUATION_BY_STATUS_NAME:
        this.serviceYearEnabled = true;
        this.enabledFields.push({Name: 'ServiceYear', Ready: false});
        this.graphsEnabled = true;
        break;
        
      default:
        break;
    }
  }

  setFilterValues() {
    switch(this.reportName) {
      case this.DIAGNOSIS_EVENTS_SUMMARY_NAME:
        this.serviceYearValue = this.appServiceYear;
        break;

      default:
        break;
    }
  }

  onGenerateReport() {
    if(this.reportName == this.DIAGNOSIS_EVENTS_SUMMARY_NAME) {
      this.dxEventSummaryComp.updateReport(this.serviceYearValue);
      return;
    }

    if(this.reportName == this.DIAGNOSIS_EVENTS_SUMMARY_TREND_NAME) {
      this.dxEventSummaryTrendComp.updateServiceYearRange(this.serviceYearComp.control.value, this.comparisonYearComp.control.value);
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_TREND_NAME) {
      this.submissionOpptyTrendComp.updateServiceYearRange(this.serviceYearComp.control.value, this.comparisonYearComp.control.value);
      return;
    }

    if(this.reportName == this.PAYER_VALIDATION_TREND_NAME) {
      this.validationTrendComp.updateServiceYearRange(this.serviceYearComp.control.value, this.comparisonYearComp.control.value);
      return;
    }

    if(this.reportName == this.HCC_PER_PATIENT_TREND_NAME) {
      const startYear = this.serviceYearComp.control.value;
      const endYear = this.comparisonYearComp.control.value;
      this.averageHccPerPatientTrendComp.updateServiceYearRange(startYear, endYear);
      this.averageHccPerPatientGraphComp.updateYearRange(startYear, endYear);
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_USER_ALLOCATION_NAME) {
      const assignedToIDs = StringFormatter.FilterObjectToString('user_id', this.usersByPermissionComp.getValues());
      this.userProgressComp.onGenerateGridData(this.serviceYearComp.control.value, assignedToIDs);
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME) {
      this.payerProgressComp.onGenerateGridData(this.serviceYearComp.control.value);
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME) {
      this.paProgressComp.onGenerateGridData(this.serviceYearComp.control.value);
      return;
    }

    if(this.reportName == this.ROOT_CAUSE_TREND_YTD_NAME) {
      this.rootCauseTrendComp.updateServiceYearRange(this.serviceYearComp.control.value, this.comparisonYearComp.control.value);
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_HCC_VALUATION_BY_STATUS_NAME) {
      this.submissionOpptyHCCValuationComp.updateServiceYear(this.serviceYearComp.control.value);
      return;
    }

    if(this.reportName == this.HCC_PATIENT_DISTRIBUTION_BY_SERVICE_YEAR_NAME) {
      this.patientDistributionComp.updateServiceYear(this.serviceYearComp.control.value);
      return;
    }

    if(this.reportName == this.PREFERRED_ACTION_SNAPSHOT_TREND_NAME) {
      const request: ProgressAllocationSnapshotRequest = {
        ServiceYear: this.serviceYearComp.control.value,
        StartDate: this.dateRangeComp.getStartDateValue(),
        EndDate: this.dateRangeComp.getEndDateValue(),
        Interval: this.timeIntervalCategoryControl.value || 7,
        IDs: []
      };

      this.allocationSnapshotComp.onGenerateGridData(request);
      return;
    }

    if(this.reportName == this.USER_PROGRESS_SNAPSHOT_TREND_NAME) {
      const ids = this.usersByPermissionComp.getValues().map(u => {
        if(u.user_id == 0) {
          return;
        }

        return u.user_id
      });

      const request: ProgressAllocationSnapshotRequest = {
        ServiceYear: this.serviceYearComp.control.value,
        StartDate: this.dateRangeComp.getStartDateValue(),
        EndDate: this.dateRangeComp.getEndDateValue(),
        Interval: this.timeIntervalCategoryControl.value || 7,
        IDs: ids
      };

      this.allocationSnapshotComp.onGenerateGridData(request);
      return;
    }

    if(this.reportName == this.PAYER_PROGRESS_SNAPSHOT_TREND_NAME) {
      const ids = this.payerSelectComp.getValues().map(p => {
        if(p.ID == 0) {
          return;
        }

        return p.ID;
      });

      const request: ProgressAllocationSnapshotRequest = {
        ServiceYear: this.serviceYearComp.control.value,
        StartDate: this.dateRangeComp.getStartDateValue(),
        EndDate: this.dateRangeComp.getEndDateValue(),
        Interval: this.timeIntervalCategoryControl.value || 7,
        IDs: ids
      };

      this.allocationSnapshotComp.onGenerateGridData(request);
      return;
    }

    if(this.reportName == this.ORG_ADMIN_AUDIT_LOG_NAME) {
      const values = this.auditActionSelect.getValues() as FilterSelectItem[];
      const ids = values.map(p => {
        if(p.ID == 0) {
          return;
        }

        return p.ID;
      });

      this.orgAuditLogComp.onGenerateGridData(this.dateRangeComp.getStartDateValue(), this.dateRangeComp.getEndDateValue(), ids.toString());
    }
    
    if(this.reportName == this.OPPORTUNITY_DATA_INGESTION) {
      this.ingestionBreakdownComp.getBreakdownData(this.serviceYearComp.control.value);
    }
  }

  onExportClick() {

    if(this.reportName == this.DIAGNOSIS_EVENTS_SUMMARY_NAME) {
      this.dxEventSummaryComp.onExport();
      return;
    }

    if(this.reportName == this.DIAGNOSIS_EVENTS_SUMMARY_TREND_NAME) {
      this.dxEventSummaryTrendComp.onExport();
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_TREND_NAME) {
      this.submissionOpptyTrendComp.onExport();
      return;
    }

    if(this.reportName == this.PAYER_VALIDATION_TREND_NAME) {
      this.validationTrendComp.onExport();
      return;
    }

    if(this.reportName == this.HCC_PER_PATIENT_TREND_NAME) {
      this.averageHccPerPatientTrendComp.onExport();
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_USER_ALLOCATION_NAME) {
      this.userProgressComp.onExport();
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME) {
      this.payerProgressComp.onExport();
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME) {
      this.paProgressComp.onExport();
      return;
    }

    if(this.reportName == this.ROOT_CAUSE_TREND_YTD_NAME) {
      this.rootCauseTrendComp.onExport();
      return;
    }

    if(this.reportName == this.SUBMISSION_OPPTY_HCC_VALUATION_BY_STATUS_NAME) {
      this.submissionOpptyHCCValuationComp.onExport();
      return;
    }

    if(this.reportName == this.HCC_PATIENT_DISTRIBUTION_BY_SERVICE_YEAR_NAME) {
      this.patientDistributionComp.onExport();
      return;
    }

    if(this.reportName == this.PREFERRED_ACTION_SNAPSHOT_TREND_NAME
      || this.reportName == this.USER_PROGRESS_SNAPSHOT_TREND_NAME
      || this.reportName == this.PAYER_PROGRESS_SNAPSHOT_TREND_NAME) {

      this.allocationSnapshotComp.onExport();
    }

    if(this.reportName == this.ORG_ADMIN_AUDIT_LOG_NAME) {
      this.orgAuditLogComp.onExport();
      return;
    }

    if(this.reportName == this.OPPORTUNITY_DATA_INGESTION) {
      this.ingestionBreakdownComp.onExport();
    }
  }

  loadInCheck() {
    const isNotReadyCheck = this.enabledFields.find(f => f.Ready == false);

    if(isNotReadyCheck) {
      return;
    }

    setTimeout(() => {
      this.onGenerateReport();
    }, 600);
  }

  setEnabledFieldReady(fieldname: string, isReady: boolean) {
    const index = this.enabledFields.findIndex(f => f.Name == fieldname);

    if(index >= 0) {
      this.enabledFields[index].Ready = isReady;
    }
  }

  onServiceYearReady() {
    this.isServiceYearReady = true;

    this.setEnabledFieldReady('ServiceYear', true);

    setTimeout(() => {
      this.loadInCheck();
    }, 200);
  }

  onComparisonYearReady() {
    this.isComparisonYearReady = true;
    
    switch(this.reportName) {
      case this.DIAGNOSIS_EVENTS_SUMMARY_NAME:
        break;

      case this.DIAGNOSIS_EVENTS_SUMMARY_TREND_NAME:
      case this.SUBMISSION_OPPTY_TREND_NAME:
      case this.PAYER_VALIDATION_TREND_NAME:
      case this.ROOT_CAUSE_TREND_YTD_NAME:
      case this.HCC_PER_PATIENT_TREND_NAME:
        this.serviceYearComp.label = 'Start Service Year';
        this.comparisonYearComp.label = 'End Service Year';
        break;

      case this.PREFERRED_ACTION_SNAPSHOT_TREND_NAME:
        this.dateRangeComp.label = 'Snapshot Date Range';
        break;

      default:
        break;
    }

    this.setEnabledFieldReady('ComparisonYear', true);
    this.loadInCheck();
  }

  onUserPermissionFieldReady() {
    setTimeout(() => {
      this.usersByPermissionComp.setSelectedValues([]);
      this.setEnabledFieldReady('User', true);
      this.loadInCheck();
    }, 200);
  }

  onPayersFieldReady() {
    setTimeout(() => {
      this.payerSelectComp.setSelectedValues([]);
      this.setEnabledFieldReady('Payer', true);
      this.loadInCheck();
    }, 200);
  }

  onDateRangeReady() {
    if(this.reportName == this.ORG_ADMIN_AUDIT_LOG_NAME) {
      this.dateRangeComp.setEndRangeDecrement(0);
      this.dateRangeComp.setDefaultState(new Date());
    }

    if(this.reportName == this.PREFERRED_ACTION_SNAPSHOT_TREND_NAME
      || this.reportName == this.USER_PROGRESS_SNAPSHOT_TREND_NAME
      || this.reportName == this.PAYER_PROGRESS_SNAPSHOT_TREND_NAME) {
        this.dateRangeComp.startDateMin = new Date('1/29/2024');
    }

    this.setEnabledFieldReady('DateRange', true);
    this.loadInCheck();
  }

  onAuditActionReady() {
    setTimeout(() => {
      this.auditActionSelect.setSelectedValues([]);
    }, 300);

    this.setEnabledFieldReady('AuditAction', true);
    this.loadInCheck();
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.exportVisible = !(event.tab.textLabel == 'Graphs');
  }

  onServiceYearChanged(value: number) {
    this.serviceYearValue = value;
  }

  onComparisonYearDefaultChange(value) {
    if(value > 0) {
      //this.checkTrendReportReady();
    }
  }

  checkTrendReportReady() {
    if(!this.isComparisonYearReady) {
      return;
    }

    if(!this.isServiceYearReady) {
      return;
    }

    this.loadInCheck();
  }

}
