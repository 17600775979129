import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class SystemAdminGuard  {

    constructor(
        private router: Router,
        private userService: UserService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.userService.currentUser$.pipe(
            map((user) => {
                if (user != null && user.app_metadata.systemAdmin == "true") {
                    return true;
                } else {
                    console.log("Unauthorized sending to login page")
                    this.router.navigate(['login']);
                    return false;
                }
            })
        );
    }
}