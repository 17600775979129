import { ComponentType } from '@angular/cdk/portal';
import { AfterViewChecked, AfterViewInit, Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { PayerRiskAdjustmentPolicy, PayerRiskAdjustmentPolicyUpdate } from '../../models/payer-config-item';
import { PreferredActionSelectComponent } from '../../components/field-components/preferred-action-select/preferred-action-select.component';
import { formatDate } from '@angular/common';
import { BooleanSelectComponent } from '../../components/field-components/static/boolean-select/boolean-select.component';
import { MatDatepickerInputEvent, MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { FieldFilterService } from '../../services/filters/field-filter.service';
import { FilterSelectItem } from '../../models/filter-select-item';
import { FilterFieldSearchSelectComponent } from '../../components/field-components/filter-field-search-select/filter-field-search-select.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatHint, MatSuffix, MatError, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { PayerConfigService } from '../../services/payer/payer-config.service';
import { AddUpdatePayerRiskAdjustmentPoliciesRequest } from '../../models/add-update-payer-risk-adjustment-policies-request';
import { PayerConfigStore } from '../../store/payer-config.store';

export class AddUpdateRiskPolicyDialogModel {
  readonly component: ComponentType<any> = AddUpdateRiskPolicyDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  data?: any;
  disableClose? = true;
  maxWidth? = '95dvw';

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
    selector: 'app-add-update-risk-policy-dialog',
    templateUrl: './add-update-risk-policy-dialog.component.html',
    styleUrls: ['./add-update-risk-policy-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatInput, MatDatepickerInput, FormsModule, ReactiveFormsModule, MatHint, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, BooleanSelectComponent, PreferredActionSelectComponent, FilterFieldSearchSelectComponent, MatLabel, MatDialogActions, MatButton, MatDialogClose]
})
export class AddUpdateRiskPolicyDialogComponent implements OnInit, AfterViewChecked {

  form: FormGroup;

  serviceYearStartControl = new FormControl(null, Validators.required);
  serviceYearEndControl = new FormControl(null, Validators.required);
  notesControl = new FormControl('');

  serviceYearStartControlName = 'serviceYearStart';
  serviceYearEndControlName = 'serviceYearEnd';
  hasRiskAdjustmentControlName = 'hasRiskAdjustment';
  hasManualOmitControlName = 'hasManaualOmit';
  preferredActionControlName = 'preferredAction';
  suppFeedTemplateControlName = 'suppFeedTemplateControl';
  payerValidationTemplateControlName = 'pvTemplateControl';

  isServiceYearStartReady = false;
  isServiceYearEndReady = false;
  isRiskAdjustmentReady = false;
  isManualOmitReady = false;
  isPreferredActionReady = false;
  suppFeedTemplateEnabled = false;

  item: PayerRiskAdjustmentPolicy;
  suppFeedTemplateItems: FilterSelectItem[] = [];

  readonly payerConfigStore = inject(PayerConfigStore);

  @ViewChild('riskPolicyPaSelect') paSelect: PreferredActionSelectComponent;
  @ViewChild('riskPolicySelect') riskPolicySelect: BooleanSelectComponent;
  @ViewChild('manualOmitSelect') manualOmitSelect: BooleanSelectComponent;
  @ViewChild('pvTemplate') pvTemplateSelect: FilterFieldSearchSelectComponent<FilterSelectItem>;
  @ViewChild('sfTemplate') sfTemplateSelect: FilterFieldSearchSelectComponent<FilterSelectItem>;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateRiskPolicyDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filterService: FieldFilterService,
    private configService: PayerConfigService,
    private fb: FormBuilder
  ) 
  {
    this.form = this.fb.group({});
    this.form.addControl(this.serviceYearStartControlName, this.serviceYearStartControl);
    this.form.addControl(this.serviceYearEndControlName, this.serviceYearEndControl);

    this.item = this.data.item;
  }

  ngOnInit(): void {
    this.suppFeedTemplateEnabled = this.item?.PreferredActionSubmissionType.ID == 4;

    //Supp Feed Templates
    this.filterService.getExportTemplatesByTypeAndPayer('1').subscribe(result => {
      this.suppFeedTemplateItems = result;
    });

    //Payer Validation Templates
    this.filterService.getExportTemplatesByTypeAndPayer('2').subscribe(result => {
      this.pvTemplateSelect.options = result;
    });
  }

  ngAfterViewChecked(): void {
    if(this.suppFeedTemplateEnabled) {
      this.sfTemplateSelect.options = this.suppFeedTemplateItems;
    }
  }

  fieldsReadyCheck() {
    if(!this.isRiskAdjustmentReady) {
      return;
    }

    if(!this.isManualOmitReady) {
      return;
    }

    if(!this.isPreferredActionReady) {
      return;
    }

    this.setFields();
  }

  setFields() {
    if(this.data.mode == 'edit') {
      this.serviceYearStartControl.setValue(new Date(this.item.ServiceYearStart));
      this.serviceYearEndControl.setValue(new Date(this.item.ServiceYearEnd));
      this.form.get(this.hasRiskAdjustmentControlName).setValue(this.item.HasRiskAdjustment ? "Yes" : "No");
      this.form.get(this.hasManualOmitControlName).setValue(this.item.HasManualOmit ? "Yes" : "No");
      this.paSelect.setSingleModeValue(this.item.PreferredActionSubmissionType);
      this.notesControl.setValue(this.item.Note);

      setTimeout(() => {
        this.pvTemplateSelect.setSingleValueByID(this.item.PayerValidationTemplateID);

        if(this.suppFeedTemplateEnabled) {
          this.sfTemplateSelect.setSingleValueByID(this.item.SuppFeedTemplateID);
        }
      }, 300);
    }
  }

  onRiskPolicyChanged(value: string) {
    if(value == 'No') {
      this.form.get(this.hasManualOmitControlName).setValue('');
      this.form.get(this.preferredActionControlName).setValue('');
    }

    if(value == 'Yes' && !this.form.get(this.hasManualOmitControlName).value) {
      this.form.get(this.hasManualOmitControlName).setValue('No');
    }
  }

  onManualOmitChanged(value: string) {
    if(value == 'Yes') {
      this.form.get(this.preferredActionControlName).setValue('');
    }
  }

  onStartDateChange(event: MatDatepickerInputEvent<Date>) {
    const endValue = this.serviceYearEndControl.value;
    const startValue = event.value;

    if(startValue > endValue) {
      this.serviceYearEndControl.reset();
      this.serviceYearEndControl.markAsTouched();
    }
  }

  onPreferredActionChanged(value: FilterSelectItem) {
    //Check for Supp Feed
    if(value.ID == 4) {
      this.suppFeedTemplateEnabled = true;

      setTimeout(() => {
        this.sfTemplateSelect.options = this.suppFeedTemplateItems;
      }, 300);
    } else {
      this.suppFeedTemplateEnabled = false;
      this.form.removeControl(this.suppFeedTemplateControlName);
    }
  }

  onSaveClick() {
    const newItem = new PayerRiskAdjustmentPolicyUpdate();
    const request: AddUpdatePayerRiskAdjustmentPoliciesRequest = new AddUpdatePayerRiskAdjustmentPoliciesRequest();
    newItem.PayerID = this.payerConfigStore.currentPayerItem().PayerID;
    newItem.PolicyID = this.item ? this.item.PolicyID : 0;
    newItem.ServiceYearStart = formatDate(this.serviceYearStartControl.value, 'M/d/yyyy', 'en');
    newItem.ServiceYearEnd = formatDate(this.serviceYearEndControl.value, 'M/d/yyyy', 'en');
    newItem.HasRiskAdjustment = this.riskPolicySelect.getSelectedValueAsBoolean() ?? false;
    newItem.HasManualOmit = this.manualOmitSelect.getSelectedValueAsBoolean() ?? false;
    newItem.PreferredActionSubmissionTypeID = this.form.get(this.preferredActionControlName)?.value?.ID || null;
    newItem.Note = this.notesControl.value ?? "";
    newItem.PayerValidationTemplateID = this.form.get(this.payerValidationTemplateControlName)?.value?.ID || null;

    if(this.suppFeedTemplateEnabled) {
      newItem.SuppFeedTemplateID = this.form.get(this.suppFeedTemplateControlName)?.value?.ID || null;
    }

    request.RiskPolicies.push(newItem);
    this.configService.addUpdatePayerRiskAdjustmentPolicies(request).subscribe(() => {
      this.dialogRef.close(newItem);
    });
  }

}
