import { SimpleOuterSubscriber, innerSubscribe, SimpleInnerSubscriber } from '../innerSubscribe';
export function exhaust() {
  return source => source.lift(new SwitchFirstOperator());
}
class SwitchFirstOperator {
  call(subscriber, source) {
    return source.subscribe(new SwitchFirstSubscriber(subscriber));
  }
}
class SwitchFirstSubscriber extends SimpleOuterSubscriber {
  constructor(destination) {
    super(destination);
    this.hasCompleted = false;
    this.hasSubscription = false;
  }
  _next(value) {
    if (!this.hasSubscription) {
      this.hasSubscription = true;
      this.add(innerSubscribe(value, new SimpleInnerSubscriber(this)));
    }
  }
  _complete() {
    this.hasCompleted = true;
    if (!this.hasSubscription) {
      this.destination.complete();
    }
  }
  notifyComplete() {
    this.hasSubscription = false;
    if (this.hasCompleted) {
      this.destination.complete();
    }
  }
}
