<div mat-dialog-title class="w-[100%]">
    <div class="w-[80%]">
        <h1>Record Details</h1>
    </div>
    <div class="w-[20%] flex justify-end">
        <button (click)="handleClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <div class="grid grid-cols-8 pt-[1rem] pb-[1rem]">
        <div class="col-span-3 flex flex-row gap-2">
            <span class="mor-dialog-label whitespace-nowrap">
                Upload Date:
            </span>
            <p>
                {{model.uploadDate}}
            </p>
        </div>
        <div class="flex flex-row gap-2">
            <span class="mor-dialog-label whitespace-nowrap">
                Payer:
            </span>
            <p>
                {{model.payerAlias}}
            </p>
        </div>
        <div class="col-span-2 flex flex-row gap-2">
            <span class="mor-dialog-label whitespace-nowrap">
                File Type:
            </span>
            <p>
                {{model.fileType == 'Multiple' ? model.fileType : 'MOR ' + model.fileType}}
            </p>
        </div>
        <div class="col-span-2 flex flex-row gap-2">
            <span class="mor-dialog-label whitespace-nowrap">
                Uploaded By:
            </span>
            <p>
                {{model.uploadedBy}}
            </p>
        </div>
    </div>
    <div class="content-grid-container">
        <ag-grid-angular
            class="ag-theme-balham ag-grid-container"
            [suppressPaginationPanel]="true"
            [pagination]="true"
            [getRowId]="getRowId"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>