"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @param func
 * @return {Observable<R>}
 * @method let
 * @owner Observable
 */
function letProto(func) {
  return func(this);
}
exports.letProto = letProto;
