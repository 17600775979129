import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CalculationsService } from '../../../services/calculations/calculations.service';
import { formatNumber } from '@angular/common';
import { Subscription } from 'rxjs';
import { AgCartesianSeriesOptions, AgChartOptions } from 'ag-charts-community';
import { DiagnosisEventMonthlyTrendItem } from '../../../models/diagnosis-event-monthly-trend-item';
import { UtilityFunctions } from '../../../helpers/utility-functions';
import { AgChartsAngularModule } from 'ag-charts-angular';

export class DxEventMonthlyBreakdownChartItem {
  ServiceYear: number;
  Total: number;
  Jan?: number;
  Feb?: number;
  Mar?: number;
  Apr?: number;
  May?: number;
  Jun?: number;
  Jul?: number;
  Aug?: number;
  Sep?: number;
  Oct?: number;
  Nov?: number;
  Dec?: number;
}

@Component({
    selector: 'app-documented-dx-event-graph',
    templateUrl: './documented-dx-event-graph.component.html',
    styleUrls: ['./documented-dx-event-graph.component.scss'],
    standalone: true,
    imports: [AgChartsAngularModule]
})
export class DocumentedDxEventGraphComponent implements OnInit, OnDestroy {
  @Input() showChartTitle = true;
  @Input() queryOnLoad = false;

  @Input() titleText = 'Documented Diagnosis Events Trend by Service Year';
  @Input() fields = [{Name: 'Total', DisplayName: 'Documented Dx Events'}];
  @Input() yAxisName = 'Documented Dx Events';

  public options: AgChartOptions = {};

  title = {text: '', enabled: true};

  parentDiv = document.getElementById('snav');
  accentColor = '';
  chartsColor1 = '';
  chartsColor2 = '';
  chartsColor3 = '';
  chartsColor4 = '';
  chartsColor5 = '';
  chartsColor6 = '';
  chartsColor7 = '';

  startYear = 2021;
  endYear = new Date().getFullYear();

  subs: Subscription[] = [];

  constructor(private calcService: CalculationsService) {
    if (this.parentDiv) {
      this.chartsColor1 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-1');
      this.chartsColor2 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-2');
      this.chartsColor3 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-3');
      this.chartsColor4 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-4');
      this.chartsColor5 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-5');
      this.chartsColor6 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-6');
      this.chartsColor7 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-7');
      this.accentColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-accent');
    }
    
  }

  ngOnInit(): void {
    if(this.fields[0].DisplayName == 'Patients' && this.queryOnLoad) {
      this.calcService.getHccSummaryCalculationsByRange(this.startYear, this.endYear);
    }

    if(this.fields[0].DisplayName == 'Patients') {
      this.subs.push(this.calcService.hccSummaryCalculationsRange.subscribe(result => {
        this.initializeOptions(result);
      }));

      return;
    }

    this.calcService.getDxMonthlyBreakdownTreand().subscribe(result => {
      const data = this.formatData(result);
      this.initializeOptions(data);
    });
  }

  initializeOptions(data) {
    const series: AgCartesianSeriesOptions[] = [];
    let colorIndex = 0;
    this.title.text = this.titleText;
    this.title.enabled = this.showChartTitle;

    if(this.fields[0].DisplayName == 'Patients') {
      this.fields.forEach(f => {
        series.push(
          {
            type: 'line',
            xKey: 'ServiceYear',
            yKey: f.Name,
            yName: f.DisplayName,
            stroke: this.getLineColor(colorIndex),
            marker: {
              fill: this.getLineColor(colorIndex),
              stroke: this.getLineColor(colorIndex)
            },
            tooltip: {
              renderer: function(params) {
                const contentValue = formatNumber(params.datum[params.yKey], 'en');
                return {
                  content: contentValue,
                  title: params.datum[params.xKey] + ' DOS'
                }
              }
            }
          }
        )
        colorIndex++;
      })
    }

    else

    {
      const field = this.fields[0];

      for(let i = 0; i < 12; i++) {
        const month = this.formatMonth(2000, i);
        series.push(
          {
            type: 'bar',
            stacked: true,
            xKey: 'ServiceYear',
            yKey: month,
            yName: month,
            fill: this.getLineColor(i),
            label: {
              formatter: function(params) {
                return params.datum[params.yKey] ? params.yName : '';
              }
            },
            tooltip: {
              renderer: function(params) {
                const contentValue = formatNumber(params.datum[params.yKey], 'en');
                return {
                  content: contentValue,
                  title: params.yName + ' DOS'
                }
              }
            }
          }
        )
      }
  
      series.push(
        {
          type: 'line',
          xKey: 'ServiceYear',
          yKey: field.Name,
          yName: field.DisplayName,
          stroke: this.getLineColor(colorIndex),
          strokeWidth: 3,
          marker: {
            fill: this.accentColor,
            stroke: this.getLineColor(colorIndex),
            strokeWidth: 3,
            size: 15
          },
          tooltip: {
            renderer: function(params) {
              const contentValue = formatNumber(params.datum[params.yKey], 'en');
              return {
                content: contentValue,
                title: params.datum[params.xKey] + ' DOS Total'
              }
            }
          }
        },
      )
    }

    this.options = {
      autoSize: true,
      title: this.title,
      data: data,
      series: series,
      axes: [
        {
          type: this.fields[0].DisplayName == 'Patients' ? 'number' : 'category',
          position: 'bottom',
          title: {
            text: 'Service Year',
            fontSize: 11,
          }
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: this.yAxisName,
            fontSize: 11,
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter ? params.formatter(params.value).replace('0y', '0') : params.value
          },
          min: 0
        }
      ],
      legend: {
        position: 'bottom',
        enabled: false
      },
      theme: {
        overrides: {
          line: {
            series: {
              highlightStyle: {
                item: {
                  fill: this.accentColor,
                  stroke: this.chartsColor1
                }
              },
            }
          }
        }
      },
    };

    if(this.options.axes && this.fields[0].DisplayName == 'Patients') {
      this.options.axes[0].tick = {
        interval: 1
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this.calcService.hccSummaryCalculationsRange.next(undefined);
  }

  getLineColor(index) {
    let color = '';
    switch(index) {
      case 0:
      case 5:
      case 10:
        color = this.chartsColor1;
        break;
      case 1:
      case 6:
      case 11:
        color = this.chartsColor2;
        break;
      case 2:
      case 7:
      case 12:
        color = this.chartsColor3;
        break;
      case 3:
      case 8:
        color = this.chartsColor4;
        break;
      case 4:
      case 9:
        color = this.chartsColor5;
        break;

      default:
        color = '';
        break;
    }

    return color;
  }


  formatData(data: DiagnosisEventMonthlyTrendItem[]) {
    const result: DxEventMonthlyBreakdownChartItem[] = [];

    const years = UtilityFunctions.distinct(data, "ServiceYear");
    this.startYear = years[0].ServiceYear;
    this.endYear = years[years.length - 1].ServiceYear;

    const map = UtilityFunctions.groupBy(data, i => i.ServiceYear);

    for(const year in map) {
      const data = map[year];
      const item = new DxEventMonthlyBreakdownChartItem();
      item.Total = 0;

      data.forEach(d => {
        item.ServiceYear = d.ServiceYear;
        const month = this.formatMonth(d.ServiceYear, d.ServiceMonth);
        item[month] = d.EventCount;
        item.Total += d.EventCount;
      });
      result.push(item);
    }
    return result;
  }

  formatMonth(year: number, month: number): string {
    return new Date(year, month - 1).toLocaleString('default', {month: 'short'});
  }

}
